import React from 'react';
import './splitTable.css';
import * as STRINGS from '../../strings/english';
import { formatNumbers } from '../../services/formatPhoneNumber';
import {
    Row,
    Col,
} from 'reactstrap';
import { eventEnum, displayModeEnum } from './enum';

function SplitTable(props) {

    const createTableBody = () => {
        let x = props.pagination.currentPage;
        let y = props.pagination.pageCount;
        let max = props.responseToShow.length > (x * y) ? (x * y) : props.responseToShow.length;
        const currentArray = props.responseToShow.slice((x - 1) * y, max);
        let arr = [];
        let colArr = [];
        if (currentArray.length > 0) {
            for (let index = 0; index < currentArray.length; index++) {
                let date = new Date(currentArray[index].StartCall + 'Z').toLocaleString('en-GB');
                let showMonitorButton = currentArray[index].DIDNumber !== 'Monitor';
                arr.push(
                    <div
                        key={currentArray[index].CallId}
                    >
                        <div className='st-table-row' style={props.drawRow(currentArray[index].EventName)}>
                            <div style={{ width: '25%', paddingLeft: '2.8rem' }}>
                                {date.split(',')[1].trim()}
                            </div>
                            <div
                                style={{ width: '39%' }}
                                className='st-table-username-cell'
                            >
                                {currentArray[index].Username}
                            </div>
                            <div style={{ width: '25%' }}>
                                {currentArray[index].Direction === eventEnum.IN ||
                                    currentArray[index].Direction === displayModeEnum.INCOMING ?
                                    formatNumbers(currentArray[index].OriginalCli) :
                                    formatNumbers(currentArray[index].Destination)}

                            </div>
                            {showMonitorButton &&
                                <div
                                    style={{
                                        width: '3px',
                                        position: 'relative',
                                        bottom: '13px',
                                    }}
                                    onClick={() => props.monitorCallbackOnClick(currentArray[index])}
                                >
                                    <div className='rtm-silent-monitor-mouse-over'>
                                        <img className='rtm-silent-monitor-inner-icon' src='assets/silent-monitor-inner-icon.svg' />
                                        <img className='rtm-silent-monitor-icon' src='assets/silent-monitoricon-icon.svg' />
                                    </div>
                                </div>}
                        </div>
                        {(index + 1) % 10 !== 0 && index !== currentArray.length - 1 &&
                            <div className='rtm-table-row-line' />}
                    </div>
                )
                if (arr.length % 10 === 0 || currentArray.length === index + 1) {
                    let col =
                        <Col
                            key={`col-${currentArray[index].CallId}`}
                        >
                            <div className='st-table-main'>
                                <div className='st-table-header'>
                                    <div style={{ width: '21%' }}>{STRINGS.TIME}</div>
                                    <div style={{ width: '42%' }}>{STRINGS.USER}</div>
                                    <div style={{ width: '23%' }}>{STRINGS.CUSTOMER}</div>
                                    <div>{STRINGS.SILENT_MONITOR}</div>
                                </div>
                                {arr.splice(0, index + 1)}
                            </div>
                        </Col>
                    colArr.push(col);
                }
            }
        }
        return colArr;
    }

    return (
        <div>
            <Row xs={'2'}>
                {createTableBody()}
            </Row>
        </div>
    )
}

export default SplitTable;