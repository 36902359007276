import React, { useEffect, useState, useRef } from 'react';
import './report.css';
import { formatNumbers } from '../../services/formatPhoneNumber';
import SecureAudioPlayer from '../../services/audioPlayer/secureAudioPlayer';
import ReportPopover from './reportPopover';
import * as permissionFunc from '../../permissionScheme/permissionFunc';

const ReportTableRow = (props) => {

    const [popoverOpen, setPopoverOpen] = useState(false);
    const onPopoverElementRef = useRef(false);
    const [onPopoverElement, setOnPopoverElement] = useState(false);
    const onNumberElementRef = useRef(false);
    const [onNumberElement, setOnNumberElement] = useState(false);


    useEffect(() => {
        const number = props.cellData.Incoming ?
            props.cellData.Source :
            props.cellData.Destination
        props.setCurrentContactNumber(number);
    }, [props.cellData, popoverOpen])

    useEffect(() => {
        onPopoverElementRef.current = onPopoverElement;
        onNumberElementRef.current = onNumberElement;
    }, [onPopoverElement, onNumberElement])

    const mouseOverToOpenPopover = (callback) => {
        callback(true);
        setPopoverOpen(!props.modal);
    }

    const mouseleaveToOpenPopover = (callback) => {
        callback(false);
        setTimeout(() => {
            if (!onPopoverElementRef.current && !onNumberElementRef.current) {
                setPopoverOpen(false);
            }
        }, 500);
    }

    const cellData = props.cellData;

    const Checkbox = (props) => {
        return <input
            type="checkbox"
            id={`id_${props.id}`}
            defaultChecked={props.checked}
            label={props.label}
            className="aa"
            onChange={props.onChange} />
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <Checkbox
                        style={{ position: "inherit" }}
                        id={cellData.idd}
                        onChange={(e) => props.handleCheckboxChange(e, cellData.idd)}
                        checked={cellData.checked}
                    />
                </td>
                <td>
                    {new Date(cellData.StartCallUTC + 'Z').toLocaleString('en-GB')}
                </td>
                {!props.isSpecificUser ?
                    <td>{cellData.Username}</td> :
                    null}
                <td>
                    {cellData.Incoming ?
                        formatNumbers(cellData.DID) :
                        formatNumbers(cellData.SentCli)}
                </td>
                <td>
                    <div
                        className='table-row-number'
                        id={cellData.Incoming ?
                            `report-contact-number-${props.rowId}` :
                            `report-table-td-source-${props.rowId}`}
                        onMouseOver={cellData.Incoming ?
                            () => mouseOverToOpenPopover(setOnNumberElement) :
                            () => { }}
                        onMouseLeave={cellData.Incoming ?
                            () => mouseleaveToOpenPopover(setOnNumberElement) :
                            () => { }}
                    >
                        {cellData.Incoming && cellData.ContactName ?
                            cellData.ContactName :
                            formatNumbers(cellData.Source)}
                    </div>
                </td>
                <td>
                    <div
                        className='table-row-number'
                        id={!cellData.Incoming ?
                            `report-contact-number-${props.rowId}` :
                            `report-table-td-destination-${props.rowId}`}
                        onMouseOver={!cellData.Incoming ?
                            () => mouseOverToOpenPopover(setOnNumberElement) :
                            () => { }}
                        onMouseLeave={!cellData.Incoming ?
                            () => mouseleaveToOpenPopover(setOnNumberElement) :
                            () => { }}
                    >
                        {!cellData.Incoming && cellData.ContactName ?
                            cellData.ContactName :
                            (!cellData.Destination.includes('&',0) ?
                             formatNumbers(cellData.Destination) :
                             cellData.Destination.replaceAll('&', '\n'))
                            }
                    </div>
                </td>
                <td>
                    {
                    //cellData.Incoming ?
                        formatNumbers(cellData.AnswerBy) 
                        //: ''
                        }
                </td>
                <td>
                    {props.showSecond ?
                        cellData.BilledSeconds :
                        cellData.BilledMinutes}
                </td>
                <td>
                    <img
                        src={`assets/${cellData.Status !== "ANSWER" ? (cellData.Incoming === true ? "missed-call" : "missed-call-outgoing") : cellData.Incoming === true ? "incoming" : "outgoing"}.svg`}
                        alt="direction-icon"
                    />
                </td>
                <td>
                    {cellData.RecordingURL ?
                        <SecureAudioPlayer url={cellData.RecordingURL} /> :
                        null}
                </td>
                <td>
                    {cellData.VoiceMailURL ?
                        <SecureAudioPlayer url={cellData.VoiceMailURL} /> :
                        null}
                </td>
                <td>
                    {props.showTranscriptionIcon && cellData.RecordingURL &&
                        <img
                            className='transcription-icon'
                            src='assets/transcription-icon.svg'
                            onClick={() => {
                                props.getCallTranscription(cellData);
                                props.setCurrentRowData(cellData);
                            }}
                        />}
                </td>
                <td>
                    {!permissionFunc.changeDisplay('call_summary') && props.showSummaryIcon && cellData.RecordingURL &&
                        <img
                            className='transcription-icon'
                            src='assets/transcription-icon.svg'
                            onClick={() => {
                                props.getCallSummary(cellData);
                                props.setCurrentRowData(cellData);
                            }}
                        />}
                </td>
                <td>{cellData.UserName}</td>
            </tr>
            <ReportPopover
                index={props.index}
                currentContactName={cellData.ContactName}
                currentContactNumber={
                    props.cellData.Incoming ?
                        props.cellData.Source :
                        props.cellData.Destination
                }
                newContactModaltoggle={props.newContactModaltoggle}
                mouseOverToOpenPopover={mouseOverToOpenPopover}
                mouseleaveToOpenPopover={mouseleaveToOpenPopover}
                setOnPopoverElement={setOnPopoverElement}
                popoverOpen={popoverOpen}
                setPopoverOpen={setPopoverOpen}
                rowId={props.rowId}
            />
        </React.Fragment>
    )
}

export default ReportTableRow;