import React, { Component } from 'react';
import './index.css';
import {
    Button,
    UncontrolledCollapse,
    Col,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    Modal
} from 'reactstrap';
import axios from '../../axios';
import Packege from './packege.js';
import * as STRINGS from '../../strings/english';
import { EditUserDetailsModal } from './advancedFeatures/editUserDetailsModal';
import { AccessNumberSettingsModal } from './advancedFeatures/accessNumberSettingsModal';
import { UserPhonebookCountryModal } from './advancedFeatures/userPhonebookCountryModal';
import CallerIdentification from './callerIdentification';
import LoginGroups from './loginGroups';
import * as permissionFunc from '../../permissionScheme/permissionFunc';
import SmsReport from './user-sms-report/smsReport';
import { USER_REPORT_ENUM } from '../../enum/index';
import { ModelManagement } from './report-modal/ModelManagement';
import ResetPasswordModal from './resetPasswordModal';

class RowTableList extends Component {
    state = {
        isVisible: false,
        isVisiblePassword: false,
        editUserName: false,
        editName: false,
        editPassword: false,
        email: this.props.elm.UserName,
        fullName: '',
        password: '',
        date: this.props.elm.RegistrationDate,
        isLoader: false,
        deleteUser: false,
        edituserDeailsToggle: false,
        accessNumberSettings: false,
        callerIdentification: false,
        loginGroups: false,
        smsReportToggle: false,
        messageReportToggle: false,
        resetPasswordToggle: false,
        resetPassModalType: '',
    };

    passButton() {
        this.setState({
            isVisiblePassword: !this.state.isVisiblePassword
        })
    }
    viewDetails(userName) {
        this.setState({
            isLoader: true, email: this.props.elm.UserName, fullName: `${this.props.elm.FirstName} ${this.props.elm.LastName}`,
            password: this.props.elm.SitePassword
        })
        axios
            .get(`/Account/GetUserByUsername/${userName}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            })
            .then(response => {
                this.setState({ userPackeges: response.data, date: response.data.RegistrationDate, isLoader: false })
            })
            .catch(error => {
                this.setState({ isLoader: false })
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    this
                        .props
                        .history
                        .push('/login')
                }
            });
    };

    savePackagesChanges() {
        let username = this.userNameValue ? this.userNameValue.value : this.state.email;
        let password = this.passwordValue ? this.passwordValue.value : this.state.password;
        let name = this.nameValue ? this.nameValue.value : this.state.fullName;
        let firstName = '';
        let lastName = '';
        if (this.nameValue) {
            let fullName = this.nameValue.value.split(' ');
            firstName = fullName[0];
            lastName = this.nameValue.value.substr(this.nameValue.value.indexOf(" ") + 1);
        }
        axios.post('/Provisioning/UpdateUserDetails', {
            "username": this.props.elm.UserName,
            "emailaddress": username,
            "password": password !== 'hidden' ? password : '',
            "firstname": this.nameValue ? firstName : this.props.elm.FirstName,
            "lastname": this.nameValue ? lastName : this.props.elm.LastName,
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({
                email: username, fullName: name, password: password,
                editUserName: false,
                editName: false,
                editPassword: false
            }, () => this.props.refresUsers())

        }).catch(error => {
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    };

    updateUsersAfterAddPackage = () => {
        this.setState({ isLoader: true });
        axios.get(`/Account/GetUserByUsername/${this.props.elm.UserName}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        })
            .then(response => {
                this.setState({ userPackeges: response.data, date: response.data.RegistrationDate, isLoader: false })
            })
            .catch(error => {
                this.setState({ isLoader: false })
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    this
                        .props
                        .history
                        .push('/login')
                }
            });
    }

    editUserDetailsOnClick = () => {
        this.setState({ edituserDeailsToggle: !this.state.edituserDeailsToggle });
    }

    accessNumberSettingsOnClick = () => {
        this.setState({ accessNumberSettings: !this.state.accessNumberSettings });
    }

    userPhonebookCountryOnClick = () => {
        this.setState({ userPhonebookCountry: !this.state.userPhonebookCountry });
    }

    callerIdentificationOnClick = () => {
        this.setState({ callerIdentification: !this.state.callerIdentification });
    }

    loginGroupsOnClick = () => {
        this.setState({ loginGroups: !this.state.loginGroups });
    }

    resetByMailOnClick = () => {
        this.setState({
            resetPassModalType: 'resetByEmail',
            resetPasswordToggle: !this.state.resetPasswordToggle
        })
    }

    resetByAdminOnClick = () => {
        this.setState({
            resetPassModalType: 'resetByAdmin',
            resetPasswordToggle: !this.state.resetPasswordToggle
        })
    }

    resetByAdminToggle = () => {
        this.setState({ resetPasswordToggle: !this.state.resetPasswordToggle });
    }

    resetCurrentPass = () => {
        if (window.confirm(STRINGS.ARE_YOU_SURE)) {
            const data = {
                Email: this.props.elm.UserName,
                TempPassword: '',
            };
            axios.post(
                '/Provisioning/ForcePasswordExpiration',
                data,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('auth-data'),
                    }
                }
            ).then(res => {
                if (res.data.Status) {
                    alert(STRINGS.THE_OPERATION_WAS_COMPLETED_SUCCESSFULLY);
                }
            }).catch(error => {
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
    }

    render() {
        let userCanceled = new Date(`${this.props.elm.CancelDate}`).getFullYear() < 9999;
        let userAlreadyCanceled = new Date(this.props.elm.CancelDate) < new Date();
        let hasFalseKeys = permissionFunc.checkDropdownItems('dashboard_more_option');
        return (
            <React.Fragment >
                <tr>
                    <td className="">
                        <div style={{ position: 'relative' }}>
                            <img src='assets/user-default-image.png' alt="" style={{ width: "40px" }} />
                            <div
                                className=""
                                style={{
                                    left: '30px',
                                    top: '30px',
                                    position: 'absolute',
                                    height: '10px',
                                    width: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: '#44d551'
                                }}>
                            </div>
                        </div>


                    </td>

                    <td >
                        <div className="d-flex">
                            {this.state.editUserName

                                ? <input
                                    ref={(userNameValue) => {
                                        this.userNameValue = userNameValue
                                    }}
                                    type='email'
                                    className="border-0"
                                    style={{
                                        outline: 'none'
                                    }}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    value={this.state.email} />
                                : <div className="UserSpikko" >{this.userNameValue && this.userNameValue.value ? this.userNameValue.value : this.props.elm.UserName}
                                    {userCanceled ?
                                        <div className="Will-be-deleted-on-2">
                                            {(userAlreadyCanceled ? STRINGS.WAS_DELETED_ON : STRINGS.WIL_BE_DELETED_ON) + ' ' + this.props.elm.CancelDate}
                                        </div> : null}
                                </div>}

                            {this.state.isVisible && !permissionFunc.changeDisplay('dashboard_edit_user_email')
                                ? <img
                                    onClick={() => this.setState({
                                        editUserName: !this.state.editUserName
                                    })}
                                    className="ml-1 cursorPointer"
                                    style={{
                                        height: 'min-content'
                                    }}
                                    id='dashboard_edit_user_email'
                                    src='assets/edit-2-outline.png'
                                    alt="" />
                                : null}
                        </div>
                    </td>
                    <td>{this.state.editName
                        ? <input
                            ref={(nameValue) => {
                                this.nameValue = nameValue
                            }}
                            type='text'
                            className="border-0"
                            style={{
                                outline: 'none'
                            }}
                            onChange={(e) => this.setState({ fullName: e.target.value })}
                            value={this.state.fullName} />
                        : this.nameValue && this.nameValue.value ? this.nameValue.value : `${this.props.elm.FirstName} ${this.props.elm.LastName}`}{this.state.isVisible
                            ? !permissionFunc.changeDisplay('dashboard_edit_user_full_name') &&
                            <img
                                onClick={() => this.setState({
                                    editName: !this.state.editName
                                })}
                                className="ml-1 cursorPointer"
                                style={{
                                    height: 'min-content'
                                }}
                                src='assets/edit-2-outline.png'
                                id='dashboard_edit_user_full_name'
                                alt="" />
                            : null}</td>
                    <td>{this.props.elm.RegistrationDate}</td>
                    <td>
                        {!permissionFunc.changeDisplay('dashboard_edit_user_password') &&
                            <UncontrolledDropdown
                                direction="down"
                            >
                                <DropdownToggle
                                    color={'white'}
                                    style={{ backgroundColor: 'white' }}
                                >
                                    {STRINGS.RESET_PASSWORD}
                                </DropdownToggle>
                                <DropdownMenu >
                                    <DropdownItem
                                        onClick={this.resetByMailOnClick}
                                    >
                                        {STRINGS.SEND_PASSWORD_RESET_EMAIL}
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.resetByAdminOnClick}
                                    >
                                        {STRINGS.CREATE_A_TEMPORARY_PASSWORD}
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.resetCurrentPass}
                                    >
                                        {STRINGS.EXPIRE_CURRENT_PASSWORD}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                    </td>
                    <td>
                        {!permissionFunc.changeDisplay('dashboard_btn_view_details') &&
                            <React.Fragment>
                                {this.state.isVisible ?
                                    <Button
                                        onClick={() => this.savePackagesChanges()}
                                        color={'white'}
                                        className="btnColor1 mr-2 px-4">Save
                                    </Button>
                                    : null}
                                <Button
                                    onClick={() => {
                                        this.viewDetails(this.props.elm.UserName);
                                        this.setState({
                                            isVisible: !this.state.isVisible,
                                            editUserName: false,
                                            editName: false,
                                            editPassword: false
                                        })
                                    }}
                                    id={`dashboard_btn_view_details-${this.props.index}`}
                                    color={'white'}
                                    style={{
                                        color: this.state.isVisible
                                            ? null
                                            : 'white'
                                    }}
                                    className={` ${this.state.isVisible
                                        ? ' btnColor1'
                                        : ' btnColor2'}`}>
                                    {this.state.isVisible
                                        ? 'Close'
                                        : 'View details'}
                                </Button>
                            </React.Fragment>}
                    </td>
                    <td style={{ borderRight: "1px solid #dee2e6" }}>
                        <UncontrolledDropdown direction="left">
                            <DropdownToggle color={'white'} >{''}
                                <img src='assets/combined-shape-copy-2.svg' className=" m-2" />
                            </DropdownToggle>
                            {!hasFalseKeys &&
                                <DropdownMenu style={{
                                    overflow: 'unset',
                                    maxHeight: '420px'
                                }}
                                >
                                    {!permissionFunc.changeDisplay('dashboard_more_option_edit_user_details') &&
                                        <DropdownItem
                                            id='dashboard_more_option_edit_user_details'
                                            onClick={this.editUserDetailsOnClick}>
                                            <img src='assets/user-details-icon.png' />
                                            {STRINGS.EDIT_USER_DETAILS}
                                        </DropdownItem>}
                                    {!permissionFunc.changeDisplay('dashboard_more_option_call_report') &&
                                        <DropdownItem
                                            id='dashboard_more_option_call_report'
                                            onClick={() => this.props.callReportClicked(this.props.elm)}>
                                            <img src='assets/call-report-new-icon.svg' />
                                            <span style={{ marginLeft: '0.563rem' }}>
                                                {STRINGS.CALL_REPORT}
                                            </span>
                                        </DropdownItem>}
                                    {!permissionFunc.changeDisplay('dashboard_more_option_sms_report') &&
                                        <DropdownItem
                                            id='dashboard_more_option_sms_report'
                                            onClick={() => this.setState({ smsReportToggle: !this.state.smsReportToggle })}>
                                            <img src='assets/sms-report-icon.svg'
                                                id='sms-report-icon' />
                                            <span style={{ marginLeft: '0.563rem' }}>
                                                {STRINGS.SMS_REPORT}
                                            </span>
                                        </DropdownItem>}
                                    {!permissionFunc.changeDisplay('dashboard_btn_users_messages_report') &&
                                        <DropdownItem
                                            id='dashboard_more_option_messages_report'
                                            onClick={() => this.setState({ messageReportToggle: !this.state.messageReportToggle })}>
                                            <img src='assets/iphon-icon.svg'
                                                id='message-report-icon'
                                                className='row-table-messages-report-iphone-icon'
                                            />
                                            <span style={{ marginLeft: '0.563rem' }}>
                                                {STRINGS.MESSAGES_REPORT}
                                            </span>
                                        </DropdownItem>}
                                    {!permissionFunc.changeDisplay('dashboard_more_option_app_access_number') &&
                                        <DropdownItem
                                            id='dashboard_more_option_app_access_number'
                                            onClick={this.accessNumberSettingsOnClick}>
                                            <img src='assets/access-num-icon.png' />
                                            {STRINGS.APP_ACCESS_NUMBER}
                                        </DropdownItem>}
                                    {!permissionFunc.changeDisplay('dashboard_more_option_phone_book_country') &&
                                        <DropdownItem
                                            id='dashboard_more_option_phone_book_country'
                                            onClick={this.userPhonebookCountryOnClick}>
                                            <img src='assets/phone-book-country-icon.png' />
                                            {STRINGS.PHONE_BOOK_COUNTRY}
                                        </DropdownItem>}
                                    {userCanceled && !permissionFunc.changeDisplay('dashboard_more_option_revive_or_delete_user') ?
                                        <DropdownItem
                                            id='dashboard_more_option_revive_or_delete_user'
                                            onClick={() => this.props.reviveUser(this.props.elm.UserName)}>
                                            <img src='assets/revive-user.svg' alt="" className="" />
                                            {STRINGS.REVIVE_USER}
                                        </DropdownItem>
                                        :
                                        <DropdownItem
                                            id='dashboard_more_option_revive_or_delete_user'
                                            onClick={() => this.props.deleteUserClicked(this.props.elm)}>
                                            <img src='assets/icon-trash-copy-9.svg' style={{ paddingBottom: '0.1rem' }} />
                                            {STRINGS.DELETE_USER}
                                        </DropdownItem>}
                                    {!permissionFunc.changeDisplay('dashboard_more_option_show_payments') &&
                                        <DropdownItem
                                            id='dashboard_more_option_show_payments'
                                            onClick={(userName, finalDate) => this.props.paymentsModal(this.state.email, this.state.date)}>
                                            <img src='assets/black-and-white-credit-cards.svg' alt="" />
                                            <span style={{ marginLeft: '0.3rem' }}>{STRINGS.SHOW_PAYMENTS}</span>
                                        </DropdownItem>}
                                    {!permissionFunc.changeDisplay('dashboard_more_option_open_user_page') &&
                                        <DropdownItem
                                            id='dashboard_more_option_open_user_page'
                                            onClick={this.props.openUserPage}>
                                            <img src='assets/open-user-page-icon.svg' alt="open user page" />
                                            <span style={{ marginLeft: '0.5rem' }}>{STRINGS.OPEN_USER_PAGE}</span>
                                        </DropdownItem>}
                                    {!permissionFunc.changeDisplay('dashboard_more_option_edit_caller_ids') &&
                                        <DropdownItem
                                            id='dashboard_more_option_edit_caller_ids'
                                            onClick={() => this.callerIdentificationOnClick()}>
                                            <img src='assets/edit-caller-IDs-icon.svg' alt="" />
                                            <span style={{ marginLeft: '0.563rem' }}>
                                                {STRINGS.EDIT_CALLER_IDS}
                                            </span>
                                        </DropdownItem>}
                                    {!permissionFunc.changeDisplay('dashboard_more_option_edit_login_groups') &&
                                        this.props.isMyPackages &&
                                        <DropdownItem
                                            id='dashboard_more_option_edit_login_groups'
                                            onClick={() => this.loginGroupsOnClick()}>
                                            <img src='assets/call-report-new-icon.svg' alt="" />
                                            <span style={{ marginLeft: '0.563rem' }}>
                                                {STRINGS.EDIT_LOGIN_GROUPS}
                                            </span>
                                        </DropdownItem>}
                                </DropdownMenu>}
                        </UncontrolledDropdown>
                    </td>
                </tr>
                <tr style={this.state.isVisible ? {} : { display: 'none' }}>
                    <td colSpan="100%">
                        {!permissionFunc.changeDisplay('dashboard_btn_view_details') &&
                            <UncontrolledCollapse toggler={`#dashboard_btn_view_details-${this.props.index}`}>
                                {this.state.isLoader ?
                                    <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }}
                                        src='assets/spikkoloaderanimation.svg'
                                        alt=""
                                        className="position-absolute " />
                                    : null}
                                {this.state.userPackeges && this.state.userPackeges.Packages.length > 0
                                    ? <div className={(this.props.showMiniPackage ? 'mini-package-main' : '')}>
                                        {this.state.userPackeges.Packages.map((value, index) => {
                                            if (value.Product[0].ProductType === "Access Number" || value.Product[0].ProductType === "DID") {
                                                return <Packege
                                                    openModal={(userName) => this.props.openModal(userName)}
                                                    userName={this.props.elm.UserName}
                                                    index={index}
                                                    value={value}
                                                    details={value}
                                                    refresh={() => this.viewDetails(this.props.elm.UserName)}
                                                    key={index}
                                                    showMiniPackage={this.props.showMiniPackage}
                                                    packageLength={this.state.userPackeges.Packages.length}
                                                    isAccessNumber={value.Product[0].ProductType === "Access Number"}
                                                    Currency={this.props.Currency}
                                                />
                                            }
                                        })}
                                    </div>
                                    : null}
                                {!permissionFunc.changeDisplay('dashboard_btn_add_package') &&
                                    <Col
                                        className="p-3 mt-2"
                                        id='dashboard_btn_add_package'
                                        style={{ borderRadius: '5px', border: 'dashed 1px #1a253a' }}>
                                        <UncontrolledDropdown direction="up">
                                            <DropdownToggle color={'white'}
                                                onClick={() => this.props.checkPackageType(this.props.elm)}
                                            >
                                                + Add package
                                            </DropdownToggle>
                                            {this.props.openOutgoingPackage &&
                                                <DropdownMenu style={{ overflow: 'unset', width: '10rem' }}>
                                                    <DropdownItem
                                                        className=""
                                                        onClick={(e) => this.props.selectPackageType(e)}>
                                                        {STRINGS.SPIKKO_NUMBER}
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        className=""
                                                        onClick={(e) => this.props.selectPackageType(e)}>
                                                        {STRINGS.OUTGOING_CALLS}
                                                    </DropdownItem>
                                                </DropdownMenu>}
                                        </UncontrolledDropdown>
                                    </Col>}
                            </UncontrolledCollapse>}
                    </td>
                </tr>
                <Modal
                    isOpen={this.state.edituserDeailsToggle}
                    toggle={() => this.setState({ edituserDeailsToggle: !this.state.edituserDeailsToggle })}
                    style={{ width: '26.188rem', marginTop: '18%' }}
                >
                    {<EditUserDetailsModal
                        userDetails={this.props.elm}
                        cancelOnClick={this.editUserDetailsOnClick}
                        refresUsers={this.props.refresUsers}
                    />}
                </Modal>
                <Modal
                    isOpen={this.state.accessNumberSettings}
                    toggle={() => this.setState({ accessNumberSettings: !this.state.accessNumberSettings })}
                    style={{ width: '29.625rem', marginTop: '18%' }}
                >
                    {<AccessNumberSettingsModal
                        userDetails={this.props.elm}
                        cancelOnClick={this.accessNumberSettingsOnClick}
                        refresUsers={this.props.refresUsers}
                    />}
                </Modal>
                <Modal
                    isOpen={this.state.userPhonebookCountry}
                    toggle={() => this.setState({ userPhonebookCountry: !this.state.userPhonebookCountry })}
                    style={{ width: '29.625rem', marginTop: '18%' }}
                >
                    {<UserPhonebookCountryModal
                        userDetails={this.props.elm}
                        cancelOnClick={this.userPhonebookCountryOnClick}
                        refresUsers={this.props.refresUsers}
                    />}
                </Modal>
                <Modal
                    isOpen={this.state.callerIdentification}
                    toggle={() => this.setState({ callerIdentification: !this.state.callerIdentification })}
                    style={{ width: '32rem', marginTop: '10%' }}
                >
                    {<CallerIdentification
                        userDetails={this.props.elm}
                        cancelOnClick={this.callerIdentificationOnClick}
                        refresUsers={this.props.refresUsers}
                    />}
                </Modal>
                <Modal
                    className="loginGroupModal"
                    isOpen={this.state.loginGroups}
                    toggle={() => this.setState({ loginGroups: !this.state.loginGroups })}
                    style={{ width: '32rem', marginTop: '10%' }}
                >
                    {<LoginGroups
                        userDetails={this.props.elm}
                        cancelOnClick={this.loginGroupsOnClick}
                        refresUsers={this.props.refresUsers}
                    />}
                </Modal>
                <Modal
                    isOpen={this.state.smsReportToggle}
                    toggle={() => this.setState({ smsReportToggle: !this.state.smsReportToggle })}
                    style={{ width: '27.125rem', marginTop: '10%' }}
                >
                    <SmsReport
                        isSpecificUser={true}
                        currentUser={this.props.elm}
                    />
                </Modal>
                <Modal
                    isOpen={this.state.messageReportToggle}
                    toggle={() => this.setState({ messageReportToggle: !this.state.messageReportToggle })}
                    style={{ width: '27.125rem', marginTop: '10%' }}
                >
                    <ModelManagement
                        modalType={USER_REPORT_ENUM.MESSAGES_MODAL_TYPE}
                        isSpecificUser={true}
                        currentUser={this.props.elm}
                        headerText={STRINGS.GET_USER_MESSAGES_REPORT}
                    />
                </Modal>
                <Modal
                    isOpen={this.state.resetPasswordToggle}
                    toggle={this.resetByAdminToggle}
                    className='row-table-reset-pass-modal'
                >
                    <ResetPasswordModal
                        userData={this.props.elm}
                        resetByAdminToggle={this.resetByAdminToggle}
                        resetPassModalType={this.state.resetPassModalType}
                    />
                </Modal>
            </React.Fragment>
        )
    }
};

export default RowTableList;