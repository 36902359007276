import React, { useState, useEffect } from 'react';
import './advance.css';
import {
    Button,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    Col,
    Form
} from 'reactstrap';
import * as STRINGS from '../../../strings/english';
import axios from '../../../axios';


export function AccessNumberSettingsModal(props) {

    const [isLoader, setIsLoader] = useState(false);
    const [countriesOpt, setCountriesOpt] = useState([]);
    const [checked, setChecked] = useState(props.userDetails.CustomAccessNumber.AccessNumberSelectionType);
    const [selectedCountry, setSelectedCountry] = useState();

    useEffect(() => {
        axios.get('/Utils/GetAccessNumberForRegion/all', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (response.status === 200) {
                setUserCurrentCountry(response.data);
                setCountriesOptions(response.data);
            }
        }).catch(e => {
            if (e.response && e.response.data) {
                alert(STRINGS.SERVER_ERROR + ': ' + e.response.data);
            }
        });
    }, [])

    const setUserCurrentCountry = (data) => {
        let currentCountry = data.find(country => country.DIDNumber === props.userDetails.CustomAccessNumber.CurrentAccessNumber);
        if (currentCountry) {
            setSelectedCountry(currentCountry);
        }
    }

    const setCountriesOptions = (data) => {
        let optionsArr = [<option key={0}>{`${STRINGS.CHOOSE_ACCESS_NUMBER}...`}</option>];
        data.map((elm, index) => {
            let option =
                <option
                    key={index + 1}
                    value={`${elm.CountryName} - ${elm.RegionName} (${elm.DIDShortNumber})`}
                    data-value={JSON.stringify(elm)}
                >
                    {`${elm.CountryName} - ${elm.RegionName} (${elm.DIDShortNumber})`}
                </option>
            optionsArr.push(option);
        })
        setCountriesOpt(optionsArr);
    }

    const saveChangeOnClick = () => {
        if (selectedCountry) {
            setIsLoader(true);
            let body = {
                "accountid": props.userDetails.AccountId,
                "selectiontype": checked,
                "customnumber": selectedCountry.DIDNumber
            }
            axios.post('/Account/SetAccountCustomAccessNumber/', body, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                if (response.status === 200) {
                    setIsLoader(false);
                    alert(STRINGS.THE_CHANGES_HAVE_BEEN_SAVED);
                    props.refresUsers();
                    props.cancelOnClick();
                }
            }).catch(e => {
                if (e.response && e.response.data) {
                    alert(STRINGS.SERVER_ERROR + ': ' + e.response.data);
                }
            });
        }
        else alert(STRINGS.PLEASE_SELECT_A_COUNTRY);
    }

    const onSelectChange = (event) => {
        let strObj = event.target[event.target.selectedIndex].getAttribute('data-value');
        if (strObj) {
            let currentSelectedCountry = JSON.parse(strObj);
            setSelectedCountry(currentSelectedCountry);
        }
    }

    const checkboxOnChange = (e) => {
        let checkedNum = e.target.checked ? 1 : 0;
        setChecked(checkedNum)
    }

    return (
        <React.Fragment>
            <ModalHeader>
                <span className='af-modal-header'>{`${STRINGS.ACCESS_NUMBER_SETTINGS}`}</span>
                <span className='af-modal-header-brackets'>{` (${STRINGS.FOR_SPIKKO_APP})`}</span>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                id='check-1'
                                checked={checked}
                                onChange={checkboxOnChange}
                            />{' '}
                            {STRINGS.OVERRIDE_USER_MANUAL_AUTOMATIC_SELECTION}
                        </Label>
                    </FormGroup>
                    <FormGroup row>
                        <Col className='mt-2' sm={12}>
                            <Input
                                type="select"
                                className={selectedCountry ? 'country-select-selected ' : 'country-select'}
                                id='country-select-1'
                                onChange={onSelectChange}
                                value={selectedCountry &&
                                    `${selectedCountry.CountryName} - ${selectedCountry.RegionName} (${selectedCountry.DIDShortNumber})`}
                            >
                                {countriesOpt}
                            </Input>
                        </Col>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    className='col-4 fs-14'
                    style={{ backgroundColor: '#ebebeb' }}
                    color={'white'}
                    onClick={props.cancelOnClick}
                >
                    {STRINGS.CANCEL}
                </Button>
                <Button
                    className='col-4 fs-14 btnColor2 btn btn-white'
                    style={{ color: 'white' }}
                    color={'white'}
                    onClick={saveChangeOnClick}
                >
                    {STRINGS.SAVE_CHANGES}
                </Button>
            </ModalFooter>
            {isLoader
                ? <img
                    style={{
                        width: '60px',
                        height: '50px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                    src='assets/spikkoloaderanimation.svg' />
                : null}
        </React.Fragment>
    )
}