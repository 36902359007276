import React, { useEffect, useState } from 'react';
import './diceDisplay.css';
import { findDuplicatesId } from '../../services/tools';
import { DiceDisplayBox } from './diceDisplayBox';


const DiceDisplay = (props) => {

    const [calls, setCalls] = useState([]);

    useEffect(() => {
        let arr = findDuplicatesId(props.responseToShow, 'UserId');
        let x = props.pagination.currentPage;
        let y = props.pagination.pageCount;
        let max = arr.length > (x * y) ? (x * y) : arr.length;
        const currentArray = arr.slice((x - 1) * y, max);
        setCalls(currentArray);
    }, [props.responseToShow, props.pagination])


    return (
        <div className='dice-dislay-main'>
            {calls.map((elm, i) => {
                return (
                    <DiceDisplayBox
                        key={`ddb-${i}`}
                        callDetails={elm}
                        responseToShow={props.responseToShow}
                    />
                )
            })}
        </div>
    )
}

export default DiceDisplay;