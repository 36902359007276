import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './index.css';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import store from './reducer.js';
import {Provider} from 'react-redux';
import { IntlProvider } from 'react-intl';


ReactDOM.render(
    <Provider store={store}>
    <BrowserRouter>
    <IntlProvider locale="en">
        <App/>
    </IntlProvider>
    </BrowserRouter>
</Provider>, document.getElementById('root'));
