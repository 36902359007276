import React, { useState, useEffect } from 'react';
import { stripAllNonNumericCharacter } from '../services/formatPhoneNumber';

function Search(props) {

    const [search, setSearch] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchData, setSearchData] = useState(props.data);
    const [finishFilter, setFinishFilter] = useState(false);

    const keysOfNumbers = [
        'DID',
        'Destination',
        'Source',
    ]

    useEffect(() => {
        if (search) {
            let searchResult = props.data.filter((item) => {
                let exist = false
                for (let i = 0; i < props.keys.length; i++) {
                    let searchWord = keysOfNumbers.includes(props.keys[i]) ?
                        stripAllNonNumericCharacter(searchValue) :
                        searchValue;
                    if (item[props.keys[i]].includes(`${searchWord}`)) {
                        exist = true
                    }
                }
                if (exist) {
                    return item
                }
            })
            setSearchData(searchResult)
            setSearch(false)
            setFinishFilter(true)

        }

        if (finishFilter) {
            if (searchData.length > 0) {
                props.action(searchData)
            } else if (searchValue.length === 0) {
                props.action(props.data)
            } else {
                props.action([])
            }
            setFinishFilter(false)

        }
    });

    return (

        <div className='border-5 form-control'>
            <input
                onChange={(e) => {
                    setSearch(true)
                    setSearchValue(e.target.value)
                }}
                style={{ outline: 'none' }}
                name="searchButton"
                id="search"
                type='text'
                placeholder={`Search`}
                className='border-0 col-11 h-100 px-0 w-100' />
            <label
                htmlFor="search"
                className='position-absolute cursorPointer'
                onClick={() => {
                    setSearch(true)
                }}>
                <img src='assets/search-outline.svg' alt="" />
            </label>
        </div>

    )
};

export default Search