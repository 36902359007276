import React, { Component } from 'react';
import './index.css';
import { Button, Col, Row, Card, CardBody, Label, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from '../../axios';
import AdvancedDropdown from './advancedFeatures/advancedDropdown.js'
import { MiniPackage } from './miniPackage';
import {
    returnInternationalNumber,
    formatInputValueWithout,
    convertFormatNumToRegularNum
} from '../../services/formatPhoneNumber';
import * as STRINGS from '../../strings/english';
import * as permissionFunc from '../../permissionScheme/permissionFunc';

class Packege extends Component {

    state = {
        addNote: false,
        editLineName: false,
        editUserName: false,
        editName: false,
        editPassword: false,
        editNumber: false,
        isRenew: this.props.value.Status,
        callRecordingSwitch: this.props.value.RecordingFeature.IsEnabled,
        SmsRecievingSwitch: this.props.value.IncomingSMSFeature.IsEnabled,
        editDate: false,
        startDate: this.getNextTenDate(),
        changeDate: false,
        textArea: this.props.value.FreeText || null,
        textareaSaved: this.props.value.FreeText || null,
        isDashboardLoader: false,
        line: this.props.value.Product[0].Freetext,
        forwardNumber: this.props.value.Product[0].Divert,
        finalDate: this.props.value.FinalDate,
        changeBackToFinlaExpDateNotSet: false,
    }

    finlaExpDateNotSet = '12/31/9999';

    handleChange(date) {
        this.setState({
            startDate: date,
            editDate: false,
            changeDate: true,
            changeBackToFinlaExpDateNotSet: false,
        });
    }
    getNextTenDate() {
        let a = new Date()
        let month = a.getMonth();
        let day = a.getDate();
        if (day < 10) {
            a.setDate(10)
        }
        else if (day >= 10) {
            a.setMonth(month + 1)
            a.setDate(10)
        }
        return a
    }
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    renewNow() {
        axios.post('/Payment/PayForExistingPackage', {
            "packageregistrationid": this.props.value.PackageRegistrationID,
            "username": this.props.userName
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({ isRenew: !this.state.isRenew })

        }).catch(error => {
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    }

    saveChanges() {
        if (this.state.callRecordingSwitch !== this.props.value.RecordingFeature.IsEnabled) {
            this.setState({ isDashboardLoader: true })
            axios.post(`/Feature/SetFeatureOptions/${this.props.value.PackageRegistrationID}/Recording`, {
                "IsEnabled": this.state.callRecordingSwitch
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ isDashboardLoader: false })
            }).catch(error => {
                this.setState({ isDashboardLoader: false })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        if (this.state.SmsRecievingSwitch !== this.props.value.IncomingSMSFeature.IsEnabled) {
            this.setState({ isDashboardLoader: true })
            axios.post(`/Feature/SetFeatureOptions/${this.props.value.PackageRegistrationID}/IncomingSMS`, {
                "IsEnabled": this.state.SmsRecievingSwitch
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ isDashboardLoader: false })
            }).catch(error => {
                this.setState({ isDashboardLoader: false })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        if (this.forwardNumber) {
            this.setState({ isDashboardLoader: true })
            axios.post('/Package/SetDivertNumber', {
                "packageregistrationid": this.props.value.PackageRegistrationID,
                "productregistrationid": this.props.value.Product[0].ProductID,
                "phonenumber": convertFormatNumToRegularNum(this.forwardNumber.value)
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ isDashboardLoader: false, editNumber: false })
            }).catch(error => {
                this.setState({ isDashboardLoader: false })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        if (this.state.changeDate || this.state.changeBackToFinlaExpDateNotSet) { // this.state.editDate
            this.setState({ isDashboardLoader: true })
            axios.post('/Package/SetPackageFinalDate', {
                "packageregistrationid": this.props.value.PackageRegistrationID,
                "finaldate": this.state.changeBackToFinlaExpDateNotSet ?
                    this.finlaExpDateNotSet : this.formatDate(this.state.startDate),
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ isDashboardLoader: false })
            }).catch(error => {
                this.setState({ isDashboardLoader: false })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        if (this.lineName) {
            this.setState({ isDashboardLoader: true })
            axios.post('/Package/PostLineName', {
                "packageregistrationid": this.props.value.PackageRegistrationID,
                "linename": this.lineName.value
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ isDashboardLoader: false, editLineName: false })
            }).catch(error => {
                this.setState({ isDashboardLoader: false })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        if (this.state.textArea) {
            this.setState({ isDashboardLoader: true })
            axios.post('/Package/UpdatePackageNote', {
                "packageregistrationid": this.props.value.PackageRegistrationID,
                "note": this.state.textArea
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ isDashboardLoader: false, textareaSaved: this.state.textArea, addNote: false })
            }).catch(error => {
                this.setState({ isDashboardLoader: false })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
    }

    cancelFinalExpDate = () => {
        this.setState({
            finalDate: this.finlaExpDateNotSet,
            editDate: false,
            changeDate: false,
            changeBackToFinlaExpDateNotSet: true,
        });
    }

    render() {
        let localNumber = returnInternationalNumber(this.props.value.Product[0].DID, this.props.value.Product[0].DIDLocationISO);
        let divertNumber = returnInternationalNumber(this.props.value.Product[0].Divert, this.props.value.Product[0].DivertLocationISO);
        let rowCss = this.state.isRenew === 'Enable' ?
            {
                enable: true,
                src: "assets/arrow-right.png",
                color: '',
                backgroundColor: 'rgba(68, 213, 81, 0.06)',
                className: 'btnColor1',
                border: 'solid 1px #44d551'
            } :
            {
                enable: false,
                src: "assets/arrow-right2.png",
                color: 'white',
                backgroundColor: 'rgba(109, 115, 129, 0.06)',
                className: 'btnColor2',
                border: 'solid 1px #6d7381'
            }


        return (
            <div>
                {!this.props.showMiniPackage ?
                    <Card>
                        <CardBody>
                            {this.state.isDashboardLoader ?
                                <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }}
                                    src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                                : null}
                            <Card
                                style={{
                                    backgroundColor: !this.props.isAccessNumber ? '#efefef' : '#dadada'
                                }}>
                                <CardBody>
                                    <div className='d-flex'>
                                        <div>
                                            <img src="assets/package.png" alt="" />
                                            <span
                                                className="font-weight-bold ml-2"
                                            >
                                                {!this.props.isAccessNumber ? `${STRINGS.PACKEGE}:` : STRINGS.OUTGOING_CALLS}
                                            </span>
                                            {localNumber} &nbsp;
                                            {!this.props.isAccessNumber ? <React.Fragment>
                                                ({this.state.editLineName ?
                                                    <input
                                                        type="text"
                                                        ref={(lineName) => {
                                                            this.lineName = lineName
                                                        }}
                                                        className="border-0"
                                                        style={{
                                                            outline: 'none'
                                                        }}
                                                        onChange={(e) => this.setState({ line: e.target.value })}
                                                        value={this.state.line} /> :
                                                    this.state.line && this.state.line.length > 0 ?
                                                        this.state.line : STRINGS.ADD_LINE_NAME}
                                                {!permissionFunc.changeDisplay('package_btn_add_line_name') &&
                                                    <img
                                                        onClick={() => this.setState({ editLineName: !this.state.editLineName })}
                                                        className="ml-1 cursorPointer"
                                                        style={{
                                                            height: 'min-content'
                                                        }}
                                                        src='assets/edit-2-outline.png'
                                                        id="package_btn_add_line_name"
                                                    />})
                                            </React.Fragment> : null}
                                        </div>
                                        <div className='ml-auto font-weight-bold'>
                                            {!permissionFunc.changeDisplay('package_row_packageaccountprice') && 
                                            <span className='mr-2'>{`${this.props.value.PackageAccountPrice} ${this.props.Currency}`}</span>}
                                            {!permissionFunc.changeDisplay('package_btn_renew_now') &&
                                                <Button
                                                    disabled={rowCss.enable}
                                                    onClick={this.renewNow}
                                                    style={{ color: rowCss.color }}
                                                    color={''}
                                                    className={` ${rowCss.className}`}
                                                    id='package_btn_renew_now'>
                                                    {STRINGS.RENEW_NOW}
                                                </Button>}
                                            {!permissionFunc.changeDisplay('package_btn_save') &&
                                                <Button
                                                    onClick={() => this.saveChanges()}
                                                    color={'white'}
                                                    className="border btnColor1"
                                                    id='package_btn_save'>
                                                    {STRINGS.SAVE}
                                                </Button>}
                                        </div>
                                    </div>
                                    {!this.props.isAccessNumber &&
                                        <Col
                                            style={{
                                                borderRadius: '5px',
                                                border: rowCss.border,
                                                backgroundColor: rowCss.backgroundColor
                                            }}
                                            className="d-flex my-3 py-3 justify-content-center"
                                        >
                                            <div className="mr-4">
                                                <Label style={{ color: 'rgba(60, 63, 74, 0.49)' }}>
                                                    {STRINGS.LOCAL_NUMBER}
                                                </Label>
                                                <div className="font-weight-bold">{localNumber}</div>
                                            </div>
                                            <img style={{ height: 'min-content' }} src={rowCss.src} alt="" />
                                            <div className="ml-4">
                                                <Label style={{ color: 'rgba(60, 63, 74, 0.49)' }}>
                                                    {`${STRINGS.FORWARD_TO}:`}
                                                </Label>
                                                <div className="font-weight-bold">
                                                    {this.state.editNumber ?
                                                        <input
                                                            type="text"
                                                            ref={(forwardNumber) => { this.forwardNumber = forwardNumber }}
                                                            className="border-0"
                                                            style={{ outline: 'none' }}
                                                            onChange={(e) => this.setState({ forwardNumber: e.target.value })}
                                                            value={formatInputValueWithout(this.state.forwardNumber)}
                                                        />
                                                        : this.forwardNumber && this.forwardNumber.value && this.forwardNumber.value.length > 0 ?
                                                            returnInternationalNumber(this.forwardNumber.value, this.props.value.Product[0].DivertLocationISO) :
                                                            divertNumber}
                                                    {!permissionFunc.changeDisplay('package_btn_edit_forward_to') &&
                                                        <img
                                                            onClick={() => this.setState({ editNumber: !this.state.editNumber })}
                                                            className="ml-1 cursorPointer"
                                                            style={{ height: 'min-content' }}
                                                            src='assets/edit-2-outline.png'
                                                            id="package_btn_edit_forward_to" />}
                                                </div>
                                            </div>
                                        </Col>}
                                    <Row style={{ marginTop: '36px' }}>
                                        <Col>
                                            <Label className="gray">Status</Label>
                                            <div>{this.props.value.Status}</div>
                                        </Col>
                                        {(this.state.finalDate !== this.finlaExpDateNotSet ||
                                            !permissionFunc.changeDisplay('package_final_expiration_date')) &&
                                            <Col id='package_final_expiration_date'>
                                                <Label className="gray">{STRINGS.FINAL_EXPIRATION_DATE}</Label>
                                                <div>
                                                    {this.state.editDate ?
                                                        <DatePicker
                                                            selected={this.state.startDate}
                                                            onChange={(date) => this.handleChange(date)}
                                                        /> :
                                                        this.state.finalDate !== this.finlaExpDateNotSet
                                                            ? !this.state.changeDate ?
                                                                this.state.finalDate :
                                                                this.formatDate(this.state.startDate) : ''}
                                                    {this.state.finalDate !== this.finlaExpDateNotSet ?
                                                        rowCss.enable ?
                                                            <React.Fragment>
                                                                <img
                                                                    onClick={() => this.setState({ editDate: !this.state.editDate })}
                                                                    className="ml-1 cursorPointer"
                                                                    style={{ height: 'min-content' }}
                                                                    src='assets/edit-2-outline.png'
                                                                    alt=""
                                                                />
                                                                {this.state.editDate &&
                                                                    <Button
                                                                        close
                                                                        onClick={this.cancelFinalExpDate}
                                                                    />}
                                                            </React.Fragment> :
                                                            <div>
                                                                <span className="cursorPointer"
                                                                    onClick={() => this.renewNow()}
                                                                    style={{ color: '#2EC2FE' }}>
                                                                    {STRINGS.RENEW_NOW}
                                                                </span>
                                                            </div>
                                                        : <span id='package_btn_call_recording'>
                                                            {this.state.changeDate && !this.state.editDate ?
                                                                this.formatDate(this.state.startDate) :
                                                                !this.state.editDate ?
                                                                    STRINGS.EXPIRY_DATE_NOT_SET : ''}
                                                            <img
                                                                onClick={() => this.setState({ editDate: !this.state.editDate })}
                                                                className="ml-1 cursorPointer"
                                                                style={{ height: 'min-content' }}
                                                                src='assets/edit-2-outline.png'
                                                                alt=""
                                                            />
                                                            {this.state.editDate &&
                                                                <Button
                                                                    close
                                                                    onClick={this.cancelFinalExpDate}
                                                                />}
                                                        </span>}
                                                </div>
                                            </Col>}
                                        <Col>
                                            <Label className="gray">Next renewal date</Label>
                                            <div>{this.props.value.ExpirationDate ? this.props.value.ExpirationDate : ''}</div>
                                        </Col>
                                        {!permissionFunc.changeDisplay('package_btn_call_recording') &&
                                            <Col id='package_btn_call_recording'>
                                                <Label className="gray">{STRINGS.CALL_RECORDING}</Label>
                                                <div className='custom-control custom-switch isolate-the-design'>
                                                    <input
                                                        type='checkbox'
                                                        className='custom-control-input'
                                                        id={`customSwitches1-${this.props.index}`}
                                                        readOnly
                                                        checked={this.state.callRecordingSwitch}
                                                        onChange={() => this.setState({ callRecordingSwitch: !this.state.callRecordingSwitch })}
                                                    />
                                                    <label className='custom-control-label'
                                                        htmlFor={`customSwitches1-${this.props.index}`}></label>
                                                </div>
                                            </Col>}
                                        {!this.props.isAccessNumber && !permissionFunc.changeDisplay('package_btn_sms_recieving') &&
                                            <Col id='package_btn_sms_recieving'>
                                                <Label className="gray">{STRINGS.SMS_RECIEVING}</Label>
                                                <div className='custom-control custom-switch isolate-the-design'>
                                                    <input
                                                        type='checkbox'
                                                        className='custom-control-input'
                                                        id={`customSwitches2-${this.props.index}`}
                                                        readOnly
                                                        checked={this.state.SmsRecievingSwitch}
                                                        onChange={() => this.setState({ SmsRecievingSwitch: !this.state.SmsRecievingSwitch })}
                                                    />
                                                    <label className='custom-control-label'
                                                        htmlFor={`customSwitches2-${this.props.index}`}></label>
                                                </div>
                                            </Col>}
                                        <div className="v-center pr-3">
                                            <AdvancedDropdown
                                                details={this.props.details}
                                                refresh={() => this.props.refresh()}
                                                isAccessNumber={this.props.isAccessNumber}
                                            />
                                        </div>
                                    </Row>
                                    <Row> <Col className='border-top mt-3 py-3'>
                                        {this.state.textareaSaved}
                                        {!permissionFunc.changeDisplay('package_btn_add_or_edit_note') &&
                                            <Button
                                                style={{ marginLeft: '1rem', marginBottom: '0.5rem' }}
                                                color={'white'}
                                                onClick={() => this.setState({ addNote: !this.state.addNote })}
                                                className="cursorPointer btnColor1 border"
                                                id='package_btn_add_or_edit_note'
                                            >
                                                {`${this.state.addNote ? 'Cancel' :
                                                    this.props.value.FreeText || this.state.textareaSaved ? STRINGS.EDIT_NOTE : `+ ${STRINGS.ADD_NOTE}`}`}
                                            </Button>}
                                        {this.state.addNote ?
                                            <Input
                                                defaultValue={this.state.textArea}
                                                onChange={(e) => this.setState({ textArea: e.target.value })}
                                                type="textarea"
                                                name="text"
                                                id="textArea" /> : null}
                                    </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {this.props.showAddPackageBtn && <Col className="p-3 mt-3" style={{ borderRadius: '5px', border: 'dashed 1px #1a253a' }}>
                                <span onClick={(userName) => this.props.openModal(this.props.userName)} className="cursorPointer"> + Add package </span>  </Col>}
                        </CardBody>
                    </Card> :
                    <MiniPackage
                        packageProps={this.props}
                        package={this.props.value}
                        packageLength={this.props.packageLength}
                        formatDate={this.formatDate}
                        getNextTenDate={this.getNextTenDate}
                        refresh={this.props.refresh}
                        isAccessNumber={this.props.isAccessNumber}
						Currency={this.props.Currency}
                    />}
            </div>
        )
    }
}


export default Packege;