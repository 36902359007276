import React, { Component } from 'react';
import axios from '../../axios';
import sourceVariable from '../../globalStartSession';
import _ from 'lodash';
import Select from 'rc-select';
import DatePicker from 'react-datepicker';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import 'rc-select/assets/index.css';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Col,
    Row,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormGroup,
    Label,
    Form,
    Card,
    Tooltip,
} from 'reactstrap';
import './index.css';
import SideBar from '../../components/sideBar';
import NavBar from '../../components/navBar';
import RowTableList from './rowTableList';
import NoUsers from './noUsers';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Report from './report.js';
import UtilsService from "../../services/UtilsService";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import PasswordExpiredModal from './passwordExpiredModal';
import OpenUserPageModal from './openUserPageModal';
import { formatNumbers } from '../../services/formatPhoneNumber';
import * as permissionFunc from '../../permissionScheme/permissionFunc';
import * as STRINGS from '../../strings/english';
import SmsReport from './user-sms-report/smsReport';
import { UserTermsModal } from './user-terms-modal/userTermsModal';
import { ModelManagement } from './report-modal/ModelManagement';
import { USER_REPORT_ENUM } from '../../enum/index';
import * as axios2 from 'axios'

const showPackageAsRegular = 'REGULAR';
const showPackageAsMini = 'MINI';

class Dashboard extends Component {
    escFunction = this.escFunction.bind(this);
    enterPressed = this.enterPressed.bind(this);
    state = {
        redirect: this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : false,
        newCountry: this.props.intl.formatMessage(messages.Select),
        oldCountry: this.props.intl.formatMessage(messages.Select),
        newNumber: 'Select desired number', // this.props.intl.formatMessage(messages.Select),
        location: this.props.intl.formatMessage(messages.SelectState),
        plans: this.props.intl.formatMessage(messages.Select),
        items: '',
        items2: '',
        items3: '',
        items4: '',
        items5: '',
        isDropdownNumber: true,
        isDropdownLocation: true,
        isDropdownUserCountry: true,
        isDropdownPlans: true,
        isLoader1: false,
        isLoader2: false,
        isLoader3: false,
        isLoader4: false,
        isLoader5: false,
        isLoader6: false,
        isLoader7: false,
        userData: [],
        users: [],
        oldUsers: [],
        dropDownValue: this.props.intl.formatMessage(messages.Email),
        mail: localStorage.getItem('email'),
        ischeck1: false,
        ischeck2: false,
        iframeModal: false,
        iframeURL: '',
        addFundsModal: false,
        selectPaymentModal: false,
        addNewPackegModal: false,
        registerUserModal: false,
        transferFundsModal: false,
        managePaymentModal: false,
        addNewPackegPaymantModal: false,
        useParentCredit: false,
        pageSize: 10,
        pageCurrent: 1,
        charged: '',
        advancedOptions: true,
        switchCallRecording: false,
        switchSmsRecording: false,
        oldNumber: '',
        productID: '',
        numberId: '',
        addNewPackegModalConfirm: false,
        endDate: new Date(),
        price: this.props.intl.formatMessage(messages.price),
        startDate: this.endDate(),
        uploadUsers: false,
        userCallReport: false,
        selectUsers: [],
        showPayments: false,
        paymentsData: [],
        orderIdModal: false,
        orderData: '',
        isDashboardLoader: false,
        userPackage: '',
        prefix: '',
        template: false,
        tampletDropdownValue: 'Choose template...',
        accountTemplates: [],
        tampletChoosedIndex: null,
        deleteUserClicked: false,
        deleteUserFrom: new Date(),
        currentUser: {},
        deleteUserAlert: false,
        modeDisplayReport: false,
        prefixOfCountries: [],
        currentPrefix: { country: "", prefix: "" },
        destination: "",
        countries: [],
        locations: [],
        userCountry: [],
        numberless: true,
        allPrefixOfCountries: [],
        tooltipOpen: false,
        passwordExpired: false,
        redirectToUserPage: localStorage.getItem('redirectToUserPage') === 'true',
        currentUserEmailwhenSelectOpenUserPage: '',
        openOutgoingPackage: false,
        outgoingPackageData: [],
        outgoingPackageOpen: false,
        isMyPackages: false,
        usersSmsReportToggle: false,
        showUserTerms: false,
        userTermsData: [],
        userTermsModalData: [],
        reportToggle: false,
    }
    rowTables = {};
    paymentMethodOfPayPal = 'PayPal';
    paymentMethodOfCreditGuard = 'CreditGuard';
    outgoingCalls = STRINGS.OUTGOING_CALLS;
    spikkoNumber = STRINGS.SPIKKO_NUMBER;

    clearLocalStorageAndLogout = () => {
        localStorage.clear();
        this.props.history.push('/login');
    }

    orderIdClick(e) {

        axios.get(`/Payment/GetOrderSummaryById/${e.target.innerText}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({ orderIdModal: true, orderData: response.data.OrderHTML })

        }).catch(error => {

        });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

    endDate() {

        let d = new Date();
        d.setDate(d.getDate() - 30)
        return d
    }

    componentDidMount() {
        if (this.state.redirect) {
            this.setState({ isDashboardLoader: true })
            document.addEventListener("keydown", this.escFunction, false);
            document.addEventListener("keyup", this.enterPressed, false);
            this.getUserByUsername(true);
            if (!this.props.isMyPackages) {
                this.getOrganizationUsers(true);
            }
        }
    }

    getUserByUsername = (handleError) => {
        let partner;
        if (localStorage.getItem('RefId')) {
            partner = localStorage.getItem('RefId');
        }
        let path = `/Account/GetUserByUsername/${localStorage.getItem('email')}${!this.props.isMyPackages ? '/false' : ''}`;
        axios.get(path, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (this.props.isMyPackages) {
                let userArr = [response.data];
                this.setState({
                    users: userArr,
                    renderUsersOrUploadFileField: true,
                    isDashboardLoader: false
                });
            }
            localStorage.setItem('silentMonitorAllowed', response.data.CustomFields.SilentMonitorAllowed);
            this.setState({ userData: response.data })
            this.getUserTerms(response.data);
            if (response.data.PaymentMethod) {
                response.data.PaymentMethod === 'PayPal'
                    ? this.setState({ ischeck2: true })
                    : this.setState({ ischeck1: true })
            }
            if (response.data.PasswordExpired) {
                this.setState({ passwordExpired: true });
            }
        }).catch(error => {
            this.setState({ isDashboardLoader: false })
            if (handleError) {
                if (partner) {
                    localStorage.setItem('RefId', partner);
                }

            }

        });
    }

    getUserTerms = (user) => {
        axios.get(`/Account/GetUserTerms/${user.AccountId}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (response.data.length > 0) {
                let userTermsModalData = this.createUserTermsModalData(response.data);
                this.setState({
                    showUserTerms: true,
                    userTermsData: response.data,
                    userTermsModalData: userTermsModalData
                });
            }
        }).catch(error => {

        });
    }

    createUserTermsModalData = (data) => {
        let arr = [];
        data.forEach((item, i) => {
            arr.push({
                id: item.Id,
                toggle: i === 0 ? true : false,
                approved: false
            });
        })
        return arr;
    }

    getOrganizationUsers = (handleError) => {
        axios.post('/Provisioning/GetOrganizationUsers', {
            OrganizationEmail: localStorage.getItem('email'),
            MaxResults: "10000",
            StartFromRow: "1",
            QueryType: "0",
            QueryTerm: "%"
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({ users: response.data.Users, oldUsers: response.data.Users, isDashboardLoader: false }, () => {
                this.setState({ renderUsersOrUploadFileField: true })
            })
        }).catch(error => {
            this.setState({ isDashboardLoader: false })
            if (handleError) {

            }
        });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        document.removeEventListener("keyup", this.enterPressed, false);
    }

    dashboardList(showPackageMini) {
        let x = this.state.pageCurrent;
        let y = this.state.pageSize;
        let max = this.state.users.length > (x * y) ? (x * y) : this.state.users.length;
        const currentArray = this.state.users.slice((x - 1) * y, max)
        let tbody = currentArray
            .map((elm, index) => {
                return (
                    <RowTableList
                        ref={obj => this.rowTables['row-table ' + index] = obj}
                        paymentsModal={(userName, finalDate) => this.openPaymentsModal(userName, finalDate)}
                        openModal={(userName) => this.openModalNewPackege(userName, index)}
                        deleteUserClicked={(currentUser) => {
                            this.modalToggle('deleteUserClicked');
                            this.setState({ currentUser });
                        }}
                        callReportClicked={(currentUser) => {
                            this.modalToggle('modeDisplayReport');
                            this.setState({ currentUser })
                        }}
                        reviveUser={(userName) => {
                            this.setUserCanselation({ "Username": userName, "revive": true }, true)
                        }}
                        key={`rowTable- ${index}`}
                        index={index}
                        elm={elm}
                        showMiniPackage={showPackageMini}
                        refresUsers={this.getOrganizationUsers}
                        openUserPage={() => this.openUserPage(elm.UserName)}
                        checkPackageType={(userData) => this.checkPackageType(userData, index)}
                        openOutgoingPackage={this.state.outgoingPackageData.length > 0}
                        selectPackageType={this.selectPackageType}
                        Currency={`${this.state.userData.Currency} (${this.state.userData.CurrencyName})`}
                        isMyPackages={this.props.isMyPackages}
                    />
                )
            })
        return tbody
    }

    openUserPage = (username) => {
        this.setState({
            isDashboardLoader: true,
            currentUserEmailwhenSelectOpenUserPage: username
        });
        let body = {
            "username": username
        }
        axios.post('Provisioning/LoginAs', body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (response && response.data) {
                let redirectURL = response.data.RedirectURL;
                this.setState({ isDashboardLoader: false, redirectToUserPage: true });
                localStorage.setItem('redirectToUserPage', true);
                window.open(redirectURL);
            }

        }).catch(error => {

        });
    }

    changeValue(e) {
        this.setState({ dropDownValue: e.currentTarget.textContent });
    }

    searchButton() {
        this.setState({ pageCurrent: 1 })
        let newItems;
        switch (this.state.dropDownValue) {
            case 'Email': {
                newItems = this.state.oldUsers.filter(value => value.UserName.includes(this.exSearch.value))
                this.setState({ users: newItems }, () => {
                    this.updateUserPackagesAfterChangeScreen();
                })
                break;
            }
            case 'Name': {
                newItems = this.state.oldUsers.filter((value) => {
                    let fullName = value['FirstName'] + ' ' + value['LastName'];
                    for (let key in value) {
                        if (fullName.toLowerCase().includes(this.exSearch.value.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                });
                this.setState({ users: newItems }, () => {
                    this.updateUserPackagesAfterChangeScreen();
                })
                break;
            }
            case 'Mobile2CRM number': {
                this.filterByCRMNumOrDestinationNum(2, newItems);
                break;
            }
            case 'Destination number': {
                this.filterByCRMNumOrDestinationNum(3, newItems);
                break
            }
            default:
                break;
        }

    }

    filterByCRMNumOrDestinationNum = (QueryType, newItems) => {
        this.setState({ isDashboardLoader: true })
        axios.post('/Provisioning/GetOrganizationUsers', {
            "OrganizationEmail": localStorage.getItem('email'),
            "MaxResults": 100,
            "StartFromRow": 1,
            "QueryType": QueryType,
            "QueryTerm": `${this.exSearch.value}%`
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then((response) => {
            newItems = response.data.Users;
            this.setState({ users: newItems, isDashboardLoader: false });
            this.updateUserPackagesAfterChangeScreen();
        }).catch(error => {

        });
    }

    signOut = () => {
        this.clearLocalStorageAndLogout();
    }

    selectPayment() {

        if (this.state.ischeck1 || this.state.ischeck2) {
            const payMent = this.state.ischeck1
                ? 'CreditGuard'
                : 'PayPal'
            axios
                .post('/Provisioning/StartSession', { "source": sourceVariable })
                .then(response => {
                    const data = response.data;
                    axios.post('/Payment/ChangePaymentMethod', {
                        "accountid": this.state.userData.AccountId,
                        "paymentmethod": payMent
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + data.SessionKey
                        }
                    }).then(response => {
                        if (payMent === 'PayPal') {

                            window.open(response.data.RedirectURL, "_blank");
                            this.setState({
                                managePaymentModal: false,
                                addFundsModal: true,
                                ischeck1: false,
                                ischeck2: true
                            })
                        } else {
                            this.setState({
                                managePaymentModal: false,
                                iframeURL: response.data.RedirectURL,
                                iframeModal: true,
                                ischeck1: true,
                                ischeck2: false
                            })
                        }
                    }).catch(error => {

                    });
                })
                .catch(error => {

                });
        } else {
            alert(this.props.intl.formatMessage(messages.SelectYourPayment))
        }
    }

    modalToggle(value) {
        this.setState({
            [value]: !this.state[value]
        }, () => {
            if (!this.state[value]) {
                this.setState({
                    numberless: true,
                    newNumber: 'Select desired number',
                    prefix: '',
                    openOutgoingPackage: false,
                    paymentmethod: '',
                    plans: this.props.intl.formatMessage(messages.Select),
                    charged: '',
                    switchCallRecording: false,
                    switchSmsRecording: false,
                    outgoingPackageOpen: false
                });
            }
        })
    }

    addFundsToggle() {

        if (this.state.userData.PaymentMethod) {
            this.setState({ addFundsModal: true })
        } else {
            this.setState({ selectPaymentModal: true })
        }
    }

    transferFundsModalClickButton() {
        this.setState({ isLoader7: true })

        axios.post('/Payment/RefillCredit', {
            "accountid": this.state.userData.AccountId,
            "amount": this.funds.value
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.forceUpdateHandler()
            this.setState({ transferFundsModal: false, isLoader7: false })
        }).catch(error => {
            this.setState({ isLoader7: false })

        });
    }

    getAccountTemplates() {
        axios.get(`/Provisioning/GetAccountTemplates`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({ accountTemplates: response.data })

        }).catch(error => {

        });
    }

    tampletDropdownValueHandler(e, index) {
        this.setState({
            tampletDropdownValue: e.currentTarget.textContent,
            tampletChoosedIndex: index
        });
    }

    registerPasswordValidation(password, confirmPassword) {
        if (password !== confirmPassword) {
            this.setState({ isPasswordValidation: false });
            return false
        }
        return true
    }

    registerUser() {
        if (this.registerPasswordValidation(this.password.value, this.repeatPassword.value)) {
            axios.post('/Provisioning/RegisterOrganizationUser', {
                "OrganizationEmail": localStorage.getItem('email'),
                "firstName": this.firstname.value,
                "lastName": this.lastName.value,
                "password": this.password.value,
                "emailaddress": this.email.value,
                "UsesParentCredit": this.state.useParentCredit
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ useParentCredit: false, registerUserModal: false })
                this.componentDidMount()
            }).catch(error => {

            });
        } else {
            alert("Password and Confirm password should match")
        }

    }

    registerUserFromTemplate() {
        if (this.state.accountTemplates[this.state.tampletChoosedIndex] && this.state.accountTemplates[this.state.tampletChoosedIndex].Id) {
            this.setState({ isLoader: true });
            axios.post('/Provisioning/RegisterOrganizationUserFromTemplate', {
                "templateID": this.state.accountTemplates[this.state.tampletChoosedIndex].Id,
                "username": this.email.value,
                "firstName": this.firstname.value,
                "lastName": this.lastName.value,
                "password": this.password.value,
                "destination": `${this.state.destination}`
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(() => {
                this.setState({ useParentCredit: false, registerUserModal: false, isLoader: false })
                this.componentDidMount()
            }).catch(error => {
                this.setState({ isLoader: false });

            });
        }
        else alert("You have to choose template")
    }

    paginationOnChange(current, pageSize) {
        this.setState({ pageCurrent: current }, () => {
            this.updateUserPackagesAfterChangeScreen();
        });
    }

    updateUserPackagesAfterChangeScreen() {
        for (const key in this.rowTables) {
            if (this.rowTables[key] && this.rowTables[key].state.isVisible) {
                this.rowTables[key].updateUsersAfterAddPackage();
            }
        }
    }

    showSizeChanger(current, pageSize) {
        this.setState({ pageSize })
    }

    forceUpdateHandler() {
        this.componentDidMount();
    };

    addFundsToBank() {
        this.setState({ isLoader6: true })
        axios.post('/Payment/RefillBank', {
            "accountid": this.state.userData.AccountId,
            "amount": this.addFunds.value
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.forceUpdateHandler()
            this.setState({ addFundsModal: false, isLoader6: false })
        }).catch(error => {
            this.setState({ isLoader6: false })

        });
    }

    handleChangeSeletetUser(e) {
        let selectUsers = this.state.selectUsers

        selectUsers.indexOf(e.target.value) === -1 ? selectUsers.push(e.target.value) : selectUsers.pop(e.target.value)

        this.setState({ selectUsers: selectUsers })

    }

    onDropClick(e) {
        switch (e.target.name) {
            case 'newCountry':
                this.setState({
                    isDropdownLocation: true,
                    isDropdownNumber: true,
                    location: this.props.intl.formatMessage(messages.SelectState),
                    newNumber: this.props.intl.formatMessage(messages.Select),
                    isLoader1: true,
                    [e.target.name]: e.target.innerText
                })
                const countryCode = _
                    .find(this.state.ShowDidCountriesData, { 'CountryName': e.target.innerText })
                    .CountryCode;
                this.setState({ countryCode })
                axios
                    .get(`/Utils/ShowDidLocations/${countryCode}`, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                        }
                    })
                    .then(response => {
                        const ShowDidLocationsData = response.data;
                        this.setState({ ShowDidLocationsData })
                        const newArray = ShowDidLocationsData.filter(value => value.CityName !== "default")
                        const items = newArray.map((value, index) => < DropdownItem key={
                            index
                        }
                            name={
                                'location'
                            }
                            onClick={
                                (e) => this.onDropClick(e)
                            }> {
                                value.CityName
                            } </DropdownItem>)
                        this.setState({ items3: items, isDropdownLocation: false, isLoader1: false })
                    })
                    .catch(error => {
                        this.setState({ isLoader1: false })

                    });
                break
            case 'location':
                this.setState({ isLoader2: true, [e.target.name]: e.target.innerText })
                const countryCodeID = _.find(this.state.ShowDidLocationsData, { 'CityName': e.target.innerText }).SpikkoCountryCodeID;
                axios
                    .post('/Provisioning/GetAvailableNumbersInLocation', { "CountryCodeID": countryCodeID }, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                        }
                    })
                    .then(response => {
                        const GetAvailableNumbersInLocation = response.data.Results;
                        this.setState({ GetAvailableNumbersInLocation })
                        if (GetAvailableNumbersInLocation.length > 0) {
                            const items = GetAvailableNumbersInLocation.map((value, index) => < DropdownItem key={
                                index
                            }
                                value={value.DIDPoolID}
                                name={
                                    'newNumber'
                                }
                                onClick={
                                    (e) => this.onDropClick(e)
                                }> {
                                    value.FormattedInternational
                                } </DropdownItem>)
                            this.setState({ items4: items, isDropdownNumber: false, isLoader2: false })
                        } else this.setState({ isLoader2: false })
                    })
                    .catch(error => {
                        this.setState({ isLoader2: false })

                    });
                break;
            case 'oldCountry':
                if (!this.state.ShowDidLocationsData) {
                    return alert("You must fill in the previous fields.")
                }
                this.setState({ isLoader4: true, [e.target.name]: e.target.innerText })
                const countryPlan = _.find(this.state.GetSupportedDestinationsData, { 'CountryName': e.target.innerText }).IntlPrefix;
                this.setState({ countryPlan, prefix: countryPlan })
                axios
                    .post('/Provisioning/GetAvailablePlans ',
                        //  {
                        {
                            "OrganizationEmail": "",
                            "SourceCountry": this.state.countryCode,
                            "DestinationPrefix": countryPlan,
                            "CountryCodeID": _
                                .find(this.state.ShowDidLocationsData, { 'CityName': this.state.location })
                                .SpikkoCountryCodeID,
                            "Username": localStorage.getItem('email')
                        },
                        {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                            }
                        }).then(response => {

                            const GetAvailablePlans = response.data;
                            this.setState({ GetAvailablePlans })
                            const items = GetAvailablePlans.map((value, index) => < DropdownItem key={
                                index
                            }
                                value={value.Price}
                                name={
                                    value.ProductID
                                }
                                onClick={
                                    (e) => this.onDropClick(e)
                                }> {
                                    `${value.Name} (${value.Price}${this.props.intl.formatMessage(messages.USDMonth)})`
                                } </DropdownItem>)
                            this.setState({ items5: items, isDropdownPlans: false, isLoader4: false })
                        })
                    .catch(error => {
                        this.setState({ isLoader4: false })

                    });
                break;
            case 'newNumber':
                this.setState({ [e.target.name]: e.target.innerText, numberId: e.target.value })
                break;

            default:
                this.setState({
                    productID: e.target.name,
                    plans: e.target.innerText,
                    price: `${e.target.value}USD / month`
                })

        }
    }

    onOptionClick(e, name, newValue) {
        if (newValue) {
            switch (name) {
                case 'newCountry':
                    this.setState({
                        isDropdownLocation: true,
                        isDropdownNumber: true,
                        isDropdownUserCountry: true,
                        location: this.props.intl.formatMessage(messages.SelectState),
                        newNumber: this.props.intl.formatMessage(messages.Select),
                        isLoader1: true,
                        [name]: newValue
                    })
                    const countryCode = _
                        .find(this.state.ShowDidCountriesData, { 'CountryName': newValue })
                        .CountryCode;
                    this.setState({ countryCode })
                    axios
                        .get(`/Utils/ShowDidLocations/${countryCode}`, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                            }
                        })
                        .then(response => {
                            const ShowDidLocationsData = response.data;
                            this.setState({ ShowDidLocationsData })
                            const newArray = ShowDidLocationsData.filter(value => value.CityName !== "default")
                            const locations = newArray.map(item => item.FullName); // the old value is 'CityName'.
                            this.setState({ locations });
                            // const items = newArray.map((value, index) =>
                            //     <DropdownItem
                            //         key={index}
                            //         name={'location'}
                            //         onClick={(e) => this.onDropClick(e)}
                            //     >
                            //         {value.CityName}
                            //     </DropdownItem>)
                            // this.setState({ items3: items, isDropdownLocation: false, isLoader1: false })
                            this.setState({ isDropdownLocation: false, isLoader1: false });
                        })
                        .catch(error => {
                            this.setState({ isLoader1: false })

                        });
                    break
                case 'location':
                    this.setState({ isLoader2: true, [name]: newValue })
                    const countryCodeID = _.find(this.state.ShowDidLocationsData, {/* // the old value is 'CityName'. */ 'FullName': newValue }).SpikkoCountryCodeID;
                    axios
                        .post('/Provisioning/GetAvailableNumbersInLocation', { "CountryCodeID": countryCodeID }, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                            }
                        })
                        .then(response => {
                            const GetAvailableNumbersInLocation = response.data.Results;
                            this.setState({ GetAvailableNumbersInLocation, isDropdownUserCountry: false })
                            if (GetAvailableNumbersInLocation.length > 0) {
                                const items = GetAvailableNumbersInLocation.map((value, index) => < DropdownItem key={
                                    index
                                }
                                    value={value.DIDPoolID}
                                    name={
                                        'newNumber'
                                    }
                                    onClick={
                                        (e) => this.onDropClick(e)
                                    }> {
                                        value.FormattedInternational
                                    } </DropdownItem>)
                                this.setState({
                                    items4: items,
                                    isDropdownNumber: false,
                                    isLoader2: false,
                                    numberless: true
                                })
                            } else this.setState({ isLoader2: false, numberless: false })
                        })
                        .catch(error => {
                            this.setState({ isLoader2: false })

                        });
                    break;
                case 'oldCountry':
                    if (!this.state.ShowDidLocationsData || !this.state.GetAvailableNumbersInLocation || this.state.isDropdownUserCountry) {
                        return alert("You must fill in the previous fields.")
                    }
                    this.setState({ isLoader4: true, [name]: newValue })
                    const countryPlan = _.find(this.state.GetSupportedDestinationsData, { 'CountryName': newValue }).IntlPrefix;
                    this.setState({ countryPlan, prefix: countryPlan })
                    axios
                        .post('/Provisioning/GetAvailablePlans ',
                            //  {
                            {
                                "OrganizationEmail": "",
                                "SourceCountry": this.state.countryCode,
                                "DestinationPrefix": countryPlan,
                                "CountryCodeID": _
                                    .find(this.state.ShowDidLocationsData, {/* // the old value is 'CityName'. */ 'FullName': this.state.location })
                                    .SpikkoCountryCodeID,
                                "Username": localStorage.getItem('email')
                            },
                            {
                                headers: {
                                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                                }
                            }).then(response => {

                                const GetAvailablePlans = response.data;
                                this.setState({ GetAvailablePlans })
                                const items = GetAvailablePlans.map((value, index) => < DropdownItem key={
                                    index
                                }
                                    value={value.Price}
                                    name={
                                        value.ProductID
                                    }
                                    onClick={
                                        (e) => this.onDropClick(e)
                                    }> {
                                        `${value.Name} (${value.Price}${this.props.intl.formatMessage(messages.USDMonth)})`
                                    } </DropdownItem>)
                                this.setState({ items5: items, isDropdownPlans: false, isLoader4: false })
                            })
                        .catch(error => {
                            this.setState({ isLoader4: false })

                        });
                    break;
                case 'newNumber':
                    this.setState({ [e.target.name]: e.target.innerText, numberId: e.target.value })
                    break;

                default:
                    this.setState({
                        productID: e.target.name,
                        plans: e.target.innerText,
                        price: `${e.target.value}USD / month`
                    })

            }
        }
    }

    confirmPackage() {
        this.setState({ isLoader3: true })
        if (!this.state.openOutgoingPackage) {
            const features = this.state.switchCallRecording && this.state.switchSmsRecording
                ? "Recording,IncomingSMS" : this.state.switchCallRecording ? "Recording" : this.state.switchSmsRecording ? "IncomingSMS" : '';
            axios.post('/Provisioning/RegisterToPackage', {
                "Username": this.state.userPackage,
                "ProductID": this.state.productID,
                "DivertNumber": this.state.oldNumber,
                "EnableFeatures": features,
                "PreSelectedNumberID": this.state.numberId,
                "SpikkoCountryCode": _
                    .find(this.state.ShowDidLocationsData, { 'FullName': this.state.location })
                    .SpikkoCountryCodeID,
                "ISOCountryCode": _
                    .find(this.state.ShowDidLocationsData, { 'FullName': this.state.location })
                    .CountryName,
                "paymentmethod": this.state.paymentmethod
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({
                    isLoader3: false,
                    addNewPackegModalConfirm: false,
                    newCountry: this.props.intl.formatMessage(messages.Select),
                    oldCountry: this.props.intl.formatMessage(messages.Select),
                    newNumber: 'Select desired number', // this.props.intl.formatMessage(messages.Select),
                    location: this.props.intl.formatMessage(messages.SelectState),
                    plans: this.props.intl.formatMessage(messages.Select),
                    charged: '',
                    switchCallRecording: false
                })
                this.rowTables['row-table ' + this.state.currentRowTableIndex].updateUsersAfterAddPackage();
            }).catch(error => {
                this.setState({ isLoader3: false })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        else {
            const features = this.state.switchCallRecording ? "Recording" : '';
            let body = {
                "Username": this.state.userPackage,
                "ProductID": this.state.productID,
                "EnableFeatures": features
            }
            axios.post('/Provisioning/RegisterToOutgoingPackage', body, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({
                    isLoader3: false,
                    addNewPackegModalConfirm: false,
                    plans: this.props.intl.formatMessage(messages.Select),
                    charged: '',
                    switchCallRecording: false
                })
                this.rowTables['row-table ' + this.state.currentRowTableIndex].updateUsersAfterAddPackage();
            }).catch(error => {
                this.setState({ isLoader3: false })

            });
        }
    }

    openPaymentsModal(userName, finalDate) {
        this.setState({ isLoader8: true })
        let today = new Date();
        let tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        axios.post('/Payment/AccountBillingHistory', {
            "fromtime": finalDate,
            "totime": this.formatDate(tomorrow),
            "foruser": userName
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({ paymentsData: response.data, isLoader8: false })
        }).catch(error => {
            this.setState({ isLoader8: false })

        });
        this.setState({ showPayments: true })
    }

    openModalNewPackege(userName, index) {
        this.setState({ userPackage: userName, currentRowTableIndex: index })
        axios
            .get('/Utils/ShowDidCountries', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            })
            .then(response => {
                const countries = response.data.map((item => item.CountryName));
                this.setState({ countries });
                const ShowDidCountriesData = response.data;
                this.setState({ ShowDidCountriesData })
                // const items = ShowDidCountriesData.map((value, index) => < DropdownItem key={
                //     index
                // }
                //     name={
                //         'newCountry'
                //     }
                //     onClick={
                //         (e) => this.onDropClick(e)
                //     }> {
                //         value.CountryName
                //     } </DropdownItem>)
                // this.setState({ items: items })
            })
            .catch(error => {

            });
        axios
            .get('/Utils/GetSupportedDestinations', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            })
            .then(response => {
                const GetSupportedDestinationsData = response.data;
                const userCountry = response.data.map(item => item.CountryName);
                this.setState({ userCountry });
                this.setState({ GetSupportedDestinationsData })
                // const items = GetSupportedDestinationsData.map((value, index) => < DropdownItem key={
                //     index
                // }
                //     name={
                //         'oldCountry'
                //     }
                //     onClick={
                //         (e) => this.onDropClick(e)
                //     }> {
                //         value.CountryName
                //     } </DropdownItem>)
                // this.setState({ items2: items })
            })
            .catch(error => {

            });
        this.setState({ addNewPackegModal: true })
    }

    moveToStep2() {
        if (this.state.oldCountry !== 'Select' && this.oldNumber.value) {
            this.setState({ addNewPackegPaymantModal: true, addNewPackegModal: false, oldNumber: this.oldNumber.value })
        } else {
            alert('Fields are missing')
        }
    }

    clearAll() {
        this.state.selectUsers.map((value) => {
            let name = `checkbox${value}`
            return (
                this.refs[name].checked = false)
        }
        )
        this.setState({ selectUsers: [] })
    }

    selectAll() {
        let selectUsers = []
        this.state.oldUsers.map((value) => {
            let name = `checkbox${value.UserId}`
            selectUsers.push(value.UserId)
            return (
                this.refs[name].checked = true)
        }
        )
        this.setState({ selectUsers: selectUsers })
    }

    reportClick() {
        axios.post('/Account/GetCallsForUsers', {
            "startFrom": this.state.startDate.toISOString().split('.')[0],
            "endTo": this.state.endDate.toISOString().split('.')[0],
            "includedusers": this.state.selectUsers.toString(),
            "GMTOffset": -1 * new Date().getTimezoneOffset()
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({ userCallReportData: response.data, userCallReport: false, showRepotTable: true })
        }).catch(error => {

        });
    }

    downloadCsv() {
        this.setState({ isLoader5: true })
        axios.post('/Account/GetCallsForUsers', {
            "startFrom": this.state.startDate.toISOString().split('.')[0],
            "endTo": this.state.endDate.toISOString().split('.')[0],
            "includedusers": this.state.selectUsers.toString(),
            "GMTOffset": -1 * new Date().getTimezoneOffset()
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({ userCallReportData: response.data })
            const { Parser } = require('json2csv');
            const parser = new Parser();
            const csv = parser.parse(response.data);
            var fileDownload = require('js-file-download');
            fileDownload(csv, `user-calls-${new Date().toISOString()}.csv`);
            this.setState({ isLoader5: false })
        }).catch(error => {
            this.setState({ isLoader5: false })

        });
    }

    paymentList() {
        if (this.state.paymentsData.length > 0) {
            let tbody = this.state.paymentsData
                .map((elm, index) => {
                    return (
                        <tr key={index} style={{ height: '30px' }}>
                            <th className="cursorPointer"
                                onClick={(e) => this.orderIdClick(e)}>{elm.OrderSummaryId}</th>
                            <td>{elm.Date}</td>
                            <td>{elm.PackageDescription}</td>
                            <td>{elm.PaymentAmount}</td>
                            <td>{elm.PaymentType}</td>
                            <td><span className="p-2" style={{
                                color: 'white',
                                backgroundColor: elm.PaymentStatus === 'Success' ? 'green' : elm.PaymentStatus === 'Failed' ? 'red' : 'yellow',
                                borderRadius: '50%',
                            }}>{elm.PaymentStatus}</span></td>
                            <td><Link
                                to=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(elm.InvoiceURL, "_blank");
                                }}>
                                <img src='assets/ic-attach-file-18-px.svg' alt="" />
                            </Link>

                            </td>
                        </tr>
                    )
                })
            return tbody
        } else
            return null
    }

    downloadPaymentToCsv() {
        const { Parser } = require('json2csv');
        const parser = new Parser();
        const csv = parser.parse(this.state.paymentsData);
        var fileDownload = require('js-file-download');
        fileDownload(csv, `user-payments-${new Date().toISOString()}.csv`);
    }

    searchPayment() {
        axios.post('/Payment/AccountBillingHistory', {
            "fromtime": this.formatDate(this.state.startDate),
            "totime": this.formatDate(this.state.endDate),
            "foruser": this.state.userData.UserName
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({ paymentsData: response.data })
        }).catch(error => {

        });
    }


    //** called when Esc preesed (to close the delete alert)*/
    escFunction(event) {
        if (event.keyCode === 27) {
            this.setState({ deleteUserAlert: false, deleteUserClicked: false })
        }
    }

    //** called when Enter pressed in search field */
    enterPressed(event) {
        if (event.keyCode === 13 && event.target.name === 'searchButton') {
            this.searchButton()
        }
    }

    //** called when delete alert pressed*/
    deleteAlertClicked() {
        let body = {
            "Username": this.state.currentUser.UserName,
            "CancelationDate": this.state.deleteUserFrom
        }
        this.setUserCanselation(body)

    }

    //**called from delete alert for cancel user and from revive user *//
    setUserCanselation(body, revive = false) {
        axios.post('/Account/SetUserCancelation/', body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (!revive) {
                this.setState({ deleteUserAlert: true, deleteUserClicked: true })
            } else {
                this.setState({ deleteUserAlert: false, deleteUserClicked: false })
            }
            this.componentDidMount()

        }).catch(error => {
            this.setState({ deleteUserAlert: false, deleteUserClicked: false })

        });

    }

    compareDate(first, second) {
        var date1 = new Date(first.getTime());
        date1.setHours(0, 0, 0, 0);
        var date2 = new Date(second.getTime());
        date2.setHours(0, 0, 0, 0);
        return date1 < date2;
    }

    //** called if state.deleteUserForm is true */
    deleteUserFormModal() {

        return <Modal
            className="fundsModal"

            isOpen={this.state.deleteUserClicked}
            toggle={() => this.modalToggle('deleteUserClicked')}
        >
            <ModalHeader className="justify-content-center text-center no-border">
                {!this.state.deleteUserAlert
                    ? <img src="assets/group-21.svg" alt="" style={{ paddingTop: "15px" }} />
                    : <img src="assets/checkmark.svg" alt="" style={{ paddingTop: "15px" }} />}
            </ModalHeader>
            {!this.state.deleteUserAlert
                ? <div className="justify-content-center text-center" style={{ fontSize: "22px" }}>Delete user</div>
                : null
            }
            <ModalBody>
                {!this.state.deleteUserAlert ?
                    <Form>
                        <div className={'text-center'}>When would you like the
                            user <b>{this.state.currentUser.FirstName + " "}</b><b>{this.state.currentUser.LastName}</b> to
                            be deleted?
                        </div>
                        <div
                            className="mt-2 check"
                        >
                            <Label>Select date</Label>
                            <div className='border-5 form-control'>
                                <DatePicker
                                    className="no-border"
                                    selected={this.state.deleteUserFrom}
                                    onChange={(date) => this.setState({ deleteUserFrom: date })
                                    }
                                />
                                <img src='assets/calendar.svg' alt="" style={{ marginLeft: '21%' }} />


                            </div>
                        </div>
                    </Form>
                    :
                    <Form>
                        <div className={'text-center'}
                            style={{ fontSize: "22px" }}>User <b>{this.state.currentUser.FirstName + " "}</b>
                            <b>{this.state.currentUser.LastName}</b><br /> was successfully deleted
                        </div>
                        <div
                            className="mt-2 check"
                        >
                            <div className={'text-center cursorPointer'} style={{ fontSize: "14px", fontWeight: "500" }}
                                onClick={() => this.setUserCanselation({
                                    "Username": this.state.currentUser.UserName,
                                    "revive": true
                                }, true)}
                            ><u>Undo delete</u></div>
                        </div>
                    </Form>
                }

            </ModalBody>
            <ModalFooter>
                {!this.state.deleteUserAlert
                    ? <Button disabled={this.compareDate(this.state.deleteUserFrom, new Date())}
                        className="text-white btn-block"
                        style={{ backgroundColor: "#e94141" }}
                        onClick={() => this.deleteAlertClicked()}
                    >
                        Delete user
                    </Button>
                    : <Button
                        className=" text-white btn-block"
                        style={{ backgroundColor: "#2ec2fe" }}
                        onClick={() => this.setState({ deleteUserAlert: false, deleteUserClicked: false })}
                    >
                        Back to my users
                    </Button>
                }
            </ModalFooter>
        </Modal>
    }

    getPrefixOfCountries() {
        UtilsService.getSupportedDestinations()
            .then(res => {
                let result = res.data
                this.setState({ allPrefixOfCountries: result })
                this.setState({
                    prefixOfCountries: result.map((value, index) => {
                        return <DropdownItem
                            key={index}
                            onClick={() => this.setState({
                                destination: value.IntlPrefix,
                                currentPrefix: { country: value.CountryName, prefix: value.IntlPrefix }
                            })}>{value.CountryName}
                        </DropdownItem>
                    })
                })
            }).catch(error => {

            })
    }

    setDestination(e) {
        // let val = this.state.destination.startsWith(this.state.currentPrefix.prefix)
        // let current = val ? e : this.state.currentPrefix.prefix
        // this.setState({ destination: "" })
        this.setState({ destination: e })
    }

    selectCountry = (e, newValue) => {
        if (newValue) {
            this.setState({
                destination: newValue.IntlPrefix,
                currentPrefix: { country: newValue.CountryName, prefix: newValue.IntlPrefix }
            });
        }
    }

    showMiniPackageOnClick = () => {
        if (localStorage.getItem("showMiniPackage") === null) {
            localStorage.setItem('showMiniPackage', showPackageAsMini);
        }
        else if (localStorage.getItem("showMiniPackage") === showPackageAsRegular) {
            localStorage.setItem('showMiniPackage', showPackageAsMini);
        }
        else if (localStorage.getItem("showMiniPackage") === showPackageAsMini) {
            localStorage.setItem('showMiniPackage', showPackageAsRegular);
        }
        // empty setState it's for the render could know when local storage changed.
        this.setState({});
    }

    paymentMethodItemOnClick = (e) => {
        this.setState({ charged: e.target.innerText }, () => {
            if (this.state.charged === 'Card Credit') {
                this.setState({ paymentmethod: 'CreditGuard' });
            }
            else if (this.state.charged === 'PayPal Credit') {
                this.setState({ paymentmethod: 'PayPal' });
            }
        })
    }

    step2NextOnClick = () => {
        if (this.state.paymentmethod) {
            this.setState({
                addNewPackegModalConfirm: true,
                addNewPackegPaymantModal: false
            })
        }
        else alert('Select your payment method.');
    }

    modalCancel = () => {
        this.clearLocalStorageAndLogout();
    }

    passwordExpiredToggle = () => {
        this.setState({ passwordExpired: !this.state.passwordExpired });
    }

    openUserPageToggle = (logoutFromUserPage) => {
        if (logoutFromUserPage) {
            this.logoutFromUserPage();
        }
        if (localStorage.getItem('redirectToUserPage') === 'true') {
            localStorage.setItem('redirectToUserPage', false);
            this.setState({ redirectToUserPage: false });
            return
        }
        if (localStorage.getItem('redirectToUserPage') === 'false') {
            localStorage.setItem('redirectToUserPage', true);
            this.setState({ redirectToUserPage: true });
            return
        }
    }

    logoutFromUserPage = () => {
        this.setState({ isDashboardLoader: true });
        let body = {
            "logout": true
        }
        axios.post('Provisioning/LoginAs', body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (response && response.data) {
                let redirectURL = response.data.RedirectURL;
                window.open(redirectURL, '_self');
                this.setState({ isDashboardLoader: false });
            }
        }).catch(error => {

        });
    }

    checkPackageType = (userData, index) => {
        if (!this.state.outgoingPackageOpen) {
            this.setState({
                isDashboardLoader: true,
                userPackage: userData.UserName,
                currentRowTableIndex: index
            });
            let body = {
                "AccountId": userData.AccountId
            }
            axios.post('Provisioning/GetOutgoingAvailablePlans', body, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                if (response && response.data) {
                    this.setState({ isDashboardLoader: false });
                    // response.data = []
                    if (response.data.length > 0) {
                        this.setState({
                            outgoingPackageData: response.data
                        });
                    }
                    else this.openModalNewPackege(userData.UserName, index);
                }
            })
                .catch(error => {

                });
        }
        this.setState({ outgoingPackageOpen: !this.state.outgoingPackageOpen });
    }

    selectPackageType = (e) => {
        if (e.target.innerText === this.outgoingCalls) {
            this.setState({
                openOutgoingPackage: true,
                isDropdownPlans: false
            }, () => this.modalToggle('addNewPackegPaymantModal'));
        }
        else if (e.target.innerText === this.spikkoNumber) {
            this.openModalNewPackege(this.state.userPackage, this.state.currentRowTableIndex);
        }
    }

    returnDropdownItemForOutgoingPackage = () => {
        const outgoingPackageAvailablePlansItems = this.state.outgoingPackageData.map((elm, index) =>
            <DropdownItem
                key={index}
                value={elm.Price}
                name={elm.ProductID}
                onClick={(e) => this.onDropClick(e)}
            >
                {`${elm.Name} (${elm.Price}${this.props.intl.formatMessage(messages.USDMonth)})`}
            </DropdownItem>
        );
        return outgoingPackageAvailablePlansItems;
    }

    returnUserTermsModal = () => {
        let arr = [];
        this.state.userTermsData.forEach((item, i) => {
            arr.push(
                <UserTermsModal
                    key={`user-terms-modal-${i}`}
                    userTermsModalData={this.state.userTermsModalData[i]}
                    item={item}
                    approveTerms={this.approveTerms}
                    userTermsModalIndex={i}
                />
            )
        })
        return arr;
    }

    approveTerms = (userTermsModalIndex) => {
        let body = {
            Accountid: this.state.userData.AccountId,
            TermsType: this.state.userTermsData[userTermsModalIndex].TermsType,
            TermsId: this.state.userTermsData[userTermsModalIndex].Id
        }
        axios.post('/Account/ApproveTerms', body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.userTermsModalToggle(userTermsModalIndex);
        }).catch(error => {
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    }

    userTermsModalToggle = (userTermsModalIndex) => {

        let userTermsModalData = this.state.userTermsModalData.slice();

        let currentUserTermsModalData = userTermsModalData[userTermsModalIndex];
        currentUserTermsModalData.toggle = false;
        userTermsModalData[userTermsModalIndex] = currentUserTermsModalData;

        if (userTermsModalIndex + 1 < this.state.userTermsModalData.length) {
            let theNextUserTermsModalData = userTermsModalData[userTermsModalIndex + 1];
            theNextUserTermsModalData.toggle = true;
            userTermsModalData[userTermsModalIndex + 1] = theNextUserTermsModalData;
        }

        this.setState({ userTermsModalData: userTermsModalData });
    }

    render() {
        let showPackageMini = (localStorage.getItem("showMiniPackage") === showPackageAsRegular ||
            localStorage.getItem("showMiniPackage") == null) ? false : true;
        let currentBalance = this.state.userData.CurrentBalance !== undefined ? this.state.userData.CurrentBalance : '';
        let currencyName = this.state.userData.CurrencyName ? this.state.userData.CurrencyName : '';
        let accountBank = this.state.userData.AccountBank ? this.state.userData.AccountBank : '';
        let userDateIsEmpty = this.state.userData.length === 0;
        let hasFalseKeysToCreditDropdown = permissionFunc.checkDropdownItems('dashboard_credit_dropdown_di');
        let hasFalseKeysToBankDropdown = permissionFunc.checkDropdownItems('dashboard_bank_dropdown_di');
        const reportUserStyle = {
            fontFamily: 'Poppins',
            fontize: '14px',
            letterSpacing: '0',
            lineHeight: '21px',
            height: '38px',
            paddingLeft: '15px',
        };
        if (this.state.redirect) {
            return (
                <div className="d-flex">
                    <SideBar
                        active1={!this.props.isMyPackages}
                        active2={this.props.isMyPackages}
                        active3={false}
                        active4={false}
                        active5={false}
                        active6={false}
                        active7={false}
                        active8={false}
                        homePage={() => {
                            this.props.history.push('/', { redirect: true });
                        }}
                        myPackagePage={() => {
                            this.props.history.push('/myPackages', { redirect: true });
                        }}
                        settingPage={() => this.props.history.push('/settings')}
                        crm_integration={() => this.props.history.push('/crm_integration')}
                        contactUs={() => this.props.history.push('/contactUs')}
                        realTimeMonitoring={() => this.props.history.push('/real_time_monitoring')}
                        graph={() => this.props.history.push('/charts')}
                        contacts={() => this.props.history.push('/contacts')}
                        user={this.state.userData}
                    />
                    <div className="container-fluid p-0" style={{ height: "100vh", overflow: "auto" }}>
                        <NavBar
                            logo={this.state.userData.LogoURL}
                            homePage={() => this.componentDidMount()}
                            signOut={() => this.signOut()}
                            mail={this.state.mail}
                            title={!this.props.isMyPackages ? "My users" : "My packages"} />
                        {!this.props.isMyPackages &&
                            <Row className="m-2">
                                <Col className='d-flex'>
                                    <h4><FormattedMessage {...messages.current} /></h4>
                                    <div className='d-flex mx-2'>
                                        {!hasFalseKeysToCreditDropdown &&
                                            <UncontrolledDropdown>
                                                {!permissionFunc.changeDisplay('dashboard_credit_dropdown_di') &&
                                                    <DropdownToggle color={'white'}
                                                        className="mx-2 cursorDef btnBorder gray">
                                                        {/*`Credit | ${currentBalance + ' ' + currencyName || 0}`*/
                                                        `Credit | $${currentBalance || 0}`
                                                        }
                                                        <img src='assets/Shape.svg' alt="" className=" m-2" />
                                                    </DropdownToggle>}
                                                {!hasFalseKeysToCreditDropdown &&
                                                    <DropdownMenu>
                                                        <DropdownItem header><FormattedMessage {...messages.Actions} /> </DropdownItem>
                                                        {!permissionFunc.changeDisplay('dashboard_credit_dropdown_di_purchase_credit_for_calls') &&
                                                            <DropdownItem
                                                                className="my-1 dropItems"
                                                                onClick={() => this.setState({ transferFundsModal: true })}
                                                                id='dashboard_credit_dropdown_di_purchase_credit_for_calls'
                                                            >
                                                                {STRINGS.PURCHASE_CREDIT_FOR_CALLS}
                                                            </DropdownItem>}
                                                    </DropdownMenu>}
                                            </UncontrolledDropdown>}
                                        {!hasFalseKeysToBankDropdown &&
                                            <UncontrolledDropdown>
                                                {!permissionFunc.changeDisplay('dashboard_bank_dropdown_di') &&
                                                    <DropdownToggle color={'white'}
                                                        className="btnBorder gray ">{/*`Bank | ${accountBank + ' ' + currencyName || 0}`*/ `Bank | $${accountBank || 0}`}
                                                        <img src='assets/Shape.svg' alt="" className=" m-2" />
                                                    </DropdownToggle>}
                                                {!hasFalseKeysToBankDropdown &&
                                                    <DropdownMenu>
                                                        <DropdownItem header>Actions</DropdownItem>
                                                        {!permissionFunc.changeDisplay('dashboard_bank_dropdown_di_add_funds_to_bank') &&
                                                            <DropdownItem
                                                                className="my-1 dropItems"
                                                                onClick={() => this.addFundsToggle()}
                                                                id='dashboard_bank_dropdown_di_add_funds_to_bank'
                                                            >
                                                                {STRINGS.ADD_FUNDS_TO_BANK}
                                                            </DropdownItem>}
                                                        {!permissionFunc.changeDisplay('dashboard_bank_dropdown_di_manage_payment_method') &&
                                                            <DropdownItem
                                                                className="my-1 dropItems"
                                                                onClick={() => this.setState({ managePaymentModal: true })}
                                                                id='dashboard_bank_dropdown_di_manage_payment_method'
                                                            >
                                                                {STRINGS.MANAGE_PAYMENT_METHOD}
                                                            </DropdownItem>}
                                                        {!permissionFunc.changeDisplay('dashboard_bank_dropdown_di_show_payments') &&
                                                            <DropdownItem className="my-1 dropItems"
                                                                onClick={() => this.openPaymentsModal(this.state.userData.UserName, this.formatDate(this.endDate()))}
                                                                id='dashboard_bank_dropdown_di_show_payments'
                                                            >
                                                                {STRINGS.SHOW_PAYMENTS}
                                                            </DropdownItem>}
                                                        {/* <DropdownItem
                                                    className="my-1 dropItems"
                                                    onClick={() => this.setState({transferFundsModal: true})}>Transfer funds to credit</DropdownItem> */}
                                                    </DropdownMenu>}
                                            </UncontrolledDropdown>}
                                    </div>
                                </Col>
                            </Row>}
                        <Row className="ml-2 mr-0 d-block mb-2">
                            {!this.props.isMyPackages && <h6 className="mx-3">Search user by</h6>}
                            <div md={12} sm='12' className='d-flex justify-content-between'>
                                <Col className='col-4 d-flex'>
                                    {!this.props.isMyPackages && <React.Fragment>
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="border mr-2" color={'white'} caret>
                                                {this.state.dropDownValue}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={(e) => this.changeValue(e)}>Email</DropdownItem>
                                                <DropdownItem onClick={(e) => this.changeValue(e)}>Name</DropdownItem>
                                                <DropdownItem onClick={(e) => this.changeValue(e)}>Mobile2CRM number</DropdownItem>
                                                <DropdownItem onClick={(e) => this.changeValue(e)}>Destination number</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <div className='border-5 col-6 form-control '>
                                            <input
                                                ref={(exSearch) => {
                                                    this.exSearch = exSearch
                                                }}
                                                autoComplete={'chrome-off'}
                                                style={{ outline: 'none' }}
                                                name="searchButton"
                                                id="searchButton"
                                                type='text'
                                                placeholder={`User ${this.state.dropDownValue}`}
                                                className='border-0 col-11 h-100 px-0 w-100 ' />
                                            <label
                                                htmlFor="searchButton"
                                                className='position-absolute cursorPointer'
                                                onClick={() => this.searchButton()}>
                                                <img src='assets/search-outline.svg' alt="" />
                                            </label>
                                        </div>
                                    </React.Fragment>}
                                    {showPackageMini ?
                                        <img
                                            src='assets/smallPackageIconImage.png'
                                            alt='view-package-icon'
                                            id='small-package'
                                            className='hover-zoom'
                                            style={{
                                                height: '20px',
                                                marginLeft: '23px',
                                                marginTop: '9px'
                                            }}
                                            onClick={this.showMiniPackageOnClick}
                                        /> :
                                        <img
                                            src='assets/view-package-table-without-background.png'
                                            alt='view-small-package-icon'
                                            id='small-package'
                                            className='hover-zoom'
                                            style={{
                                                height: '38px',
                                                marginLeft: '13px',
                                            }}
                                            onClick={this.showMiniPackageOnClick}
                                        />}
                                    <Tooltip
                                        placement="right"
                                        isOpen={this.state.tooltipOpen}
                                        target="small-package"
                                        toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}>
                                        {!showPackageMini ? 'Table view' : 'Package view'}
                                    </Tooltip>
                                </Col>
                                {!this.props.isMyPackages && <div className='d-flex pr-3'>
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            className="border mr-2"
                                            style={{ backgroundColor: '#EBEBEB' }}
                                            color={'#EBEBEB'}
                                            caret
                                        >
                                            <img src='assets/user-report-icon.svg' alt="" />
                                            &nbsp;
                                            {STRINGS.USER_REPORT}
                                            &nbsp;&nbsp;
                                            <span className='report-user-btn-line' />
                                            &nbsp;&nbsp;
                                        </DropdownToggle>
                                        <DropdownMenu >
                                            {!permissionFunc.changeDisplay('dashboard_btn_users_sms_report') &&
                                                <DropdownItem
                                                    onClick={() => this.setState({ usersSmsReportToggle: !this.state.usersSmsReportToggle })}
                                                    disabled={userDateIsEmpty}
                                                    id='dashboard_btn_users_sms_report'
                                                    style={reportUserStyle}
                                                >
                                                    <img alt='sms-report-icon' src='assets/sms-report-icon.svg' className='report-user-sms-report-btn' />
                                                    &nbsp;
                                                    {STRINGS.SMS_REPORT}
                                                </DropdownItem>}
                                            {!permissionFunc.changeDisplay('dashboard_btn_users_call_report') &&
                                                <DropdownItem
                                                    onClick={() => this.setState({ userCallReport: true })}
                                                    disabled={userDateIsEmpty}
                                                    id='dashboard_btn_users_call_report'
                                                    style={reportUserStyle}
                                                >
                                                    <img alt='call-report-icon' src='assets/call-report-new-icon.svg' className='report-user-call-report-btn' />
                                                    &nbsp;
                                                    {STRINGS.CALL_REPORT}
                                                </DropdownItem>}
                                            {!permissionFunc.changeDisplay('dashboard_btn_users_messages_report') &&
                                                <DropdownItem
                                                    onClick={() => this.setState({ reportToggle: !this.state.reportToggle })}
                                                    disabled={userDateIsEmpty}
                                                    id='dashboard_btn_user_report'
                                                    style={reportUserStyle}
                                                >
                                                    <img alt='iphon-icon' src='assets/iphon-icon.svg' className='iphon-icon-messages-report-btn' />
                                                    &nbsp;
                                                    {STRINGS.MESSAGES_REPORT}
                                                </DropdownItem>}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {!permissionFunc.changeDisplay('dashboard_btn_upload_users_by_csv') &&
                                        <Button onClick={() => this.setState({ uploadUsers: true })}
                                            style={{ color: 'white' }} color={'white'} className="btnColor2 mr-2">
                                            <img src='assets/upload-users.svg' alt="" className="" id='dashboard_btn_upload_users_by_csv'
                                                style={{ marginRight: "5px" }} />
                                            Upload users by CSV
                                        </Button>}
                                    {!permissionFunc.changeDisplay('dashboard_btn_register_user') &&
                                        <Button
                                            onClick={() => {
                                                this.setState({ registerUserModal: true, template: false });
                                                this.getAccountTemplates();
                                            }}
                                            color={''}
                                            style={{ color: 'white' }}
                                            className="btnColor2"
                                            id='dashboard_btn_register_user'>
                                            + Register user
                                        </Button>}
                                </div>}
                            </div>
                        </Row>
                        {this.state.isDashboardLoader ?
                            <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }}
                                src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                            : null}
                        {this.state.renderUsersOrUploadFileField && <React.Fragment><Row
                            style={{
                                height: '70vh',
                                overflow: 'auto'
                            }}
                            className=" ml-2 mr-0"
                        >
                            {(this.state.users && this.state.users.length > 0)
                                ?
                                <Table hover className='m-3' style={{ height: 'max-content', borderCollapse: 'separate', borderSpacing: '0 15px' }}>
                                    <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th></th>
                                            <th>Full name</th>
                                            <th>Registration date</th>
                                            <th>Login password</th>
                                            <th>Action</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="notranslate">
                                        {this.dashboardList(showPackageMini)}
                                    </tbody>
                                </Table>

                                : <NoUsers homePage={() => this.componentDidMount()} />}
                        </Row>
                            {!this.props.isMyPackages &&
                                <Pagination style={{
                                    marginLeft: "30vw",
                                    marginTop: "2vh"
                                }}
                                    selectComponentClass={Select}
                                    showQuickJumper
                                    showSizeChanger
                                    showTotal={(total) => this.state && this.state.users ? this.state.users.length : 0}
                                    defaultPageSize={10}
                                    defaultCurrent={1}
                                    current={this.state.pageCurrent}
                                    onShowSizeChange={(current, pageSize) => this.showSizeChanger(current, pageSize)}
                                    onChange={(current, pageSize) => this.paginationOnChange(current, pageSize)}
                                    locale={localeInfo}
                                    total={this.state && this.state.users ? this.state.users.length : 0}
                                />}
                        </React.Fragment>}

                    </div>
                    {this.state.deleteUserClicked ? this.deleteUserFormModal() : null}
                    <Modal className="iframeModal" isOpen={this.state.iframeModal}
                        toggle={() => this.modalToggle('iframeModal')}>
                        <iframe src={this.state.iframeURL} title="aaa" width='1000px' height='720px'
                            name="theFrame"></iframe>
                        <Button color={''} style={{ color: 'white' }} className="btnColor2 col-1 ml-auto mr-2 my-2"
                            onClick={() => this.setState({ addFundsModal: true, iframeModal: false })}>OK</Button>
                    </Modal>
                    <Modal isOpen={this.state.orderIdModal} toggle={() => this.modalToggle('orderIdModal')}>
                        <div dangerouslySetInnerHTML={{ __html: this.state.orderData }} />
                    </Modal>
                    <Modal
                        className="fundsModal"
                        isOpen={this.state.addFundsModal}
                        toggle={() => this.modalToggle('addFundsModal')}>
                        <ModalHeader toggle={() => this.modalToggle('addFundsModal')}>Add Funds to your
                            Bank</ModalHeader>
                        <ModalBody>
                            How much would you like to deposit?
                            <div className="d-flex">
                                <Input
                                    className="col-3 mt-1"
                                    type="number"
                                    name="funds"
                                    id="fundsToBank"
                                    innerRef={(addFunds) => {
                                        this.addFunds = addFunds
                                    }} />
                                <span className="m-2">USD</span>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => this.addFundsToBank()} style={{ color: 'white' }} color={''}
                                className="col-12 btnColor3">
                                Confirm</Button>
                            {this.state.isLoader6 ?
                                <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }}
                                    src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                                : null}
                        </ModalFooter>
                    </Modal>
                    <Modal className="paymentModal paymentContantModal"
                        isOpen={this.state.showPayments}
                        toggle={() => this.modalToggle('showPayments')}>
                        <div className="d-flex m-4 borderButtom  pb-4">
                            <h4>Payments</h4>
                            <Button
                                onClick={() => this.downloadPaymentToCsv()}
                                style={{ color: 'white' }} color={''}
                                className="btnColor2 ml-auto">
                                <img src='assets/export.svg' alt="" className="mr-1" />Download as csv
                            </Button>
                        </div>
                        <ModalBody>
                            <Row>
                                <Col className="ml-4" md={4}>
                                    <Label className="gray">From</Label>
                                    <div>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            onChange={(date) => this.setState({
                                                startDate: date
                                            })}
                                        /></div>
                                </Col>
                                <Col md={4}>
                                    <Label className="gray">To</Label>
                                    <div>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            onChange={(date) => this.setState({
                                                endDate: date
                                            })}
                                        /></div>
                                </Col>
                                <Button onClick={() => this.searchPayment()} color={'white'}
                                    className="btnColor1 col-1 mt-4">Search</Button>
                            </Row>
                            <Row
                                style={{
                                    overflow: 'auto'
                                }}
                                className=" ml-2 mr-0">
                                {this.paymentList()
                                    ? <Table hover className='m-3'>
                                        <thead>
                                            <tr>
                                                <th>Order ID</th>
                                                <th>Date</th>
                                                <th>Payment method</th>
                                                <th>Amount</th>
                                                <th>Payment type</th>
                                                <th>Payment status</th>
                                                <th>Invoice</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.paymentList()}
                                        </tbody>
                                    </Table>

                                    : ''}
                                {this.state.isLoader8 ?
                                    <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }}
                                        src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                                    : null}                    </Row>
                        </ModalBody>
                    </Modal>
                    <Modal
                        className="fundsModal"
                        isOpen={this.state.transferFundsModal}
                        toggle={() => this.modalToggle('transferFundsModal')}>
                        <ModalHeader>Purchase Credit for calls</ModalHeader>
                        <ModalBody>
                            How much would you like to purchase?
                            <div className="d-flex">
                                <Input
                                    className="col-3 mt-1"
                                    type="number"
                                    name="funds"
                                    id="fundsToBank"
                                    innerRef={(funds) => {
                                        this.funds = funds
                                    }} />
                                <span className="m-2">USD</span>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={() => this.transferFundsModalClickButton()}
                                style={{ color: 'white' }} color={''}
                                className="col-12 btnColor3">
                                Confirm</Button>
                            {this.state.isLoader7 ?
                                <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }}
                                    src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                                : null}
                        </ModalFooter>
                    </Modal>

                    <Modal
                        isOpen={this.state.addNewPackegModal}
                        toggle={() => this.modalToggle('addNewPackegModal')}>
                        <ModalHeader className="text-center justify-content-center widthModal">
                            <div className='d-flex border-bottom d-flex pb-2 mb-4'>
                                <div className="btnColor2 text-center"
                                    style={{ color: 'white', borderRadius: '50%', height: '30px', width: '30px' }}>1
                                </div>
                                <div className="border-bottom col mb-3 mx-2"></div>
                                <div className="btnColor4 text-center"
                                    style={{ color: '#9199AB', borderRadius: '50%', height: '30px', width: '30px' }}>2
                                </div>
                                <div className="border-bottom col mb-3 mx-2"></div>
                                <div className="btnColor4 text-center"
                                    style={{ color: 'white', borderRadius: '50%', height: '30px', width: '30px' }}><img
                                        src='assets/ic-flag-24-px.png' alt="" className=" m-2" /></div>
                            </div>
                            <div className="mb-1">Add new package</div>
                            <div>
                                <h6 className="mb-1">Step 1:</h6>
                                <h6 className="my-3 font-weight-bold">Select your source and destination</h6></div>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup className="mt-2">
                                <InputLabel htmlFor="input-with-icon-adornment">
                                    Select a country for your new number
                                </InputLabel>
                                <Autocomplete
                                    id="field1"
                                    className="new-package-modal-select-country"
                                    options={this.state.countries}
                                    getOptionLabel={(option) => option}
                                    onChange={(event, newValue) =>
                                        this.onOptionClick(event, 'newCountry', newValue)}
                                    size="small"
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Select a country…"
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                        />}
                                />
                            </FormGroup>
                            <FormGroup>
                                <InputLabel htmlFor="input-with-icon-adornment">
                                    Location
                                </InputLabel>
                                <Autocomplete
                                    id="new-package-modal-select-location"
                                    disabled={this.state.isDropdownLocation}
                                    options={this.state.locations}
                                    getOptionLabel={(option) => option}
                                    onChange={(event, newValue) =>
                                        this.onOptionClick(event, 'location', newValue)}
                                    size="small"
                                    renderInput={(params) =>
                                        <TextField {...params} label="Select state / city" variant="outlined" inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "disabled", // disable autocomplete and autofill
                                        }} />}
                                />
                                {this.state.isLoader1
                                    ? <img
                                        style={{
                                            marginLeft: '45%',
                                            width: '60px',
                                            height: '50px',
                                            marginTop: 'auto'
                                        }}
                                        src='assets/spikkoloaderanimation.svg'
                                        alt=""
                                        className="loginImg" />
                                    : null}
                            </FormGroup>
                            {this.state.numberless ? <FormGroup>
                                <InputLabel>Number</InputLabel>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        disabled={this.state.isDropdownNumber}
                                        className="btn-block btn-light"
                                        caret>
                                        {this.state.newNumber}&nbsp;&nbsp;&nbsp;&nbsp;
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {this.state.items4}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {this.state.isLoader2
                                    ? <img
                                        style={{
                                            marginLeft: '45%',
                                            width: '60px',
                                            height: '50px',
                                            marginTop: 'auto'
                                        }}
                                        src='assets/spikkoloaderanimation.svg'
                                        alt=""
                                        className="loginImg" />
                                    : null}
                            </FormGroup> : <React.Fragment />}
                            <FormGroup className="border-top pt-4">
                                <InputLabel>Country</InputLabel>
                                <Autocomplete
                                    // disabled={this.state.isDropdownUserCountry}
                                    id="field2"
                                    className="new-package-modal-user-country"
                                    options={this.state.userCountry}
                                    getOptionLabel={(option) => option}
                                    onChange={(event, newValue) =>
                                        this.onOptionClick(event, 'oldCountry', newValue)}
                                    size="small"
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Select a country…"
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                        />}
                                />
                            </FormGroup>
                            <FormGroup>
                                <InputLabel>Enter your divert number</InputLabel>
                                <Input
                                    onChange={(e) => this.setState({ prefix: e.target.value })}
                                    value={this.state.prefix}
                                    type="text"
                                    name="oldNumber"
                                    id="oldNumber"
                                    innerRef={(oldNumber) => {
                                        this.oldNumber = oldNumber
                                    }} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => this.moveToStep2()} color={''} style={{ color: 'white' }}
                                className="col-12 btnColor2">
                                Next</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal
                        className="fundsModal"
                        isOpen={this.state.selectPaymentModal}
                        toggle={() => this.modalToggle('selectPaymentModal')}>
                        <ModalHeader>Select a payment method</ModalHeader>
                        <ModalBody>
                            Please note that this will be your payment method for all payments.

                            <FormGroup className="ml-auto mr-3" check>
                                <Label check>
                                    <Input
                                        onChange={() => this.setState({
                                            ischeck1: !this.state.ischeck1,
                                            ischeck2: false
                                        })}
                                        checked={this.state.ischeck1}
                                        className="mt-4"
                                        type="checkbox"
                                        name="radio1" />{' '}
                                    <img src='assets/group-2.png' alt="" className="ml-1" />
                                </Label>

                                <Label check>
                                    <Input
                                        onChange={() => this.setState({
                                            ischeck2: !this.state.ischeck2,
                                            ischeck1: false
                                        })}
                                        checked={this.state.ischeck2}
                                        className="mt-4"
                                        type="checkbox"
                                        name="radio1" />{' '}
                                    <img src='assets/group-14.png' alt="" className="ml-1" />
                                </Label>
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={() => this.selectPayment()}
                                color=""
                                style={{
                                    color: 'white'
                                }}
                                className="col-12 btnColor2">
                                Next
                                <img src='assets/arrow.svg' alt="" className="ml-1" /></Button>
                        </ModalFooter>
                    </Modal>

                    <Modal
                        isOpen={this.state.addNewPackegPaymantModal}
                        toggle={() => this.modalToggle('addNewPackegPaymantModal')}
                    >
                        <ModalHeader className="text-center justify-content-center widthModal">
                            <div className='d-flex border-bottom d-flex pb-2 mb-4'>
                                {!this.state.openOutgoingPackage ? <React.Fragment>
                                    <div className="btnColor2 text-center"
                                        style={{ color: 'white', borderRadius: '50%', height: '30px', width: '30px' }}>✓
                                    </div>
                                    <div className="btnBorderButtom col mb-3 mx-2"></div>
                                </React.Fragment> : null}
                                <div className="btnColor2 text-center"
                                    style={{ color: '#9199AB', borderRadius: '50%', height: '30px', width: '30px' }}>
                                    {!this.state.openOutgoingPackage ? '2' : '1'}
                                </div>
                                <div className="border-bottom col mb-3 mx-2"></div>
                                <div className="btnColor4 text-center"
                                    style={{ color: 'white', borderRadius: '50%', height: '30px', width: '30px' }}><img
                                        src='assets/ic-flag-24-px.png' alt="" className=" m-2" /></div>
                            </div>
                            <div className="mb-1">Add new package</div>
                            <div>
                                <h6 className="mb-1">{!this.state.openOutgoingPackage ? 'Step 2:' : 'Srep 1'}</h6>
                                <h6 className="my-3  font-weight-bold">Plan & Payment method details</h6></div>
                        </ModalHeader>
                        <ModalBody className="ml-2">
                            <FormGroup>
                                <Label>Select from available plans</Label>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        disabled={this.state.isDropdownPlans}
                                        className="btn-block btn-light"
                                        caret>
                                        {this.state.plans}&nbsp;
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {!this.state.openOutgoingPackage ? this.state.items5 : this.returnDropdownItemForOutgoingPackage()}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {this.state.isLoader4
                                    ? <img
                                        style={{
                                            marginLeft: '45%',
                                            width: '60px',
                                            height: '50px',
                                            marginTop: 'auto'
                                        }}
                                        src='assets/.svg'
                                        alt=""
                                        className="loginImg" />
                                    : null}
                            </FormGroup>
                            <FormGroup className='d-flex'>
                                <div>
                                    <Label>You will be charged with</Label>
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            className="btn-block btn-light"
                                            caret>
                                            {this.state.charged}&nbsp;
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.userData && this.state.userData.PaymentMethod ?
                                                <DropdownItem onClick={this.paymentMethodItemOnClick}>
                                                    {this.state.userData.PaymentMethod === this.paymentMethodOfCreditGuard ?
                                                        'Card Credit' :
                                                        this.state.userData.PaymentMethod === this.paymentMethodOfPayPal ?
                                                            'PayPal Credit' : ''}
                                                </DropdownItem> : null}
                                            {this.state.userData && this.state.userData.AccountBank > 0 ?
                                                <DropdownItem onClick={(e) => this.setState({ charged: e.target.innerText, paymentmethod: 'Bank' })}>
                                                    Bank Credit
                                                </DropdownItem> : null}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                                <Label className="ml-5 col-5">Price
                                    <div className="pt-1">{this.state.price}</div></Label>
                            </FormGroup>
                            <h6>Advanced options</h6>
                            <FormGroup className="d-flex">
                                <Label
                                    style={{
                                        paddingLeft: '20px'
                                    }}
                                    className="mt-2 check"
                                    check>
                                    <Input
                                        type="checkbox"
                                        onChange={() => this.setState({
                                            advancedOptions: !this.state.advancedOptions
                                        })} />
                                    Cancel after:
                                </Label>

                                <Input className="col-2 ml-3" type="number"
                                    disabled={this.state.advancedOptions}
                                />
                                <div className="ml-2 mt-2">Months</div>
                            </FormGroup>
                            <FormGroup className="d-flex">
                                <div className='custom-control custom-switch isolate-the-design'>

                                    <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        id='customSwitches1'
                                        readOnly
                                        checked={this.state.switchCallRecording}
                                        onChange={() => this.setState({ switchCallRecording: !this.state.switchCallRecording })}
                                    />
                                    <label
                                        style={{ paddingLeft: '0.7rem', paddingTop: '0.2rem' }}
                                        className='custom-control-label'
                                        htmlFor='customSwitches1'
                                    >
                                        Call recording
                                    </label>
                                </div>
                                {!this.state.openOutgoingPackage && <div className='custom-control custom-switch ml-5 isolate-the-design'>
                                    <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        id='customSwitches2'
                                        readOnly
                                        checked={this.state.switchSmsRecording}
                                        onChange={() => this.setState({ switchSmsRecording: !this.state.switchSmsRecording })}
                                    />
                                    <label
                                        style={{ paddingLeft: '0.7rem', paddingTop: '0.2rem' }}
                                        className='custom-control-label ml-4'
                                        htmlFor='customSwitches2'
                                    >
                                        SMS recieving
                                    </label>
                                </div>}
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={this.step2NextOnClick}
                                color={''}
                                style={{ color: 'white' }}
                                className="col-12 btnColor2"
                            >
                                Next
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal
                        isOpen={this.state.addNewPackegModalConfirm}
                        toggle={() => this.modalToggle('addNewPackegModalConfirm')}
                    >
                        <ModalHeader className="text-center justify-content-center widthModal">
                            <div className='d-flex border-bottom d-flex pb-2 mb-4'>
                                <div className="btnColor2 text-center"
                                    style={{ color: 'white', borderRadius: '50%', height: '30px', width: '30px' }}>✓
                                </div>
                                <div className={`btnBorderButtom col mb-3 ${!this.state.openOutgoingPackage ? 'mx-2' : 'ml-2'}`}></div>
                                {!this.state.openOutgoingPackage && <div className="btnColor2 text-center"
                                    style={{ color: 'white', borderRadius: '50%', height: '30px', width: '30px' }}>✓
                                </div>}
                                {/* <div className="btnBorderButtom col mb-3 mx-2"></div> */}
                                <div className={`btnBorderButtom col mb-3 ${!this.state.openOutgoingPackage ? 'mx-2' : 'mr-2'}`}></div>
                                <div className="btnColor2 text-center"
                                    style={{ color: 'white', borderRadius: '50%', height: '30px', width: '30px' }}><img
                                        src='assets/ic-flag-25-px.png' alt="" className=" m-2" /></div>
                            </div>
                            <div>
                                <h6 className="mb-1">{!this.state.openOutgoingPackage ? 'Step 3:' : 'Srep 2'}</h6>
                                <h6 className="my-3 font-weight-bold">Package confirmation</h6>
                                <h4>You are about to purchase <br />our Pay As You Talk Plan</h4></div>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Card style={{ marginTop: '50px', backgroundColor: '#EFEFEF' }}>
                                    <Col style={{ marginTop: '-40px' }} className="text-center">
                                        <img src="assets/group-37.png" alt="" />
                                    </Col>
                                    <Row>
                                        <Label className="lableFont col ml-1">Package
                                            <div className="fontColor">{this.state.plans}</div>
                                        </Label>
                                        <Label className="lableFont col ml-2">Payment method
                                            <div className="fontColor">{this.state.charged}</div>
                                        </Label>
                                        <Label className="lableFont col ml-2">Price
                                            <div className="fontColor">{this.state.price}</div>
                                        </Label>
                                    </Row>
                                    {!this.state.openOutgoingPackage && <Col className="d-flex justify-content-center mt-2">
                                        <div className="mr-4">
                                            <Label style={{ color: 'rgba(60, 63, 74, 0.49)' }}>Local number</Label>
                                            <div className="font-weight-bold">{this.state.newNumber}</div>
                                        </div>
                                        <img style={{ height: 'min-content' }} src="assets/arrow-right.png" alt="" />
                                        <div className="ml-4">
                                            <Label style={{ color: 'rgba(60, 63, 74, 0.49)' }}>Forward to:</Label>
                                            <div className="font-weight-bold">{formatNumbers(this.state.oldNumber)}</div>
                                        </div>
                                    </Col>}
                                </Card>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => this.confirmPackage()} color={''} style={{ color: 'white' }}
                                className="col-12 btnColor3">
                                ✓ Confirm & purchase</Button>
                        </ModalFooter>
                        {this.state.isLoader3
                            ? <img
                                style={{
                                    marginLeft: '45%',
                                    width: '60px',
                                    height: '50px',
                                    marginTop: 'auto'
                                }}
                                src='assets/spikkoloaderanimation.svg'
                                alt=""
                                className="loginImg" />
                            : null}
                    </Modal>

                    <Modal
                        isOpen={this.state.uploadUsers}
                        toggle={() => this.modalToggle('uploadUsers')}>
                        <ModalHeader className="justify-content-center">Upload users</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Form>
                                    <NoUsers homePage={() => this.modalToggle('uploadUsers')} isExistingUsers={true} />
                                </Form>
                            </FormGroup>
                        </ModalBody>
                    </Modal>
                    {/** display user report model */}

                    <Modal
                        className="paymentModal"
                        isOpen={this.state.modeDisplayReport}
                        toggle={() => this.modalToggle('modeDisplayReport')}>
                        <Report
                            isSpecificUser={true}
                            currentUser={this.state.currentUser}
                            parentUser={this.state.userData}
                            downloadCsv={() => this.downloadCsv()} />
                    </Modal>


                    {this.state.oldUsers && this.state ?
                        <Modal
                            className="paymentModal"
                            isOpen={this.state.userCallReport}
                            toggle={() => this.modalToggle('userCallReport')}>
                            <Report
                                isSpecificUser={false}
                                users={this.state.oldUsers}
                                currentUser={this.state.userData}
                                parentUser={this.state.userData}
                                downloadCsv={() => this.downloadCsv()}
                            />
                        </Modal>
                        : null}

                    <Modal
                        isOpen={this.state.managePaymentModal}
                        toggle={() => this.modalToggle('managePaymentModal')}>
                        <ModalHeader>Manage a payment method</ModalHeader>
                        <ModalBody>
                            <span>
                                Please note that this will be your payment method for all payments.</span>
                            <h5 className="text-center">{this.state.userData.PaymentMethod === 'CreditGuard'
                                ? `xxxx-xxxx-xxxx-${this.state.userData.PaymentMethodDetails || ''}`
                                : this.state.userData.PaymentMethodDetails || ''}</h5>
                            <FormGroup className="ml-auto mr-3" check>
                                <Label check>
                                    <Input
                                        onChange={() => this.setState({
                                            ischeck1: !this.state.ischeck1,
                                            ischeck2: false
                                        })}
                                        checked={this.state.ischeck1}
                                        className="mt-4"
                                        type="checkbox"
                                        name="radio1" />{' '}
                                    <img src='assets/group-2.png' alt="" className="ml-1" />
                                </Label>

                                <Label check>
                                    <Input
                                        onChange={() => this.setState({
                                            ischeck2: !this.state.ischeck2,
                                            ischeck1: false
                                        })}
                                        checked={this.state.ischeck2}
                                        className="mt-4"
                                        type="checkbox"
                                        name="radio1" />{' '}
                                    <img src='assets/group-14.png' alt="" className="ml-1" />
                                </Label>
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={() => this.selectPayment()}
                                color=""
                                style={{
                                    color: 'white'
                                }}
                                className="col-12 btnColor2">
                                Next
                                <img src='assets/arrow.svg' alt="" className="ml-1" /></Button>
                        </ModalFooter>
                    </Modal>
                    <Modal
                        className="fundsModal"
                        isOpen={this.state.registerUserModal}
                        toggle={() => this.modalToggle('registerUserModal')}>
                        <ModalHeader className="justify-content-center text-center"><img src='assets/group-15.png'
                            alt="" className="ml-1" />
                            <div>Register user</div>
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                {this.state.accountTemplates.length > 0 ?
                                    <span>
                                        <Label
                                            style={{
                                                paddingLeft: '20px'
                                            }}
                                            className="mt-2 check"
                                            check>
                                            <Input
                                                type="checkbox"
                                                onChange={() => {
                                                    this.setState({
                                                        template: !this.state.template
                                                    })
                                                    this.getPrefixOfCountries()
                                                }} />
                                            Register from Template?
                                        </Label>
                                        {this.state.template ?
                                            <UncontrolledDropdown className="border border-5">
                                                <DropdownToggle className='choose-template-btn' style={{ width: "100%" }} color={'white'} caret>
                                                    {this.state.tampletDropdownValue}
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    {this.state.accountTemplates.map((temp, i) => {
                                                        return <DropdownItem key={i}
                                                            onClick={(e) => this.tampletDropdownValueHandler(e, i)}>{temp.Username}</DropdownItem>
                                                    })}

                                                </DropdownMenu>
                                            </UncontrolledDropdown> : null}
                                    </span> : null}
                                <br />
                                <br />
                                <div className="d-flex">
                                    <FormGroup>
                                        <Label for="firstName">First name</Label>
                                        <Input
                                            type="text"
                                            name="firstName"
                                            id="exFirstName"
                                            innerRef={(firstName) => {
                                                this.firstname = firstName
                                            }} />
                                    </FormGroup>
                                    <FormGroup className="ml-4">
                                        <Label for="lastName">Last name</Label>
                                        <Input
                                            type="text"
                                            name="lastName"
                                            id="exLastName"
                                            innerRef={(lastName) => {
                                                this.lastName = lastName
                                            }} />
                                    </FormGroup>
                                </div>
                                <FormGroup>
                                    <Label for="newEmail">Email</Label>
                                    <Input
                                        type="email"
                                        name="newEmail"
                                        id="exnewEmail"
                                        innerRef={(email) => {
                                            this.email = email
                                        }} />
                                </FormGroup>
                                <div className="d-flex">
                                    <div>
                                        <Label for="exNewPassword">Password</Label>
                                        <Input
                                            type="password"
                                            name="exNewPassword"
                                            id="exNewPassword"
                                            innerRef={(password) => {
                                                this.password = password
                                            }} />
                                    </div>
                                    <div className="ml-4">
                                        <Label for="RepeatPassword">Repeat password</Label>
                                        <Input
                                            type="password"
                                            name="RepeatPassword"
                                            id="RepeatPassword"
                                            innerRef={(repeatPassword) => {
                                                this.repeatPassword = repeatPassword
                                            }} />
                                    </div>
                                </div>

                                {this.state.template ?
                                    <span>
                                        <FormGroup className="border-top mt-3">
                                            <Label>Destination Country</Label>
                                            <Autocomplete
                                                id=""
                                                className=""
                                                options={this.state.allPrefixOfCountries.length > 0 ? this.state.allPrefixOfCountries : []}
                                                getOptionLabel={(option) => option.CountryName}
                                                onChange={this.selectCountry}
                                                size="small"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        label="Select a country…"
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "disabled", // disable autocomplete and autofill
                                                        }}
                                                    />}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="destination">Destination Number</Label>
                                            <Input
                                                value={this.state.destination}
                                                type="number"
                                                name="destination"
                                                id="exDestination"
                                                onChange={(e) => this.setDestination(`${e.target.value}`)}
                                            />
                                        </FormGroup>
                                    </span>
                                    :
                                    <Label
                                        style={{
                                            paddingLeft: '20px'
                                        }}
                                        className="mt-2 check"
                                        check>
                                        <Input
                                            type="checkbox"
                                            onChange={() => this.setState({
                                                useParentCredit: !this.state.useParentCredit
                                            })} />
                                        Uses Central Credit
                                    </Label>}

                                {this.state.isLoader
                                    ? <img
                                        style={{
                                            width: '60px',
                                            height: '50px',
                                            margin: 'auto'
                                        }}
                                        src='assets/spikkoloaderanimation.svg'
                                        alt=""
                                        className="loginImg d-flex" />
                                    : null}

                            </Form>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                disabled={this.state.isAgreement}
                                style={{
                                    backgroundColor: ' #2EC2FE'
                                }}
                                className="col-12"
                                onClick={() => this.state.template
                                    ? this.registerUserFromTemplate()
                                    : this.registerUser()}
                                block>{this.state.template ? "Register from Template" : "Save"}
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {<PasswordExpiredModal
                        match={this.props.match}
                        passwordExpired={this.state.passwordExpired}
                        history={this.props.history}
                        location={this.props.location}
                        modalCancel={this.modalCancel}
                        passwordExpiredToggle={this.passwordExpiredToggle}
                    />}
                    {<OpenUserPageModal
                        open={this.state.redirectToUserPage}
                        openUserPageToggle={this.openUserPageToggle}
                        username={this.state.currentUserEmailwhenSelectOpenUserPage}
                    />}
                    <Modal
                        isOpen={this.state.usersSmsReportToggle}
                        toggle={() => this.setState({ usersSmsReportToggle: !this.state.usersSmsReportToggle })}
                        style={{ width: '27.125rem', marginTop: '10%' }}
                    >
                        <SmsReport
                            isSpecificUser={false}
                            currentUser={this.state.userData}
                            users={this.state.oldUsers}
                        />
                    </Modal>
                    <Modal
                        isOpen={this.state.reportToggle}
                        toggle={() => this.setState({ reportToggle: !this.state.reportToggle })}
                        style={{ width: '27.125rem', marginTop: '10%' }}
                    >
                        <ModelManagement
                            modalType={USER_REPORT_ENUM.MESSAGES_MODAL_TYPE}
                            isSpecificUser={false}
                            currentUser={this.state.userData}
                            users={this.state.oldUsers}
                            headerText={STRINGS.GET_USER_MESSAGES_REPORT}
                        />
                    </Modal>
                    {this.returnUserTermsModal()}
                </div>
            );
        } else {
            return (<Redirect to='/login' />)
        }
    }
}

export default injectIntl(Dashboard);

