import React from 'react';
import './newContactModal.css';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Col,
    Label,
    FormGroup,
} from 'reactstrap';
import * as STRINGS from '../../strings/english';

const NewContactModal = (props) => {

    return (
        <React.Fragment>
            <ModalHeader toggle={props.toggle}>
                {`${props.isNewContact ? STRINGS.ADD :
                    STRINGS.EDIT} contact`}
            </ModalHeader>
            <ModalBody>
                <FormGroup row>
                    <Label
                        for="new-contact-name"
                        sm={2}
                    >
                        {STRINGS.NAME}
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="new-contact-name"
                            name="contact-name"
                            onChange={(e) => props.setName(e.target.value)}
                            value={props.name}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        for="new-contact-number"
                        sm={2}
                    >
                        {STRINGS.PHONE_NUMBER}
                    </Label>
                    <Col
                        className='new-contact-number-col'
                        sm={10}
                    >
                        <Input
                            id="new-contact-number"
                            name="contact-number"
                            type='number'
                            onChange={(e) => props.setNumber(e.target.value)}
                            value={props.number}
                            disabled={props.disabledNumber}
                        />
                    </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    className='btnColor2 white fs-14 no-border btnColor2'
                    onClick={props.addContactOnClick}
                >
                    {STRINGS.SAVE}
                </Button>
            </ModalFooter>
        </React.Fragment>
    )
}

export default NewContactModal;