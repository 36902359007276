import React, { Component } from 'react';
import './index.css';
import SideBar from '../../components/sideBar';
import NavBar from '../../components/navBar';
import axios from '../../axios';
import sourceVariable from '../../globalStartSession';
import FileDrop from 'react-file-drop';
import {
    Button,
    Input,
    FormGroup,
    Label,
    Modal,
    Col,
    Popover,
    PopoverHeader,
    PopoverBody,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import * as STRINGS from '../../strings/english';
import * as permissionFunc from '../../permissionScheme/permissionFunc';
import MfaModal from './mfaModal';

class Settings extends Component {
    state = {
        dataUser2: {},
        userData: {},
        selectedFile: null,
        fileName: 'Choose a file',
        fileLogo: null,
        // adminFullName: '',
        companyName: '',
        additionDetails: '',
        orgChargeDate: '',
        password: '',
        email: '',
        isLoader: false,
        payMent: null,
        iframeModal: false,
        iframeURL: '',
        // repeatPassword: ''
        confirmPassword: '',
        fullName: '',
        userEmail: '',
        loginWithDropdownOpen: false,
        mfaModalToggle: false,
    }

    componentDidMount() {
        const grantType = process.env.REACT_APP_GRANDE_TYPE_SF;
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET_SF;
        const clientId = process.env.REACT_APP_CLIENT_ID_SF;
      //const salesForceUrl = `${process.env.REACT_APP_SF_URL}token`;
      const salesForceUrl = localStorage.getItem('REACT_APP_SF_URL');

        this.getUserData();

        const params = new Map(this.props.location.search.slice(1).split('&').map(kv => kv.split('=')))
        const co = this.props.location.search.split('=')[1];

        if (params.has('code')) {
            var uri = window.location.href.split("?")[0];
            axios.post('/Provisioning/UpdateSFApexDetails', {
                "username": localStorage.getItem('email'),
                "authorization_code": decodeURIComponent(co),
                "crmUrl": salesForceUrl,
                "redirectUri": uri
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
            }).catch(error => {
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
    }

    getUserData = () => {
        axios.get(`/Account/GetUserByUsername/${localStorage.getItem('email')}/false`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({
                userData: response.data,
                password: response.data.SitePassword,
                fullName: `${response.data.FirstName} ${response.data.LastName}`,
                userEmail: response.data.UserName
            })

            axios
                .get(`/Provisioning/GetOrganizationDetails/${response.data.AccountId}`, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                    }
                })
                .then(response2 => {
                    this.setState({ dataUser2: response2.data });
                    this.setState({
                        companyName: response2.data.OrganizationName,
                        additionDetails: response2.data.InvoicingAdditionalDetails,
                        orgChargeDate: response2.data.OrganizationChargeDate,
                        email: response2.data.InvoicingEmail,
                        payMent: response.data.PaymentMethod,
                    });

                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        localStorage.clear();
                        this
                            .props
                            .history
                            .push('/login')
                    }
                });
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                this
                    .props
                    .history
                    .push('/login')
            }
        });
    }

    handleDrop = (files) => {
        this.setState({
            selectedFile: URL.createObjectURL(files[0]),
            loaded: 0,
            fileName: files[0].name,
            fileLogo: files[0]
        })
    }

    handelInput(files) {
        this.setState({
            selectedFile: URL.createObjectURL(files.target.files[0]),
            loaded: 0,
            fileName: files.target.files[0].name,
            fileLogo: files.target.files[0]
        })
    }
    signOut = () => {
        localStorage.clear();
        this
            .props
            .history
            .push('/login')
    }
    forceUpdateHandler() {
        this.componentDidMount();
    };
    savePackagesChanges() {
        if (this.state.userData.SitePassword !== this.state.password) {
            this.setState({ popoverOpen: true });
            return;
        }
        else this.savePackagesChangesRest();
    }

    savePackagesChangesRest() {

        let firstName = this.state.fullName.split(' ').slice(0, -1).join(' ');
        let lastName = this.state.fullName.split(' ').slice(-1).join(' ');
        let userNameChanged = this.state.fullName.trim() !== `${this.state.userData.FirstName} ${this.state.userData.LastName}`;
        if (this.state.dataUser2.OrganizationName !== this.state.companyName ||
            this.state.dataUser2.InvoicingEmail !== this.state.email ||
            this.state.dataUser2.InvoicingAdditionalDetails !== this.state.additionDetails) {
            this.setState({ isLoader: true })
            axios.post('/Provisioning/UpdateOrganizationDetails', {
                "OrganizationName": this.state.companyName,
                "invoicingemail": this.state.email,
                "invoicingadditionaldetails": this.state.additionDetails
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                if (this.state.fileLogo) {
                    this.uploadOrganizationLogo();
                } else {
                    this.setState({ isLoader: false })
                    this.forceUpdateHandler()
                }

            }).catch(error => {
                this.setState({ isLoader: false })
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                }
            });
        }
        else if (this.state.userData.SitePassword !== this.state.password || this.state.userEmail !== this.state.userData.UserName
            || userNameChanged) {
            this.setState({ isLoader: true });
            let data = {
                "emailaddress": this.state.userEmail,
                "firstname": firstName,
                "lastname": lastName,
                "username": localStorage.getItem('email'),
            }
            if (this.state.password !== '' && this.state.password !== '****') {
                data.password = this.state.password
            }
            axios.post('/Provisioning/UpdateUserDetails', data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ confirmPassword: '', isLoader: false });
                localStorage.setItem('auth-data', response.data.HashedPassword);
                localStorage.setItem('email', this.state.userEmail);
                this.navBarRef.updateEmail(this.state.userEmail);
            }).catch(error => {
                this.setState({ isLoader: false, confirmPassword: '' })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        else if (this.state.fileLogo) {
            this.uploadOrganizationLogo();
        }
    }

    uploadOrganizationLogo() {

        const logoDataFile = new FormData()
        logoDataFile.append('file', this.state.fileLogo)
        logoDataFile.append('username', localStorage.getItem('email'))

        axios
            .post('/Provisioning/UploadOrganizationLogo', logoDataFile, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            })
            .then(response => {
                this.setState({ isLoader: false })
                this.forceUpdateHandler()
            })
            .catch(error => {
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    }

    passButton() {
        this.password.type === 'text'
            ? this.password.type = 'password'
            : this.password.type = 'text'
    }
    activButtonClick() {
        axios
            .post('/Provisioning/StartSession', { "source": sourceVariable })
            .then(response => {
                const data = response.data;
                axios.post('/Payment/ChangePaymentMethod', {
                    "accountid": this.state.userData.AccountId,
                    "paymentmethod": this.state.payMent
                }, {
                    headers: {
                        Authorization: 'Bearer ' + data.SessionKey
                    }
                }).then(response => {
                    if (this.state.payMent === 'PayPal') {
                        window.open(response.data.RedirectURL, "_blank");
                    }
                    else { this.setState({ iframeURL: response.data.RedirectURL, iframeModal: true }) }
                }).catch(error => {
                    alert(error.response
                        ? error.response.data.Description || error.response.data.Message : error)
                });
            })
            .catch(error => {
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    }
    modalToggle(value) {
        this.setState({
            [value]: !this.state[value]
        })
    }

    savePackagesChangesAfterConfirmPassword = () => {
        if (this.state.password === this.state.confirmPassword) {
            this.setState({ popoverOpen: false });
            this.savePackagesChangesRest();
        }
        else return alert(STRINGS.PASSWORDS_DO_NOT_MATCH);
    }

    loginWithOnClick = (loginWith) => {
        switch (loginWith) {
            case STRINGS.LOGIN_WITH_SALESFORCE:
                localStorage.setItem('REACT_APP_SF_URL', `${process.env.REACT_APP_SF_URL}token`);
                window.location.replace(`${process.env.REACT_APP_SF_URL}authorize?response_type=code
                    &client_id=${process.env.REACT_APP_CLIENT_ID_SF}&redirect_uri=${window.location.href}`);
                break;
            case STRINGS.LOGIN_WITH_SALEFORCE_SANDBOX:
                localStorage.setItem('REACT_APP_SF_URL', `${process.env.REACT_APP_SF_URL_SB}token`);
                window.location.replace(`${process.env.REACT_APP_SF_URL_SB}authorize?response_type=code
                &client_id=${process.env.REACT_APP_CLIENT_ID_SF_SB}&redirect_uri=${window.location.href}`);
                break;
            default:
                break;
        }
    }

    mfaBtnOnClick = () => {
        this.setState({ mfaModalToggle: !this.state.mfaModalToggle });
    }

    localtimeOnChange = (e) => {
        localStorage.setItem('showLocalTime', e.target.checked)
    }

    render() {

        if (!permissionFunc.changeDisplay('side_bar_account_settings')) {
            return (
                <div className="d-flex">
                    <SideBar
                        active1={false}
                        active2={false}
                        active3={true}
                        active4={false}
                        active5={false}
                        active6={false}
                        active7={false}
                        active8={false}
                        homePage={() => this.props.history.push('/', { redirect: true })}
                        myPackagePage={() => this.props.history.push('/myPackages', { redirect: true })}
                        settingPage={() => this.props.history.push('/settings')}
                        crm_integration={() => this.props.history.push('/crm_integration')}
                        contactUs={() => this.props.history.push('/contactUs')}
                        realTimeMonitoring={() => this.props.history.push('/real_time_monitoring')}
                        graph={() => this.props.history.push('/charts')}
                        contacts={() => this.props.history.push('/contacts')}
                        user={this.state.userData}
                        />
                    <div className="container-fluid p-0">
                        <NavBar
                            ref={(obj) => this.navBarRef = obj}
                            logo={this.state.userData.LogoURL}
                            homePage={() => this.componentDidMount()}
                            signOut={() => this.signOut()}
                            title="Account settings"
                        />
                        <FormGroup className="ml-3 d-flex">
                            <div>
                                <h2 className="ml-3 mt-4">Account settings</h2>
                                <h6 className="font-weight-bold mt-4">Organization settings</h6>
                            </div>
                            <ButtonDropdown
                                className="h-100 ml-auto mr-5 mt-auto fs-14"
                                isOpen={this.state.loginWithDropdownOpen}
                                toggle={() => this.setState({
                                    loginWithDropdownOpen: !this.state.loginWithDropdownOpen
                                })}>
                                <DropdownToggle
                                    caret
                                    style={{
                                        background: '#2EC2FE',
                                        border: 'unset'
                                    }}
                                >
                                    {STRINGS.LOGIN_WITH_SALESFORCE_OPTIONS}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        onClick={() => this.loginWithOnClick(STRINGS.LOGIN_WITH_SALESFORCE)}
                                    >
                                        {STRINGS.LOGIN_WITH_SALESFORCE}
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => this.loginWithOnClick(STRINGS.LOGIN_WITH_SALEFORCE_SANDBOX)}
                                    >
                                        {STRINGS.LOGIN_WITH_SALEFORCE_SANDBOX}
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                            <Button
                                onClick={() => this.savePackagesChanges()}
                                color={''}
                                style={{
                                    color: 'white'
                                }}
                                className="btnColor2 h-100 mr-5 mt-auto">
                                Save changes
                            </Button>
                        </FormGroup>
                        <FormGroup className="ml-3 d-flex">
                            <div className="Rectangle2 col-2">
                                {this.state.selectedFile
                                    ? <img
                                        style={{
                                            width: 'inherit'
                                        }}
                                        alt=""
                                        src={this.state.selectedFile} />
                                    : (
                                        <FileDrop onDrop={(files, event) => this.handleDrop(files, event)}>
                                            <img src='assets/rect.svg' alt="" className="mt-4" />
                                            <FormGroup>
                                                <div
                                                    style={{
                                                        color: '#B8BABE'
                                                    }}
                                                    className="m-2">Company logo
                                                </div>
                                                <Label
                                                    className="btn-link"
                                                    style={{
                                                        position: 'relative'
                                                    }}>
                                                    <Input
                                                        accept="image/x-png,image/jpeg"
                                                        style={{
                                                            opacity: 0,
                                                            position: "absolute"
                                                        }}
                                                        onChange={(e) => this.handelInput(e)}
                                                        type="file" />{this.state.fileName}</Label>
                                                <div>Or drag it here</div>
                                            </FormGroup>
                                        </FileDrop>
                                    )}
                            </div>
                            <div className="ml-2 col-4">
                                <FormGroup>
                                    <Label for="fullName">full name</Label>
                                    <Input
                                        type="text"
                                        name="fullName"
                                        id="exFullName"
                                        value={this.state.fullName}
                                        onChange={(e) => this.setState({ fullName: e.target.value })} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="userEmail">Email</Label>
                                    <Input
                                        type="text"
                                        name="userEmail"
                                        id="exUserEmail"
                                        value={this.state.userEmail}
                                        onChange={(e) => this.setState({ userEmail: e.target.value })} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="lastName">Company name</Label>
                                    <Input
                                        type="text"
                                        name="lastName"
                                        id="exLastName"
                                        value={this.state.companyName}
                                        onChange={(e) => this.setState({ companyName: e.target.value })} />
                                </FormGroup>
                                <FormGroup>
                                    <Button
                                        color={''}
                                        style={{
                                            color: 'white'
                                        }}
                                        className="btnColor2"
                                        onClick={this.mfaBtnOnClick}
                                    >
                                        {this.state.userData.MFAStatus ?
                                            STRINGS.DISABLE_MFA :
                                            STRINGS.ENABLE_MFA}
                                    </Button>
                                </FormGroup>
                                <FormGroup check>
                                    <Input
                                        id="show-local-time-checkbox"
                                        name="check"
                                        type="checkbox"
                                        onChange={this.localtimeOnChange}
                                        defaultChecked={localStorage.getItem('showLocalTime') === 'true'}
                                    />
                                    <Label
                                        check
                                        for="show-local-time-checkbox"
                                    >
                                        {STRINGS.SHOW_LOCAL_TIME_IN_USER_REPORT}
                                    </Label>
                                </FormGroup>

                            </div>
                            <div className="ml-2 col-4">
                                <FormGroup>
                                    <img
                                        onClick={() => this.passButton()}
                                        className="float-right cursorPointer "
                                        src='assets/eye.svg'
                                        alt="" />
                                    <Label for="exNewPassword">Password
                                    </Label>
                                    <Input
                                        type="password"
                                        name="exNewPassword"
                                        id="exNewPassword"
                                        innerRef={(password) => {
                                            this.password = password
                                        }}
                                        value={this.state.password}
                                        onChange={(e) => this.setState({ password: e.target.value })} />
                                    <Popover placement="top" isOpen={this.state.popoverOpen} target="exNewPassword">
                                        <PopoverHeader>{STRINGS.CONFIRM_PASSWORD}</PopoverHeader>
                                        <PopoverBody>
                                            <Input
                                                type='password'
                                                name="exConfirmPassword"
                                                id="exConfirmPassword"
                                                value={this.state.confirmPassword}
                                                onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                                            />
                                            <div className='d-flex'>
                                                <Button
                                                    color={''}
                                                    style={{ color: 'white', width: '95%', margin: '0.5rem' }}
                                                    className="btnColor2"
                                                    onClick={this.savePackagesChangesAfterConfirmPassword}
                                                >
                                                    {STRINGS.SAVE}
                                                </Button>
                                                <Button
                                                    style={{ width: '95%', margin: '0.5rem' }}
                                                    onClick={() => this.setState({ confirmPassword: '', popoverOpen: false })}
                                                >
                                                    {STRINGS.CANCEL}
                                                </Button>
                                            </div>
                                        </PopoverBody>
                                    </Popover>
                                </FormGroup>
                                {/* <FormGroup>
                                    <Label for="exNewPassword">Repeat password</Label>
                                    <Input
                                        type="password"
                                        name="exNewPassword"
                                        id="exNewPassword"
                                        value={this.state.repeatPassword}
                                        onChange={(e) => this.setState({repeatPassword: e.target.value})}/>
                                </FormGroup> */}
                                <FormGroup>
                                    <Label for="RepeatPassword">Email for invoicing</Label>
                                    <Input
                                        type="email"
                                        name="RepeatPassword"
                                        id="RepeatPassword"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({ email: e.target.value })} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="newEmail">Addition details for invoicing</Label>
                                    <Input
                                        type="email"
                                        name="newEmail"
                                        id="exnewEmail"
                                        value={this.state.additionDetails}
                                        onChange={(e) => this.setState({ additionDetails: e.target.value })} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="orgChargeDate">Organization Charge Date</Label>
                                    <Input
                                        type="text"
                                        name="orgChargeDate"
                                        id="orgChargeDate"
                                        value={this.state.orgChargeDate} />
                                </FormGroup>
                            </div>
                        </FormGroup>
                        {this.state.isLoader
                            ? <img
                                style={{
                                    width: '80px',
                                    height: '80px',
                                    marginLeft: '45%',
                                    marginTop: 'auto'
                                }}
                                src='assets/spikkoloaderanimation.svg'
                                alt=""
                                className="loginImg" />
                            : null}
                        <h6 className="font-weight-bold mt-4 ml-3">Payment methods</h6>
                        <Col className="my-3">
                            <div className="d-flex cardBorder">
                                <div><span className="border mr-1 p-2" style={{ borderRadius: '50%' }}>&#10004;</span>Credit card</div>
                                <Button
                                    disabled={this.state.payMent !== 'PayPal' && this.state.payMent !== null}
                                    onClick={() => {
                                        this.setState({ payMent: 'CreditGuard' });
                                        this.activButtonClick('CreditGuard')
                                    }}
                                    className="btnColor2 ml-auto col-1">{this.state.payMent !== 'PayPal' && this.state.payMent !== null
                                        ? 'Deactivate'
                                        : 'Activate'}</Button>
                            </div>
                        </Col>
                        <Col className="my-3">
                            <div className="d-flex cardBorder">
                                <div><img className="mr-2" src='assets/group-17.png' alt="" />Paypal</div>
                                <Button
                                    disabled={this.state.payMent === 'PayPal'}
                                    onClick={() => {
                                        this.setState({ payMent: 'PayPal' });
                                        this.activButtonClick('CreditGuard')
                                    }}
                                    className="btnColor2 ml-auto col-1">{this.state.payMent === 'PayPal'
                                        ? 'Deactivate'
                                        : 'Activate'}</Button>
                            </div>
                        </Col>
                    </div>
                    <Modal className="iframeModal" isOpen={this.state.iframeModal} toggle={() => this.modalToggle('iframeModal')}><iframe src={this.state.iframeURL} title="aaa" width='1000px' height='720px' name="theFrame"></iframe>
                        <Button color={''} style={{ color: 'white' }} className="btnColor2 col-1 ml-auto mr-2 my-2" onClick={() => this.setState({ iframeModal: false })}>OK</Button>
                    </Modal>
                    <Modal
                        className="mfa-modal-main"
                        isOpen={this.state.mfaModalToggle}
                        toggle={this.mfaBtnOnClick}
                    >
                        <MfaModal
                            userData={this.state.userData}
                            getUserData={this.getUserData}
                            mfaBtnOnClick={this.mfaBtnOnClick}
                        />
                    </Modal>
                </div>
            )
        }
        else return null;
    }
}

export default Settings