import React, { useState } from 'react';
import './miniPackage.css';
import { ModalHeader, ModalBody } from 'reactstrap';
import AdvanceModal from "./advancedFeatures/advanceModal";
import * as STRINGS from '../../strings/english';
import * as permissionFunc from '../../permissionScheme/permissionFunc';

export const Showing = {
    VOICE: "voiceMail",
    RECORDING: "recording",
    TIME: "timeForward",
    ATTENDANT: "autoAttendant",
    GROUP_RINGING: "groupRinging",
    SEQUENTIAL_RINGING: "sequentialRinging"
}

function AdvancedFeaturesBtnList(props) {
    return (
        <div
            className='advanced-features-modal-btn-list'
            onClick={props.onClick}
            id={props.id}
        >
            <img src={props.imgSrc} alt="" className="" />
            <span
                style={props.spanStyle ? props.spanStyle : {}}
            >
                {props.btnText}
            </span>
        </div>
    )
}

function AdvancedFeaturesModal(props) {

    const [show, setShow] = useState({
        [Showing.VOICE]: false,
        [Showing.RECORDING]: false,
        [Showing.TIME]: false,
        [Showing.ATTENDANT]: false,
        [Showing.GROUP_RINGING]: false,
        [Showing.SEQUENTIAL_RINGING]: false
    });

    const toggle = (name) => {
        setShow({ [name]: !show[name] })

    };

    function getCurrentModel() {
        let currentModal = Object.keys(show).find(key => show[key] === true);
        return { key: currentModal, value: show[currentModal] };
    };


    return (
        <div>
            <ModalHeader className='advanced-features-modal-header'>{STRINGS.ADVANCED_FEATURES}</ModalHeader>
            <ModalBody>
                {!props.isAccessNumber && !permissionFunc.changeDisplay('advanced_features_dropdown_di_voice_mail_options') ?
                    <AdvancedFeaturesBtnList
                        onClick={() => toggle(Showing.VOICE)}
                        imgSrc={'assets/voicemail-2.svg'}
                        btnText={STRINGS.VOICE_MAIL_OPTIONS}
                        id='advanced_features_dropdown_di_voice_mail_options'
                    /> : null}
                {!permissionFunc.changeDisplay('advanced_features_dropdown_di_recording_options') &&
                    <AdvancedFeaturesBtnList
                        onClick={() => toggle(Showing.RECORDING)}
                        imgSrc={'assets/mic.svg'}
                        btnText={STRINGS.RECORDING_OPTIONS}
                        id='advanced_features_dropdown_di_recording_options'
                    />}
                {!props.isAccessNumber ?
                    <React.Fragment>
                        {!permissionFunc.changeDisplay('advanced_features_dropdown_di_time_forward_options') &&
                            <AdvancedFeaturesBtnList
                                onClick={() => toggle(Showing.TIME)}
                                imgSrc={'assets/icon-clock.svg'}
                                btnText={STRINGS.TIME_FORWARD_OPTIONS}
                                id='advanced_features_dropdown_di_time_forward_options'
                            />}
                        {!permissionFunc.changeDisplay('advanced_features_dropdown_di_auto_attendant') &&
                            <AdvancedFeaturesBtnList
                                onClick={() => toggle(Showing.ATTENDANT)}
                                imgSrc={'assets/auto-attendant-icon.png'}
                                btnText={STRINGS.AUTO_ATTENDANT}
                                id='advanced_features_dropdown_di_auto_attendant'
                            />}
                        {!permissionFunc.changeDisplay('advanced_features_dropdown_di_group_ringing') &&
                            <AdvancedFeaturesBtnList
                                onClick={() => toggle(Showing.GROUP_RINGING)}
                                imgSrc={'assets/group-ringing-icon.svg'}
                                btnText={STRINGS.GROUP_RINGING}
                                id='advanced_features_dropdown_di_group_ringing'
                                spanStyle={{ marginLeft: '0.5rem' }}
                            />}
                        {!permissionFunc.changeDisplay('advanced_features_dropdown_di_sequential_ringing') &&
                            <AdvancedFeaturesBtnList
                                onClick={() => toggle(Showing.SEQUENTIAL_RINGING)}
                                imgSrc={'assets/sequential-ringing-icon.svg'}
                                btnText={STRINGS.SEQUENTIAL_RINGING}
                                id='advanced_features_dropdown_di_sequential_ringing'
                                spanStyle={{ marginLeft: '0.5rem' }}
                            />}
                    </React.Fragment> : null}
            </ModalBody>

            <AdvanceModal
                refresh={() => props.refresh()}
                details={props.details}
                modal={getCurrentModel()}
                toggle={(e) => toggle(e)}
            />
        </div>
    );
}

export default AdvancedFeaturesModal

