import React from 'react';
import './reportPopover.css';
import {
    Popover,
    PopoverBody,
} from 'reactstrap';
import * as STRINGS from '../../strings/english';
import { formatNumbers } from '../../services/formatPhoneNumber';


const ReportPopover = (props) => {

    return (
        <tr
            id={`report-popover-tr-${props.rowId}`}
            onMouseOver={() => props.mouseOverToOpenPopover(props.setOnPopoverElement)}
            onMouseLeave={() => props.mouseleaveToOpenPopover(props.setOnPopoverElement)}
        >
            <Popover
                key={`popover-key-${props.rowId}`}
                target={`report-contact-number-${props.rowId}`}
                placement='top'
                isOpen={props.popoverOpen}
                toggle={() => props.setPopoverOpen(!props.popoverOpen)}
                className='report-popover'
            >
                <PopoverBody>
                    {props.currentContactName ?
                        formatNumbers(props.currentContactNumber) :
                        <React.Fragment>
                            {STRINGS.ADD_TO_CONTACTS}
                            <img
                                src='assets/person-add.svg'
                                alt='person-add-icon'
                                className='report-person-add-icon'
                                onClick={props.newContactModaltoggle}
                            />
                        </React.Fragment>}
                </PopoverBody>
            </Popover>
        </tr>
    )
}

export default ReportPopover;