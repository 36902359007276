import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import './rtmIconsTooltips.css';


const RtmIconsTooltips = (props) => {
    
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <span>
            <img
                className={props.className}
                style={props.item.hover ?
                    {
                        transform: 'scale(1.3)',
                    } : {}}
                id={props.item.id}
                src={`assets/${props.item.imgName}.svg`}
                alt={props.item.alt}
                onClick={props.changeDisplayIconsOnClick}
            />
            <Tooltip
                placement={props.item.placement}
                isOpen={tooltipOpen}
                target={props.item.id}
                toggle={toggle}
            >
                {`${props.item.content} view`}
            </Tooltip>
        </span>
    )
}

export default RtmIconsTooltips;