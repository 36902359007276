import React, { useState } from 'react';
import { Alert } from 'reactstrap';

const AlertBU = (props) => {
    const [visible, setVisible] = useState(true);

    const onDismiss = () => setVisible(false);

    return (
        <Alert className="fs-22" color="info" isOpen={visible} toggle={onDismiss}>
            {props.message}
        </Alert>
    );
}
export default AlertBU;