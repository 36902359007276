import React from 'react';
import './otherMessageCube.css';
import { FileMessage } from './fileMessage';
import { urlify } from '../../../services/tools';
import DOMPurify from 'dompurify';


export const OtherMessageCube = (props) => {

    const message = props.message;
    let dirty = urlify(message.Message);
    const clean = message.MessageType !== 7 ?
    DOMPurify.sanitize(dirty) : '';

    return (
        <div className='other-message-cube-main'>
            <div className='other-message-cube-name-and-time'>
                <div className='other-message-cube-name'>
                    {props.otherName}
                </div>
                <div className='other-message-cube-time'>
                    {props.sendingTime}
                </div>
            </div>
            <div className='other-message-cube-content'>
                <div
                    className='user-message-cube-content-text'
                    dangerouslySetInnerHTML={{ __html: clean }}
                />
                {(message.HasMedia ||
                    message.HasAttachment ||
                    message.MessageType === 7) &&
                    <FileMessage
                        mediaUrl={props.mediaUrl}
                        mediaType={props.mediaType}
                        fileName={props.fileName}
                        message={message}
                    />}
            </div>
        </div>
    )
}