import React, { useState } from 'react';
import './group-msg-header.css';
import { Popover, PopoverBody } from 'reactstrap';

export const GroupMsgHeader = (props) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);

    return (
        <div className='group-msg-header-main'>
            <div>
                <div className='group-msg-header-group-name'>{props.toNum}</div>
                <div className='group-msg-header-group-numbers'>
                    {props.patricipantsNumberArr.join(', ')}
                </div>
            </div>
            <div>
                <img
                    src='assets/info-icon.svg'
                    className='group-msg-header-info-icon'
                    id='group-msg-header-info-icon-tooltip'
                />
                <Popover
                    flip
                    trigger='hover'
                    isOpen={tooltipOpen}
                    target='group-msg-header-info-icon-tooltip'
                    placement='bottom'
                    style={{
                        // paddingTop: '0.5rem',
                        paddingLeft: '0.5rem',
                        // paddingBottom: '0.5rem',
                        backgroundColor: '#FBFBFB',
                        borderRadius: '6px',
                        minWidth: '180px',
                    }}
                    toggle={() => setTooltipOpen(!tooltipOpen)}
                >
                    <PopoverBody>
                        {props.patricipantsNumberArr.map((num, i) => {
                            return (
                                <React.Fragment key={`group-msg-header-all-num-main-${i}`}>
                                    <div key={`group-msg-header-all-num-${i}`}>
                                        {num}
                                    </div>
                                    {i !== props.patricipantsNumberArr.length - 1 &&
                                        <div
                                            key={`group-msg-header-all-num-divider-${i}`}
                                            className='group-msg-header-all-num-divider'
                                        />}
                                </React.Fragment>
                            )
                        })}
                        {/* {numArr.length === 0 ?
                            <React.Fragment>
                                <div>
                                    {props.fromNum}
                                </div>
                                <div className='group-msg-header-group-name-main'>
                                    <img
                                        src='assets/arrow-right-small.png'
                                        className='group-msg-header-arrow-right-small'
                                    />
                                    <img
                                        src='assets/group-name-icon.svg'
                                        className='group-msg-header-group-name-icon'
                                    />
                                    <div className='group-msg-header-pophover-group-name'>{props.toNum}</div>
                                </div>
                            </React.Fragment> : props.patricipantsNumberArr.map((num, i) => {
                                return (
                                    <div key={i}>
                                        {num}
                                    </div>
                                )
                            })} */}

                    </PopoverBody>
                </Popover>
            </div>
        </div>)
}