import React, { useState, useEffect } from 'react';
import './advance.css';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    ModalBody,
    ModalHeader,
    UncontrolledDropdown,
    ModalFooter
} from 'reactstrap';
import * as STRINGS from '../../../strings/english';
import FeatureService from "../../../services/FeatureService";
import axios from '../../../axios';

export function EditUserDetailsModal(props) {

    const [timeZone, setTimeZone] = useState({});
    const [userTimeZoneId, setUserTimeZoneId] = useState(props.userDetails.TimeZoneId);
    const [isLoader, setIsLoader] = useState(false);

    useEffect(() => {
        getTimeZone()
    }, [])

    function getTimeZone() {
        FeatureService.getSystemTimeZones()
            .then((res) => {
                if (res.status === 200) {
                    setTimeZone(res.data);
                }
            }).catch(e => {
                if (e.response && e.response.data) {
                    alert(STRINGS.SERVER_ERROR + ': ' + e.response.data);
                }
            })
    }

    const saveChange = () => {
        setIsLoader(true);
        let body = {
            "username": props.userDetails.UserName,
            "timeZone": userTimeZoneId
        }
        axios.post('/Provisioning/UpdateUserSettings', body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (response.status === 200) {
                setIsLoader(false);
                alert(STRINGS.THE_CHANGES_HAVE_BEEN_SAVED);
                props.refresUsers();
                props.cancelOnClick();
            }
        }).catch(e => {
            if (e.response && e.response.data) {
                alert(STRINGS.SERVER_ERROR + ': ' + e.response.data);
            }
        })
    }


    return (
        <React.Fragment>
            <ModalHeader className='af-modal-header'>{STRINGS.CHOOSE_YOUR_TIME_ZONE}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <UncontrolledDropdown>
                        <DropdownToggle style={{ width: "100%", textAlign: "left", }}
                            className="border mr-2 fs-14 oneLine" color={'white'} caret>
                            {Object.keys(timeZone).map(key => {
                                return timeZone[key].Id === userTimeZoneId ? timeZone[key].ZoneName : null
                            })}
                        </DropdownToggle>
                        <DropdownMenu>
                            {Object.keys(timeZone).map(key => {
                                return <DropdownItem key={key} style={{ whiteSpace: 'normal' }}
                                    onClick={(e) => { setUserTimeZoneId(timeZone[key].Id) }}>
                                    {timeZone[key].ZoneName}
                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    className='col-5 fs-14'
                    style={{ backgroundColor: '#ebebeb' }}
                    color='white'
                    onClick={props.cancelOnClick}
                >
                    {STRINGS.CANCEL}
                </Button>
                <Button
                    className='col-6 btnColor2 btn btn-white fs-14'
                    style={{ color: 'white' }}
                    color={'white'}
                    onClick={saveChange}
                >
                    {STRINGS.SAVE_TIME_ZONE}
                </Button>
            </ModalFooter>
            {isLoader
                ? <img
                    style={{
                        width: '60px',
                        height: '50px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                    src='assets/spikkoloaderanimation.svg' />
                : null}
        </React.Fragment>
    )
}