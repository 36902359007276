import { AsYouType } from 'libphonenumber-js';
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const getCountryISO2 = require("country-iso-3-to-2");

export function returnInternationalNumber(orgNum, location) {
    let iso2Location = getCountryISO2(location);
    let number;
    try {
        number = phoneUtil.parseAndKeepRawInput(orgNum, iso2Location);
    } catch (error) {

    }
    if (number) {
        return phoneUtil.format(number, PNF.INTERNATIONAL);
    }
    else return orgNum
}

export function formatNumbers(number) {
    const asYouType = new AsYouType();
    let formatNum = asYouType.input('+' + number);
    //if (/\d/.test(formatNum)) {
    if (asYouType.getNumber()) {
        return formatNum;
    }
    else return number
}

export function formatInputValue(value) {
    const asYouType = new AsYouType();
    asYouType.input('+');
    let number = asYouType.input(value);
    let flagIcon;
    if (asYouType.getNumber()) {
        flagIcon = countryToFlag(asYouType.getNumber().country);
    }
    if (flagIcon) {
        return flagIcon + number;
    }
    return number;
}

export function formatInputValueWithout(value) {
    const asYouType = new AsYouType();
    asYouType.input('+');
    let number = asYouType.input(value);
    return number;
}

export function convertFormatNumToRegularNum(value) {
    const asYouType = new AsYouType();
    asYouType.input(value);
    return asYouType.getChars().slice(1);
}

export function stripAllNonNumericCharacter(value) {
    if (value.startsWith('0')) {
        value = value.substring(1);
    }
    return value.replace(/\D/g, '');
}

function countryToFlag(isoCode) {
    if (isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }
}