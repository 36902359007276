import React, { Component } from 'react';
import axios from '../../axios';
import sourceVariable from '../../globalStartSession';
import { Redirect } from 'react-router-dom'
import {
    Button,
    Input,
    Form,
    Col,
    Progress,
    Row,
    Label
} from 'reactstrap';
import './index.css';

class EnterCode extends Component {

    state = {
        sessionData: '',
        redirect: this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : false,
        isEnable: true
    }

    createCount() {
        const code = this.num1.value + this.num2.value + this.num3.value + this.num4.value + this.num5.value + this.num6.value
        axios
            .post('/Provisioning/StartSession', { "source": sourceVariable })
            .then(response => {
                this.setState({ sessionData: response.data })
                axios.post('/Provisioning/VerifyEmail', {
                    "Email": localStorage.getItem('email'),
                    "Code": code
                }, {
                    headers: {
                        Authorization: 'Bearer ' + this.state.sessionData.SessionKey
                    }
                }).then(response => {
                    axios
                        .post('/Provisioning/Login', {
                            "username": this.props.location.state.email,
                            "password": this.props.location.state.password
                        })
                        .then(response => {
                            const data = response.data;
                            localStorage.setItem('auth-data', data.HashedPassword)
                            if (this.props.location.state.logo) {

                                const logoDataFile = new FormData()
                                logoDataFile.append('file', this.props.location.state.logoData)
                                logoDataFile.append('username', localStorage.getItem('email'))

                                axios
                                    .post('/Provisioning/UploadOrganizationLogo', logoDataFile, {
                                        headers: {
                                            Authorization: 'Bearer ' + data.HashedPassword
                                        }
                                    })
                                    .then(response => {

                                    })
                                    .catch(error => {
                                        alert(error.response
                                            ? error.response.data.Description || error.response.data.Message : error)
                                    });
                            }
                        })
                        .catch(error => {
                            alert(error.response
                                ? error.response.data.Description || error.response.data.Message : error)
                        });;
                    this
                        .props
                        .history
                        .push('/success', { redirect: true, logo: this.props.location.state.logo, countId: this.props.location.state.countId })
                }).catch(error => {
                    alert(error.response
                        ? error.response.data.Description || error.response.data.Message : error)
                })

            })
            .catch(error => {
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            })
    }

    render() {
        if (this.state.redirect) {
            return (
                <div className=" wrapper row m-0 ">
                    <div className="divCountImg">
                        <img src='assets/Capture3.PNG' alt="" className="countImg" /></div>
                    <Form className="enterCodeForm">
                        <div className="mt-3">Step 2</div>
                        <Progress animated className="mb-3" value="50" />
                        <Row className="my-5">
                            <Col className="text-center">
                                <Label className="font-weight-bold">Enter a code sent via email</Label>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col>
                                <Input
                                    id='one'
                                    onChange={(e) => e.target.value && e.target.value.length > 0 ? document.getElementById('second').focus() : document.getElementById('one').focus()}
                                    className="rectangleCode"
                                    type="number"
                                    innerRef={(num1) => {
                                        this.num1 = num1
                                    }}></Input>
                            </Col>
                            <Col>
                                <Input
                                    onChange={(e) => e.target.value && e.target.value.length > 0 ? document.getElementById('third').focus() : document.getElementById('one').focus()}
                                    id='second'
                                    innerRef={(num2) => {
                                        this.num2 = num2
                                    }}
                                    className="rectangleCode"
                                    type="number"></Input>
                            </Col>
                            <Col>
                                <Input
                                    id='third'
                                    onChange={(e) => e.target.value && e.target.value.length > 0 ? document.getElementById('fourth').focus() : document.getElementById('second').focus()}
                                    innerRef={(num3) => {
                                        this.num3 = num3
                                    }}
                                    className="rectangleCode"
                                    type="number"></Input>
                            </Col>
                            <Col>
                                <Input
                                    onChange={(e) => e.target.value && e.target.value.length > 0 ? document.getElementById('fifth').focus() : document.getElementById('third').focus()}
                                    id='fourth'
                                    innerRef={(num4) => {
                                        this.num4 = num4
                                    }}
                                    className="rectangleCode"
                                    type="number"></Input>
                            </Col>
                            <Col>
                                <Input
                                    id='fifth'
                                    onChange={(e) => e.target.value && e.target.value.length > 0 ? document.getElementById('six').focus() : document.getElementById('fourth').focus()}
                                    innerRef={(num5) => {
                                        this.num5 = num5
                                    }}
                                    className="rectangleCode"
                                    type="number"></Input>
                            </Col>
                            <Col>
                                <Input
                                    id={'six'}
                                    innerRef={(num6) => {
                                        this.num6 = num6
                                    }}
                                    onChange={(e) => e.target.value && e.target.value.length > 0 ? this.setState({ isEnable: false }) : document.getElementById('fifth').focus()}
                                    className="rectangleCode"
                                    type="number"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                id={'verify'}
                                className="m-auto"
                                style={{
                                    backgroundColor: '#44d551',
                                    borderRadius: '50%',
                                    height: '40px',
                                    width: '40px'
                                }}
                                disabled={this.state.isEnable}
                                onClick={() => this.createCount()}
                                block>
                                <img src='assets/checkmark.png' alt="" />
                            </Button>
                        </Row>
                    </Form>
                </div>
            );
        } else {
            return (<Redirect to='/login' />)
        }
    }
}

export default EnterCode;