import React, { useState, useEffect } from 'react';
import { FormGroup, Modal } from 'reactstrap';
import AccountService from "../../services/AccountService";
import ProvisioningService from '../../services/ProvisioningService';
import './index.css';
import SideBar from '../../components/sideBar';
import NavBar from '../../components/navBar';
import Cell from './cell.js';
import CRMFormModal from './CRMFormModal';
import * as permissionFunc from '../../permissionScheme/permissionFunc';


function CRMIntegration(props) {
    const [user, setUser] = useState(null)
    const [integrations, setIntegrations] = useState([])
    const [activeIntegrations, setActiveIntegrations] = useState([])
    const [formModalMode, setFormModalMode] = useState(false)
    const [currentModalShowing, setCurrentModalShowing] = useState(null)
    const [currentModalDetails, setCurrentModalDetails] = useState(null)

    useEffect(() => {
        if (!user) {
            AccountService.getUserByUsername(localStorage.getItem('email'))
                .then(res => {
                    setUser(res.data)
                })
        }
        if (integrations.length === 0 && activeIntegrations.length === 0) {
            getDataFromServer()
        }
    })

    const getDataFromServer = () => {
        ProvisioningService.getIntegrationOptions()
            .then(res => {
                setIntegrations(res.data)
            })
            .catch(e => console.log(e))
        ProvisioningService.getActiveIntegrationOptions({})
            .then(res => {
                setActiveIntegrations(res.data)
            })
            .catch(e => console.log(e))
    }

    function renderCellsIntegration() {
        return integrations.map((item, index) => {
            const parameters = activeIntegrations.filter(x => x.IntegrationName === item.IntegrationName)
            return <Cell
                key={index}
                active={parameters.length > 0 ? parameters[0].IntegrationParameters.Active === "true" : false}
                integrationName={item.IntegrationName}
                integrationDescription={item.IntegrationDescription}
                logoURL={item.LogoURL}
                integrationParameters={parameters.length > 0 ? parameters[0].IntegrationParameters : item.IntegrationParameters}
                formCrmModal={() => {
                    setFormModalMode(true)
                    setCurrentModalShowing(index)
                    setCurrentModalDetails(parameters.length > 0 ? parameters[0].IntegrationParameters : item.IntegrationParameters)
                }}
            />
        })
    }

    function renderCRMFormModal() {
        if (activeIntegrations && integrations[currentModalShowing]) {
            // console.log(activeIntegrations.filter(x =>  x.IntegrationName === integrations[currentModalShowing].IntegrationName))
            // console.log(currentModalDetails)
            let modelStyle = {
                maxHeight: "55%",
                maxWidth: "434px",
            }
            return (
                <Modal
                    className="paymentModal"
                    isOpen={formModalMode}
                    style={modelStyle}
                    toggle={() => setFormModalMode(!formModalMode)}
                >
                    <CRMFormModal
                        active={!!activeIntegrations.find(x => x.IntegrationName === integrations[currentModalShowing].IntegrationName)}
                        details={currentModalDetails}
                        mainDetails={integrations[currentModalShowing]}
                        refreshData={() => getDataFromServer()}
                        toggel={() => setFormModalMode(false)}
                    />
                </Modal>
            )
        }
    }
    const signOut = () => {
        localStorage.clear();
        props.history.push('/login')
    }

    if (!permissionFunc.changeDisplay('side_bar_crm_integration')) {
        return (
            <div className="d-flex">
                <SideBar
                    active1={false}
                    active2={false}
                    active3={false}
                    active4={true}
                    active5={false}
                    active6={false}
                    active7={false}
                    active8={false}
                    homePage={() => props.history.push('/', { redirect: true })}
                    myPackagePage={() => props.history.push('/myPackages', { redirect: true })}
                    settingPage={() => props.history.push('/settings')}
                    crm_integration={() => props.history.push('/crm_integration')}
                    contactUs={() => props.history.push('/contactUs')}
                    realTimeMonitoring={() => props.history.push('/real_time_monitoring')}
                    graph={() => props.history.push('/charts')}
                    contacts={() => props.history.push('/contacts')}
                    user={user}
                />

                <div className="container-fluid" style={{ height: "100vh", overflow: "auto" }}>
                    <div>
                        <NavBar
                            logo={user ? user.LogoURL : ""}
                            signOut={() => signOut()}
                            mail={localStorage.getItem('email')}
                            title="Account settings" 
                            homePage={() => { }}/>
                    </div>
                    {renderCRMFormModal()}
                    <div >
                        <FormGroup className="ml-3 d-flex">
                            <div>
                                <h2 className="ml-3 mt-4">CRM integration</h2>
                                <h6 className="ml-3 font-weight-bold mt-4">Log in options</h6>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            {renderCellsIntegration()}
                        </FormGroup>
                    </div>
                </div>
            </div>
        )
    }
    else return null;
}

export default CRMIntegration