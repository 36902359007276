import React, { useState, useEffect } from 'react';
import './fileMessage.css';
import axios from 'axios';
import { Modal, ModalBody } from 'reactstrap';
import { downloadDocx, arrayContains } from '../../../services/tools';
import {
    fileMessageEnum as ENUM,
    audioTypeArr,
    imageTypeArr,
    displayMediaArr
} from './fileMessageEnum';
import * as STRINGS from '../../../strings/english';
import prettyBytes from 'pretty-bytes';

export const FileMessage = (props) => {

    const [loader, setLoader] = useState(false);
    const [mediaSrc, setMediaSrc] = useState('');
    const [mimetype, setMimeType] = useState('');
    const [mediaSize, setMediaSize] = useState(0);
    const [mediatype, setMediatype] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [documentType, setDocumentType] = useState('');
    const [displayMediaType, setDisplayMediaType] = useState('');

    const message = props.message;
    useEffect(() => {
        setMimeType(
            defineFileMimetype(props.fileName.split('.').pop())
        );
        editSmsTypes();
        editMessagesTypes();
    }, []);

    const editSmsTypes = () => {
        if (message.HasMedia && props.mediaType.split('/')[0] === ENUM.IMAGE) {
            setMediatype(ENUM.IMAGE);
            setDisplayMediaType(props.mediaType.split('/')[1].toUpperCase());
        }
    }

    const editMessagesTypes = () => {
        if (message.HasAttachment) {
            if (arrayContains(props.mediaType.toLowerCase(), imageTypeArr)) {
                setDisplayMediaType(props.mediaType.toUpperCase());
                setMediatype(ENUM.IMAGE);
            } else if (props.mediaType === ENUM.PTT) {
                setDisplayMediaType(props.mediaType.toUpperCase());
                setMediatype(ENUM.PTT);
            } else if (props.mediaType === ENUM.VIDEO) {
                setDisplayMediaType(props.mediaType.toUpperCase());
                setMediatype(ENUM.VIDEO);
            } else if (props.mediaType === ENUM.DOCUMENT) {
                setDocumentType(props.fileName.split('.').pop());
                setDisplayMediaType(props.fileName.split('.').pop().toUpperCase());
                setMediatype(ENUM.DOCUMENT);
            }
        }
    }

    const defineFileMimetype = (extension) => {
        const fileMimeType = {
            'jpeg': "image/jpeg",
            'jpg': "image/jpg",
            'png': "image/png",
            'webp': "image/webp",
            'gif': "image/gif",
            'mp4': "video/mp4",
            'pdf': "application/pdf",
            'ogg': "audio/ogg",
            'oga': "audio/oga",
            'txt': "text/plain",
            'docx': "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            'xlsx': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            'mp3': "audio/mpeg",
        };
        const mediaMimetype = fileMimeType[extension];
        return mediaMimetype;
    }

    const fileOnClick = () => {
        if (props.mediaUrl) {
            setLoader(true);
            axios.get(props.mediaUrl, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': mimetype,
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(res => {
                setLoader(false);
                let media = new Blob([res.data], { type: mimetype });
                setMediaSize(media.size);
                let url = window.URL.createObjectURL(media);
                if (arrayContains(mediatype, displayMediaArr) ||
                    arrayContains(documentType, displayMediaArr)) {
                    setMediaSrc(url);
                    setOpenModal(true);
                } else {
                    downloadDocx(url, props.fileName);
                }
            }).catch(err => {
                alert(STRINGS.AN_ERROR_OCCURRED_WHILE_LOADING_FILE);
                setLoader(false);
            });
        } else if (message.MessageType === 7) {
            const obj = JSON.parse(message.Message);
            window.open(`https://maps.google.com?q=${obj.latitude},${obj.longitude}`);
        }
    }

    const returnFileIcon = () => {
        if (documentType === ENUM.PDF) {
            return (
                <img
                    alt='pdf-icon'
                    src='assets/pdf-icon.svg'
                    className='file-msg-pdf-icon'
                />
            );
        } else if (documentType === ENUM.DOCX) {
            return (
                <img
                    alt='docs-icon'
                    src='assets/docs-icon.svg'
                    className='file-msg-docs-icon'
                />
            );
        } else if (documentType === ENUM.XLSX) {
            return (
                <img
                    alt='excel-icon'
                    src='assets/excel-icon.svg'
                    className='file-msg-docs-icon'
                />
            );
        } else if (mediatype === ENUM.VIDEO) {
            return (
                <img
                    alt='video-icon'
                    src={'assets/video-icon.svg'}
                    className='file-msg-image-icon'
                />
            );
        } else if (mediatype === ENUM.PTT ||
            arrayContains(documentType, audioTypeArr)) {
            return (
                <img
                    alt='audio-icon'
                    src={'assets/audio-icon.svg'}
                    className='file-msg-image-icon'
                />
            );
        } else if (mediatype === ENUM.IMAGE) {
            return (
                <img
                    alt='image-icon'
                    src={'assets/image-icon.svg'}
                    className='file-msg-image-icon'
                />
            );
        } else if (message.MessageType === 7) {
            return (
                <img
                    alt='image-icon'
                    src={'assets/geo-alt.svg'}
                    className='file-msg-image-icon'
                />
            );
        } else {
            return (
                <img
                    alt='unknown-icon'
                    src={'assets/unknown-icon.svg'}
                    className='file-msg-image-icon'
                />
            );
        }
    }

    const formatBytes = mediaSize > 0 ? ` • ${prettyBytes(mediaSize)}` : '';

    const desc = message.MessageType === 7 ? JSON.parse(message.Message).description : 'Location message'; 
    
    return (
        <div
            className={
                `file-msg-main ${(props.mediaUrl || message.MessageType === 7) && 'file-msg-main-hover'}`
            }
            style={loader ?
                { cursor: 'wait' } :
                {}}
            onClick={fileOnClick}
        >
            <div>
                {returnFileIcon()}
            </div>
            <div className='file-msg-details-main'>
                <div className='file-msg-file-name'>
                    {props.fileName}
                </div>
                <div className='file-msg-file-details'>
                    {`${displayMediaType}${formatBytes}`}
                    {message.MessageType === 7 &&
                        `${desc}`}
                </div>
            </div>
            <Modal
                isOpen={openModal}
                toggle={() => setOpenModal(!openModal)}
                className='file-msg-modal-media'
            >
                <ModalBody>
                    {mediatype === ENUM.IMAGE &&
                        <img
                            alt='file-msg-image'
                            src={mediaSrc}
                            className='file-msg-image'
                            title={props.fileName}
                        />}

                    {(mediatype === ENUM.PTT ||
                        arrayContains(documentType, audioTypeArr)) &&
                        <audio
                            src={mediaSrc}
                            controls
                            title={props.fileName}
                        >
                            Your browser does not support the <code>audio</code> element.
                        </audio>}

                    {mediatype === ENUM.VIDEO &&
                        <video
                            controls
                            className='file-msg-video'
                            title={props.fileName}
                        >
                            <source src={mediaSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>}

                    {documentType === ENUM.PDF &&
                        <iframe
                            src={mediaSrc}
                            width='750px'
                            height='750px'
                            frameBorder={0}
                        />}
                </ModalBody>
            </Modal>
            {!props.mediaUrl &&
                message.MessageType !== 7 &&
                <img
                    className='file-ms-x-icon'
                    src='assets/x-lg.svg'
                    alt='x-icon'
                />}
        </div>
    )
}