import React, { Component } from 'react';
import { Tooltip} from 'reactstrap';
import './index.css';
import * as permissionFunc from '../../permissionScheme/permissionFunc';


class SideBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active1: this.props.active1,
            active2: this.props.active2,
            active3: this.props.active3,
            active4: this.props.active4,
            active5: this.props.active5,
            active6: this.props.active6,
            active7: this.props.active7,
            active8: this.props.active8,
            tooltipOpen2: false,
            tooltipOpen3: false,
            tooltipOpen4: false,
            tooltipOpen5: false,
            tooltipOpen6: false,
            tooltipOpen7: false,
            tooltipOpen8: false,
        }
    }

    sideBarToggle(active, callBack) {
        for (const key in this.state) {
            if (key === active) {
                this.setState({
                    [key]: true
                }, callBack())
            } else {
                this.setState({
                    [key]: false
                }, callBack())
            }
        }
    }

    render() {

        let showactive6 = (localStorage.getItem('silentMonitorAllowed') === 'true') ||
            (this.props.user &&
                this.props.user.CustomFields &&
                this.props.user.CustomFields.SilentMonitorAllowed);
        return (
            <div className="siteBar">
                <div className="d-flex flex-column" style={{ height: "95%" }}>
                    <img src='assets/m2c-icon.svg' alt="" className="p-3 mt-4 " />
                    <img
                        style={this.state.active1
                            ? {
                                backgroundColor: '#2f3d58'
                            }
                            : null}
                        src='assets/group-4.png'
                        alt=""
                        id="myUsersBtn"
                        className="border-5 p-3 mt-4 mx-auto imgStyle cursorPointer"
                        onClick={() => {
                            this.sideBarToggle(
                                'active1',
                                this.props.homePage,
                            );
                        }}
                    />
                    <Tooltip
                        placement="right"
                        isOpen={this.state.tooltipOpen1}
                        target="myUsersBtn"
                        toggle={() => this.setState({ tooltipOpen1: !this.state.tooltipOpen1 })}>
                        {'My Users'}
                    </Tooltip>
                    {!permissionFunc.changeDisplay('side_bar_my_packages') &&
                        <img
                            style={this.state.active2
                                ? {
                                    backgroundColor: '#2f3d58'
                                }
                                : null}
                            src='assets/group-4-copy.svg'
                            alt=""
                            className="border-5 p-3 mt-4 mx-auto imgStyle cursorPointer"
                            id='side_bar_my_packages'
                            onClick={() => {
                                this.sideBarToggle(
                                    'active2',
                                    this.props.myPackagePage,
                                );
                            }}
                        />}
                    {!permissionFunc.changeDisplay('side_bar_my_packages') &&
                        <Tooltip
                            placement="right"
                            isOpen={this.state.tooltipOpen2}
                            target="side_bar_my_packages"
                            toggle={() => this.setState({ tooltipOpen2: !this.state.tooltipOpen2 })}>
                            {'My Packages'}
                        </Tooltip>
                    }
                    {!permissionFunc.changeDisplay('side_bar_account_settings') &&
                        <img
                            style={this.state.active3
                                ? {
                                    backgroundColor: '#2f3d58'
                                }
                                : null}
                            src='assets/group-4-copy-2.svg'
                            alt=""
                            className="border-5 p-3 mt-4 mx-auto imgStyle cursorPointer"
                            id='side_bar_account_settings'
                            onClick={() => {
                                this.sideBarToggle(
                                    'active3',
                                    this.props.settingPage,
                                );
                            }}
                        />}
                    {!permissionFunc.changeDisplay('side_bar_account_settings') &&
                        <Tooltip
                            placement="right"
                            isOpen={this.state.tooltipOpen3}
                            target="side_bar_account_settings"
                            toggle={() => this.setState({ tooltipOpen3: !this.state.tooltipOpen3 })}>
                            {'Account Settings'}
                        </Tooltip>
                    }
                    {!permissionFunc.changeDisplay('side_bar_crm_integration') &&
                        <img
                            style={this.state.active4
                                ? {
                                    backgroundColor: '#2f3d58'
                                }
                                : null}
                            src='assets/group-4-copy-3.svg'
                            alt=""
                            className="border-5 mt-4 mx-auto imgStyle cursorPointer"
                            id='side_bar_crm_integration'
                            onClick={() => {
                                this.sideBarToggle(
                                    'active4',
                                    this.props.crm_integration,
                                );
                            }}
                        />}
                    {!permissionFunc.changeDisplay('side_bar_crm_integration') &&
                        <Tooltip
                            placement="right"
                            isOpen={this.state.tooltipOpen4}
                            target="side_bar_crm_integration"
                            toggle={() => this.setState({ tooltipOpen4: !this.state.tooltipOpen4 })}>
                            {'Integrations'}
                        </Tooltip>
                    }
                    {showactive6 ?
                        <img
                            style={this.state.active6
                                ? {
                                    backgroundColor: '#2f3d58'
                                }
                                : null}
                            src='assets/real-time-monitoring-sidebar-icon.svg'
                            alt=""
                            id="silentMonitorBtn"
                            className="border-5 mt-4 mx-auto imgStyle cursorPointer"
                            onClick={() => {
                                this.sideBarToggle(
                                    'active6',
                                    this.props.realTimeMonitoring,
                                );
                            }}
                        /> : null}
                    {showactive6 ?
                    <Tooltip
                        placement="right"
                        isOpen={this.state.tooltipOpen5}
                        target="silentMonitorBtn"
                        toggle={() => this.setState({ tooltipOpen5: !this.state.tooltipOpen5 })}>
                        {'Real Time Monitor'}
                    </Tooltip>
                    : null}

                    {!permissionFunc.changeDisplay('side_bar_graph') &&
                        <img
                            style={this.state.active7
                                ? {
                                    backgroundColor: '#2f3d58',
                                }
                                : null}
                            src='assets/graph-icon.svg'
                            alt=""
                            className="border-5 mt-4 mx-auto imgStyle cursorPointer"
                            id='side_bar_graph'
                            onClick={() => {
                                this.sideBarToggle(
                                    'active7',
                                    this.props.graph,
                                );
                            }}
                        />}
                    {!permissionFunc.changeDisplay('side_bar_graph') &&
                    <Tooltip
                        placement="right"
                        isOpen={this.state.tooltipOpen7}
                        target="side_bar_graph"
                        toggle={() => this.setState({ tooltipOpen7: !this.state.tooltipOpen7 })}>
                        {'Insights'}
                    </Tooltip>}

                    {!permissionFunc.changeDisplay('side_bar_contacts') &&
                        <img
                            style={this.state.active8
                                ? {
                                    backgroundColor: '#2f3d58',
                                }
                                : null}
                            src='assets/person-lines-fill.svg'
                            alt=""
                            className="border-5 mt-4 mx-auto imgStyle cursorPointer"
                            id='side_bar_contacts'
                            onClick={() => {
                                this.sideBarToggle(
                                    'active8',
                                    this.props.contacts,
                                );
                            }}
                        />}
                    {!permissionFunc.changeDisplay('side_bar_contacts') &&
                    <Tooltip
                        placement="right"
                        isOpen={this.state.tooltipOpen8}
                        target="side_bar_contacts"
                        toggle={() => this.setState({ tooltipOpen8: !this.state.tooltipOpen8 })}>
                        {'Account Contacts'}
                    </Tooltip>}

                    <img
                        src={this.state.active5 ? 'assets/contact_us_icon.svg' : 'assets/contact_us_icon2.svg'}
                        alt=""
                        id="contactUsBtn"
                        className="border-5 mt-4 mx-auto imgStyle cursorPointer cursorPointer mt-auto"
                        onClick={() => {
                            this.sideBarToggle(
                                'active5',
                                this.props.contactUs,
                            );
                        }}
                    />
                    <Tooltip
                        placement="right"
                        isOpen={this.state.tooltipOpen6}
                        target="contactUsBtn"
                        toggle={() => this.setState({ tooltipOpen6: !this.state.tooltipOpen6 })}>
                        {'Contact Us'}
                    </Tooltip>   

                </div>
            </div>
        );
    }
}

export default SideBar;
