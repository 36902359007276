import React, { useState, useEffect, useCallback } from 'react';
import { Button, Input, FormGroup, Label, Form, ModalHeader, Spinner, ModalBody } from 'reactstrap';
import axios from '../../axios';
import './mfaModal.css';
import ReactCodeInput from 'react-code-input';
import * as STRINGS from '../../strings/english';
import * as mfaConf from '../../services/mfaConf';

function MfaModal(props) {

    const [isLoader, setIsLoader] = useState(false);
    const [isVerifyLoader, setIsVerifyLoader] = useState(false);
    const [qrData, setQrData] = useState(null);
    const [pinCode, setPinCode] = useState('');

    const mfaStatus = props.userData.MFAStatus;

    const handleUserKeyPress = useCallback(event => {
        const { keyCode } = event;
        const elmDataId = event.target.getAttribute('data-id');
        if (keyCode === 13 && elmDataId === '5') {
            verifyOnClick();
        }
    }, [pinCode]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress])

    useEffect(() => {
        if (!mfaStatus) {
            setIsLoader(true);
            axios.post(
                '/Provisioning/MFAEnable',
                null,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                    }
                }
            ).then(res => {
                setQrData(res.data);
                setIsLoader(false);

            }).catch(err => {
                setIsLoader(false);
            });
        }
    }, [])

    const codeInputOnChange = (value) => {
        setPinCode(value);
    }

    const verifyOnClick = () => {
        let urlPath = mfaStatus ? 'MFADisable' : 'MFAEnableApprove';
        let data = {
            [mfaStatus ? 'DisableCode' : 'FirstCode']: pinCode
        }
        if (pinCode.length === mfaConf.fieldsLength) {
            setIsVerifyLoader(true);
            axios.post(
                `/Provisioning/${urlPath}`,
                data,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                    }
                }
            ).then(res => {
                if (!res.data.Success) {
                    alert(STRINGS.WRONG_AUTHENTICATION_CODE);
                } else if (res.data.Success) {
                    alert(
                        `${STRINGS.TWO_FACTOR_AUTHENTICATION} ${mfaStatus ? STRINGS.HAS_BEEN_DISABLED : STRINGS.ENABLED}`
                    )
                };
                setIsVerifyLoader(false);
                props.mfaBtnOnClick()
                props.getUserData();
            }).catch(err => {
                setIsVerifyLoader(false);
            });
        } else {
            alert(`${STRINGS.PLEASE_MATCH_THE_REQUESTED_FORMAT}`);
        }
    }


    return (
        <span>
            <ModalHeader
                className='mfa-modal-header'
            >
                <div
                    className='mfa-modal-header-div-main'
                >
                    {`${mfaStatus ? STRINGS.DISABLE : ''} `}
                    {STRINGS.TWO_FACTOR_AUTHENTICATION}
                </div>
            </ModalHeader>

            <ModalBody>
                <div
                    className='mfa-modal-body-div-main'
                >
                    {isLoader ?
                        <Spinner
                            className='mfa-modal-body-spinner'
                        />
                        :
                        qrData && !mfaStatus ?
                            <div>
                                <h5 className='mfa-modal-qr-Manual-explanation'>
                                    {/* Temporary text */}
                                    Scan the QR code
                                </h5>
                                <img
                                    className='mfa-modal-qr-image'
                                    alt='qr-code'
                                    src={qrData.QR}
                                />
                                <h5 className='mfa-modal-qr-Manual-explanation'>
                                    {/* Temporary text */}
                                    or copy the text
                                </h5>
                                <p className='mfa-modal-Manual'>
                                    {qrData.Manual}
                                </p>
                            </div> :
                            null
                    }
                    <p className='mfa-modal-code-input-header'>
                        {STRINGS.AUTHENTICATION_CODE}
                    </p>
                    <ReactCodeInput
                        type='number'
                        fields={mfaConf.fieldsLength}
                        {...mfaConf.reactCodeInputProps}
                        onChange={codeInputOnChange}
                    />
                    <Button
                        className='mfa-modal-verify-btn'
                        onClick={verifyOnClick}
                        color=""
                    >
                        {isVerifyLoader ?
                            <Spinner size='md' /> :
                            STRINGS.VERIFY}
                    </Button>
                    <p className='mfa-modal-explanation-input-code'>
                        {STRINGS.EXPLANATION_INPUT_CODE}
                    </p>
                </div>
            </ModalBody>

        </span>
    )
}
export default MfaModal;