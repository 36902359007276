
const nameWithoutTwoPoint = (opts) => {
    const values = opts.ctx.rangeBar.getTooltipValues(opts);
    return values.seriesName.split(':')[0];
}

const percentValue = (val, opts) => {
    return [val + '%']
}

const nameTwoPointValue = (seriesName, opts) => {
    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
}

const onlyValue = (val) => {
    return val;
}

function chartsFunctionManager(name) {
    if (typeof name === 'string') {
        switch (name) {
            case 'nameWithoutTwoPoint':
                return nameWithoutTwoPoint;
            case 'percentValue':
                return percentValue;
            case 'nameTwoPointValue':
                return nameTwoPointValue;
            case 'onlyValue':
                return onlyValue;
            default:
                break;
        }
    }
    else return name;
}

export const addFuncToData = (data) => {
    data.forEach(elm => {
        if (elm.options.chart.type === 'heatmap') {
            elm.options.dataLabels.formatter = chartsFunctionManager(elm.options.dataLabels.formatter);
        }
        if (elm.options.chart.type === 'rangeBar') {
            elm.options.tooltip.custom = chartsFunctionManager(elm.options.tooltip.custom);
        }
        if (elm.options.chart.type === 'radialBar') {
            elm.options.legend.formatter = chartsFunctionManager(elm.options.legend.formatter);
            elm.options.plotOptions.radialBar.dataLabels.value.formatter =
                chartsFunctionManager(elm.options.plotOptions.radialBar.dataLabels.value.formatter);
        }
    });
    return data;
}