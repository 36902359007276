import React, { useState } from "react";
import './advancedDropdown.css';
import AdvanceModal from './advanceModal'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import * as STRINGS from '../../../strings/english';
import * as permissionFunc from '../../../permissionScheme/permissionFunc';


export const Showing = {
    VOICE: "voiceMail",
    RECORDING: "recording",
    TIME: "timeForward",
    ATTENDANT: "autoAttendant",
    GROUP_RINGING: "groupRinging",
    SEQUENTIAL_RINGING: "sequentialRinging"
}

function AdvancedDropdown(props) {

    const [show, setShow] = useState({
        [Showing.VOICE]: false,
        [Showing.RECORDING]: false,
        [Showing.TIME]: false,
        [Showing.ATTENDANT]: false,
        [Showing.GROUP_RINGING]: false,
        [Showing.SEQUENTIAL_RINGING]: false
    });


    const toggle = (name) => {
        setShow({ [name]: !show[name] })

    };

    function getCurrentModel() {
        let currentModal = Object.keys(show).find(key => show[key] === true);
        return { key: currentModal, value: show[currentModal] };
    };

    let hasFalseKeys = permissionFunc.checkDropdownItems('advanced_features_dropdown_di');
    return <div>
        <UncontrolledDropdown >
            <DropdownToggle color={'white'} className="btnBorder white ">
                {STRINGS.ADVANCED_FEATURES}
                <img src='assets/Shape.svg' alt="" className=" m-2" />
            </DropdownToggle>
            {!hasFalseKeys && <DropdownMenu style={{overflowX: 'hidden'}}>

                {!props.isAccessNumber && !permissionFunc.changeDisplay('advanced_features_dropdown_di_voice_mail_options') ? 
                    <DropdownItem
                        className="my-1 white"
                        id='advanced_features_dropdown_di_voice_mail_options'
                        onClick={() => toggle(Showing.VOICE)}>
                        <img src='assets/voicemail-2.svg' alt="" className="" />
                        {STRINGS.VOICE_MAIL_OPTIONS}
                    </DropdownItem> : null}

                {!permissionFunc.changeDisplay('advanced_features_dropdown_di_recording_options') && 
                    <DropdownItem
                        className="my-1 white"
                        id='advanced_features_dropdown_di_recording_options'
                        onClick={() => toggle(Showing.RECORDING)}>
                        <img src='assets/mic.svg' alt="" className="" />
                        {STRINGS.RECORDING_OPTIONS}
                    </DropdownItem>}

                {!props.isAccessNumber ? 
                <React.Fragment>
                    {!permissionFunc.changeDisplay('advanced_features_dropdown_di_time_forward_options') && 
                        <DropdownItem
                            className="my-1 white"
                            id='advanced_features_dropdown_di_time_forward_options'
                            onClick={() => toggle(Showing.TIME)}
                        >
                            <img src='assets/icon-clock.svg' alt="" className="" />
                            {STRINGS.TIME_FORWARD_OPTIONS}
                        </DropdownItem>}

                    {!permissionFunc.changeDisplay('advanced_features_dropdown_di_auto_attendant') && 
                    <DropdownItem
                            className="my-1 white"
                            id='advanced_features_dropdown_di_auto_attendant'
                            onClick={() => toggle(Showing.ATTENDANT)}
                        >
                            <img src='assets/auto-attendant-icon.png' alt="" className="" />
                            {STRINGS.AUTO_ATTENDANT}
                        </DropdownItem>}

                    {!permissionFunc.changeDisplay('advanced_features_dropdown_di_group_ringing') && 
                        <DropdownItem
                            className="my-1 white"
                            id='advanced_features_dropdown_di_group_ringing'
                            onClick={() => toggle(Showing.GROUP_RINGING)}
                        >
                            <img src='assets/group-ringing-icon.svg' alt="" className="" />
                            <span
                                style={{ marginLeft: '0.5rem' }}
                            >
                                {STRINGS.GROUP_RINGING}
                            </span>
                        </DropdownItem>}

                    {!permissionFunc.changeDisplay('advanced_features_dropdown_di_sequential_ringing') && 
                        <DropdownItem
                            className="my-1 white"
                            id='advanced_features_dropdown_di_sequential_ringing'
                            onClick={() => toggle(Showing.SEQUENTIAL_RINGING)}
                        >
                            <img src='assets/sequential-ringing-icon.svg' alt="" className="" />
                            <span
                                style={{ marginLeft: '0.5rem' }}
                            >
                                {STRINGS.SEQUENTIAL_RINGING}
                            </span>
                        </DropdownItem>}
                </React.Fragment> : null}

            </DropdownMenu>}
        </UncontrolledDropdown>


        <AdvanceModal
            refresh={() => props.refresh()}
            details={props.details}
            modal={getCurrentModel()}
            toggle={(e) => toggle(e)}
        />

    </div>
}

export default AdvancedDropdown