import React, { useEffect } from "react";
import useAudioPlayer from './useAudioPlayer';

function AudioPlayer(props) {
    const { setPlaying, setEditUrl, duration, curTime } = useAudioPlayer();

    useEffect(() => {
        stopWhenFinish()
        if (curTime && props.duration) {
            props.duration(time_convert(curTime))
        }
    }, [curTime]);


    useEffect(() => {
        if (duration && props.duration) {
            props.duration(time_convert(duration))
        }
    }, [duration]);



    function time_convert(number) {
        if (number) {
            let num = Math.floor(number)
            const hours = Math.floor(num / 60);
            const minutes = num % 60;
            return `${hours < 10 ? 0 : ""}${hours}:${minutes < 10 ? 0 : ""}${minutes}`;
        } else {
            return null
        }
    };

    function stopWhenFinish() {
        if (duration && curTime) {
            if (duration === curTime) {
                props.setPlaying({ id: props.playing.id, work: false, audio: props.playing.audio, src: props.playing.src })
            }
        }
    }

    return (
        <div className="player" style={{ cursor: "pointer" }}>
            <audio id="audio">
                Your browser does not support the <code>audio</code> element.
            </audio>
            <div className="controls">
                <img
                    src={`assets/${props.playing.work ? "stop" : "play"}-button.svg`}
                    alt=""
                    onClick={() => {
                        window.open(props.playing.src)
                        // props.setPlaying({ id: props.playing.id, work: !props.playing.work, audio: props.playing.audio, src: props.playing.src  })
                        // setPlaying({ mode: !props.playing.work, src: props.playing.src})
                        setEditUrl(true)
                    }}
                />
            </div>
        </div>
    );
}


export default AudioPlayer;
