import React, {Component} from 'react';
import './index.css';
import FileDrop from 'react-file-drop';
import {FormGroup, Col, Label, Input,Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import axios from '../../axios';
import * as STRINGS from '../../strings/english';

class NoUsers extends Component {
    state = {
        selectedFile: null,
        fileName: 'Choose a file Or drag it here',
        loaded: 0
    }

    componentDidMount() {
        axios.get('/Provisioning/GetOrganizationCSVTemplates', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({userData: response.data})
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                this
                    .props
                    .history
                    .push('/login')
            }
        });
    }

    handelInput(files) {
        if(files.target&&files.target.files){
        const dataFile = new FormData()
        dataFile.append('file',files.target.files[0] )
        dataFile.append('username', localStorage.getItem('email'))
        this.setState({
            selectedFile:files.target.files[0],
            loaded: 0,
            fileName: files.target.files[0].name,
            dataFile:dataFile
        })
    }
    }
    handleDrop = (files, event) => {
        if (files) {
            const dataFile = new FormData()
            dataFile.append('file',files[0])
            dataFile.append('username', localStorage.getItem('email'))
        this.setState({
            selectedFile:files[0],
            loaded: 0,
            fileName: files[0].name,
            dataFile: dataFile
        })
        }
    }
    downloadTemplate(){
        let fileDownload = require('js-file-download');
        fileDownload(this.state.userData.OrganizationCSVUsersTemplates, 'user-template.csv');
    }
    downloadPackage() {
        let fileDownload = require('js-file-download');
        fileDownload(this.state.userData.OrganizationCSVPackagesTemplates, 'package-template.csv');
    }
    sendFile() {
        axios
        .post('/Provisioning/UploadOrganizationCSV', this.state.dataFile, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
    })
        .then(response => {
            this.props.homePage()
        })
            .catch(error => {
            console.log(error);
            alert(error.response
                ? error.response.data.Description||error.response.data.Message : error)
        });
    }
    render() {
        return (
            <React.Fragment>
                <Col md='12' className="font-weight-bold mt-5 text-center">
                    <h4>{this.props.isExistingUsers? "" : "No users yet"}</h4>
                </Col>
                <Col
                    style={{
                    border: 'dashed 1px #1a253a',
                    height: '400px'
                }}
                    md='12'
                    className="text-center">
                    <FileDrop onDrop={(files, event) => this.handleDrop(files, event)}>
                        <img src='assets/group-16.png' alt="" className="mt-4"/>
                        <FormGroup>
                            <Label
                                className="btn-link"
                                style={{
                                position: 'relative'
                            }}>
                                <Input
                                    accept=".csv"
                                    style={{
                                    opacity: 0,
                                    position: "absolute"
                                }}
                                    onChange={(e) => this.handelInput(e)}
                                    type="file"/><div className='small' style={{color:'#b8babe'}}>You can upload a file with all your users</div>
                                   <div>{this.state.fileName}</div> </Label>
                        </FormGroup>
                    </FileDrop>
                    <Button style={{color:'white'}} onClick={()=>this.sendFile()} color={''} className={`col-${this.props.isExistingUsers ? 2: 1} btnColor3`}>
                            Send</Button>
                </Col>
                <Col md={12} className="my-2">
                    <img src='assets/icon-download.png' alt=""/>
                   <Link onClick={()=>this.downloadTemplate()} className="ml-1" to="">{STRINGS.DOWNLOAD_A_USER_TEMPLATE}</Link> </Col>
                <Col md={12} className="my-2">
                    <img src='assets/icon-download.png' alt=""/>
                   <Link onClick={()=>this.downloadPackage()} className="ml-1" to="">{STRINGS.DOWNLOAD_A_PACKAGES_TEMPLATE}</Link> </Col>
            </React.Fragment>
        )
    }
}

export default NoUsers