import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import './index.css';
import * as STRINGS from '../../strings/english';

class OpenUserPageModal extends Component {

    render() {
        return (
            <div>
                <Modal
                    toggle={() => this.props.openUserPageToggle(false)}
                    isOpen={this.props.open}
                    className={''}
                    backdrop={'static'}
                    size={'md'}
                >
                    <ModalHeader className="d-flex justify-content-center">
                        <span className='font-weight-bold'>{STRINGS.LOGGED_IN_AS_USER + ': '}</span>
                        <span style={{fontFamily: 'serif'}}>{this.props.username}</span>
                    </ModalHeader>
                    <ModalBody className='ml-auto mr-auto'>
                        <Button 
                            onClick={() => this.props.openUserPageToggle(true)}
                            color='success'    
                        >{STRINGS.UNDO_LOGIN}</Button>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default OpenUserPageModal;