import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledDropdown,
    ModalFooter
} from 'reactstrap';
import './advance.css';
import '../index.css';
import FeatureService from "../../../services/FeatureService";
import {
    formatNumbers
} from '../../../services/formatPhoneNumber';
import CountrySelect from '../../../services/format-number-input/countrySelect';
import { formatTime } from '../../../services/tools';

function TimeForward(props) {

    let initialDays = [
        { day: "Sunday", selected: false },
        { day: "Monday", selected: false },
        { day: "Tuesday", selected: false },
        { day: "Wednesday", selected: false },
        { day: "Thursday", selected: false },
        { day: "Friday", selected: false },
        { day: "Saturday", selected: false }
    ]
    let optionsArray = ["Forward to the default number defined", "Go to Voice mail"]

    const [days, setDays] = useState(initialDays)
    const [selectedDays, setSelectedDays] = useState([])
    const [isEnabled, setIsEnabled] = useState(false)
    const [options, setOptions] = useState(0)
    const [timeZone, setTimeZone] = useState({})
    const [userTimeZoneId, setUserTimeZoneId] = useState(0)
    const [description, setDescription] = useState("")
    const [time, setTime] = useState({ start: "00:00", end: "00:00" })
    const [addDivert, setAddDivert] = useState(false)
    const [divert, setDivert] = useState("")
    const [localTimes, setLocalTimes] = useState([])
    const [isLoader, setIsLoader] = useState(false)
    const [edit, setEdit] = useState({ mode: false, place: null })

    useEffect(() => {
        const fetchData = async () => {
            const timezone = await getTimeZone();
            setTimeZone(timezone.data);
            await getFeature(timezone.data);
        }
        fetchData().catch(e => {
            console.error(e);
            setIsLoader(false);
        });
    }, [])

    const getTimeZone = async () => {
        return FeatureService.getSystemTimeZones();
    }

    async function getFeature(timezone) {
        setIsLoader(true);
        const feature = await FeatureService.getFeature(props.PackageRegistrationID);
        setIsEnabled(feature.data.IsEnabled);
        setOptions(feature.data.Options ? feature.data.Options : 0);
        setUserTimeZoneId(feature.data.UserTimeZoneId ? feature.data.UserTimeZoneId : 0);
        convertUTCTimeToLocalTime(JSON.parse(JSON.stringify(feature.data.Times)), timezone, feature.data.UserTimeZoneId);
        setIsLoader(false);
    }

    function convertUTCTimeToLocalTime(arr, timeZoneArr, timeZoneId) {
        if (arr.length > 0) {
            arr.forEach(time => {
                const myTimeZone = findMyTimeZone(timeZoneArr, timeZoneId);
                const offset = (new Date().getTimezoneOffset() + myTimeZone.OffsetInMinutes) * -1;

                let splitStartTime = time.UTCStartTime.split(':');
                let startTime = new Date();
                startTime.setHours(splitStartTime[0], splitStartTime[1]);
                startTime.setMinutes(startTime.getMinutes() + offset);

                let splitEndTime = time.UTCEndTime.split(':');
                let endTime = new Date();
                endTime.setHours(splitEndTime[0], splitEndTime[1]);
                endTime.setMinutes(endTime.getMinutes() + offset);

                time.UTCStartTime = formatTime(startTime);
                time.UTCEndTime = formatTime(endTime);
            })
            setLocalTimes(arr);
        }
    }

    const saveChanges = (body) => {
        setIsLoader(true)
        FeatureService.setTimedForwardingFeatureOptions(body, props.PackageRegistrationID)
            .then((res) => {
                setIsLoader(false)
                getFeature()
            })
            .catch(e => {
                console.log(e)
                setIsLoader(false)
            })
    };

    function timeOffset(timeZone, requestBody) {
        const offset = new Date().getTimezoneOffset() + timeZone.OffsetInMinutes;

        let splitStartTime = time.start.split(':');
        let startTime = new Date();
        startTime.setHours(splitStartTime[0], splitStartTime[1]);
        startTime.setMinutes(startTime.getMinutes() + offset);

        let splitEndTime = time.end.split(':');
        let endTime = new Date();
        endTime.setHours(splitEndTime[0], splitEndTime[1]);
        endTime.setMinutes(endTime.getMinutes() + offset);

        requestBody.utcstart = formatTime(startTime).replace(":", "");
        requestBody.utcend = formatTime(endTime).replace(":", "");
    }

    function findMyTimeZone(timeZoneArr = [], id = null) {
        const arr = Object.keys(timeZoneArr).length > 0 ? timeZoneArr : timeZone;
        const timeZoneId = id ? id : userTimeZoneId;
        for (const key in arr) {
            if (arr[key].Id === timeZoneId) {
                return arr[key];
            }
        }
    }

    async function saveTimedForwardingDivert() {
        let body = {
            "packageregistrationid": props.PackageRegistrationID,
            // "divert": convertFormatNumToRegularNum(divert),
            "divert": divert,
            "utcstart": time.start.replace(":", ""),
            "utcend": time.end.replace(":", ""),
            "description": description
        }
        const myTimeZone = findMyTimeZone();
        timeOffset(myTimeZone, body);
        if (divert.length > 0) {
            setIsLoader(true)
            for (let key in selectedDays) {
                let day = selectedDays[key]
                await new Promise((resolve, reject) => {
                    setTimeout(() => {
                        if (edit.mode) {
                            body.currentposition = edit.place
                        }
                        FeatureService[edit.mode ? 'updateTimedForwardingDivert' : 'insertTimedForwardingDivert']({ ...body, dayofweek: day }).then(() => {
                            resolve()
                            setIsLoader(false)
                        }, 100)
                    })
                })
            }
            setIsLoader(false)
            getFeature()
            closeAddDivert(false)
        } else {
            alert("Divert to is a required field")
            setIsLoader(false)
        }
    }


    const removeTimedForwardingDivert = (index) => {
        setIsLoader(true)
        let body = {
            "packageregistrationid": props.PackageRegistrationID,
            "currentposition": index /* zero based */
        }
        FeatureService.removeTimedForwardingDivert(body)
            .then(() => {
                setIsLoader(false)
                getFeature()
            })
            .catch((e) => {
                setIsLoader(false)
                console.log(e)
            })
    };

    const handleTime = (type, value) => {
        setTime({ ...time, [type]: value });
    };

    const handleSelectedDays = index => {
        let array = [...days]
        array[index].selected = !days[index].selected
        setDays(array)
        setSelectedDays([...selectedDays, index])
    };

    function getSelectedDays() {
        let isSelect = days.filter((item, index) => {
            return item.selected ? item.day : null
        })
        return isSelect.length > 0 ? isSelect.map((item,) => item.day + ", ") : "select..."
    }

    function closeAddDivert(divertMode) {
        setAddDivert(divertMode)
        setDays(initialDays)
        setDescription("")
        setSelectedDays([])
        setTime({ start: "00:00", end: "00:00" })
        setDivert("")
        setEdit({ mode: false, place: null })
    }

    function editDivert(index) {
        setEdit({ mode: true, place: index })
        let currentTime = localTimes[index]
        setAddDivert(true)
        handleSelectedDays(currentTime.DayOfWeek)
        setDescription(currentTime.Description)
        setTime({ start: currentTime.UTCStartTime, end: currentTime.UTCEndTime })
        setDivert(currentTime.Destination)
    }

    const renderTableBody = () => {
        let options = [];
        if (localTimes && localTimes.length !== 0) {
            localTimes.forEach((time, i) => {
                options.push(<tr key={`listRow${i}`} id='tf-tr' className="tr">
                    <td id='tf-td-1'>{time.DayOfWeek < 7 ? initialDays[parseInt(time.DayOfWeek)].day : ""}</td>
                    <td id='tf-td-2' className='th-3-points'>{time.Description}</td>
                    <td id='tf-td-3'>{formatNumbers(time.Destination)}</td>
                    <td>{time.UTCStartTime}</td>
                    <td>{time.UTCEndTime}</td>
                    <td>
                        <UncontrolledDropdown direction="up">
                            <DropdownToggle color={'white'} >{''}
                                <img src='assets/combined-shape-copy-3.svg' alt="" className=" m-2" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    className=""
                                    onClick={() => editDivert(i)}>
                                    <img src='assets/edit-2-outline-copy.svg' alt="" className="" />
                                    Edit
                                </DropdownItem>
                                <DropdownItem
                                    className=""
                                    onClick={() => { removeTimedForwardingDivert(i) }}>
                                    <img src='assets/icon-trash-copy-9.svg' alt="" className="" />
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td>
                </tr>
                )
            })
        }
        return options
    };

    function divertsTable() {
        return (
            <div>
                <table className="table">
                    <thead className="fs-12 gray thead">
                        <tr className="trh">
                            <th id='tf-th-1'>Day</th>
                            <th id='tf-th-2'>Description</th>
                            <th id='tf-th-3'>Diverted to</th>
                            <th>Local Start</th>
                            <th>Local end</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: "300px" }} className="tbody">
                        {renderTableBody(localTimes)}
                    </tbody>
                </table>
            </div>
        );
    }

    const callBack = (number) => {
        setDivert(number)
    }

    return <span>
        <ModalHeader
            style={{
                display: "flow-root",
                height: "90px"
            }}
        >
            <div className="d-flex justify-content-between fs-22" style={{ padding: "1.5rem  1rem" }}>
                <div>Time Forward options</div>
                <div className='custom-control custom-switch isolate-the-design'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={`customSwitches2-`}
                        checked={isEnabled}
                        onChange={() => { setIsEnabled(!isEnabled) }}
                    />
                    <label className='custom-control-label' htmlFor={`customSwitches2-`} />
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
            <Form style={{ padding: "1.5rem  1rem" }}>

                <FormGroup className="background-lightGrey">
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="GMT Offset" className="fs-12">GMT Offset</Label>
                                <UncontrolledDropdown id="GMT Offset">
                                    <DropdownToggle style={{ width: "100%", textAlign: "left" }}
                                        className="border mr-2 fs-14 oneLine" color={'white'} caret>
                                        {Object.keys(timeZone).map(key => {
                                            return timeZone[key].Id === userTimeZoneId ? timeZone[key].ZoneName : null

                                        })}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {Object.keys(timeZone).map(key => {
                                            return <DropdownItem key={key}
                                                onClick={(e) => { setUserTimeZoneId(timeZone[key].Id) }}>
                                                {timeZone[key].ZoneName}
                                            </DropdownItem>
                                        })}

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="times" className="fs-12">When out of defined times</Label>
                                <UncontrolledDropdown id="times">
                                    <DropdownToggle style={{ width: "100%", textAlign: "left" }} className="border mr-2 fs-14 oneLine" color={'white'}
                                        caret>
                                        {options === 0 ? "Select..." : optionsArray[options - 2]}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={(e) => setOptions(2)}>{optionsArray[0]}</DropdownItem>
                                        <DropdownItem onClick={(e) => setOptions(3)}>{optionsArray[1]}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-end fs-14">
                            <div className="mr-2 pointer" onClick={() => props.toggel()}>Cancel</div>
                            <div className="mr-0 light-blue pointer"
                                onClick={() => saveChanges({ "Options": options, "UserTimeZoneId": userTimeZoneId })}> Save</div>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <div
                        className="d-flex justify-content-start"
                        style={{ width: "fit-content", cursor: "pointer" }}
                        onClick={() => closeAddDivert(true)}>
                        <img className="ml-0" src="assets/plus-circle-btn.svg" alt="" />
                        <div className="ml-2 light-blue">Add divert</div>
                    </div>
                </FormGroup>
                {isLoader ?
                    <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }} src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                    : null}
                {addDivert ?
                    <FormGroup className="background-lightGrey">
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="Days" className="fs-12">Days</Label>
                                    <UncontrolledDropdown id="Days">
                                        <DropdownToggle style={{ width: "100%", textAlign: "left" }}
                                            className="border mr-2" color={'white'}>
                                            <div>{getSelectedDays()}</div>
                                        </DropdownToggle>

                                        <DropdownMenu>
                                            {days.length > 0 ? days.map((item, i) => {
                                                return (
                                                    <ul key={i} >
                                                        <div className="d-flex">
                                                            <Input type="checkbox"
                                                                checked={!!item.selected}
                                                                onChange={() => handleSelectedDays(i)} />
                                                            <div>{item.day}</div>
                                                        </div>
                                                    </ul>)
                                            }) : null}

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="Description" className="fs-12">Description</Label>
                                    <Input
                                        id="Description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    {/* <Label for="DivertedTo" className="fs-12">Diverted to</Label> */}
                                    {/* <Input
                                        id="DivertedTo"
                                        type="text"
                                        onChange={(e) => setDivert(e.target.value)}
                                        value={formatInputValue(divert)}>

                                    </Input> */}
                                    <React.Fragment>
                                        <Label for="country-select-input" className="fs-12 sr-form-label">Diverted to</Label>
                                        <CountrySelect
                                            numberToUpdate={edit.mode ? divert : null}
                                            getNumberToUpdate={callBack}
                                        />
                                    </React.Fragment>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="fs-12" for="startTime">Local start time</Label>
                                    <Input
                                        type="time"
                                        name="startTime"
                                        id="startTime"
                                        value={time.start}
                                        onChange={(e) => {
                                            handleTime("start", e.target.value)
                                        }}>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="fs-12" for="endTime">Local end time</Label>
                                    <Input
                                        type="time"
                                        name="endTime"
                                        id="endTime"
                                        value={time.end}
                                        onChange={(e) => {
                                            handleTime("end", e.target.value)
                                        }}></Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-end fs-14">
                                <div className="mr-2 pointer" onClick={() => closeAddDivert(false)}>Cancel</div>
                                <div className="mr-0 light-blue pointer" onClick={() => saveTimedForwardingDivert()}>{!edit.mode ? "Save" : "Update"}</div>
                            </Col>
                        </Row>
                    </FormGroup> : null}
                <FormGroup style={{ height: "20%" }}>
                    {localTimes.length > 0 ? divertsTable() : null}
                </FormGroup>

            </Form>
        </ModalBody>
        <ModalFooter style={{ display: "block" }}>
            <FormGroup row className="justify-content-end">
                <Col md={3}><Button className="btnColor5 fontColor fs-14 no-border" style={{ width: "100%" }}
                    size="lg"
                    onClick={() => props.toggel()}>Cancel</Button></Col>
                <Col md={3}><Button className="btnColor2 white fs-14 no-border btnColor2" style={{ width: "100%" }}
                    size="lg"
                    onClick={() => {
                        saveChanges({ "IsEnabled": isEnabled, "Options": options, "UserTimeZoneId": userTimeZoneId })
                        props.toggel()
                    }}>Save changes</Button></Col>
            </FormGroup>
        </ModalFooter>
    </span>
}

export default TimeForward