import React, { Component } from "react";
import './miniPackage.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Modal
} from 'reactstrap';
import axios from "../../axios";
import { Mp3pointsModal } from './mp-3pointsModal';
import AdvancedFeaturesModal from './advancedFeaturesModal';
import {
    returnInternationalNumber,
    formatInputValueWithout,
    convertFormatNumToRegularNum
} from '../../services/formatPhoneNumber';
import * as STRINGS from '../../strings/english';
import * as permissionFunc from '../../permissionScheme/permissionFunc';


export class MiniPackage extends Component {

    state = {
        editNumber: false,
        forwardNumber: this.props.package.Product[0].Divert,
        expiryDateNotSet: false,
        editDate: false,
        editForwardTo: '',
        startDate: this.props.getNextTenDate(),
        isDashboardLoader: false,
        advancedFeaturesModal: false
    }
    expiryDate = 4000;

    componentDidMount() {
        let finalExpiration = this.props.package.FinalDate;
        let finalExpirationYear = new Date(finalExpiration).getFullYear()
        if (finalExpirationYear >= this.expiryDate) {
            this.setState({ expiryDateNotSet: true });
        } else this.setState({ expiryDateNotSet: false });
    }

    handleChange = (date) => {
        this.setState({
            startDate: date
        })
    }
    saveChange = () => {
        if (this.state.editDate) {
            this.setState({ isDashboardLoader: true });
            axios.post('/Package/SetPackageFinalDate', {
                "packageregistrationid": this.props.package.PackageRegistrationID,
                "finaldate": this.props.formatDate(this.state.startDate)
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ isDashboardLoader: false });
            }).catch(error => {
                this.setState({ isDashboardLoader: false });
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        if (this.forwardNumber) {
            this.setState({ isDashboardLoader: true });
            axios.post('/Package/SetDivertNumber', {
                "packageregistrationid": this.props.package.PackageRegistrationID,
                "productregistrationid": this.props.package.Product[0].ProductID,
                "phonenumber": convertFormatNumToRegularNum(this.forwardNumber.value)
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({ isDashboardLoader: false, editNumber: false });
            }).catch(error => {
                this.setState({ isDashboardLoader: false })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
    }

    onChange = (e) => {
        this.setState({ forwardNumber: e.target.value });
    }

    open3pointsModal = (e) => {
        this.mp3pointsObj.toggle(e.target.name);
    }

    getNewLineName = (newName) => {
        this.setState({ newLineName: newName });
    }

    render() {
        let localNumber = returnInternationalNumber(this.props.package.Product[0].DID, this.props.package.Product[0].DIDLocationISO);
        let divertNumber = returnInternationalNumber(this.props.package.Product[0].Divert, this.props.package.Product[0].DivertLocationISO);
        let hasFalseKeysTodvancedDropdownModal = permissionFunc.checkDropdownItems('advanced_features_dropdown_di');
        let hasFalseKeysToMoreOptionDropDown = permissionFunc.checkDropdownItems('advanced_features_dropdown_di') &&
            permissionFunc.checkDropdownItems('package_btn_add');
        return (
            <div>
                {this.props.packageProps.index === 0 ?

                    <div className='mini-package-header'>
                        <div style={{ width: '27.2%', marginTop: '0.438rem' }}>
                            <img className='mini-package-header-icon-local-num-title'
                                src='assets/mp-local-number-icon.png' 
                                alt='local-number-icon' />
                            <span style={{ marginLeft: '0.625rem' }} className='mini-package-header-title'>
                                {STRINGS.LOCAL_NUMBER}
                            </span>
                        </div>
                        <span style={{ width: '29%' }}
                            className='mini-package-header-title'>{STRINGS.FORWARD_TO}</span>
                        {!permissionFunc.changeDisplay('package_row_packageaccountprice') &&
                        <span style={{ width: '7.7%' }}
                            className='mini-package-header-title'>{STRINGS.COST}</span>}
                        <span style={{ width: '7.8%' }}
                            className='mini-package-header-title'>{STRINGS.STATUS}</span>
                        <span style={{ width: '13.6%' }} className='mini-package-header-title'>{STRINGS.FINAL_EXPIRATION}</span>
                        <span style={{ width: '8.4%' }}
                            className='mini-package-header-title'>{STRINGS.NEXT_RENEWAL}</span>
                        <span className='mini-package-header-title'>{STRINGS.MORE_OPTIONS}</span>
                    </div> : null}
                <div className='mini-package-details-list'
                    style={this.props.isAccessNumber ?
                        {
                            backgroundColor: '#f1efef',
                            marginLeft: '20px',
                            marginRight: '20px',
                            borderRadius: '5px'
                        }
                        : {}}>
                    <div style={{ marginLeft: '1.75rem', width: '25%' }}
                        className='d-flex mini-package-details-list-font'>
                        {!this.props.isAccessNumber ?
                            <React.Fragment>
                                <div style={{ width: '90%' }}>
                                    <span>{localNumber}</span>
                                    <span style={{ marginLeft: '0.1rem' }}>
                                        {`(${this.state.newLineName ? this.state.newLineName :
                                            this.props.package.Product[0].Freetext ?
                                                this.props.package.Product[0].Freetext : this.props.package.Product[0].DIDLocation})`}
                                    </span>
                                </div>
                                <img
                                    style={{ height: '1.188rem' }}
                                    src='assets/arrow-right-small.png'
                                />
                            </React.Fragment> : <h6>{STRINGS.OUTGOING_CALLS}</h6>}
                    </div>
                    <div style={{ width: '30%', marginLeft: '1%' }}
                        className='mini-package-details-list-font'>
                        {!this.props.isAccessNumber && <div className='d-flex'>
                            {this.state.editNumber ?
                                <input
                                    type="text"
                                    ref={(forwardNumber) => {
                                        this.forwardNumber = forwardNumber
                                    }}
                                    style={{ width: '70%' }}
                                    onChange={(e) => this.setState({ forwardNumber: e.target.value })}
                                    value={formatInputValueWithout(this.state.forwardNumber)}
                                />
                                :
                                <div style={{ width: '70%' }}>
                                    {this.forwardNumber && this.forwardNumber.value && this.forwardNumber.value.length > 0 ?
                                        <div className='d-flex'>
                                            <div>
                                                {returnInternationalNumber(this.forwardNumber.value, this.props.package.Product[0].DivertLocationISO)}
                                            </div>
                                            <div style={{ marginLeft: '0.1rem' }}>
                                                {`(${this.props.package.Product[0].DivertLocation})`}
                                            </div>
                                        </div> :
                                        <div className='d-flex'>
                                            <div>{divertNumber}</div>
                                            <div style={{ marginLeft: '0.1rem' }}>
                                                {`(${this.props.package.Product[0].DivertLocation})`}
                                            </div>
                                        </div>
                                    }
                                </div>}
                            {!permissionFunc.changeDisplay('package_btn_edit_forward_to') &&
                                <img
                                    className='mini-package-forward-to'
                                    id='package_btn_edit_forward_to'
                                    onClick={() => this.setState({ editNumber: !this.state.editNumber })}
                                    src='assets/edit-2-outline.png'
                                    style={{ height: '1.313rem' }}
                                />}
                        </div>}
                    </div>
                    {!permissionFunc.changeDisplay('package_row_packageaccountprice') &&
                        <span className='mini-package-usd' style={{ width: '8%' }}>
                        {`${this.props.package.PackageAccountPrice} ${this.props.Currency}`}
                    </span>}
                    <span style={{ width: '8%' }}>
                        {this.props.package.Status}
                    </span>
                    <div style={{ width: '14%' }}>
                        <div className='mini-package-details-list-font'>
                            {!this.state.expiryDateNotSet && this.props.package.FinalDate}
                            {this.state.expiryDateNotSet && !permissionFunc.changeDisplay('package_final_expiration_date') &&
                                <div className='d-flex'>
                                    {!this.state.editDate ? 'Expiry date not set' :
                                        <div style={{ width: '8rem' }}>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    }
                                    <img
                                        className='mini-package-expiry-date-not-set-icon'
                                        id='package_final_expiration_date'
                                        onClick={() => this.setState({ editDate: !this.state.editDate })}
                                        src='assets/edit-2-outline.png'
                                        style={{ height: '1.313rem', marginLeft: '0.25rem' }}
                                    />
                                </div>}
                        </div>
                    </div>
                    <span style={{ width: '10%' }} className='mini-package-details-list-font'>
                        {this.props.package.ExpirationDate}
                    </span>
                    <div style={{ width: !this.props.isAccessNumber ? '5%' : '4%' }}>
                        {this.state.editDate || this.state.editNumber ?
                            <Button
                                size='sm'
                                onClick={this.saveChange}
                            >
                                {STRINGS.SAVE}
                            </Button>
                            :
                            <UncontrolledDropdown direction="left">
                                <DropdownToggle
                                    color={'white'}
                                    tag='div'
                                >
                                    <img className='mini-package-3-points-icon' src='assets/3-points1.png' />
                                </DropdownToggle>
                                {!hasFalseKeysToMoreOptionDropDown &&
                                    <DropdownMenu
                                        className='mp-main-test'
                                    >
                                        {!hasFalseKeysTodvancedDropdownModal &&
                                            <DropdownItem
                                                className='mt-2 mb-2'
                                                onClick={() => this.setState({ advancedFeaturesModal: true })}
                                                name='Advanced features'
                                            >
                                                {STRINGS.ADVANCED_FEATURES}
                                            </DropdownItem>}
                                        {!this.props.isAccessNumber && !permissionFunc.changeDisplay('package_btn_add_line_name') ?
                                            <DropdownItem
                                                className='mt-2 mb-2'
                                                id='package_btn_add_line_name'
                                                name='Add line name'
                                                onClick={this.open3pointsModal}
                                            >
                                                {`+ ${this.props.package.Product[0].Freetext || this.state.newLineName ?
                                                    STRINGS.EDIT : STRINGS.ADD} ${` ${STRINGS.LINE_NAME}`}`}
                                            </DropdownItem> : null}
                                        {!permissionFunc.changeDisplay('package_btn_add_or_edit_note') &&
                                            <DropdownItem
                                                className='mt-2 mb-2'
                                                id='package_btn_add_or_edit_note'
                                                onClick={this.open3pointsModal}
                                                name='Add Note'
                                            >
                                                {`+ ${this.props.package.FreeText || this.state.addFirstNote ?
                                                    STRINGS.EDIT : STRINGS.ADD} ${` ${STRINGS.NOTE}`}`}
                                            </DropdownItem>}
                                    </DropdownMenu>}
                            </UncontrolledDropdown>
                        }
                    </div>
                </div>
                {this.props.packageProps.index !== this.props.packageLength - 1 &&
                    <div className='mini-package-list-bottom-line' />}
                {this.state.isDashboardLoader &&
                    <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '9999' }}
                        src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />}
                <Mp3pointsModal
                    ref={obj => this.mp3pointsObj = obj}
                    package={this.props.package}
                    getNewLineName={this.getNewLineName}
                    addFirstNote={() => this.setState({ addFirstNote: true })}
                />
                <Modal
                    style={{ width: '22.5rem', marginTop: '18%' }}
                    isOpen={this.state.advancedFeaturesModal}
                    toggle={() => this.setState({ advancedFeaturesModal: !this.state.advancedFeaturesModal })}
                >
                    {<AdvancedFeaturesModal
                        details={this.props.package}
                        refresh={() => this.props.refresh()}
                        isAccessNumber={this.props.isAccessNumber}
                    />}
                </Modal>
            </div>
        )
    }
}