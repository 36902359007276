import { reactCodeInput } from './CodeInputField.scss';

export const fieldsLength = 6;
export const reactCodeInputProps = {
    className: reactCodeInput,
    inputStyle: {
        fontFamily: 'monospace',
        margin: '4px',
        MozAppearance: 'textfield',
        width: '50px',
        borderRadius: '3px',
        fontSize: '24px',
        height: '50px',
        paddingLeft: '14px',
        backgroundColor: 'black',
        color: 'lightskyblue',
        border: '1px solid lightskyblue'
    },
    inputStyleInvalid: {
        fontFamily: 'monospace',
        margin: '4px',
        MozAppearance: 'textfield',
        width: '25px',
        borderRadius: '3px',
        fontSize: '14px',
        height: '26px',
        paddingLeft: '7px',
        backgroundColor: 'black',
        color: 'red',
        border: '1px solid red'
    }
}