import React, { useState } from 'react';
import {
    Button,
    Col,
    Row,
    ModalHeader,
    ModalBody,
    Input,
    FormGroup,
    Label,
    Form,
} from 'reactstrap';
import './advance.css';
import '../index.css';
import UploadFile from '../../../services/uploadFiles/uploadFiles.js'
import FeatureService from "../../../services/FeatureService";
import AudioPlayer from "../../../services/audioPlayer/audioPlayer";

function VoiceMail(props) {

    const initialCurrentFile = () => {
        if (props.details.PersonalGreetingPath) {
            let name = props.details.PersonalGreetingPath.split("/")
            return {
                fileName: name[name.length - 1],
                url: props.details.PersonalGreetingPath
            }
        } else return null
    };

    const initialDuration = () => {
        if (props.details.PersonalGreetingPath) {
            let audio = new Audio(props.details.PersonalGreetingPath)
            audio.onloadedmetadata = () => {
                return setDuration(time_convert(audio.duration))
            }
        } else return null
    };

    const [options, setOptions] = useState(props.details.Options)
    const [seconds, setSeconds] = useState(props.details.TimeToVM)
    const [personalGreeting, setPersonalGreeting] = useState(props.details.PersonalGreetingEnabled)
    const [uploadingMode, setUploadingMode] = useState(props.details.PersonalGreetingPath ? 2 : 1)
    const [percentage, setPercentage] = useState(0)
    const [file, setFile] = useState({})
    const [currentFile, setCurrentFile] = useState(initialCurrentFile)
    const [playing, setPlaying] = useState(false)
    const [email, setEmail] = useState(" ")
    const [duration, setDuration] = useState(initialDuration)


    const handleUploadFile = (file) => {
        if (file.selectedFile) {
            setUploadingMode(1)
            let data = new FormData()
            data.append('vm', file.selectedFile)
            data.append('did', props.did)
            data.append('packageregistrationid', props.PackageRegistrationID)
            let config = {
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setPercentage(percentCompleted)
                }
            };
            FeatureService.upload(data, config.onUploadProgress)
                .then(res => {
                    setUploadingMode(2)
                    let currentFile = {
                        fileName: file.fileName,
                        url: res.data.URL,
                        work: false
                    }
                    setCurrentFile(currentFile)
                    props.refresh()
                })
                .catch(e => {
                    console.log("Error: ", e)
                })
        }
    };

    const saveChanges = () => {
        let body = {
            "packageregistrationid": props.PackageRegistrationID,
            "voicemailoption": options.toFixed(),
            "email": email,
            "timetovoicemail": seconds
        }
        FeatureService.setVoiceMailOptions(body, props.PackageRegistrationID)
            .then(() => {
                props.refresh()

            })
            .then(() => {
                if (props.details.PersonalGreetingEnabled !== personalGreeting) {
                    setEnableVMG(personalGreeting)
                }
            })
            .then(() => {
                props.toggel()
            })
            .catch(e => {
                console.log(e)
            })

    };

    const setEnableVMG = (mode, callback) => {
        FeatureService.enableVMG(mode, props.PackageRegistrationID).then(() => {
            if (callback) {
                callback()
            }
        })
    };

    function uploading() {
        return <FormGroup row className="fs-10 border-5">
            <Col md={2} className="v-center text-left pl-0"><img src="assets/voicemail-3.svg" alt="" /></Col>
            <Col md={9} className="pl-2 pr-0">
                <Row >
                    <Col md={6} className="pl-0 text-left">
                        {uploadingMode > 0 ? file.fileName : null}
                    </Col>
                    <Col md={6} className="pr-0 text-right">
                        <img
                            src="assets/exit-btn.svg"
                            alt=""
                            onClick={() => {
                                setEnableVMG(false, () => {
                                    setCurrentFile(null)
                                    props.refresh()
                                })
                            }} />
                    </Col>
                </Row>
                <Row ><div className="progress"><div style={{ width: `${percentage}%` }} /></div></Row>
                <Row >
                    <Col md={6} className="pl-0 text-left fc-grey">{``}</Col>
                    <Col md={6} className="pr-0 text-right fc-grey">{`${percentage > 0 ? percentage + " %" : " "}`}</Col>
                </Row>
            </Col>
        </FormGroup>
    }

    function player() {
        return <FormGroup>
            {currentFile ?
                <React.Fragment>
                    <FormGroup>
                        <div className='fs-10 fc-grey v-center box-yellow'
                            style={{ backgroundColor: "#fffbea", height: "40px" }}>
                            Replace the existing file by uploading a new one
                </div>
                    </FormGroup>

                    <FormGroup>
                        <div className='d-flex fs-10 v-center border-grey ' style={{ height: "40px",overflow: "hidden"}}>
                            <div className="pl-2">
                                <AudioPlayer
                                    style={{ height: "24px", width: "24px" }}
                                    id={currentFile.url}
                                    src={currentFile.url}
                                    setPlaying={() => setPlaying(!playing)}
                                    playing={{ id: currentFile.url, work: playing, audio: false, src: currentFile.url }}
                                    duration={(e) => setDuration(e)}
                                />
                            </div>
                            <div style={{ height: "inherit", width: "35px" }}><img src="assets/voicemail-3.svg" alt="" /></div>
                            <div>{currentFile.fileName}</div>
                            <div className="ml-auto p-2 fc-grey">{`${duration ? duration : "--:--"}`}</div>
                            <div className="p-2"><img src="assets/exit-btn.svg" alt="" onClick={() => {
                                setEnableVMG(false, () => {
                                    setPlaying(false)
                                    setCurrentFile(null)
                                })
                            }} /></div>
                        </div>
                    </FormGroup>
                </React.Fragment>
                : null}
        </FormGroup>
    }

    function time_convert(number) {
        if (number) {
            let num = Math.floor(number)
            const hours = Math.floor(num / 60);
            const minutes = num % 60;
            return `${hours < 10 ? 0 : ""}${hours}:${minutes < 10 ? 0 : ""}${minutes}`;
        } else {
            return null
        }
    }

    return <span className="fs-14">
        <ModalHeader
            style={{
                display: "flow-root",
                height: "90px"
            }}

        >
            <div className="d-flex justify-content-between fs-22" style={{ padding: "1.5rem  1rem" }} >
                <div >Voice mail options</div>
                <div className='custom-control custom-switch isolate-the-design'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={`customSwitches2-`}
                        checked={options !== 0}
                        onChange={() => setOptions(options === 0 ? 0.5 : 0)}
                    />
                    <label className='custom-control-label' htmlFor={`customSwitches2-`} />
                </div>
            </div>
        </ModalHeader>
        <ModalBody >
            <Form style={{ padding: "1.5rem  1rem" }}>

                {options > 0 ?
                    <React.Fragment><FormGroup>
                        <FormGroup check inline >
                            <Label check>
                                <Input type="radio" name="radio1"
                                    onChange={() => setOptions(1)}
                                    checked={options === 1} />{' '}
                                On no answer
                            </Label>
                        </FormGroup>

                        <FormGroup check inline>
                            <Label check>
                                <Input type="radio" name="radio2"
                                    onChange={() => setOptions(2)}
                                    checked={options === 2} />{' '}
                                Always
                            </Label>
                        </FormGroup>
                    </FormGroup>

                        <FormGroup>
                            <div className="line-1" />
                        </FormGroup>
                    </React.Fragment>
                    : null}

                <FormGroup className="fs-12" row>
                    <div className="col-5 pr-0" > Go to voice mail after</div>
                    <div className="col-3"> <Input className="fs-12" type="number" name="password" id="" value={seconds}
                        onChange={(e) => setSeconds(e.target.value)} /> </div>
                    <div className="col-2 fc-grey"> seconds</div>
                </FormGroup>

                <FormGroup>
                    <div className="line-1" />
                </FormGroup>

                <FormGroup className="d-flex v-center">
                    <div className="col-1 pl-0">
                        <input type="checkbox"
                            id={`id_${props.details.PackageRegistrationID}`}
                            label={props.label}
                            onChange={() => setPersonalGreeting(!personalGreeting)}
                            checked={personalGreeting}
                        /></div>
                    <div className="col-11 fs-12 fontColor justify-content-center">I want to use my own personal greeting</div>
                </FormGroup>

                {personalGreeting ?
                    <React.Fragment>
                        <UploadFile
                            file={
                                file => {
                                    handleUploadFile(file)
                                    setFile(file)
                                }}
                            did={props.did}
                            duration={(e) => setDuration(time_convert(e))}
                            packageregistrationid={props.PackageRegistrationID} />
                        <FormGroup>{uploadingMode === 1 ? uploading() : player()}</FormGroup>
                    </React.Fragment>
                    : null}

                <FormGroup>
                    <div className="line-1" />
                </FormGroup>

                <FormGroup>
                    <Label
                        for="2email"
                        className='cursorPointer fs-12 fontColor'>
                        When a call is terminated, send me an email with the recorded voice mail to:
                    </Label>
                    <Input
                        style={{ outline: 'none' }}
                        name="email"
                        id="2email"
                        type='email'
                        placeholder={``}
                        className='border-5 h-100 px-0 w-100'
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormGroup>

                <FormGroup row >
                    <Col md={6}><Button className="btnColor5 fontColor no-border fs-14" style={{ width: "100%" }} size="lg" onClick={()=>props.toggel()}>Cancel</Button></Col>
                    <Col md={6}><Button className="btnColor2 white no-border fs-14" style={{ width: "100%" }} size="lg" onClick={() => saveChanges()}>Save changes</Button></Col>
                </FormGroup>

            </Form>
        </ModalBody>
    </span>
}
export default VoiceMail