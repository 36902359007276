import React, { Component } from 'react';
import './index.css';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';


class NavBar extends Component {

    state = {
        email: localStorage.getItem('email'),
    }

    updateEmail = (email) => {
        this.setState({ email });
    }

    render() {
        
        return (
            <div className="navBar d-flex p-4">
                {this.props.logo &&
                    <img
                        style={{
                            width: '94px',
                            height: '32px'
                        }}
                        alt="logo-icon"
                        src={this.props.logo}
                    />}
                <span onClick={() => this.props.homePage()}
                    className="cursorPointer font-weight-bold p-1"> {this.props.title}</span>
                <div className="ml-auto">
                    <UncontrolledDropdown >
                        <DropdownToggle className="border mr-2" color={'white'}>
                            EN
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem >EN</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                {/* <img src='assets/Oval.svg' alt="" className=" pt-1"/> */}
                <span
                    className='mt-1 mr-1 pt-1 text-center text-capitalize'
                    style={{
                        color: 'white',
                        backgroundColor: 'blue',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px'
                    }}>{this.props.mail ? this.props.mail[0] : ''}</span>
                <span className="font-weight-bold mt-2">{this.state.email}</span>
                <div
                    className="btn-link cursorPointer p-2"
                    onClick={() => this.props.signOut()}>sign out</div>
            </div>
        );
    }
}

export default NavBar;