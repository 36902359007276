import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as STRINGS from '../strings/english';


const ForbiddenModal = () => {

    const [modal, setModal] = useState(true);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <span
                        style={{
                            color: 'rgb(126, 69, 69)'
                        }}
                    >
                        403
                    </span>
                    {` ${STRINGS.FORBIDDEN}`}
                </ModalHeader>
                <ModalBody>
                    {`${STRINGS.FORBIDDEN_MODAL_TEXT_MSG}.`}
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ForbiddenModal;