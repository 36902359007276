import React, { useState } from 'react';
import {
    Button,
    Col,
    ModalHeader,
    ModalBody,
    Input,
    FormGroup,
    Label,
    Form
} from 'reactstrap';
import './advance.css';
import '../index.css';
import FeatureService from "../../../services/FeatureService";
import * as STRINGS from '../../../strings/english';

function CallRecording(props) {

    const [isEnabled, setIsEnabled] = useState(props.details.IsEnabled)
    const [email, setEmail] = useState(props.details.Email)
    const [showing, setShowing] = useState(!!props.details.Email)

    const saveChanges = () => {
        let body = {};
        if (showing && !email) {
            return alert(STRINGS.INPUT_MUST_BE_FILLED_OUT);
        }
        if (showing && email) {
            body.email = email;
        }
        if (!showing) {
            body.email = '';
        }
        body.IsEnabled = isEnabled;
        FeatureService.setRecordingFeatureOptions(body, props.PackageRegistrationID)
            .then(() => {
                props.refresh()
            })
            .then(() => {
                props.toggel()
            })
            .catch(e => {
                console.log(e)
            })
    };

    return <span>
        <ModalHeader
            style={{
                display: "flow-root",
                height: "90px"
            }}>

            <div className="d-flex justify-content-between fs-22" style={{ padding: "1.5rem  1rem" }}>
                <div>Call recording options</div>
                <div className='custom-control custom-switch isolate-the-design'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={`customSwitches2-`}
                        checked={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                    />
                    <label className='custom-control-label' htmlFor={`customSwitches2-`} />
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
            <Form style={{ padding: "1.5rem  1rem" }}>
                <FormGroup className="d-flex v-center">
                    <div className="col-1 pl-0">
                        <input type="checkbox"
                            id={`id_${props.PackageRegistrationID}`}
                            value={email}
                            label={props.label}
                            onChange={() => setShowing(!showing)}
                            checked={showing}
                        />
                    </div>
                    <div className="col-11 fs-12 fontColor justify-content-center">Send me an Email when a call is recorded</div>
                </FormGroup>

                {showing ?
                    <FormGroup>
                        <Label
                            for="3email"
                            className='cursorPointer fs-12 fontColor'>
                            Enter email
                        </Label>
                        <Input
                            style={{ outline: 'none' }}
                            name="email"
                            id="3email"
                            type='email'
                            placeholder={``}
                            className='border-5 h-100 px-0 w-100'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    : null}
                <FormGroup row>
                    <Col md={6}><Button className="btnColor5 fontColor no-border fs-14 " style={{ width: "100%" }}
                        onClick={()=>props.toggel()} size="lg">Cancel</Button></Col>
                    <Col md={6}><Button className="btnColor2 white no-border fs-14 " style={{ width: "100%" }} size="lg"
                        onClick={() => saveChanges()}>Save changes</Button></Col>
                </FormGroup>

            </Form>
        </ModalBody>
    </span>
}

export default CallRecording