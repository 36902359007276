import http from "../axios";


class UtilsService {

    headers() {
        return {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data'),

            }

        }
    }

    contactUs(data) {
        return http.post("Utils/ContactUs", data ,this.headers());
    };

    getSupportedDestinations() {
        return http.get("Utils/GetSupportedDestinations", this.headers());
    };

    showDidLocationsData(countryCode) {
        return http.get(`/Utils/ShowDidLocations/${countryCode}`, this.headers());
    };
}

export default new UtilsService();




