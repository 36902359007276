import React, { useState, useEffect } from 'react';
import './diceDisplayBox.css';
import { formatNumbers } from '../../services/formatPhoneNumber';
import { eventEnum } from './enum';
import * as STRINGS from '../../strings/english';


export const DiceDisplayBox = (props) => {

    const [callActive, setCallActive] = useState('');
    const CALL_ACTIVE = 'call active';

    useEffect(() => {
        if (props.callDetails.EventName === eventEnum.STARTCALL_IN ||
            props.callDetails.EventName === eventEnum.STARTCALL_OUT ||
            props.callDetails.EventName === eventEnum.ANSWERCALL_IN ||
            props.callDetails.EventName === eventEnum.ANSWERCALL_OUT) {
            setCallActive(CALL_ACTIVE)
        } else {
            let hasActiveCall = props.responseToShow.find(
                e => e.EventName !== eventEnum.CALL_IN && e.EventName !== eventEnum.CALL_OUT
            );
            if (!hasActiveCall) {
                setCallActive('');
            } else setCallActive(CALL_ACTIVE)
        };
    }, [props.callDetails])


    const drawRow = () => {
        let color = '#ffffff';
        switch (props.callDetails.EventName) {
            case eventEnum.STARTCALL_IN:
            case eventEnum.STARTCALL_OUT:
            case eventEnum.ANSWERCALL_IN:
            case eventEnum.ANSWERCALL_OUT:
                color = '#7fff00';
                break;
            case eventEnum.CALL_IN:
            case eventEnum.CALL_OUT:
                if (callActive !== CALL_ACTIVE) {
                    color = '#979393';
                } else color = '#7fff00';
                break;
            default:
                break;
        }
        return {
            boxShadow: `0 0 4px 4px ${color}`,
        }
    }

    return (
        <div
            className='ddb-main'
            style={drawRow()}
        >
            <div>
                <img
                    src='assets/person-fill.svg'
                    alt='person-fill-icon'
                />
                <span className='ddb-marg-text-from-icon'>
                    {props.callDetails.Username}
                </span>
            </div>
            <div className='ddb-did-num'>
                <img
                    src='assets/telephone-fill.svg'
                    alt='telephone-fill-icon'
                />
                <span className='ddb-marg-text-from-icon'>
                    {formatNumbers(props.callDetails.DIDNumber)}
                </span>
            </div>
            <div
                className={
                    callActive !== CALL_ACTIVE ?
                        'ddb-call-active-text'
                        : ''}
            >
                <img
                    src='assets/check-circle-fill.svg'
                    alt='check-circle-fill-icon'
                />
                <span className='ddb-marg-text-from-icon'>
                    {STRINGS.CALL_ACTIVE}
                </span>
            </div>
        </div>
    )
}
