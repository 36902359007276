export const fileMessageEnum = Object.freeze(
    {
        'JPEG': 'jpeg',
        'JPG': 'jpg',
        'PNG': 'png',
        'WEBP': 'webp',
        'GIF': 'gif',
        'MP4': 'mp4',
        'PDF': 'pdf',
        'OGG': 'ogg',
        'OGA': 'oga',
        'TXT': 'txt',
        'DOCX': 'docx',
        'XLSX': 'xlsx',
        'IMAGE': 'image',
        'VIDEO': 'video',
        'DOCUMENT': 'document',
        'PTT': 'ptt',
        'MP3': 'mp3',
    }
);

export const imageTypeArr = [
    fileMessageEnum.IMAGE,
    fileMessageEnum.PNG,
    fileMessageEnum.JPEG,
    fileMessageEnum.JPG,
];

export const audioTypeArr = [
    fileMessageEnum.MP3,
    fileMessageEnum.OGA,
    fileMessageEnum.OGG,
    fileMessageEnum.PTT,
];

export const displayMediaArr = [
    fileMessageEnum.MP3,
    fileMessageEnum.OGA,
    fileMessageEnum.OGG,
    fileMessageEnum.PTT,
    fileMessageEnum.IMAGE,
    fileMessageEnum.VIDEO,
    fileMessageEnum.PDF,
];