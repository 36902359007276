import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Input} from 'reactstrap'
import axios from "../../axios";

export class Mp3pointsModal extends Component {

    state = {
        modal: false,
        modalName: '',
        textArea: this.props.package.FreeText || '',
        lineName: this.props.package.Product[0].Freetext || '',
        isDashboardLoader: false
    }

    toggle = (name = '') => {
        this.setState({ modal: !this.state.modal, modalName: name });
    }

    save = () => {
        if (this.state.textArea && this.noteInput) {
            this.setState({ isDashboardLoader: true });
            axios.post('/Package/UpdatePackageNote', {
                "packageregistrationid": this.props.package.PackageRegistrationID,
                "note": this.state.textArea
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.props.addFirstNote();
                this.setState({ isDashboardLoader: false, modal: false });
            }).catch(error => {
                this.setState({ isDashboardLoader: false })
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        else if (this.state.lineName && this.lineNameInput) {
            this.setState({ isDashboardLoader: true });
            axios.post('/Package/PostLineName', {
                "packageregistrationid": this.props.package.PackageRegistrationID,
                "linename": this.state.lineName
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.props.getNewLineName(this.state.lineName)
                this.setState({ isDashboardLoader: false, modal: false });
            }).catch(error => {
                this.setState({ isDashboardLoader: false });
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        else alert('Input must be filled out.')
    }

    render() {
        const addNote = 'Add Note';
        const addLineName = 'Add line name';
        return(
            <div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.toggle('')}
                    style={{marginTop:'18%'}}
                >
                    <ModalHeader>{this.state.modalName}</ModalHeader>
                    <ModalBody>
                        {this.state.modalName == addNote ?
                            <Input
                                innerRef={(obj) => this.noteInput = obj}
                                type='textarea'
                                defaultValue={this.state.textArea}
                                onChange={(e) => this.setState({ textArea: e.target.value })}
                            />
                            : <Input
                                innerRef={(obj) => this.lineNameInput = obj}
                                type='text'
                                onChange={(e) => this.setState({ lineName: e.target.value })}
                                value={this.state.lineName}
                            />}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{ color: 'white' }}
                            color=""
                            className='btnColor2 d-flex ml-auto mr-auto'
                            onClick={this.save}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.state.isDashboardLoader &&
                <img style={{width: '100px', height: 'auto', right: '45%', zIndex: '9999'}}
                     src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute"/>}
            </div>

        )
    }
}