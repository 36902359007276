import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, FormGroup, ModalHeader, ModalBody, ModalFooter, Col } from 'reactstrap';
import './personalUserSettingsModal.css';
import * as STRINGS from '../../strings/english';
import axios from '../../axios';
import { PersonDetails } from './personalDetails';

function PersonalUserSettingsModal(props) {

    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [isFloatingLoader, setIsFloatingLoader] = useState(false);
    const [inputsData, setInputData] = useState({});
    const headerOfActive = 'Active';

    let inputRef = useRef(null);
    let allUsersRef = useRef(allUsers);

    useEffect(() => {
        allUsersRef.current = allUsers;
    }, [allUsers]);

    useEffect(() => {
        setIsLoader(true);
        axios.post('/Provisioning/GetOrganizationUsers', {
            OrganizationEmail: localStorage.getItem('email'),
            MaxResults: "10000",
            StartFromRow: "1",
            QueryType: "0",
            QueryTerm: "%"
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            setIsLoader(false);
            setAllUsers(response.data.Users);
            setUsers(response.data.Users);
        }).catch(error => {
            setIsLoader(false);
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    }, []);

    const enterPressed = useCallback(event => {
        if (event.keyCode === 13 && event.target.name === 'PUSsearchButton') {
            searchOnClick();
        }
    }, [])

    useEffect(() => {
        document.addEventListener("keyup", enterPressed);
        return () => {
            document.removeEventListener("keyup", enterPressed);
        }
    }, [enterPressed])

    const returnPersonDetails = () => {
        let arr = [];
        users.map((item, index) => {
            arr.push(
                <PersonDetails
                    key={'person-details-' + index}
                    email={item.UserName}
                    fullName={`${item.FirstName} ${item.LastName}`}
                    integrationParameters={props.cellData.integrationParameters}
                    item={item}
                    personDetailsOnChange={personDetailsOnChange}
                    inputsData={inputsData}
                    index={index}
                />
            )
        })
        return arr;
    }

    const personDetailsOnChange = (e, item) => {
        setInputData({
            ...inputsData,
            [item.UserName]: {
                CrmType: props.cellData.integrationName,
                ...inputsData[item.UserName],
                [e.target.name]: e.target.name !== headerOfActive ? e.target.value : e.target.checked
            }
        })
    }

    const searchOnClick = () => {
        let arr = [];
        for (const key in allUsersRef.current) {
            if (allUsersRef.current[key].UserName.includes(inputRef.current.value)) {
                arr.push(allUsersRef.current[key]);
            }
        }
        setUsers(arr);
    }

    const dynamicHeader = () => {
        let arr = [];
        let keysArr = [];
        let styleObj = { textAlign: 'center' }
        for (const key in props.cellData.integrationParameters) {
            keysArr.push(key);
            arr.push(
                <div
                    key={`dynamic-header-${key}`}
                    className={`${key !== headerOfActive ? `personal-user-settings-modal-details-dynamic-header`
                        : `personal-user-settings-modal-details-dynamic-active-header`}`}
                    style={key === headerOfActive ? styleObj : {}}
                >
                    {key}
                </div>
            )
        }
        let indexOfActive = keysArr.indexOf(headerOfActive);
        let activeHeader = arr.splice(indexOfActive, 1);
        arr[arr.length] = activeHeader;
        return arr;
    }

    const saveChanges = () => {
        if (Object.keys(cleanEmptyKeys(inputsData)).length > 0) {
            setIsFloatingLoader(true);
            for (const key in inputsData) {
                let body = {
                    Username: key,
                    ...inputsData[key]
                }
                axios.post('/Provisioning/MapCRMUser', body, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                    }
                }).then(response => {
                    setIsFloatingLoader(false);
                    setInputData({});
                    props.personalUserSettingsToggle();
                }).catch(error => {
                    setIsFloatingLoader(false);
                    setInputData({});
                    alert(error.response
                        ? error.response.data.Description || error.response.data.Message : error);
                });
            }
        }
        else {
            alert(STRINGS.NO_CHANGES_TO_SAVE);
        }
    }

    const cleanEmptyKeys = (object) => {
        for (const key in object) {
            let currentInputRow = object[key];
            Object.keys(currentInputRow).forEach(item => currentInputRow[item] === '' && delete currentInputRow[item]);
            if (Object.keys(currentInputRow).length === 1) {
                delete currentInputRow['CrmType'];
                delete object[key]
            }
        }
        return object;
    }

    return (
        <span>
            <ModalHeader>
                <div className='personal-user-settings-modal-header'>
                    <div className='d-flex'>
                        <img className='personal-user-settings-modal-logo' src={props.cellData.logoURL} />
                        <p className='personal-user-settings-modal-header-text'>{STRINGS.PERSONAL_USER_SETTINGS}</p>
                    </div>
                </div>
            </ModalHeader>
            <ModalHeader>
                <div className='border-5 col-12 form-control'>
                    <input
                        autoComplete={'chrome-off'}
                        style={{ outline: 'none' }}
                        name="PUSsearchButton"
                        id="PUSsearchButton"
                        type='text'
                        placeholder={`${STRINGS.USER_EMAIL}...`}
                        className='border-0 col-11 h-100 px-0 w-100'
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                        ref={inputRef}
                    />
                    <label
                        htmlFor="PUSsearchButton"
                        className='position-absolute cursorPointer'
                        onClick={searchOnClick}>
                        <img src='assets/search-outline.svg' alt="" />
                    </label>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className='personal-user-settings-modal-details-headers'>
                    <div className='personal-user-settings-modal-details-header-email'>
                        {STRINGS.EMAIL}
                    </div>
                    <div className='personal-user-settings-modal-details-header-full-name'>
                        {STRINGS.FULL_NAME}
                    </div>
                    {dynamicHeader()}
                </div>
                <div className='personal-user-settings-modal-details-main'>
                    {returnPersonDetails()}
                    {isLoader && <img
                        src='assets/spikkoloaderanimation.svg'
                        alt="loader"
                        className="personal-user-settings-loader"
                    />}
                    {isFloatingLoader && <img
                        src='assets/spikkoloaderanimation.svg'
                        alt="loader"
                        className="personal-user-settings-floating-loader"
                    />}
                </div>
            </ModalBody>
            <ModalFooter style={{ display: "block" }}>
                <FormGroup row className="justify-content-end">
                    <Col md={3}>
                        <Button
                            className="btnColor5 fontColor fs-14 no-border"
                            style={{ width: "100%" }}
                            color={'#ebebeb'}
                            size="lg"
                            onClick={props.personalUserSettingsToggle}
                        >
                            {STRINGS.CANCEL}
                        </Button>
                    </Col>
                    <Col md={3}>
                        <Button
                            className="btnColor2 white fs-14 no-border"
                            style={{ width: "100%" }}
                            size="lg"
                            onClick={saveChanges}
                        >
                            {STRINGS.SAVE_CHANGES}
                        </Button>
                    </Col>
                </FormGroup>
            </ModalFooter>
        </span>
    )
}
export default PersonalUserSettingsModal;