import React, { useState } from 'react';
import './smsReportUserDetails.css';


export const SmsReportUserDetails = React.forwardRef((props, ref) => {

    return (
        <div 
            ref={ref}
            className='sms-report-user-details-main'
            style={props.index === 0 ? { backgroundColor: '#ebf9ff' } : {}}
            onClick={props.smsReportUserDetailsOnClick}
        >
            <div className='sms-report-user-details-user-icon-div'>
                <img src='assets/user-default-image.png' alt="" style={{ width: "40px" }} />
                <div className='sms-report-user-details-user-icon' />
            </div>
            <div className='sms-report-user-details-right'>
                <div className='sms-report-user-details-around-date-and-name'>
                    <div className='sms-report-user-details-user-name'>{props.userName}</div>
                    <div className='sms-report-user-details-date'>{props.date}</div>
                </div>
                <div className='sms-report-user-details-user-email'>
                    {props.userEmail}
                </div>

            </div>
        </div>
    )
})