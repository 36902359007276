import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './chart.css';

function Chart(props) {
    return (
        <div
            className='chart-main'
            style={props.style}
            key={`${props.name}-${props.index}`}
            id={`${props.name}-${props.index}`}
        >
            <ReactApexChart
                options={props.data.options}
                series={props.data.series}
                type={props.data.options.chart.type}
                width={props.data.options.chart.width}
                height={props.data.options.chart.height}
            />
        </div>
    );
}

export default Chart;