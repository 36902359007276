import { data as permissionData } from '../permissionScheme/permissionData';
import axios from '../axios';

export const savePermissionData = async (callback = null) => {
    await axios.get('Provisioning/GetUserRoles', {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
        }
    }).then(res => {
        let copyPermissionDataByValue = JSON.parse(JSON.stringify(permissionData));
        let userRolesObjLowerCase = Object.keys(res.data).reduce((c, k) => (c[k.toLowerCase()] = res.data[k], c), {});
        let updatingPermissionData = Object.assign(copyPermissionDataByValue, userRolesObjLowerCase[localStorage.getItem('email').toLowerCase()]);
        localStorage.setItem('permissionData', JSON.stringify(updatingPermissionData));
        if (callback) {
            callback();
        }
    })
}