import React, {Component} from 'react';
import './index.css';
import axios from '../../axios';
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {Link} from 'react-router-dom';

class ResetPassword extends Component {
    state = {
        isLoader: false
    }
    submit() {
        this.setState({isLoader: true})
        axios
            .post('/Provisioning/ResetPassword', {
            "email": this.exmail.value,
            "returnurl": `${window.location.origin}/enterPassword`
        })
            .then(response => {
                this.setState({isLoader: false})
                alert("We sent an email to your email with a link to help verify it is you.")
            })
            .catch(error => {
                this.setState({isLoader: false})
                alert(error.response
                    ? error.response.data.Description||error.response.data.Message                    : error)
            });;
    }
    render() {
        return (
            <div className=" wrapper row justify-content-center m-0 ">
                <img src='assets/Capture.PNG' alt="" className="loginImg"/>
                <Form className="loginForm col-3">
                    <h4>Reset your password</h4>
                    <p className="font-weight-bold">Please enter your registered Email.<br/>
                        Your reset password instructions will be sent to that Email
                    </p>
                    <FormGroup>
                        <Label for="resetPasswordEmail">Email</Label>
                        <Input
                            type="email"
                            name="email"
                            id="resetPasswordEmail"
                            innerRef={(exmail) => {
                            this.exmail = exmail
                        }}/>
                    </FormGroup>
                    <Button
                        style={{
                        backgroundColor: ' #2EC2FE'
                    }}
                        className="mb-4"
                        onClick={() => this.submit()}
                        size="lg"
                        block>Send
                    </Button>
                   
                    <Link to="/login">
                        Cancel</Link>
                        {this.state.isLoader?
                         <img style={{width:'60px',height:'50px',marginTop: 'auto'}} src='assets/spikkoloaderanimation.svg' alt="" className="loginImg"/>
                        : null}
                </Form>
            </div>
        );
    }
}

export default ResetPassword;