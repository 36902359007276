import React, {Component} from 'react';
import './index.css';
import FileDrop from 'react-file-drop';
import axios from '../../axios';
import sourceVariable from '../../globalStartSession';
import {
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Progress
} from 'reactstrap';
import {Link} from 'react-router-dom';
import * as STRINGS from '../../strings/english';

class CreateCcount extends Component {

    state = {
        selectedFile: null,
        fileName: 'Choose a file',
        isAgreement: true,
        isLoader: false,
        fileLogo: null,
        businessTypes: [],
        currentBusinessUsageType: null
    }

    componentDidMount() {
        axios.post('/Provisioning/StartSession', { "source": sourceVariable })
            .then(response => {
                if (response && response.data) {
                    const data = response.data;
                    axios.get('/Provisioning/GetBusinessTypes', {
                        headers: {
                            Authorization: 'Bearer ' + data.SessionKey
                        }
                    }).then(response => {
                        if (response && response.data) {
                            this.setState({ businessTypes: response.data });
                        }
                    })
                    .catch(error => {
                        this.setState({isLoader: false})
                        alert(error.response
                            ? error.response.data.Description||error.response.data.Message                        : error)
                    });
                }
            })
            .catch(error => {
                alert(error.response
                    ? error.response.data.Description||error.response.data.Message                        : error)
            });
    }

    handelInput(files) {

        this.setState({
            selectedFile: URL.createObjectURL(files.target.files[0]),
            loaded: 0,
            fileName: files.target.files[0].name,
            fileLogo: files.target.files[0]
        })
    }

    next() {
        // INSERT TO CONDITION
        localStorage.setItem('email', this.email.value)
        if (this.email.value && this.firstname.value && this.lastName.value && this.lastName.value && this.state.currentBusinessUsageType) {
            this.setState({isLoader: true})
            axios
                .post('/Provisioning/StartSession', {"source": sourceVariable})
                .then(response => {
                    const data = response.data;
                    let body = {
                        "email": this.email.value,
                        "firstName": this.firstname.value,
                        "lastName": this.lastName.value,
                        "password": this.password.value,
                        "customertype" : this.state.currentBusinessUsageType.Id
                    }
                    if (localStorage.getItem('RefId')) {
                        body['RefId'] = localStorage.getItem('RefId');
                    }
                    axios.post('/Provisioning/RegisterOrganization', body, {
                        headers: {
                            Authorization: 'Bearer ' + data.SessionKey
                        }
                    }).then(response => {
                        const data = response.data;
                        localStorage.removeItem('RefId');
                        this.setState({isLoader: false})
                        this
                            .props
                            .history
                            .push('/enterCode', {
                                logoData: this.state.fileLogo,
                                logo: this.state.selectedFile,
                                email: this.email.value,
                                password: this.password.value,
                                countId: data.AccountId,
                                redirect: true
                            });
                    }).catch(error => {
                        this.setState({isLoader: false})
                        alert(error.response
                            ? error.response.data.Description||error.response.data.Message                            : error)
                    });

                })
                .catch(error => {
                    this.setState({isLoader: false})
                    alert(error.response
                        ? error.response.data.Description||error.response.data.Message                        : error)
                });
        } else {
            alert('Fields are missing');
        }
    }
    handleDrop = (files) => {
        this.setState({
            selectedFile: URL.createObjectURL(files[0]),
            loaded: 0,
            fileName: files[0].name,
            fileLogo: files[0]
        })
    }
    selectBusinessUsageTypeOnChange = (event) => {
        let obj = JSON.parse(event.target[event.target.selectedIndex].getAttribute('data-value'));
        this.setState({ currentBusinessUsageType: obj });
    }
    render() {
        return (
            <div className=" wrapper row m-0 ">
                <div className="divCountImg">
                    <img src='assets/Capture3.PNG' alt="" className="countImg"/></div>
                <Form className="loginForm" autoComplete="off">
                {this.state.isLoader
                        ? <img
                                style={{
                                width: '100px',
                                height: 'auto',
                                right: '45%',
                                zIndex: '100'
                            }}
                                src='assets/spikkoloaderanimation.svg'
                                alt=""
                                className="position-absolute"/>
                        : null}
                    <h4 className="my-3">Create a business account</h4>
                    <div className="mt-3">Step 1</div>
                    <Progress animated className="mb-3" value="25"/>
                    <FormGroup className='d-flex' style={{ justifyContent: 'space-between' }}>
                        <h6>{STRINGS.SELECT_YOUR_BUSINESS_USAGE_TYPE}</h6>
                        <Input 
                            type="select" 
                            name="businessUsageType" 
                            id="businessUsageType" 
                            style={{ width: '12.438rem' }}
                            onChange={(e) => this.selectBusinessUsageTypeOnChange(e)}
                        >
                            <option key={0}>{STRINGS.SELECT + '...'}</option>
                            {this.state.businessTypes.length > 0 && this.state.businessTypes.map((elm, index) => {
                                return (
                                    <option 
                                        key={index + 1}
                                        data-value={JSON.stringify(elm)}
                                    >
                                        {elm.Name}
                                    </option>
                                )
                            })}
                        </Input>
                    </FormGroup>
                    <div className="d-flex mb-3">
                        <div className="Rectangle col-4">
                            {this.state.selectedFile
                                ? <img
                                        style={{
                                        width: 'inherit'
                                    }}
                                        alt=""
                                        src={this.state.selectedFile}/>
                                : (
                                    <FileDrop onDrop={(files, event) => this.handleDrop(files, event)}>
                                        <img src='assets/rect.svg' alt="" className="mt-4"/>
                                        <FormGroup>
                                            <div className="m-2">Your Business Logo
                                            </div>
                                            <Label
                                                className="btn-link"
                                                style={{
                                                position: 'relative'
                                            }}>
                                                <Input
                                                    accept="image/x-png,image/jpeg"
                                                    style={{
                                                    opacity: 0,
                                                    position: "absolute"
                                                }}
                                                    onChange={(e) => this.handelInput(e)}
                                                    type="file"/>{this.state.fileName}</Label>
                                            <div>Or drag it here</div>
                                        </FormGroup>
                                    </FileDrop>
                                )}
                        </div>
                        <div className="ml-3">
                            <div className="d-flex">
                                <FormGroup>
                                    <Label for="firstName">First name</Label>
                                    <Input
                                        type="text"
                                        name="firstName"
                                        id="exFirstName"
                                        innerRef={(firstName) => {
                                        this.firstname = firstName
                                    }}/>
                                </FormGroup>
                                <FormGroup className="ml-4">
                                    <Label for="lastName">Last name</Label>
                                    <Input
                                        type="text"
                                        name="lastName"
                                        id="exLastName"
                                        innerRef={(lastName) => {
                                        this.lastName = lastName
                                    }}/>
                                </FormGroup>
                            </div>
                            <FormGroup>
                                <Label for="newEmail">Email</Label>
                                <Input
                                    type="email"
                                    name="newEmail"
                                    id="exnewEmail"
                                    innerRef={(email) => {
                                    this.email = email
                                }}/>
                            </FormGroup>
                            <div className="d-flex">
                                <div>
                                    <Label for="exNewPassword">Password</Label>
                                    <Input
                                        type="password"
                                        name="exNewPassword"
                                        id="exNewPassword"
                                        innerRef={(password) => {
                                        this.password = password
                                    }}/>
                                </div>
                                <div className="ml-4">
                                    <Label for="RepeatPassword">Repeat password</Label>
                                    <Input
                                        type="password"
                                        name="RepeatPassword"
                                        id="RepeatPassword"
                                        innerRef={(repeatPassword) => {
                                        this.repeatPassword = repeatPassword
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormGroup check className="d-flex">
                        <Label className="check" check>
                            <Input
                                onClick={() => this.setState({
                                isAgreement: !this.state.isAgreement
                            })}
                                type="checkbox"/>
                            I agree to the
                            <Link
                                to=''
                                onClick={(e) => {
                                e.preventDefault();
                                window.open('https://mobile2crm.com/general-terms-and-conditions', "_blank");
                            }}>
                                &nbsp; Terms & Conditions
                            </Link>
                        </Label>
                        <Button
                            disabled={this.state.isAgreement}
                            style={{
                            backgroundColor: ' #2EC2FE'
                        }}
                            className="col-3 ml-auto"
                            onClick={() => this.next()}
                            block>Next
                            <img src='assets/arrow.svg' alt="" className="ml-1"/>
                        </Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

export default CreateCcount;