import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledDropdown,
    ModalFooter
} from 'reactstrap';
import './advance.css';
import '../index.css';
import FeatureService from "../../../services/FeatureService";
import * as STRINGS from '../../../strings/english';
import {
    formatNumbers,
} from '../../../services/formatPhoneNumber';
import CountrySelect from '../../../services/format-number-input/countrySelect';

function SequentialRinging(props) {

    const [isEnabled, setIsEnabled] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [edit, setEdit] = useState({ mode: false, place: null });
    const [divert, setDivert] = useState('');
    const [diverts, setDiverts] = useState([]);
    const [addDivert, setAddDivert] = useState(false);
    const [description, setDescription] = useState("");
    const [startAfter, setStartAfter] = useState('');
    const [ringFor, setRingFor] = useState('');

    useEffect(() => {
        getDiverts();
    }, [])

    function getDiverts() {
        setIsLoader(true);
        let body = {
            "packageregistrationid": props.PackageRegistrationID
        }
        FeatureService.sequentialRingingGetSequentialRingingDiverts(body).then(res => {
            if (res.status === 200) {
                setIsEnabled(res.data.IsEnabled);
                setDiverts(res.data.Diverts);
                setIsLoader(false);
            }
        })
            .catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    }

    const saveChanges = () => {
        setIsLoader(true);
        FeatureService.sequentialRingingEnableSequentialRinging(isEnabled, props.PackageRegistrationID)
            .then((res) => {
                if (res) {
                    setIsLoader(false);
                    closeAddDivert(false);
                    props.toggel();
                }
            })
            .catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        save(false);
    }

    const updateCurrentDivertData = (isRemove) => {
        // diverts[edit.place].Divert = convertFormatNumToRegularNum(divert);
        if (isRemove) {
            diverts.splice(edit.place, 1);
        }
        else {
            diverts[edit.place].Divert = divert;
            diverts[edit.place].Description = description;
            diverts[edit.place].RingingDuration = ringFor;
            diverts[edit.place].TimeToRing = startAfter;
        }
    }

    const setDivertsToUpdate = (isRemove) => {
        updateCurrentDivertData(isRemove);
        let divertsToUpdate = [];
        diverts.map((d, i) => {
            let divertObj = {
                Divert: d.Divert,
                SIPChannel: d.SIPChannel,
                IsSIP: d.IsSIP,
                Description: d.Description,
                RingingDuration: d.RingingDuration,
                TimeToRing: d.TimeToRing                
            }
            divertsToUpdate.push(divertObj);
        });
        return divertsToUpdate;
    }

    const save = (showAlert) => {
        //console.log('divert: ' + divert + ' startAfter: ' + startAfter + ' ringFor:' + ringFor); // nittay
        if (divert && startAfter && ringFor) {
            setIsLoader(true);
            let body = {};
            if (edit.mode) {
                body['IsEnabled'] = isEnabled;
                body['FeatureName'] = 'SequentialRinging';
                body['Diverts'] = setDivertsToUpdate(false)
            }
            else if (!edit.mode) {
                body['packageregistrationid'] = props.PackageRegistrationID;
                // body['divert'] = convertFormatNumToRegularNum(divert);
                body['divert'] = divert;
                body['ringingduration'] = ringFor;
                body['timetoring'] = startAfter;
                body['description'] = description;
            }
            if (!edit.mode) {
                FeatureService.sequentialRingingInsertSequentialRingingDivert(body)
                    .then((res) => {
                        if (res) {
                            setIsLoader(false);
                            closeAddDivert(false);
                            getDiverts();
                        }
                    })
                    .catch(error => {
                        setIsLoader(false);
                        alert(error.response
                            ? error.response.data.Description || error.response.data.Message : error)
                    });
            }
            if (edit.mode) {
                FeatureService.sequentialRingingUpdateSequentialRinging(props.PackageRegistrationID, body)
                    .then((res) => {
                        if (res) {
                            setIsLoader(false);
                            closeAddDivert(false);
                            getDiverts();
                        }
                    })
                    .catch(error => {
                        setIsLoader(false);
                        alert(error.response
                            ? error.response.data.Description || error.response.data.Message : error)
                    });
            }
        }
        else if (showAlert) alert(STRINGS.INPUT_MUST_BE_FILLED_OUT);
    };

    const deleteDivert = () => {
        console.log('delete divert: ' + divert + ' startAfter: ' + startAfter + ' ringFor:' + ringFor);
        
        setIsLoader(true);
        let body = {};

        body['IsEnabled'] = isEnabled;
        body['FeatureName'] = 'SequentialRinging';
        body['Diverts'] = setDivertsToUpdate(true)
            
        FeatureService.sequentialRingingUpdateSequentialRinging(props.PackageRegistrationID, body)
                    .then((res) => {
                        if (res) {
                            setIsLoader(false);
                            closeAddDivert(false);
                            getDiverts();
                        }
                    })
                    .catch(error => {
                        setIsLoader(false);
                        alert(error.response
                            ? error.response.data.Description || error.response.data.Message : error)
                    });
        
    };


    function closeAddDivert(divertMode) {
        setAddDivert(divertMode)
        setDivert('');
        setDescription('');
        setStartAfter('');
        setRingFor('');
        setEdit({ mode: false, place: null });
    }

    function editDivert(index) {
        setAddDivert(true);
        setEdit({ mode: true, place: index });
        let currenDivert = diverts[index];
        //console.log(currenDivert); // nittay
        setDivert(currenDivert.Divert);
        //console.log('setDivert --- ' + divert + ',' + currenDivert.Divert); // nittay
        setStartAfter(currenDivert.TimeToRing);
        //console.log('setStartAfter --- ' + startAfter + ',' + currenDivert.TimeToRing); // nittay
        setRingFor(currenDivert.RingingDuration);
        //console.log('setRingFor --- ' + ringFor + ',' + currenDivert.RingingDuration); // nittay
        setDescription(currenDivert.Description);
        //console.log('setDescription --- ' + description + ',' + currenDivert.Description); // nittay
    }

    const renderTableBody = () => {
        let options = [];
        if (diverts && diverts.length !== 0) {
            diverts.forEach((divert, i) => {
                options.push(<tr
                    key={`listRow${i}`}
                    className={`tr ${divert.Active === 0 ? 'tr-disabled' : 'tr-enabled'}`}
                    style={edit.mode && edit.place === i ? { border: '2px solid #2EC2FE' } : {}}
                >
                    <td id='sr-td-1' className='th-3-points'>{formatNumbers(divert.Divert)}</td>
                    <td id='sr-td-2' className='th-3-points'>{divert.Description}</td>
                    <td id='sr-td-3' className='th-3-points'>{divert.TimeToRing}</td>
                    <td id='sr-td-4' className='th-3-points'>{divert.RingingDuration}</td>
                    <td>
                        <UncontrolledDropdown direction="down" style={{ lineHeight: '2rem' }}>
                            <DropdownToggle color={'white'}>{''}
                                <img src='assets/combined-shape-copy-3.svg' alt="" className=" m-2" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    className=""
                                    onClick={() => editDivert(i)}>
                                    <img src='assets/edit-2-outline-copy.svg' alt="" className="" />
                                    {STRINGS.EDIT}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td>
                </tr>
                )
            })
        }
        return options;
    };

    function divertsTable() {
        return (
            <div>
                <table className="table">
                    <thead className="fs-12 gray thead gr-mb">
                        <tr className="trh">
                            <th id='sr-th-1' style={{ paddingLeft: '0.75rem' }}>{STRINGS.DIVERTED_TO}</th>
                            <th id='sr-th-2' style={{ paddingLeft: '0.4rem' }}>{STRINGS.DESCRIPTION}</th>
                            <th id='sr-th-3'>{STRINGS.START_AFTER}</th>
                            <th id='sr-th-4'>{STRINGS.RING_FOR}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: "111px", minHeight: '108px' }} className="tbody aaBody">
                        {renderTableBody()}
                    </tbody>
                </table>
            </div>
        );
    }

    const callBack = (number) => {
        //console.log('callback ' + number); // nittay
        setDivert(number)
    }

    return <span>
        <ModalHeader
            style={{
                display: "flow-root",
                height: "90px"
            }}
        >
            <div className="d-flex justify-content-between fs-22" style={{ padding: "1.5rem  1rem" }}>
                <div>{STRINGS.SEQUENTIAL_RINGING_OPTIONS}</div>
                <div className='custom-control custom-switch isolate-the-design'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={`customSwitches2-`}
                        checked={isEnabled}
                        onChange={(e) => { setIsEnabled(e.target.checked) }}
                    />
                    <label className='custom-control-label' htmlFor={`customSwitches2-`} />
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
            <Form style={{ padding: "1.5rem  1rem" }}>

                {addDivert && <FormGroup className="background-lightGrey-2 sr-form">
                    <Row>
                        <Col md={6}>
                            {/* {edit.mode ?
                                <React.Fragment>
                                    <Label for="DivertedTo" className="fs-12 sr-form-label">{STRINGS.DIVERTED_TO}</Label>
                                    <Input
                                        id="DivertedTo"
                                        type="text"
                                        onChange={(e) => setDivert(e.target.value)}
                                        value={formatInputValue(divert)}>
                                    </Input>
                                </React.Fragment>
                                :  */}
                                <React.Fragment>
                                    <Label for="country-select-input" className="fs-12 sr-form-label">{STRINGS.DIVERTED_TO}</Label>
                                    <CountrySelect
                                        numberToUpdate={edit.mode ? divert : null}
                                        getNumberToUpdate={callBack}
                                    />
                                </React.Fragment>
                                {/* } */}
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="Description" className="fs-12 sr-form-label">{STRINGS.DESCRIPTION}</Label>
                                <Input
                                    type='text'
                                    id="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Label for="StartAfter" className="fs-12 sr-form-label">{STRINGS.START_AFTER}</Label>
                            <div className='d-flex'>
                                <Input
                                    id="StartAfter"
                                    type="number"
                                    onChange={(e) => setStartAfter(e.target.value)}
                                    value={startAfter}>
                                </Input>
                                <div className='fs-12' style={{ marginLeft: '0.938rem', marginTop: '0.5rem', color: '#9199ab' }}>
                                    {STRINGS.SECOND}
                                </div>
                            </div>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={4}>
                            <Label for="RingFor" className="fs-12 sr-form-label">{STRINGS.RING_FOR}</Label>
                            <div className='d-flex'>
                                <Input
                                    id="RingFor"
                                    type="number"
                                    onChange={(e) => setRingFor(e.target.value)}
                                    value={ringFor}>
                                </Input>
                                <div className='fs-12' style={{ marginLeft: '0.938rem', marginTop: '0.5rem', color: '#9199ab' }}>
                                    {STRINGS.SECOND}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="d-flex justify-content-end fs-14 mt-4">
                            <div className="mr-4 pointer" onClick={() => closeAddDivert(false)}>{STRINGS.CANCEL}</div>
                            <div className="mr-4 light-blue pointer"
                                onClick={() => save(true)}> {edit.mode ? STRINGS.UPDATE : STRINGS.SAVE}</div>
                            <div className="mr-0 light-red pointer"
                                onClick={() => deleteDivert()}> {edit.mode ? STRINGS.DELETE : ''}</div>    
                        </Col>
                    </Row>
                </FormGroup>}



                <FormGroup>
                    <div
                        className="d-flex justify-content-start"
                        style={{ width: "fit-content", cursor: "pointer" }}
                        onClick={() => closeAddDivert(true)}>
                        <img className="ml-0" src="assets/plus-circle-btn.svg" alt="" />
                        <div className="ml-2 light-blue fs-14">{STRINGS.ADD_DIVERT}</div>
                    </div>
                </FormGroup>
                {isLoader ?
                    <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }} src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                    : null}

                <FormGroup style={{ height: "20%" }}>
                    {diverts && diverts.length > 0 ? divertsTable() : null}
                </FormGroup>

            </Form>
        </ModalBody>
        <ModalFooter style={{ display: "block" }}>
            <FormGroup row className="justify-content-end">
                <Col md={3}><Button className="btnColor5 fontColor fs-14 no-border" style={{ width: "100%" }}
                    size="lg"
                    onClick={() => props.toggel()}>{STRINGS.CANCEL}</Button></Col>
                <Col md={3}><Button className="btnColor2 white fs-14 no-border btnColor2" style={{ width: "100%" }}
                    size="lg"
                    onClick={() => {
                        saveChanges()
                    }}>{STRINGS.SAVE_CHANGES}</Button></Col>
            </FormGroup>
        </ModalFooter>
    </span>
}

export default SequentialRinging;