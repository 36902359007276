import React from "react";
import VoiceMail from './voiceMail';
import CallRecording from './callRecording';
import TimeForward from "./timeForward";
import AutoAttendant from './autoAttendant';
import GroupRinging from './GroupRinging';
import SequentialRinging from './SequentialRinging';
import { Modal } from 'reactstrap';
import './advance.css';

function AdvanceModal(props) {

    const modalSelector = (modal) => {
        switch (modal) {
            case "voiceMail":
                return <VoiceMail
                    refresh={() => props.refresh()}
                    details={props.details.VoiceMailFeature}
                    did={props.details.Product[0] ? props.details.Product[0].DID : ""}
                    PackageRegistrationID={props.details.PackageRegistrationID}
                    toggel={() => props.toggle(modal)} />

            case "recording":
                return <CallRecording
                    refresh={() => props.refresh()}
                    details={props.details.RecordingFeature}
                    PackageRegistrationID={props.details.PackageRegistrationID}
                    toggel={() => props.toggle(modal)} />

            case "timeForward":
                return <TimeForward
                    refresh={() => props.refresh()}
                    details={props.details.RecordingFeature}
                    PackageRegistrationID={props.details.PackageRegistrationID}
                    toggel={() => props.toggle(modal)} />

            case "autoAttendant":
                return <AutoAttendant
                    refresh={() => props.refresh()}
                    details={props.details.RecordingFeature}
                    did={props.details.Product[0] ? props.details.Product[0].DID : ""}
                    PackageRegistrationID={props.details.PackageRegistrationID}
                    toggel={() => props.toggle(modal)} />

            case 'groupRinging':
                return <GroupRinging
                    refresh={() => props.refresh()}
                    details={props.details.RecordingFeature}
                    did={props.details.Product[0] ? props.details.Product[0].DID : ""}
                    PackageRegistrationID={props.details.PackageRegistrationID}
                    toggel={() => props.toggle(modal)} />
                    
            case 'sequentialRinging':
                return <SequentialRinging
                    refresh={() => props.refresh()}
                    details={props.details.RecordingFeature}
                    did={props.details.Product[0] ? props.details.Product[0].DID : ""}
                    PackageRegistrationID={props.details.PackageRegistrationID}
                    toggel={() => props.toggle(modal)} />

            default:
                return null
        }
    }

    let modelStyle = {
        width: `${props.modal.key === "timeForward" ? "69vw" : props.modal.key === "autoAttendant" ? '38.688rem' :
            props.modal.key === "groupRinging" ? '27.125rem' : props.modal.key === "sequentialRinging" ? '51.875rem' : "30vw"}`,
        maxHeight: "32%",
        padding: `${props.modal.key !== "autoAttendant" && props.modal.key !== "groupRinging" 
            && props.modal.key !== "sequentialRinging" ? '1.563rem' : '0'}`
    }

    return <Modal
        style={modelStyle}
        className="paymentModal"
        isOpen={props.modal.value}
        toggle={() => props.toggle(props.modal.key)}>
        {modalSelector(props.modal.key)}
    </Modal>
}

export default AdvanceModal