export const PHONE_BOOK_COUNTRY = 'Phone book country';
export const APP_ACCESS_NUMBER = 'App access number';
export const EDIT_USER_DETAILS = 'Edit user details';
export const CALL_REPORT = 'Call report';
export const REVIVE_USER = 'Revive user';
export const DELETE_USER = 'Delete user';
export const INPUT_MUST_BE_FILLED_OUT = 'Input must be filled out';
export const CHOOSE_YOUR_TIME_ZONE = 'Choose your time zone';
export const CANCEL = 'Cancel';
export const SAVE_TIME_ZONE = 'Save time zone';
export const ACCESS_NUMBER_SETTINGS = 'Access number settings';
export const FOR_SPIKKO_APP = 'for Spikko App';
export const SAVE_CHANGES = 'Save changes';
export const USER_PHONEBOOK_COUNTRY = 'User phonebook country';
export const OVERRIDE_USER_MANUAL_AUTOMATIC_SELECTION = 'Override user manual/automatic selection';
export const SELECT_COUNTRY = 'Select Country';
export const PLEASE_SELECT_A_COUNTRY = 'Please select a contry';
export const THE_CHANGES_HAVE_BEEN_SAVED = 'The changes have been saved';
export const SERVER_ERROR = 'Server Error';
export const CHOOSE_ACCESS_NUMBER = 'Choose access number';
export const AUTO_ATTENDANT_OPTIONS = 'Auto attendant options';
export const PLAY_THE_AUTO_ATTENDANT_MENU_AFTER = 'Play the auto attendant menu after';
export const SECOND = 'Second';
export const TIMES = 'Times';
export const TIME = 'Time';
export const OR_DRAG_IT_HERE = 'Or drag it here';
export const CHOOSE_A_FILE = 'Choose a file';
export const REPLACE_THE_AUTO_ATTENDANT_MENU = 'Replace the auto attendant menu recording by uploading a new one';
export const UPLOAD_AUTO_ATTENDANT_MENU_RECORDING = 'Upload auto attendant menu recording';
export const EXT_NUMBER = 'Ext. Number';
export const DESCRIPTION = 'Description';
export const DIVERTED_TO = 'Diverted to';
export const ADD_EXTENSION = 'Add extension';
export const DIRECT_TO_VOICE_MAIl = 'Direct to voice mail';
export const PRICE_PER_MINUTE = 'Price Per Minute';
export const SAVE = 'Save';
export const UPDATE = 'Update';
export const DELETE = 'Delete';
export const EDIT = 'Edit';
export const ENABLED = 'Enabled';
export const DISABLED = 'Disabled';
export const GO_BACK_TO_LOGIN = 'Go back to Login';
export const CONFIRM_PASSWORD = 'Confirm password';
export const PASSWORDS_DO_NOT_MATCH = 'Passwords do not match';
export const AUTO_ATTENDANT = 'Auto attendant';
export const TIME_FORWARD_OPTIONS = 'Time Forward options';
export const RECORDING_OPTIONS = 'Recording options';
export const VOICE_MAIL_OPTIONS = 'Voice mail options';
export const ADVANCED_FEATURES = 'Advanced features';
export const GROUP_RINGING = 'Group ringing';
export const SEQUENTIAL_RINGING = 'Sequential ringing';
export const GROUP_RINGING_OPTIONS = 'Group ringing options';
export const ADD_DIVERT = 'Add divert';
export const SEQUENTIAL_RINGING_OPTIONS = 'Sequential ringing options';
export const START_AFTER = 'Start after';
export const RING_FOR = 'Ring for';
export const PRICE = 'Price';
export const OPEN_USER_PAGE = 'Open user page';
export const LOGGED_IN_AS_USER = 'Logged in as User';
export const UNDO_LOGIN = 'Undo login';
export const DOWNLOAD_A_USER_TEMPLATE = 'Download a user template';
export const DOWNLOAD_A_PACKAGES_TEMPLATE = 'Download a packages template';
export const SELECT_YOUR_BUSINESS_USAGE_TYPE = 'Select your business usage type';
export const SELECT = 'Select';
export const SET_YOUR_CALLER_IDENTIFICATION_CLI = 'Set your caller identification (CLI)';
export const EDIT_CALLER_IDS = "Edit CallerId's";
export const ADD_NUMBER = 'Add number';
export const EDIT_CALLER_IDS_HEADER_TEXT = `This list of numbers are the phone numbers you will be using to call your access number.
If you call your access number from a phone that is not on this list, you will be asked to enter a PIN code.`
export const YOU_CAN_ENTER_UP_TO_20_NUMBERS = 'You can enter up to 20 numbers';
export const CHOOSE_DESTINATION = 'Choose Destination';
export const NUMBER = 'Number';
export const SUMMARY = 'Summary';
export const TRANSCRIPTION = 'Transcription';
export const GET_CALL_TRANSCRIPTION = 'Get call transcription';
export const GET_CALL_SUMMARY = 'Get call summary';
export const SELECT_TRANSCRIPTION_LANGUAGE = 'Select transcription language';
export const CHOOSE_LANGUAGE = 'Choose_language';
export const DATE = 'Date';
export const USER = 'User';
export const SPIKKO_NUMBER = 'Mobile2CRM number';
export const SOURCE = 'Source';
export const DESTINATION = 'Destination';
export const SILENT_MONITOR = 'Silent monitor';
export const CALL_DIRECTION = 'Call Direction';
export const DIRECTION = 'Direction';
export const LANGUAGE = 'Language';
export const DURATION = 'Duration';
export const PRICE_FOR_MIN = 'Price for min';
export const TOTAL_PRICE = 'Total price';
export const CONFIRM_TRANSCRIPTION = 'Confirm transcription';
export const TRANSCRIBE_NOW = 'Transcribe now';
export const TRANSCRIPTION_LINK = 'Transcription link';
export const TRANSCRIPTION_LANGUAGE = 'Transcription language';
export const CALL_TRANSCRIPTION = 'Call transcription';
export const CALL_SUMMARY = 'Call summary';
export const IN_PROGRESS = 'In Progress';
export const REAL_TIME_MONITORING = 'Real time monitoring';
export const USER_EMAIL = 'User email';
export const GET_CURRENT_STATUS = 'Get current status';
export const SEARCH_BY_USER = 'Search by user';
export const MONITOR_CALLBACK = 'Monitor callback';
export const OUTGOING_CALLS = 'Outgoing calls';
export const NUMBER_OF_ENTRIES = 'Number of entries';
export const FILL_THE_MONITOR_CALLBACK_NUMBER = 'Fill the Monitor callback number';
export const THE_OPERATION_WAS_COMPLETED_SUCCESSFULLY = 'The operation was completed successfully';
export const ANSWER_CALL_TO_MONITOR = 'Answer the call to start monitoring';
export const NOT_AUTHORIZED = 'This operation is not allowed due to your organizational restrictions';
export const OK = 'OK';
export const PACKEGE = 'Packege';
export const USERS_SELECTED = 'Users selected';
export const ALL_USERS_SELECTED = 'All Users selected';
export const WAS_DELETED_ON = 'Was deleted on';
export const WIL_BE_DELETED_ON = 'Will be deleted on';
export const SHOW_PAYMENTS = 'Show payments';
export const MORE_OPTIONS = 'More options';
export const SMS_RECIEVING = 'SMS recieving';
export const CALL_RECORDING = 'Call recording';
export const EXPIRY_DATE_NOT_SET = 'Expiry date not set';
export const RENEW_NOW = 'Renew now';
export const FINAL_EXPIRATION_DATE = 'Final expiration date';
export const EDIT_NOTE = 'Edit Note';
export const ADD_NOTE = 'Add Note';
export const FORWARD_TO = 'Forward to';
export const LOCAL_NUMBER = 'Local number';
export const ADD_LINE_NAME = 'add line name';
export const COST = 'Cost';
export const STATUS = 'Status';
export const FINAL_EXPIRATION = 'Final expiration';
export const NEXT_RENEWAL = 'Next renewal';
export const ADD = 'Add';
export const NOTE = 'Note';
export const LINE_NAME = 'line name';
export const PURCHASE_CREDIT_FOR_CALLS = 'Purchase Credit for calls';
export const ADD_FUNDS_TO_BANK = 'Add funds to bank';
export const MANAGE_PAYMENT_METHOD = 'Manage payment method';
export const SMS_REPORT = 'SMS report';
export const SMS_REPORT_UPPERCASE = 'SMS report';
export const USERS_SMS_REPORT = 'Users SMS report';
export const GET_USER_SMS_REPORT = 'Get user SMS report';
export const SELECT_A_DATE_RANGE = 'Select a date range';
export const LEAVE_EMPTY_FOR_ALL_SMS = 'leave empty for all';
export const SELECT_USERS = 'Select users';
export const DOWNLOAD_AS_CSV = 'Download as csv';
export const REPORT_AS_TABLE = 'Report as table';
export const SELECT_ALL = 'Select all';
export const CLEAR_ALL = 'Clear all';
export const USERS = 'Users';
export const CONVERSATIONS = 'Conversations';
export const CHAT = 'Chat';
export const NO_MESSAGES = 'No Messages';
export const PERSONAL_USER_SETTINGS = 'Personal user settings';
export const EMAIL = 'Email';
export const FULL_NAME = 'Full name';
export const CRM_USER = 'CRM user';
export const NO_CHANGES_TO_SAVE = 'No changes to save';
export const PLEASE_CONFIRM_THE_TERMS = 'Please click OK to approve our service terms';
export const PRIVACY_POLICY = 'Privacy Policy';
export const TERMS_AND_CONDITIONS = 'General Terms and Conditions';
export const NO_INFORMATION_TO_DISPLAY = 'No information to display';
export const NO_INFORMATION_TO_DOWNLOAD = 'No information to download';
export const LOGIN_WITH_SALESFORCE = 'Login with Salesforce';
export const LOGIN_WITH_SALEFORCE_SANDBOX = 'Login with Saleforce Sandbox';
export const LOGIN_WITH_SALESFORCE_OPTIONS = 'Login with Salesforce options';
export const MESSAGES_REPORT = 'Messages report';
export const USER_REPORT = 'User report';
export const GET_USER_MESSAGES_REPORT = 'Get user Messages report';
export const FROM = 'From';
export const TO = 'To';
export const NOT_DELIVERED = 'Not delivered';
export const INSIGHTS = 'Insights';
export const NUMBER_OF_CALLS = 'Number Of Calls';
export const ANSWERED_CALLS = 'Answered Calls';
export const TOTAL_DURATION = 'Total Duration';
export const LOADING_AUDIO = 'Loading audio';
export const AN_ERROR_OCCURRED_WHILE_LOADING_AUDIO = 'An error occurred while loading audio';
export const AN_ERROR_OCCURRED_WHILE_LOADING_FILE = 'An error occurred while loading file';
export const REPORT_TYPE = 'Report Type';
export const USERS_CALLS_REPORT = 'Users Calls report';
export const DAILY = 'Daily';
export const WEEKLY = 'Weekly';
export const MONTHLY = 'Monthly';
export const SHOW_THE_DURATION_IN = 'show the duration in';
export const MINUTES = 'minutes';
export const SECONDS = 'seconds';
export const ENABLE_MFA = 'Enable MFA';
export const DISABLE_MFA = 'Disable MFA';
export const VERIFY = 'Verify';
export const PLEASE_MATCH_THE_REQUESTED_FORMAT = 'Please match the requested format';
export const EXPLANATION_INPUT_CODE = `Open the two-factor authenticator (TOTP) app on your mobile device to view your authentication code.`
export const TWO_FACTOR_AUTHENTICATION = 'Two-factor authentication';
export const AUTHENTICATION_CODE = 'Authentication code';
export const WRONG_AUTHENTICATION_CODE = 'Wrong Authentication code';
export const DISABLE = 'Disable';
export const HAS_BEEN_DISABLED = 'has been disabled';
export const SUBMIT = 'Submit';
export const RESET_PASSWORD = 'Reset password';
export const ARE_YOU_SURE = 'Are you sure';
export const TEMPORARY_PASSWORD_FOR = 'Temporary password for';
export const SEND_PASSWORD_RESET_EMAIL = 'Send password reset email';
export const CREATE_A_TEMPORARY_PASSWORD = 'Create a temporary password';
export const EXPIRE_CURRENT_PASSWORD = 'Expire current password';
export const OPTIONAL = 'optional';
export const NEW_PASSWORD = 'New assword';
export const PASSWORD_RESET_EMAIL = 'Password reset email';
export const CUSTOM_RANGE = 'Custom Range';
export const TRYING_TO_RECONNECT_TO_REAL_TIME_EVENTS = 'Trying to reconnect to Real time events';
export const CALL_DURATION = 'Call duration';
export const SHOW_LOCAL_TIME_IN_USER_REPORT = 'Show Local time in User reports';
export const CUSTOMER = 'Customer';
export const CALL_ACTIVE = 'Call active';
export const CONTACTS = 'Contacts';
export const MISSING_DETAILS = 'Missing details';
export const NAME = 'Name';
export const PHONE_NUMBER = 'Phone number';
export const NAME_UPPERCASE = 'NAME';
export const PHONE_NUMBER_UPPERCASE = 'PHONE NUMBER';
export const TOTAL = 'Total';
export const CONTACTS_LOWER_C = 'contacts';
export const ADD_A_CONTACT = 'Add a contact';
export const ADD_TO_CONTACTS = 'Add to contacts';
export const BACK = 'Back';
export const FORBIDDEN_MODAL_TEXT_MSG = 'This Operation is not allowed Please contact Mobile2CRM support team';
export const FORBIDDEN = 'Forbidden';
export const EDIT_LOGIN_GROUPS = 'Login Groups';