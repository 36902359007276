import React from 'react';
import './dataCell.css';

function DataCell(props) {
    return (
        <div
            key={`data-cell-${props.index}`}
            className='data-cell-main'
        >
            <div>
                <div className='data-cell-header'>
                    {props.header}
                </div>
                {props.color &&
                    <img alt='HighVsAvg-icon' src={`assets/${props.color}.svg`} />}
            </div>
            <div className='data-cell-value-main'>
                <div className='data-cell-value'>
                    {props.content}
                </div>
                {props.iconName &&
                    <img alt='' src={`assets/${props.iconName}.svg`} />}
            </div>
        </div>
    );
}

export default DataCell;