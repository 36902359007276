import { defineMessages } from 'react-intl';

const scope='app.src.containers.dashboard';

export default defineMessages({
  current: {
    id: `${scope}.current`,
    defaultMessage: 'Current:'
  },
  Actions: {
    id: `${scope}.Actions`,
    defaultMessage: 'Actions'
  },
  Select: {
    id: `${scope}.Select`,
    defaultMessage: 'Select'
  },
  SelectState: {
    id: `${scope}.SelectState`,
    defaultMessage: 'Select state / city'
  },
  Email: {
    id: `${scope}.Email`,
    defaultMessage: 'Email'
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'USD / month'
  },
  SelectYourPayment : {
    id: `${scope}.SelectYourPayment`,
    defaultMessage: 'Select your payment method '
  },
  USDMonth: {
    id: `${scope}.USDMonth`,
    defaultMessage: 'USD / month'
  },
});
