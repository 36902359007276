import React, { useState, useEffect } from 'react';
import '../../containers/dashboard/index.css';
import FileDrop from 'react-file-drop';
import {FormGroup, Col, Label, Input} from 'reactstrap';


function UploadFile(props){
    
   const initializeFile = {
        selectedFile: null,
        fileName: 'Choose a file Or drag it here',
        loaded: 0,
        dataFile: null
    }

    const [file, setFile] = useState(initializeFile)
    useEffect(()=>{
        props.file(file)
    },[file])


   const handleDuration = (file) => {
       let duration = 0
        try {
            let reader =  new FileReader();
            reader.readAsDataURL(file)
            reader.onload = () => {
                let media = new Audio(reader.result);
                 media.onloadedmetadata = () => {
                     duration =  media.duration
                     props.duration(duration)
                     return duration
                }
            };
        }catch (e) {
            console.log(e)
        }

    }

  const handleInput = async (files) => {
        if (files.target && files.target.files) {
            let file = files.target.files[0]
            let duration = await handleDuration(file)
            setFile({
                selectedFile: file,
                loaded: 0,
                fileName: file.name,
                dataFile: file,
                duration: duration
            })
        }
    }

    const handleDrop = (files) => {
        if (files) {
            let file = files[0]
            let duration = handleDuration(file)
            setFile({
                selectedFile: file,
                loaded: 0,
                fileName: file.name,
                dataFile: file,
                duration: duration
            })
        }
    };

    
    return <FormGroup>
    
    <Col
        style={{
        border: 'dashed 1px #1a253a',
        height: '200px'
    }}
        md='12'
        className="text-center">
        <FileDrop onDrop={(files, event) => handleDrop(files, event)}>
            <img src='assets/group-16.png' alt="" className="mt-4"/>
            <FormGroup>
                <Label
                    className="btn-link"
                    style={{
                    position: 'relative'
                }}>
                    <Input
                        accept=".mp3, .wav, .m4a"
                        style={{
                        opacity: 0,
                        position: "absolute"
                    }}
                        onChange={(files) => handleInput(files)}
                        type="file"
                        id = "uploadFile"
                        />
                        <div 
                        className='small' 
                        style={{color:'#3c3f4a'}}>
                        </div>
                       <div>{file.fileName}</div> </Label>
            </FormGroup>
        </FileDrop>
        {/*<Button */}
        {/*style={{color:'white'}} */}
        {/*onClick={()=>sendFile()} */}
        {/*color={''} */}
        {/*className={`col-1 btnColor3`}>*/}
        {/*        Send</Button>*/}
    </Col>
   { /*<Col className="my-2">
        <img src='assets/icon-download.png' alt=""/>
       <Link 
       onClick={()=>downloadTemplate()} 
       className="ml-1" to="">Download a template file</Link>
        </Col>*/}
</FormGroup>
}

export default UploadFile