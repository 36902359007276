import React, { Component } from 'react';
import SideBar from '../../../components/sideBar';
import NavBar from '../../../components/navBar';
import * as STRINGS from '../../../strings/english';
import AccountService from '../../../services/AccountService';
import {
    Button,
    Spinner,
    FormGroup,
    Popover,
    PopoverBody,
    Modal,
} from 'reactstrap';
import './usersSmsReport.css';
import { formatNumbers } from '../../../services/formatPhoneNumber';
import { SmsReportUserDetails } from './smsReportUserDetails';
import { UserConversations } from './userConversations';
import { OtherMessageCube } from './otherMessageCube';
import { UserMessageCube } from './userMessageCube';
import axios from '../../../axios';
import {
    formatMessagesDate,
    formatMessagesTime,
    downloadCsv,
    setTimeForDate,
} from '../../../services/tools';
import DatePicker from 'react-datepicker';
import { USER_REPORT_ENUM } from '../../../enum/index';
import { GroupMsgHeader } from './group-msg-header';
import NewContactModal from '../../contacts/newContactModal';


class UsersSmsReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            users: [],
            userNumbers: {},
            usersWithNumbers: [],
            userConversations: [],
            userConversationsData: [],
            currentUserConversationsLength: 0,
            startFrom: '',
            endTo: '',
            conversationMessages: [],
            currentUserDidNumber: '',
            currentContactNumber: '',
            currentContactName: '',
            isLoader: false,
            noMessages: false,
            lastMessageDateArray: [],
            entryForFheFirstTime: true,
            usersIdsArr: [],
            downloadAsCsvIsLoader: false,
            dateInputValueLength: 0,
            numbersInfo: {},
            isSMSreport: false,
            isWAreport: false,
            reportType: '',
            isGroupMsg: false,
            patricipantsNumberArr: [],
            filterByDateToLatest: false,
            userDetailsIndex: 0,
            popoverOpen: false,
            newContactName: '',
            modal: false,
            onNumberElement: false,
            onPopoverElement: false,
            flag: false,
        }
        this.smsReportUserDetailsRefs = [];
        this.userConversationsRefs = [];
        // this.userConversationsRefs = [];
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setData();
            AccountService.getUserByUsername(localStorage.getItem('email')).then(res => {
                this.setState({ userData: res.data });
            }).catch(error => {
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
    }

    setData = () => {
        this.setState({
            users: this.props.location.state.users,
            startFrom: this.props.location.state.startFrom,
            endTo: this.props.location.state.endTo,
            downloadAsCsvOnClick: this.props.location.state.downloadAsCsvOnClick,
            usersIdsArr: this.props.location.state.usersIdsArr,
            isSMSreport: this.props.reportType === USER_REPORT_ENUM.SMS_MODAL_TYPE,
            isWAreport: this.props.reportType === USER_REPORT_ENUM.MESSAGES_MODAL_TYPE,
        }, () => {
            this.getSMSNumbersForUsers();
        });
    }

    addTypeToWAMessages = (userMessages) => {
        for (const key in userMessages) {
            for (const x in userMessages[key]) {
                if (userMessages[key][x][USER_REPORT_ENUM.NUMBER_CONVERSATIONS_TYPE] ||
                    userMessages[key][x][USER_REPORT_ENUM.GROUP_CONVERSATIONS_TYPE]) {
                    for (const z in userMessages[key][x]) {
                        userMessages[key][x][z].forEach(elm => {
                            elm.type = z;
                        })
                    }
                }
            }
        }
        return userMessages;
    }

    signOut = () => {
        localStorage.clear();
        this.props.history.push('/login');
    }

    filterData = async () => {
        let arr = [];
        for (const key in this.state.userNumbers) {
            let user = this.state.users.find(user => user.UserName === key);
            let userNumbersArr = this.covertUserNumberToArray(this.state.userNumbers[key]);
            user.sortedNumbers = userNumbersArr;
            arr.push(user);
        }
        arr.sort((a, b) => {
            return new Date(b.sortedNumbers[0].allNumbers[0].LatestTime) -
                new Date(a.sortedNumbers[0].allNumbers[0].LatestTime);
        })
        this.setState({ usersWithNumbers: arr }, () => {
            this.getTheFirstUserData();
        })
    }

    covertUserNumberToArray = (numbers) => {
        let numbersArr = [];
        for (const key in numbers) {
            const element = numbers[key];
            let userNumbersArr;
            if (this.state.isSMSreport) {
                userNumbersArr = element;
            } else {
                if (Object.keys(element).length > 1) {
                    userNumbersArr = [].concat.apply([], Object.values(element));
                } else {
                    userNumbersArr = Object.values(element)[0];
                }
            }
            let numberObj = {
                userNumber: key,
                allNumbers: userNumbersArr,
            }
            numbersArr.push(numberObj);
        }
        const sortedArr = this.sortUserNumber(numbersArr, true);
        return sortedArr;
    }

    sortUserNumber = (numbers, sortToLatest = false) => {
        numbers.forEach(element => {
            element.allNumbers.sort((a, b) => {
                if (sortToLatest || this.state.filterByDateToLatest) {
                    return new Date(b.LatestTime) -
                        new Date(a.LatestTime);
                } else {
                    return new Date(a.LatestTime) -
                        new Date(b.LatestTime);
                }
            })
        });
        numbers.sort((a, b) => {
            if (sortToLatest || this.state.filterByDateToLatest) {
                return new Date(b.allNumbers[0].LatestTime) -
                    new Date(a.allNumbers[0].LatestTime);
            } else {
                return new Date(a.allNumbers[0].LatestTime) -
                    new Date(b.allNumbers[0].LatestTime);
            }
        })
        return numbers;
    }

    getTheFirstUserData = () => {
        this.createUserConversations(this.state.usersWithNumbers[0]);
    }

    returnUsersDetails = () => {
        let arr = [];
        this.state.usersWithNumbers.map((item, index) => {
            const date = item.sortedNumbers[0].allNumbers[0].LatestTime;
            const formatDate = date ? `${formatMessagesDate(date)} ${formatMessagesTime(date)}` : '';
            arr.push(
                <SmsReportUserDetails
                    ref={obj => this.smsReportUserDetailsRefs[index] = obj}
                    key={'sms-report-details-' + index}
                    userName={`${item.FirstName} ${item.LastName}`}
                    date={formatDate}
                    userEmail={item.UserName}
                    smsReportUserDetailsOnClick={() => this.smsReportUserDetailsOnClick(index, item)}
                    index={index}
                    entryForFheFirstTime={this.state.entryForFheFirstTime}
                    dateInfo={item.dateInfo}
                />
            )
        });
        return arr;
    }

    smsReportUserDetailsOnClick = (currentItemIndex, user) => {
        this.setState({
            entryForFheFirstTime: false,
            userDetailsIndex: currentItemIndex,
        }, () => {
            this.highlightsButtonAndTurnsOfTheRest(currentItemIndex);
            this.createUserConversations(user, currentItemIndex);
        });
    }

    highlightsButtonAndTurnsOfTheRest = (currentItemIndex) => {
        this.smsReportUserDetailsRefs.forEach((elm, index) => {
            if (elm) {
                if (currentItemIndex !== index) {
                    elm.style = {};
                }
                else elm.style.backgroundColor = '#ebf9ff';
            }
        })
    }

    createUserConversations = (user, smsReportUserDetailsIndex = 0) => {
        let arr = [];
        user.sortedNumbers.forEach((number, i) => {
            let detailsForMessages = {
                Username: user.UserName,
                did: number.userNumber
            }
            if (this.state.startFrom && this.state.endTo) {
                detailsForMessages.StartFrom = this.state.startFrom;
                detailsForMessages.EndTo = this.state.endTo;
            }
            if (i === 0 && this.state.entryForFheFirstTime) {
                this.getFirstContact(detailsForMessages, number.allNumbers);
                this.renderConversationMessages(detailsForMessages);
            }
            let userConversationsData = {
                key: `${number.userNumber}-conversations`,
                userNumber: number.userNumber,
                amountOfCorrespondence: number.allNumbers.length,
                allUserNumbers: number.allNumbers,
                detailsForMessages: detailsForMessages,
                smsReportUserDetailsIndex: smsReportUserDetailsIndex,
                userId: user.UserId,
            }
            arr.push(userConversationsData);
        })
        this.setState({
            userConversations: arr,
            userConversationsData: arr,
            currentUserConversationsLength: user.sortedNumbers.length
        })

    }

    getFirstContact = (obj, userNumber) => {
        if (this.state.isSMSreport) {
            obj.Contact = userNumber[0].Contact;
            obj.ContactName = userNumber[0].ContactName;
        } else {
            if (userNumber[0].type === USER_REPORT_ENUM.NUMBER_CONVERSATIONS_TYPE) {
                obj.Contact = userNumber[0].Contact;
                obj.ContactName = userNumber[0].ContactName;
            } else {
                obj.Group = userNumber[0].Contact;
            }
        }
    }

    calcAmountOfCorrespondence = (userNumbers) => {
        if (this.state.isSMSreport) {
            return userNumbers.length;
        } else {
            let count = 0
            for (const key in userNumbers) {
                count += userNumbers[key].length;
            }
            return count;
        }
    }

    renderUserConversations = () => {
        let arr = [];
        this.state.userConversationsData.map((item, i) => {
            arr.push(
                <UserConversations
                    ref={obj => this.userConversationsRefs[i] = obj}
                    key={item.key}
                    userNumber={item.userNumber}
                    amountOfCorrespondence={item.amountOfCorrespondence}
                    allUserNumbers={item.allUserNumbers}
                    detailsForMessages={item.detailsForMessages}
                    renderConversationMessages={this.renderConversationMessages}
                    userConversationsDetailsOnClick={(index) => this.userConversationsDetailsOnClick(index, i)}
                    entryForFheFirstTime={this.state.entryForFheFirstTime}
                    userConversationsIndex={i}
                    // userConversationsOnClick={this.userConversationsOnClick}
                    numbersInfo={this.state.numbersInfo}
                    smsReportUserDetailsIndex={item.smsReportUserDetailsIndex}
                    isSMSreport={this.state.isSMSreport}
                    isWAreport={this.state.isWAreport}
                />)
        })
        return arr;
    }

    // userConversationsOnClick = () => {
    //     this.setState({ entryForFheFirstTime: false });
    // }

    userConversationsDetailsOnClick = (usersDetailsIndex, fatherIndex) => {
        this.setState({
            entryForFheFirstTime: false,
        })
        let fatherPressedIndex = fatherIndex;
        let usersDetailsPressedIndex = usersDetailsIndex;
        this.userConversationsRefs.forEach((item, index) => {
            if (item && item.ref.children[1]) {
                for (const key in item.ref.children[1].children) {
                    let i = Object.keys(item.ref.children[1].children).indexOf(key);
                    if (item.ref.children[1].children[key].style) {
                        if (index === fatherPressedIndex && i === usersDetailsPressedIndex) {
                            item.ref.children[1].children[key].style.backgroundColor = '#ebf9ff'
                        }
                        else {
                            item.ref.children[1].children[key].style = {};
                        }
                    }
                }
            }
        })
    }

    renderConversationMessages = (body) => {
        this.setState({ isLoader: true });
        const path = `/SMS/${this.state.isWAreport ?
            USER_REPORT_ENUM.GET_WA_MESSAGES_FOR_CONVERSATION :
            USER_REPORT_ENUM.GET_SMS_MESSAGES_FOR_CONVERSATION}`
        axios.post(path, body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    conversationMessages: response.data.reverse(),
                    currentUserDidNumber: body.did,
                    currentContactNumber: body.Contact || body.Group,
                    currentContactName: body.ContactName,
                    isLoader: false,
                    noMessages: false,
                    isGroupMsg: body.Group ? true : false,
                }, () => {
                    this.getGroupMsgParticipants(response.data);
                });
            }
            else this.setState({
                noMessages: true,
                isLoader: false,
            })
        }).catch(error => {
            this.setState({ isLoader: false });
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    }

    renderMessagesCyub = () => {
        let arr = [];
        if (this.state.conversationMessages.length > 0) {
            this.state.conversationMessages.map((msg, i) => {
                const time = msg.InsertTime || msg.ReceiveTime;
                if (msg.From === this.state.currentUserDidNumber) {
                    arr.push(
                        <UserMessageCube
                            key={`user-message-cube-${i}`}
                            userNumber={formatNumbers(msg.From)}
                            sendingTime={`${formatMessagesDate(time)} ${formatMessagesTime(time)}`}
                            isSMSreport={this.state.isSMSreport}
                            mediaUrl={this.returnMediaUrl(msg)}
                            mediaType={this.returnMediaType(msg)}
                            fileName={this.returnFileName(msg) || ''}
                            message={msg}
                        />)
                }
                else {
                    arr.push(
                        <OtherMessageCube
                            key={`other-message-cube-${i}`}
                            otherName={formatNumbers(msg.From)}
                            sendingTime={`${formatMessagesDate(time)} ${formatMessagesTime(time)}`}
                            mediaUrl={this.returnMediaUrl(msg)}
                            mediaType={this.returnMediaType(msg)}
                            fileName={this.returnFileName(msg) || ''}
                            message={msg}
                        />)
                }
            });
        }
        return arr;
    }

    returnMediaUrl = (msg) => {
        if (msg.HasMedia) {
            return msg.Media[0].m_Item2;
        }
        else if (msg.HasAttachment) {
            return msg.AttachmentURL;
        }
    }

    returnMediaType = (msg) => {
        if (msg.HasMedia) {
            return msg.Media[0].m_Item1;
        }
        else if (msg.HasAttachment) {
            return msg.AttachmentType;
        }
    }

    returnFileName = (msg) => {
        if (msg.HasMedia) {
            const strArr = msg.Media[0].m_Item2.split('/');
            return strArr[strArr.length - 1]
        }
        else if (msg.HasAttachment) {
            const strArr = msg.AttachmentURL.split('/');
            return strArr[strArr.length - 1]
        }
    }

    getGroupMsgParticipants = (data) => {
        if (this.state.isGroupMsg) {
            const msg = data[0];
            let patricipantsNumberArr = [];
            const patricipantsArr = JSON.parse(msg.GroupParticipants);
            patricipantsArr.forEach(elm => {
                patricipantsNumberArr.push(formatNumbers(elm.id.user));
            });
            this.setState({ patricipantsNumberArr });
        }
    }

    getSMSMessagesForUsers = () => {
        this.setState({ downloadAsCsvIsLoader: true });
        const path = `/SMS/${this.state.isSMSreport ?
            USER_REPORT_ENUM.GET_SMS_MESSAGES_FOR_USERS :
            USER_REPORT_ENUM.GET_WA_MESSAGES_FOR_USERS}`
        let body = {
            includedusers: this.state.usersIdsArr
        };
        if (this.state.startFrom && this.state.endTo) {
            body.StartFrom = this.state.startFrom;
            body.EndTo = this.state.endTo;
        }
        axios.post(path, body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            this.setState({ downloadAsCsvIsLoader: false });
            if (Object.keys(response.data).length > 0) {
                downloadCsv(response.data);
            }
        }).catch(error => {
            this.setState({ downloadAsCsvIsLoader: false });
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    };

    downloadAsCsvOnClick = () => {
        if (this.state.usersIdsArr.length > 0) {
            this.getSMSMessagesForUsers();
        }
    }

    handleInputChange = (name, value) => {
        if (value) {
            this.setState({
                [name]: value
            }, () => {
                this.getSMSNumbersForUsers();
            });
        }
    };

    getSMSNumbersForUsers = () => {
        const path = `/SMS/${this.state.isSMSreport ?
            USER_REPORT_ENUM.GET_SMS_NUMBERS_FOR_USERS :
            USER_REPORT_ENUM.GET_WA_NUMBERS_FOR_USERS}`
        let body = {
            startFrom: this.state.startFrom,
            endTo: this.state.endTo,
            includedusers: this.state.usersIdsArr
        };
        axios.post(path, body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (Object.keys(response.data.Users).length > 0) {
                this.setState({
                    userNumbers: this.addTypeToWAMessages(response.data.Users),
                    numbersInfo: response.data.NumbersInfo,
                    usersWithNumbers: [],
                    lastMessageDateArray: []
                }, () => {
                    this.filterData();
                })
            }
        }).catch(error => {
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    };

    filterByDate = () => {
        let usersWithNumbers = JSON.parse(JSON.stringify(this.state.usersWithNumbers));
        for (let i = 0; i < this.state.usersWithNumbers.length; i++) {
            let userWithNumbers = usersWithNumbers[i];
            this.sortUserNumber(userWithNumbers.sortedNumbers);
            usersWithNumbers[i] = userWithNumbers;
        }
        this.setState({
            usersWithNumbers,
            entryForFheFirstTime: true,
        }, () => {
            let usersWithNumbers = JSON.parse(JSON.stringify(this.state.usersWithNumbers));
            usersWithNumbers.sort((a, b) => {
                if (this.state.filterByDateToLatest) {
                    return new Date(b.sortedNumbers[0].allNumbers[0].LatestTime) -
                        new Date(a.sortedNumbers[0].allNumbers[0].LatestTime);
                } else {
                    return new Date(a.sortedNumbers[0].allNumbers[0].LatestTime) -
                        new Date(b.sortedNumbers[0].allNumbers[0].LatestTime);
                }
            });
            this.setState({
                usersWithNumbers,
                filterByDateToLatest: !this.state.filterByDateToLatest,
            }, () => {
                this.createUserConversations(this.state.usersWithNumbers[this.state.userDetailsIndex]);
                this.setAllUserConversationsStyle();

            });
        });
    }

    setAllUserConversationsStyle = () => {
        this.userConversationsRefs.forEach((item) => {
            if (item && item.ref.children[1]) {
                for (const key in item.ref.children[1].children) {
                    if (item.ref.children[1].children[key].style) {
                        item.ref.children[1].children[key].style = {};
                    }
                }
            }
        });
        this.userConversationsRefs.forEach((item) => {
            if (item) {
                if (this.state.usersWithNumbers[this.state.userDetailsIndex]
                    .sortedNumbers[0].userNumber === item.number) {
                    item.toggle(true);
                } else item.toggle(false);
            }
        });
    }

    popoverToggle = () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen,
        });
    }

    newContactModaltoggle = () => {
        if (this.state.popoverOpen) {
            this.setState({
                popoverOpen: false,
            });
        }
        this.setState({
            modal: !this.state.modal,
        });
    }

    addContactOnClick = () => {
        if (this.state.newContactName) {
            const data = {
                "ContactName": this.state.newContactName,
                "ContactNumber": this.state.currentContactNumber,
            }
            axios.post(`/Account/InsertUserContact`, data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(res => {
                this.newContactModaltoggle();
                this.getSMSNumbersForUsers();
            }).catch(err => {
                console.log(err);
            })
        } else alert(STRINGS.MISSING_DETAILS)
    }

    setName = (name) => {
        this.setState({
            newContactName: name,
        });
    }

    newContactModalOnClose = () => {
        this.setState({
            newContactName: '',
        });
    }

    mouseOverToOpenPopover = (key) => {
        this.setState({
            [key]: true,
        })
        setTimeout(() => {
            if (this.state.onPopoverElement ||
                this.state.onNumberElement) {
                this.setState({
                    popoverOpen: true,
                })
            }
        }, 500);
    }

    mouseleaveToOpenPopover = (key) => {
        this.setState({
            [key]: false,
        })
        setTimeout(() => {
            if (!this.state.onPopoverElement &&
                !this.state.onNumberElement) {
                this.setState({
                    popoverOpen: false,
                })
            }
        }, 1000);
    }

    render() {
        let usersLength = this.state.usersWithNumbers.length;
        let numberInfo = this.state.numbersInfo[this.state.currentUserDidNumber];
        let lineName = numberInfo && numberInfo.LineName ? `(${numberInfo.LineName})` : numberInfo ? `(${numberInfo.Country})` : '';
        const title = this.state.isSMSreport ? STRINGS.SMS_REPORT_UPPERCASE : STRINGS.MESSAGES_REPORT;
        if (this.props.location.state) {
            return (
                <div className="d-flex">
                    <SideBar
                        active1={true}
                        active2={false}
                        active3={false}
                        active4={false}
                        active5={false}
                        active6={false}
                        active7={false}
                        active8={false}
                        homePage={() => {
                            this.props.history.push('/', { redirect: true });
                        }}
                        myPackagePage={() => {
                            this.props.history.push('/myPackages', { redirect: true });
                        }}
                        settingPage={() => this.props.history.push('/settings')}
                        crm_integration={() => this.props.history.push('/crm_integration')}
                        contactUs={() => this.props.history.push('/contactUs')}
                        realTimeMonitoring={() => this.props.history.push('/real_time_monitoring')}
                        graph={() => this.props.history.push('/charts')}
                        contacts={() => this.props.history.push('/contacts')}
                        user={this.state.userData}
                    />
                    <div className="container-fluid p-0" style={{ height: "100vh", overflow: "auto" }}>
                        <NavBar
                            logo={this.state.userData.LogoURL}
                            homePage={() => { }}
                            signOut={() => this.signOut()}
                            mail={'this.state.mail'}
                            title={title} />
                        <div className='users-sms-report-main-div'>
                            <div className='users-sms-report-above-main-div'>
                                <Button
                                    color=""
                                    style={{ color: 'white' }}
                                    className="btnColor2 fs-14 users-sms-report-back-btn"
                                    onClick={() => {
                                        this.props.history.push('/', { redirect: true });
                                    }}
                                >
                                    <img
                                        src='assets/arrow-left.svg'
                                        alt='arrow-left-icon'
                                        className='users-sms-report-arrow-left-icon'
                                    />
                                    {STRINGS.BACK}
                                </Button>
                                <div className='users-sms-report-above-main-date-inputs'>
                                    <FormGroup className="mr-2" >
                                        <h6 htmlFor="from" className="gray">From</h6>
                                        <div className='border-52 form-control'>
                                            <DatePicker
                                                id="from"
                                                className="no-border2"
                                                selected={this.state.startFrom}
                                                onChange={(date) => {
                                                    this.handleInputChange("startFrom", setTimeForDate(date, "start"));
                                                }}
                                                onChangeRaw={(event) => this.setState({ dateInputValueLength: event.target.value.length })}
                                                strictParsing
                                            />
                                            <img src='assets/calendar.svg' alt="" />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="mr-2">
                                        <h6 htmlFor="to" className="gray">To</h6>
                                        <div className='border-52 form-control'>
                                            <DatePicker
                                                id="to"
                                                className="no-border2"
                                                selected={this.state.endTo}
                                                onChange={(date) => {
                                                    this.handleInputChange("endTo", setTimeForDate(date));
                                                }}
                                                onChangeRaw={(event) => this.setState({ dateInputValueLength: event.target.value.length })}
                                                strictParsing
                                            />
                                            <img src='assets/calendar.svg' alt="" />
                                        </div>
                                    </FormGroup>
                                </div>
                                <Button
                                    color=""
                                    style={{ color: 'white' }}
                                    className="btnColor2 fs-14 users-sms-report-download-as-csv-button"
                                    onClick={this.downloadAsCsvOnClick}
                                >
                                    <img src='assets/export.svg' className="sms-report-export-icon" />
                                    {STRINGS.DOWNLOAD_AS_CSV}
                                    {this.state.downloadAsCsvIsLoader ?
                                        <Spinner
                                            size="sm"
                                            color="#FFFFFF"
                                            className='users-sms-report-download-as-csv-spinner'
                                        />
                                        : ''}
                                </Button>
                            </div>
                            <div className='users-sms-report-dashboard-main'>
                                <div className='users-sms-report-main-headers'>
                                    <div className='users-sms-report-main-header-bold'>
                                        <div className='users-sms-report-main-header-users-number'>
                                            {`${STRINGS.USERS} (${usersLength})`}
                                        </div>
                                        <div className='users-sms-report-main-header-conversations-number'>
                                            {`${STRINGS.CONVERSATIONS} (${this.state.currentUserConversationsLength})`}
                                        </div>
                                        <div className='users-sms-report-main-header-icon'>
                                            <img
                                                src='assets/users-report-arrow-icon.svg'
                                                className='users-report-arrow-icon'
                                                onClick={this.filterByDate}
                                            />
                                        </div>
                                        <div>
                                            {!this.state.isGroupMsg ? STRINGS.CHAT : <img src='assets/group-name-big-icon.svg' />}
                                        </div>
                                    </div>
                                    {this.state.currentUserDidNumber && this.state.currentContactNumber &&
                                        !this.state.isGroupMsg ?
                                        <div className='users-sms-report-main-header-number'>
                                            <div className='users-sms-report-main-header-from-number'>
                                                {`${formatNumbers(this.state.currentUserDidNumber)} ${lineName}`}
                                            </div>
                                            <div className='users-sms-report-main-header-to-icon'>
                                                <img
                                                    style={{ height: '1.188rem' }}
                                                    src='assets/arrow-right-small.png'
                                                />
                                            </div>
                                            <div
                                                className={this.state.currentContactName ?
                                                    'users-sms-report-main-header-to-number' : ''}
                                                id='users-sms-report-to-number'
                                                onMouseOver={() => this.mouseOverToOpenPopover('onNumberElement')}
                                                onMouseLeave={() => this.mouseleaveToOpenPopover('onNumberElement')}
                                            >
                                                {this.state.currentContactName ?
                                                    `${formatNumbers(this.state.currentContactName)}` :
                                                    `${formatNumbers(this.state.currentContactNumber)}`}
                                                {!this.state.currentContactName &&
                                                    <img
                                                        src='assets/person-add.svg'
                                                        alt='person-add-icon'
                                                        className='users-sms-report-person-add-icon'
                                                        onClick={this.newContactModaltoggle}
                                                    />}
                                            </div>
                                            {this.state.currentContactName &&
                                                <div
                                                    onMouseOver={() => this.mouseOverToOpenPopover('onPopoverElement')}
                                                    onMouseLeave={() => this.mouseleaveToOpenPopover('onPopoverElement')}
                                                >
    
                                                    <Popover
                                                        target="users-sms-report-to-number"
                                                        placement='top'
                                                        isOpen={this.state.popoverOpen}
                                                        toggle={this.popoverToggle}
                                                        className='users-sms-report-popover'
                                                    >
                                                        <PopoverBody>
                                                            {formatNumbers(this.state.currentContactNumber)}
                                                        </PopoverBody>
                                                    </Popover>
                                                </div>}
                                            <Modal
                                                isOpen={this.state.modal}
                                                toggle={this.newContactModaltoggle}
                                                onClosed={this.newContactModalOnClose}
                                            >
                                                <NewContactModal
                                                    toggle={this.newContactModaltoggle}
                                                    name={this.state.newContactName}
                                                    setName={this.setName}
                                                    number={this.state.currentContactNumber}
                                                    setNumber={() => { }}
                                                    addContactOnClick={this.addContactOnClick}
                                                    isNewContact={true}
                                                    disabledNumber={true}
                                                />
                                            </Modal>
                                        </div> :
                                        <GroupMsgHeader
                                            fromNum={`${formatNumbers(this.state.currentUserDidNumber)} ${lineName}`}
                                            toNum={`${formatNumbers(this.state.currentContactNumber)}`}
                                            patricipantsNumberArr={this.state.patricipantsNumberArr}
                                        />
                                    }
                                </div>
                                <div className='users-report-dashboard-details'>
                                    <div className='users-report-dashboard-users-list'>
                                        {this.returnUsersDetails()}
                                    </div>
                                    <div className='users-report-dashboard-users-number'>
                                        {this.renderUserConversations()}
                                    </div>
                                    <div className='users-report-dashboard-users-messages'>
                                        {this.state.isLoader
                                            ? <img
                                                style={{
                                                    height: '75px',
                                                    marginRight: 'auto',
                                                    marginLeft: 'auto'
                                                }}
                                                src='assets/spikkoloaderanimation.svg'
                                                alt=""
                                                className="loginImg d-flex" />
                                            : !this.state.noMessages ?
                                                <div className='usr-chat'>
                                                    {this.renderMessagesCyub()}
                                                </div>
                                                :
                                                <div className='user-sms-report-no-message'>
                                                    <p className='user-sms-report-no-message-text'>
                                                        {STRINGS.NO_MESSAGES}
                                                    </p>
                                                </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <React.Fragment />
        }
    }
}

export default UsersSmsReport;
