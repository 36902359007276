import React from 'react'
import './selectUsers.css';
import { CustomInput, Input } from 'reactstrap';
import * as STRINGS from '../../../strings/english';


function SelectUsers(props) {

    const boldObj = { fontWeight: 'bold' };
    const borderObj = {
        border: 'solid 1px rgba(36, 49, 63, 0.12)',
        marginTop: '1rem',
    }

    const returnUsersList = () => {
        let arr = props.users.map((item, index) => {
            return (
                <div
                    key={'select-users-user-list-' + index}
                    className='select-users-user-list'
                >
                    <CustomInput
                        type="checkbox"
                        id={"select-users-user-list-checkbox-" + index}
                        label={item.UserName}
                        onChange={(e) => { props.checkBoxOnChange(e, item) }}
                        checked={item.smsReportChecked || false}
                    />
                    {index + 1 !== props.users.length &&
                        <div className='select-users-user-list-bottom-line' />}
                </div>
            )
        });
        return arr;
    }

    const setStyle = (str) => {
        if (props.selectAllOrClearAllPressed === str) {
            return boldObj;
        }
        else return {};
    }

    return (
        <div>
            {props.type !== 'dropdown' &&
                <div>
                    <span className='select-users-date-header-1'>
                        {STRINGS.SELECT_USERS}
                    </span>
                    <span className='select-users-date-header-2'>
                        {` (${STRINGS.LEAVE_EMPTY_FOR_ALL_SMS})`}
                    </span>
                </div>}
            <div
                className='select-users-all-users-main'
                style={props.type !== 'dropdown' ? borderObj : {}}
            >
                <div className='select-users-all-users-buttons-main'>
                    <button
                        type='button'
                        className='select-users-all-users-button'
                        style={setStyle('sms_report_btn_select_all')}
                        onClick={props.selectAll}
                        id='sms_report_btn_select_all'
                    >
                        {STRINGS.SELECT_ALL}
                    </button>
                    <button
                        type='button'
                        className='select-users-all-users-button'
                        style={setStyle('sms_report_btn_clear_all')}
                        onClick={props.clearAll}
                        id='sms_report_btn_clear_all'
                    >
                        {STRINGS.CLEAR_ALL}
                    </button>
                </div>
                <div
                    className='select-users-search-input'
                >
                    <Input
                        onChange={(e) => {
                            props.setSearchValue(e.target.value);
                        }}
                        value={props.searchValue}
                    />
                </div>
                <div className='select-users-all-users-list'>
                    {returnUsersList()}
                </div>
            </div>
        </div>
    )
}

export default SelectUsers