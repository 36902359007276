import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormGroup,
    Form,
} from 'reactstrap';
import './index.css';
import { Link } from "react-router-dom";
import axios from "../../axios";
import * as STRINGS from '../../strings/english';

class PasswordExpiredModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPass: '',
            newPass: '',
            confirmPass: '',
            isLoader: false
        }

    }

    handleChange = (e) => {
        let value = e.target.value;
        switch (e.target.id) {
            case 'old-pass': {
                this.setState({ oldPass: value });
                break
            }
            case 'new-pass': {
                this.setState({ newPass: value });
                break
            }
            case 'confirm-pass': {
                this.setState({ confirmPass: value });
                break
            }
        }
    }

    passwordValidation = () => {
        if (this.state.oldPass == '' || this.state.newPass == '' || this.state.confirmPass == '') {
            alert('You have empty input.');
            return false;
        }
        else if (this.state.newPass !== this.state.confirmPass) {
            alert('Passwords do not match.');
            return false;
        }
        else return true;
    }

    changePasswordOnClick = () => {
        if (this.passwordValidation()) {
            this.setState({ isLoader: true })
            axios.post('/Provisioning/UpdateUserDetails/', {
                "username": localStorage.getItem('email'),
                "password": this.state.newPass,
                "oldPassword": this.state.oldPass
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                if (response.status === 200) {
                    axios.post('/Provisioning/Login', {
                        "username": localStorage.getItem('email'),
                        "password": this.state.newPass
                    })
                        .then(response => {
                            alert('Password changed successfully.');
                            let data = response.data;
                            localStorage.setItem('auth-data', data.HashedPassword);
                            this.setState({ isLoader: false});
                            this
                                .props
                                .history
                                .push('/', { mail: localStorage.getItem('email'), redirect: true })
                                this.props.passwordExpiredToggle();
                        })
                        .catch(error => {
                            this.setState({ isLoader: false });
                            alert(error.response
                                ? error.response.data.Description || error.response.data.Message : error);
                        });
                }
            }).catch(error => {
                this.setState({ isLoader: false });
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error);
            });
        }
    }

    render() {

        return (
            <div>
                <Modal
                    toggle={() => this.props.passwordExpiredToggle()}
                    isOpen={this.props.passwordExpired}
                    className={''}
                    backdrop={'static'}
                    size={'md'}
                >
                    <ModalHeader className="d-flex justify-content-center">
                        Your password is expired, <br />
                        please change password
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Input
                                    id={'old-pass'}
                                    type={"password"}
                                    onChange={this.handleChange}
                                    placeholder={'Old Password'} />
                                <Link
                                    style={{ marginTop: '0.5rem' }}
                                    className="float-left"
                                    to="/resetPassword">
                                    Forgot password?
                                    </Link>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    id={'new-pass'}
                                    type={"password"}
                                    onChange={this.handleChange}
                                    placeholder={'New Password'} />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    id={'confirm-pass'}
                                    type={"password"}
                                    onChange={this.handleChange}
                                    placeholder={'Confirm Password'} />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex' style={{ width: '100%', justifyContent: 'space-around' }}>
                            <Button
                                onClick={this.changePasswordOnClick}
                                className={'btnColor2 col-5'}
                                color={''}
                                style={{ color: 'white' }}
                            >
                                Change Password
                            </Button>
                            <Button
                                className='col-5'
                                onClick={() => this.props.modalCancel()}
                            >
                                {STRINGS.GO_BACK_TO_LOGIN}
                            </Button>
                        </div>
                    </ModalFooter>
                {this.state.isLoader ?
                    <img
                        style={{ width: '100px', marginLeft: 'auto', marginRight: 'auto' }}
                        src='assets/spikkoloaderanimation.svg'
                        alt="" 
                        className="position-absolute loginImg" />
                    : null}
                </Modal>
            </div>
        )
    }
}

export default PasswordExpiredModal;