import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledDropdown,
    ModalFooter
} from 'reactstrap';
import './advancedFeatures/advance.css';
import './index.css';
import FeatureService from "../../services/FeatureService";
import * as STRINGS from '../../strings/english';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import axios from '../../axios';
import { formatNumbers } from '../../services/formatPhoneNumber';

function CallerIdentification(props) {

    const [isLoader, setIsLoader] = useState(false);
    const [countries, setCountries] = useState([]);
    const [addNewNumber, setAddNewNumber] = useState(false);
    const [getSupportedDestinationsData, setGetSupportedDestinationsData] = useState([]);
    const [prefix, setPrefix] = useState(0);
    const [newNumber, setNewNumber] = useState('');
    const [callerIds, setCallerIds] = useState([]);

    useEffect(() => {
        getCountries();
        getCallerIds();
    }, [])
    
    function getCountries() {
        axios.get('/Utils/GetSupportedDestinations', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        })
            .then(response => {
                const countries = response.data.map((item => item.CountryName));
                setCountries(countries);
                setGetSupportedDestinationsData(response.data);
            })
            .catch(error => {
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    }

    function getCallerIds() {
        setIsLoader(true);
        FeatureService.callerIdsGetAllowedCLIs(props.userDetails.AccountId).then(res => {
            if (res.status === 200) {
                setIsLoader(false);
                setCallerIds(res.data);
            }
        }).catch(error => {
            setIsLoader(false);
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    }

    const save = () => {
        if (prefix && newNumber) {
            setIsLoader(true);
            let body = {
                accountid: props.userDetails.AccountId,
                cli: prefix + newNumber
            };
            FeatureService.callerIdsInsertAllowedCLI(body).then(res => {
                if (res.status === 200) {
                    getCallerIds();
                    closeAddNumber();
                }
            }).catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        }
        else alert(STRINGS.INPUT_MUST_BE_FILLED_OUT);
    };


    const deleteDivert = (index) => {
        setIsLoader(true);
        let body = {
            accountid: props.userDetails.AccountId,
            cli: callerIds[index].CLINumber
        };
        FeatureService.callerIdsRemoveAllowedCLI(body).then(res => {
            if (res.status === 200) {
                getCallerIds();
            }
        }).catch(error => {
            setIsLoader(false);
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    }


    function addNumber() {
        setAddNewNumber(true);
    }

    function closeAddNumber() {
        setAddNewNumber(false)
        setPrefix(0);
        setNewNumber('');
    }

    const renderTableBody = () => {
        let options = [];
        if (callerIds && callerIds.length !== 0) {
            callerIds.forEach((callerId, i) => {
                options.push(<tr key={`callerId-listRow${i}`} className='tr'>
                    <td>{formatNumbers(callerId.CLINumber)}</td>
                    <td>
                        <UncontrolledDropdown direction="down" style={{ lineHeight: '2rem' }}>
                            <DropdownToggle color={'white'} style={{ marginLeft: '8rem' }}>{''}
                                <img src='assets/combined-shape-copy-3.svg' alt="" className=" m-2" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    className=""
                                    onClick={() => deleteDivert(i)}>
                                    <img src='assets/icon-trash-copy-9.svg' alt="" className="" />
                                    {STRINGS.DELETE}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td>
                </tr>
                )
            })
        }
        return options;
    };

    function divertsTable() {
        return (
            <div>
                <table className="table">
                    <thead className="fs-12 gray thead">
                        <tr className="trh" >
                            <th>{STRINGS.NUMBER}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: "168px", minHeight: '108px' }} className="tbody aaBody">
                        {renderTableBody()}
                    </tbody>
                </table>
            </div>
        );
    }

    function onOptionClick(event, newValue) {
        if (newValue) {
            let selectedCountry = getSupportedDestinationsData.find(elm => elm.CountryName == newValue);
            setPrefix(selectedCountry.IntlPrefix);
        }
    }

    return <span>
        <ModalHeader
            style={{
                display: "flow-root",
            }}
        >
            <div className="fs-22">
                <div>{STRINGS.SET_YOUR_CALLER_IDENTIFICATION_CLI}</div>
                <div style={{ fontSize: '14px', marginTop: '1rem' }}>
                    {STRINGS.EDIT_CALLER_IDS_HEADER_TEXT}
                </div>
                <div style={{ fontSize: '15px', fontWeight: 'bold' }}>{`${STRINGS.YOU_CAN_ENTER_UP_TO_20_NUMBERS}.`}</div>
            </div>
        </ModalHeader>
        <ModalBody>
            <Form style={{ padding: "1.5rem  1rem" }}>

                {addNewNumber && <FormGroup className="background-lightGrey-2">

                    <FormGroup className='d-flex'>
                        <Col md={9}>
                            <Autocomplete
                                id="field3"
                                options={countries}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) =>
                                    onOptionClick(event, newValue)}
                                size="small"
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={`${STRINGS.CHOOSE_DESTINATION}...`}
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />}
                            />
                        </Col>
                        <Col md={3}>
                            <Input
                                onChange={(e) => setPrefix(e.target.value)}
                                value={prefix}
                                type='number'
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className='d-flex'>
                        <Col md={9}>
                            <Input
                                placeholder={`${STRINGS.NUMBER}...`}
                                onChange={(e) => setNewNumber(e.target.value)}
                                value={newNumber}
                                type='number'
                            />
                        </Col>
                    </FormGroup>

                    <Row>
                        <Col className="d-flex justify-content-end fs-14 mt-3">
                            <div className="mr-4 pointer" onClick={() => closeAddNumber()}>{STRINGS.CANCEL}</div>
                            <div className="mr-0 light-blue pointer"
                                onClick={() => save(true)}> {STRINGS.SAVE}</div>
                        </Col>
                    </Row>
                </FormGroup>}



                <FormGroup>
                    <div
                        className="d-flex justify-content-start"
                        style={{ width: "fit-content", cursor: "pointer" }}
                        onClick={() => addNumber(true)}>
                        <img className="ml-0" src="assets/plus-circle-btn.svg" alt="" />
                        <div className="ml-2 light-blue fs-14">{STRINGS.ADD_NUMBER}</div>
                    </div>
                </FormGroup>
                {isLoader ?
                    <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }}
                        src='assets/spikkoloaderanimation.svg'
                        alt=""
                        className="position-absolute" />
                    : null}

                <FormGroup style={{ height: "20%" }}>
                    {callerIds && callerIds.length > 0 ? divertsTable() : null}
                </FormGroup>

            </Form>
        </ModalBody>
        <ModalFooter style={{ display: "block" }}>
            <FormGroup row className="justify-content-end">
                <Col md={5}>
                    <Button 
                        className="btnColor2 fontColor fs-14 no-border" 
                        style={{ width: "100%", color: 'white' }}
                        size="lg"
                        onClick={() => props.cancelOnClick()}>{STRINGS.OK}</Button></Col>
            </FormGroup>
        </ModalFooter>
    </span>
}

export default CallerIdentification;