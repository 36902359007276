import { useState, useEffect } from "react";

function useAudioPlayer() {
    const [duration, setDuration] = useState();
    const [curTime, setCurTime] = useState();
    const [playing, setPlaying] = useState({ mode: false, src: "null" });
    const [editUrl, setEditUrl] = useState(false)
    const [clickedTime, setClickedTime] = useState();

    useEffect(() => {
        const audio = document.getElementById("audio");

        // state setters wrappers
        const setAudioData = () => {
            setDuration(audio.duration);
            setCurTime(audio.currentTime);
        }

        const setAudioTime = () => setCurTime(audio.currentTime);

        // DOM listeners: update React state on DOM events
        if (audio != null){


            audio.addEventListener("loadeddata", setAudioData);

            audio.addEventListener("timeupdate", setAudioTime);
        }


        if (editUrl) {
            if (playing.mode) {
                audio.setAttribute("src", playing.src)
                var playPromise = audio.play();
                if (!playPromise) {
                    playPromise
                        .then(_ => {
                            // Automatic playback started!
                            // Show playing UI.
                            console.log("audio played auto");
                        })
                        .catch(error => {
                            // Auto-play was prevented
                            // Show paused UI.
                            audio.currentTime = 0
                            console.log("playback prevented");
                        });

                }
            } else {
                audio.pause()
                audio.currentTime = 0
            }
            setEditUrl(false)
        }

        if (clickedTime && clickedTime !== curTime) {
            audio.currentTime = clickedTime;
            setClickedTime(null);
        }

        // effect cleanup
        return () => {
            if (audio != null){

                audio.removeEventListener("loadeddata", setAudioData);
                audio.removeEventListener("timeupdate", setAudioTime);

            }
        }
    });


    return {
        curTime,
        duration,
        playing,
        setPlaying,
        setClickedTime,
        setEditUrl
    }
}

export default useAudioPlayer;