import React, { useState, useEffect } from 'react';
import AccountDataService from '../../services/AccountService';
import Select from 'rc-select';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import 'rc-select/assets/index.css';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import './report.css';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Col,
    Row,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Form,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import Search from '../../components/Search';
import * as STRINGS from '../../strings/english';
import TranscriptionModal from './transcriptionModal';
import axios from '../../axios';
import NewContactModal from '../contacts/newContactModal';
import ReportTableRow from './reportTableRow';
import * as permissionFunc from '../../permissionScheme/permissionFunc';

let userLengeth = null;

function Report(props) {

    const initialDataState = {
        startFrom: setTimeForDate(calcDateFromToDayDateTo7DaysBefore(), "start"),
        endTo: setTimeForDate(new Date()),
        includedusers: props.isSpecificUser ? `${props.currentUser.UserId}` : ""
    }

    const [data, setData] = useState(initialDataState);

    useEffect(() => {

        if (userLengeth === null || data.includedusers.length !== userLengeth) {
            getCallsForUser()
            userLengeth = data.includedusers.length;
        }
    })
    useEffect(() => {
        getCallsForUser()
    }, [data])

    const [isSummaryModal, setIsSummaryModal] =  useState(false);
    const [isTranscriptionModalLoader, setTranscriptionModalIsLoader] = useState(false);
    const [isLoader, setIsLoader] = useState(false)
    const [reportMode, setReportMode] = useState(false);
    const initialPaginationState = {
        pageCount: 10,
        currentPage: 1
    }
    const [pagination, setPagination] = useState(initialPaginationState);
    const [response, setResponse] = useState([]);
    const [responseToShow, setResponseToShow] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [usersChecked, setUsersChecked] = useState(!props.isSpecificUser ? props.users.map((item, i) => {
        item.idd = i
        item.checked = false
        return item
    })
        : null)
    const [transcriptionModalOpen, setTranscriptionModalOpen] = useState(false);
    const [currentRowData, setCurrentRowData] = useState({});
    const [status, setStatus] = useState(null);
    const [transcriptionData, setTranscriptionData] = useState([]);
    const [allLanguages, setAllLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [showSecond, setShowSecond] = useState(false);
    const [newContactName, setnewContactName] = useState('');
    const [currentContactName, setCurrentContactName] = useState('');
    const [currentContactNumber, setCurrentContactNumber] = useState('');
    const [modal, setModal] = useState(false);


    const transcriptionFeature = props.parentUser.Features.TranscriptionFeature;
    const showTranscriptionIcon = transcriptionFeature.IsEnabled && transcriptionFeature.IsOnDemand && transcriptionFeature.IsVisibleInLog;
    const showSummaryIcon = transcriptionFeature.IsEnabled && transcriptionFeature.IsOnDemand && transcriptionFeature.IsVisibleInLog;


    const handelUsersChecked = (id) => {
        let newUsers = usersChecked.map(user =>
            Object.assign({}, user, { checked: user.idd === id ? !user.checked : user.checked }))
        setUsersChecked(newUsers)
        let users = newUsers.filter(u => u.checked).map(u => u.UserId.toString());
        handleInputChange("includedusers", users)
    }

    const handelAllUsersChecked = (boolean) => {
        let newUsers = usersChecked.map(user =>
            Object.assign({}, user, { checked: boolean }))
        setUsersChecked(newUsers)
        let users = newUsers.filter(u => u.checked).map(u => u.UserId.toString());
        handleInputChange("includedusers", users)
    };

    const handleInputChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const handleCheckboxChange = (e, id) => {
        let newArr = [...responseToShow];
        newArr.forEach(item => {
            if (item.idd === id) {
                item.checked = !item.checked
            }
            return item
        });
        setResponseToShow(newArr);
    };

    const handelPagination = (name, value) => {
        setPagination({ ...pagination, [name]: value })
        // createReport(responseToShow)
    }

    const checkboxAll = () => {
        setCheckAll(!checkAll)
        let res = responseToShow.map((item) => {
            item.checked = !checkAll
            return item
        });
        setResponseToShow(res)
    };

    const Checkbox = (props) => {
        return <input
            type="checkbox"
            id={`id_${props.id}`}
            defaultChecked={props.checked}
            label={props.label}
            className="aa"
            onChange={props.onChange} />
    };

    function setTimeForDate(date, status) {
        if (date) {
            if (status === "start") {
                date.setUTCHours(0)
                date.setUTCMinutes(0)
                date.setUTCSeconds(0)
                date.setUTCMilliseconds(0)
            } else {
                date.setUTCHours(23)
                date.setUTCMinutes(59)
                date.setUTCSeconds(59)
                date.setUTCMilliseconds(0)
            }
            return date
        }
    }

    const getCallsForUser = () => {
        setIsLoader(true);
        setReportMode(false)
        let body = {
            startFrom: data.startFrom,
            endTo: data.endTo,
            includedusers: data.includedusers,
            GMTOffset: -1 * new Date().getTimezoneOffset()
        };
        AccountDataService.getCallsForUsers(body)
            .then(response => {
                setIsLoader(false);
                let res = response.data.map((item, i) => {
                    item.checked = false
                    item.idd = i
                    return item;
                })
                setResponse(res)
                setResponseToShow(res)
                setReportMode(true)
            })
            .catch(e => {
                console.log(e)
            });
    };

    const downloadCsv = async () => {
        const { Parser } = require('json2csv');
        const parser = new Parser();
        const checked = responseToShow.filter((item) => { return item.checked === true }).map((item) => {
            delete item.checked
            delete item.idd
            return item
        });
        if (checked.length > 0) {
            const csv = parser.parse(checked);
            var fileDownload = require('js-file-download');
            fileDownload(csv, `user-calls-${new Date().toISOString()}.csv`);
        } else {
            alert("No file creation information, you must select fields.")
        }

    };

    function firstDayPreviousMonth(originalDate) {
        let d = new Date(originalDate);
        d.setDate(0); // set to last day of previous month
        d.setDate(1); // set to the first day of that month
        return d;
    };

    function calcDateFromToDayDateTo7DaysBefore() {
        let days = 7; // Days you want to subtract
        let date = new Date();
        let last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
        return last;
    }

    function diffMinutes(end, start) {
        if (end && start) {
            let diff = (new Date(end).getTime() - new Date(start).getTime()) / 1000;
            let result = diff /= 60;
            if (0 < result < 1) {
                return 1
            } else {
                return Math.abs(Math.round(diff));

            }
        }
    };

    const select = () => {
        return (
            <FormGroup style={{ width: "200px" }} className="mr-2">
                <h6 htmlFor="select" className="gray"><b>Select users</b></h6>
                <UncontrolledDropdown className="border-5 form-control" style={{ padding: '0px' }}>
                    <DropdownToggle style={{ width: "100%", height: '100%', textAlign: 'left' }} color={'white'} >
                        {responseToShow ?
                            <div className="p-2 pt-0 fs-10" >
                                {checkAll ? STRINGS.ALL_USERS_SELECTED : checkedFilter() > 0 ?
                                    `${checkedFilter()} ${STRINGS.USERS_SELECTED}` : ''}
                            </div> : null}
                    </DropdownToggle>
                    <DropdownMenu>
                        <div className="d-flex" style={{ backgroundColor: "#fbfbfb" }}>
                            <div>
                                <div
                                    className="ml-2 cursorPointer"
                                    onClick={() => handelAllUsersChecked(true)}
                                >
                                    <b>Select all</b>
                                </div>
                            </div>
                            <div
                                className="mr-2 ml-auto cursorPointer"
                                onClick={() => handelAllUsersChecked(false)}>
                                Clear all</div>
                        </div>
                        {usersChecked.map(item => {
                            return (

                                <Row key={item.idd} className='borderButtom' style={{ width: "450px" }}>
                                    <ul>
                                        <Checkbox
                                            onChange={() => handelUsersChecked(item.idd)}
                                            id={`id_${item.UserId}`}
                                            checked={item.checked}
                                            label={item.UserName}
                                        />
                                        {`   ${item.UserName}`}
                                    </ul>
                                </Row>

                            )
                        })}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FormGroup>
        )
    }

    const createReport = (dataArray) => {
        if (reportMode) {
            let x = pagination.currentPage;
            let y = pagination.pageCount;
            let max = dataArray.length > (x * y) ? (x * y) : dataArray.length;
            const currentArray = dataArray.slice((x - 1) * y, max)
            return currentArray.map((cellData, i) => {
                const rowId = dataArray.findIndex(e => e.CallId === cellData.CallId);
                return (
                    <ReportTableRow
                        key={`row-#-${rowId}`}
                        index={i}
                        rowId={rowId}
                        cellData={cellData}
                        showSecond={showSecond}
                        isSpecificUser={props.isSpecificUser}
                        setCurrentRowData={setCurrentRowData}
                        currentContactName={currentContactName}
                        setCurrentContactName={setCurrentContactName}
                        handleCheckboxChange={handleCheckboxChange}
                        showTranscriptionIcon={showTranscriptionIcon}
                        showSummaryIcon={showSummaryIcon}
                        getCallTranscription={getCallTranscription}
                        getCallSummary={getCallSummary}
                        newContactModaltoggle={newContactModaltoggle}
                        setCurrentContactNumber={setCurrentContactNumber}
                        modal={modal}
                    />
                );
            })
        }
    };

    const getCallTranscription = (cellData) => {
        setIsSummaryModal(false);
        setTranscriptionModalIsLoader(true);
        let body = {
            "accountId": props.currentUser.AccountId,
            "callId": cellData.CallId
        }
        axios.post('Call/GetCallTranscription', body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        })
            .then(response => {
                if (response) {
                    if (response.data == null) {
                        setStatus(null);
                        axios.get('Provisioning/GetTranscribeLanguages', {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                            }
                        }).then(response => {
                            if (response && response.data) {
                                setAllLanguages(response.data);
                                setSelectedLanguage(response.data[0]);
                                setTranscriptionModalOpen(!transcriptionModalOpen);
                                setTranscriptionModalIsLoader(false);
                            }
                        })
                            .catch(error => {
                                setTranscriptionModalIsLoader(false);
                                alert(error.response
                                    ? error.response.data.Description || error.response.data.Message : error)
                            });
                    }
                    else if (response.data) {
                        setTranscriptionModalIsLoader(false);
                        setStatus(response.data.Status);
                        setTranscriptionData(response.data);
                        setTranscriptionModalOpen(!transcriptionModalOpen);
                    }
                }
            })
            .catch(error => {
                setTranscriptionModalIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });

    }

    const getCallSummary = (cellData) => {
        setIsSummaryModal(true);
        setTranscriptionModalIsLoader(true);
        let body = {
            "accountId": props.currentUser.AccountId,
            "callId": cellData.CallId
        }
        axios.post('Call/GetCallSummary', body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        })
            .then(response => {
                if (response) {
                    if (response.data == null) {
                        setStatus(null);
                        setTranscriptionModalOpen(!transcriptionModalOpen);
                        setTranscriptionModalIsLoader(false);
                    }
                    if (response.data) {
                        setTranscriptionModalIsLoader(false);
                        setStatus(response.data.Status);
                        setTranscriptionData(response.data);
                        setTranscriptionModalOpen(!transcriptionModalOpen);
                    }
                }
            })
            .catch(error => {
                setTranscriptionModalIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });

    }

    const goToFirstPage = () => {
        setPagination({
            pageCount: 10,
            currentPage: 1
        })
    }

    const checkedFilter = () => {
        let numOfChecked = usersChecked.filter(item => item.checked == true);
        return numOfChecked.length;
    }

    const selsctMinOrSec = () => {
        setShowSecond(!showSecond)
    }

    const newContactModalOnClose = () => {
        setnewContactName('');
    }

    const newContactModaltoggle = () => {
        setModal(!modal);
    }

    const addContactOnClick = () => {
        if (newContactName) {
            const data = {
                "ContactName": newContactName,
                "ContactNumber": currentContactNumber,
            }
            axios.post(`/Account/InsertUserContact`, data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(res => {
                newContactModaltoggle();
                getCallsForUser();
            }).catch(err => {
                console.log(err);
            })
        } else alert(STRINGS.MISSING_DETAILS)
    }

    return (<div>
        <ModalHeader className="no-border block" >
            <div className="d-flex justify-content-between v-center">
                {props.isSpecificUser
                    ? <div className="start poppins-regular">
                        User <b>{props.currentUser.UserName}</b> call report</div>
                    : <div>Users call report</div>}
                <div>
                    {createReport.length > 0 ?

                        <Button
                            onClick={() => downloadCsv()}
                            color=""
                            style={{
                                color: 'white',
                                // width: "185px",
                                // marginLeft: '30%'
                            }}
                            className="btnColor2 mr-2">
                            <img src='assets/export.svg' alt="" className="mr-1" /> Download as csv
                        </Button> : null}
                </div>
            </div>
        </ModalHeader>
        <hr />
        <ModalBody>
            <FormGroup>
                <Form>
                    <div>
                        <div>

                            <Row>
                                <Col className="d-flex flex-row " >
                                    <FormGroup className="mr-2" >
                                        <h6 htmlFor="from" className="gray">From</h6>
                                        <div className='border-52 form-control'>
                                            <DatePicker
                                                id="from"
                                                className="no-border2"
                                                selected={data.startFrom}
                                                onChange={(date) => {
                                                    handleInputChange("startFrom", setTimeForDate(date, "start"))
                                                }}
                                                strictParsing
                                            />
                                            <img src='assets/calendar.svg' alt="" />
                                        </div>
                                        {responseToShow ? <div className="p-2 pt-0 fs-10" >{`${responseToShow.length} result found`}</div> : null}
                                        {/* {responseToShow ? <div className="p-2 pt-0 fs-10" >
                                            {checkAll ? STRINGS.ALL_USERS_SELECTED : checkedFilter() > 0 ? 
                                                `${checkedFilter()} ${STRINGS.USERS_SELECTED}` : ''}
                                            </div> : null}                                         */}


                                    </FormGroup>
                                    <FormGroup className="mr-2">
                                        <h6 htmlFor="to" className="gray">To</h6>
                                        <div className='border-52 form-control'>
                                            <DatePicker
                                                id="to"
                                                className="no-border2"
                                                selected={data.endTo}
                                                onChange={(date) => {
                                                    handleInputChange("endTo", setTimeForDate(date))
                                                }}
                                                strictParsing
                                            />
                                            <img src='assets/calendar.svg' alt="" />
                                        </div>
                                    </FormGroup>

                                    {usersChecked ? select(props.users) : null}

                                    {reportMode ? <FormGroup className="mr-2" style={{ marginTop: "26px" }}>
                                        <Search
                                            data={response}
                                            action={(array) => { setResponseToShow(array); goToFirstPage() }}
                                            keys={["Source", "SentCli", "StartCallUTC", "Destination", "Username", "DID"]} />
                                    </FormGroup> : null}
                                </Col>
                                <Col className=" d-flex justify-content-end col-3">
                                    <FormGroup>
                                        <Label htmlFor="pagination" className="gray"></Label>
                                        <UncontrolledDropdown id="pagination" className="poppins-regular">
                                            Number of entries&nbsp;&nbsp;
                                            <DropdownToggle className="border mr-2" color={'white'} caret>
                                                {pagination.pageCount}&nbsp;&nbsp;&nbsp;&nbsp;
                                            </DropdownToggle>
                                            <DropdownMenu>{[10, 25, 50, 100].map((number, i) => {
                                                return <DropdownItem
                                                    key={i}
                                                    onClick={e => handelPagination("pageCount",
                                                        parseInt(e.currentTarget.textContent))}>
                                                    {number}
                                                </DropdownItem>
                                            })}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isTranscriptionModalLoader && <img
                                style={{
                                    width: '60px',
                                    height: '50px',
                                    margin: 'auto'
                                }}
                                src='assets/spikkoloaderanimation.svg'
                                alt=""
                                className="loginImg d-flex"
                            />}
                        </div>
                        <FormGroup>
                            <Button
                                className='min-sec-formgroup-main'
                                onClick={selsctMinOrSec}
                                size='sm'
                                outline
                            >
                                {`${STRINGS.SHOW_THE_DURATION_IN} 
                                ${showSecond ? STRINGS.MINUTES : STRINGS.SECONDS}`}
                            </Button>
                        </FormGroup>
                        {reportMode ?
                            <Table className="tableBorder">
                                <thead className="gray fs-12" style={{ backgroundColor: "#fbfbfb" }}>
                                    <tr>
                                        <th>{<Checkbox
                                            onChange={() => checkboxAll()}
                                            checked={checkAll}
                                        />}</th>
                                        <th>Date</th>
                                        {!props.isSpecificUser ? <th>User</th> : null}
                                        <th>Mobile2CRM Number</th>
                                        <th>Source</th>
                                        <th>Destination</th>
                                        <th>Answered By</th>
                                        <th>
                                            {`${showSecond ? STRINGS.SECONDS : STRINGS.MINUTES}`}
                                        </th>
                                        <th>Type</th>
                                        <th>Call Recording</th>
                                        <th>Voice Mail</th>
                                        <th>{STRINGS.TRANSCRIPTION}</th>
                                        {!permissionFunc.changeDisplay('call_summary') ? <th>{STRINGS.SUMMARY}</th> : null}
                                        <th>Deleted</th>
                                    </tr>
                                </thead>
                                <tbody className="fs-14">
                                    {createReport(responseToShow)}
                                </tbody>
                            </Table>
                            : null}
                        <Modal
                            isOpen={modal}
                            toggle={newContactModaltoggle}
                            onClosed={newContactModalOnClose}
                        >
                            <NewContactModal
                                toggle={newContactModaltoggle}
                                name={newContactName}
                                setName={setnewContactName}
                                number={currentContactNumber}
                                setNumber={() => { }}
                                addContactOnClick={addContactOnClick}
                                isNewContact={true}
                                disabledNumber={true}
                            />
                        </Modal>
                    </div>
                </Form>
            </FormGroup>
            {isLoader
                ? <img
                    style={{
                        width: '60px',
                        height: '50px',
                        margin: 'auto'
                    }}
                    src='assets/spikkoloaderanimation.svg'
                    alt=""
                    className="loginImg d-flex" />
                : null}
        </ModalBody>
        {reportMode ?
            <Pagination
                className='pagination-new'
                style={{
                    marginLeft: "28vw",
                    marginTop: "2vh",
                }}
                selectComponentClass={Select}
                pageSize={pagination.pageCount}
                defaultPageSize={pagination.pageCount}
                defaultCurrent={pagination.currentPage}
                current={pagination.currentPage}
                onShowSizeChange={pageCount => handelPagination("pageCount", pageCount)}
                onChange={currentPage => handelPagination("currentPage", currentPage)}
                locale={localeInfo}
                total={responseToShow ? responseToShow.length : 0}
            /> : null}
        <Modal
            isOpen={transcriptionModalOpen}
            toggle={() => setTranscriptionModalOpen(!transcriptionModalOpen)}
            style={{
                width: status == null ? '47.125rem' : '26.813rem',
                marginTop: '10%',
                maxWidth: '1300px'
            }}
        >
            {<TranscriptionModal
                userData={props.parentUser}
                rowData={currentRowData}
                cancelOnClick={() => setTranscriptionModalOpen(!transcriptionModalOpen)}
                status={status}
                isSummaryModal={isSummaryModal}
                transcriptionData={transcriptionData}
                allLanguages={allLanguages}
                selectedLanguage={selectedLanguage}
            />}
        </Modal>
    </div>

    );
}


export default Report;