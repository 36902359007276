import React, { useState, useEffect, useRef } from 'react';
import AccountService from "../../services/AccountService";
import './index.css';
import SideBar from '../../components/sideBar';
import NavBar from '../../components/navBar';
import * as STRINGS from '../../strings/english';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import Select from 'rc-select';
import '../dashboard/report.css';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Col,
    FormGroup,
    Label,
    Tooltip,
} from 'reactstrap';
import {
    formatNumbers,
    formatInputValueWithout,
    convertFormatNumToRegularNum
} from '../../services/formatPhoneNumber';
import axios from '../../axios';
import { isJsonString } from '../../services/tools';
import Timer from './timer';
import { eventEnum, displayModeEnum } from './enum';
import { changeDisplay } from '../../permissionScheme/permissionFunc';
import SplitTable from './splitTable';
import DiceDisplay from './diceDisplay';
import RtmIconsTooltips from './rtmIconsTooltips';


function RealTimeMonitoring(props) {
    const [user, setUser] = useState(null);
    const [responseToShow, setResponseToShow] = useState([]);
    const [monitorCallback, setMonitorCallback] = useState('');
    const initialPaginationState = {
        pageCount: 10,
        currentPage: 1
    }
    const [pagination, setPagination] = useState(initialPaginationState);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [originalRes, setOriginalRes] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [listening, setListening] = useState(false);
    const [errorInSSE, setErrorInSSE] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [sseStatus, setSseStatus] = useState('...');
    const [statusColor, setStatusColor] = useState('');
    const [displayMode, setDisplayMode] = useState(displayModeEnum.TABLE);
    const [newCall, setNewCall] = useState({});

    const originArr = useRef(originalRes);
    originArr.current = originalRes;

    const responseToShowArr = useRef(responseToShow);
    responseToShowArr.current = responseToShow;

    const es = useRef(null);
    const authorization = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
        }
    }

    useEffect(() => {
        async function fetchData() {
            setIsLoader(true);
            const res = await AccountService.getUserByUsername(localStorage.getItem('email'));
            setUser(res.data);
            await getActiveCallsForOrg(res.data);
            if (!listening && !changeDisplay('real_time_monitoring_register_to_sse')) {
                setIsLoader(false);
                setSseStatus('connecting');
                setStatusColor('orange');
                const data = JSON.stringify({
                    username: localStorage.getItem('email'),
                    token: localStorage.getItem('auth-data'),
                    userId: res.data.UserId,
                });
                es.current = new EventSource(
                    `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_SERVER_PORT}/events/registration_management?data=${data}`
                );

                setListening(true);

                es.current.onopen = () => {
                    console.log('SSE opened!');
                    setErrorInSSE(false);
                    setSseStatus('Connected');
                    setStatusColor('green');
                }

                es.current.onerror = (err) => {
                    console.log('SSE error!');
                    console.log(err);
                    setErrorInSSE(true);
                    setSseStatus('Error');
                    setStatusColor('red');
                }

            }
        }
        fetchData();

        return () => {
            if (!es.current) return;
            console.log('SSE closed!');
            es.current.close();
        };
    }, [])

    useEffect(() => {
        if (!es.current) return;
        es.current.onmessage = (event) => {
            if (isJsonString(event.data)) {
                const parsedData = JSON.parse(event.data);
                setNewCall(parsedData);
                if (parsedData.EventName === eventEnum.CALL_IN ||
                    parsedData.EventName === eventEnum.CALL_OUT) {
                    setTimeout(() => {
                        cleanEventsFromScreen(parsedData);
                    }, parseInt(process.env.REACT_APP_TIME_TO_DELETE_AN_EVENT));
                }
                const index = responseToShow.findIndex(s => s.CallId === parsedData.CallId);
                if (index > -1) {
                    let newArr = [...responseToShow];
                    newArr[index] = parsedData;
                    setResponseToShow(newArr);
                    setOriginalRes(newArr);
                } else {
                    setResponseToShow((elm) => elm.concat(parsedData));
                    setOriginalRes((elm) => elm.concat(parsedData));
                }
            }
        };

    }, [responseToShow, originalRes, sseStatus])

    const cleanEventsFromScreen = (event) => {
        eventsFilter(responseToShowArr.current, setResponseToShow, event);
        eventsFilter(originArr.current, setOriginalRes, event);
    }

    const eventsFilter = (arr, setArr, event) => {
        let tempArr = [...arr];
        let filterArr = tempArr.filter(elm => elm.CallId !== event.CallId);
        setArr(filterArr);
    }

    const getActiveCallsForOrg = async (user) => {
        setIsLoader(true);
        axios.get(`Call/GetActiveCallsForOrg/${user.AccountId}`, authorization)
            .then(response => {
                setIsLoader(false);
                let activeCallsArr = response.data;
                setOriginalRes(activeCallsArr);
                setResponseToShow(activeCallsArr);
            })
    }

    const signOut = () => {
        localStorage.clear();
        props.history.push('/login')
    }

    const createReport = () => {
        let x = pagination.currentPage;
        let y = pagination.pageCount;
        let max = responseToShow.length > (x * y) ? (x * y) : responseToShow.length;
        const currentArray = responseToShow.slice((x - 1) * y, max);
        let arr = [];
        if (currentArray.length > 0) {
            for (let index = 0; index < currentArray.length; index++) {
                let date = new Date(currentArray[index].StartCall + 'Z').toLocaleString('en-GB');
                let showMonitorButton = currentArray[index].DIDNumber !== 'Monitor';
                arr.push(
                    <div
                        key={currentArray[index].CallId}
                    >
                        <div className='rtm-table-row' style={drawRow(currentArray[index].EventName)}>
                            <div style={{ width: '11%', paddingLeft: '2.8rem' }}>
                                {date.split(',')[1].trim()}
                            </div>
                            <div
                                style={{ width: '16%' }}
                                className='rtm-table-username-cell'
                            >
                                {currentArray[index].Username}
                            </div>
                            <div style={{ width: '12%' }}>{formatNumbers(currentArray[index].DIDNumber)}</div>
                            <div style={{ width: '13%' }}>{formatNumbers(currentArray[index].OriginalCli)}</div>
                            <div style={{ width: '13.5%' }}>{formatNumbers(currentArray[index].Destination)}</div>
                            <div style={{
                                width: '10%',
                                position: 'relative',
                                bottom: '5px',
                                left: '10px',
                            }}>
                                {<img
                                    src={`assets/${currentArray[index].Direction === eventEnum.IN ? "incoming" : "outgoing"}.svg`}
                                    alt="direction-icon"
                                />}
                            </div>
                            <div style={{ width: '9.5%' }}>
                                {currentArray[index].EventName === eventEnum.ANSWERCALL_IN ||
                                    currentArray[index].EventName === eventEnum.ANSWERCALL_OUT ||
                                    currentArray[index].EventName === eventEnum.CALL_IN ||
                                    currentArray[index].EventName === eventEnum.CALL_OUT ?
                                    <Timer status={currentArray[index].EventName} /> : '-'}
                            </div>
                            <div style={{ width: '8.5%' }}>
                                {currentArray[index].EventName ? currentArray[index].Status : '-'}
                            </div>
                            {showMonitorButton &&
                                <div
                                    style={{
                                        width: '3px',
                                        position: 'relative',
                                        bottom: '13px',
                                    }}
                                    onClick={() => monitorCallbackOnClick(currentArray[index])}
                                >
                                    <div className='rtm-silent-monitor-mouse-over'>
                                        <img className='rtm-silent-monitor-inner-icon' src='assets/silent-monitor-inner-icon.svg' />
                                        <img className='rtm-silent-monitor-icon' src='assets/silent-monitoricon-icon.svg' />
                                    </div>
                                </div>}
                        </div>
                        {index !== currentArray.length - 1 &&
                            <div className='rtm-table-row-line' />}
                    </div>
                )
            }
        }
        return arr;
    };

    const drawRow = (str) => {
        let color = '';
        switch (str) {
            case eventEnum.STARTCALL_IN:
            case eventEnum.STARTCALL_OUT:
                color = '#98fb98';
                break;
            case eventEnum.ANSWERCALL_IN:
            case eventEnum.ANSWERCALL_OUT:
                color = '#7fff00';
                break;
            case eventEnum.CALL_IN:
            case eventEnum.CALL_OUT:
                color = '';
                break;
            default:
                break;
        }
        return {
            backgroundColor: color,
        }
    }

    const handelPagination = (name, value) => {
        setPagination({ ...pagination, [name]: value });
    }

    const filterUserByEmail = (event) => {
        setSearchInputValue(event.target.value);
    }

    const searchIconOnClick = () => {
        let tempArr = [...originalRes];
        let filterArr = tempArr.filter(elm => elm.Username.includes(searchInputValue));
        setResponseToShow(filterArr);
    }

    const monitorCallbackOnClick = (call) => {
        if (monitorCallback) {
            setIsLoader(true);
            let body = {
                "callid": call.CallId,
                "callbacknumber": convertFormatNumToRegularNum(monitorCallback),
            }
            axios.post('/Call/StartMonitorActiveCall', body, authorization)
                .then(response => {
                    setIsLoader(false);
                    if (response) {
                        alert(STRINGS.ANSWER_CALL_TO_MONITOR);
                    }
                })
                .catch(error => {
                    setIsLoader(false);
                    alert(STRINGS.NOT_AUTHORIZED);
                        //error.response
                        //? error.response.data.Description || error.response.data.Message : error)
                });
        } else {
            const input = document.getElementById('rtm-number-input-div');
            input.style.boxShadow = '0 0 4px 4px #2EC2FE';
            setTimeout(() => {
                input.style.boxShadow = '';
            }, 5000);
            setTimeout(() => {
                alert(STRINGS.FILL_THE_MONITOR_CALLBACK_NUMBER);
            }, 100);
        }
    }

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const changeDisplayIconsOnClick = (e) => {
        switch (e.target.id) {
            case 'rtm-change-display-table-icon':
                setDisplayMode(displayModeEnum.TABLE);
                handelPagination("pageCount", 10);
                break;
            case 'rtm-change-display-layout-split-icon':
                handelPagination("pageCount", 20);
                setDisplayMode(displayModeEnum.SPLIT_TABLE);
                break;
            case 'rtm-change-display-grid-icon':
                handelPagination("pageCount", 25);
                setDisplayMode(displayModeEnum.BOXES);
                break;

            default:
                break;
        }
    }

    const multiTooltip = () => {
        return (
            <React.Fragment>
                {[
                    {
                        placement: 'top',
                        hover: displayMode === displayModeEnum.TABLE,
                        id: 'rtm-change-display-table-icon',
                        imgName: 'rtm-table-view-icon',
                        alt: 'table-view-icon',
                        content: 'Table',
                    },
                    {
                        placement: 'top',
                        hover: displayMode === displayModeEnum.SPLIT_TABLE,
                        id: 'rtm-change-display-layout-split-icon',
                        imgName: 'rtm-layout-split',
                        alt: 'layout-split-icon',
                        content: 'Split',
                    },
                    {
                        placement: 'top',
                        hover: displayMode === displayModeEnum.BOXES,
                        id: 'rtm-change-display-grid-icon',
                        imgName: 'grid-3x3-gap',
                        alt: 'grid-icon',
                        content: 'Grid',
                    },
                ].map((tooltip, i) => {
                    return (
                        <RtmIconsTooltips
                            key={`rtmIconsTooltips-${i}`}
                            item={tooltip}
                            changeDisplayIconsOnClick={changeDisplayIconsOnClick}
                            className='rtm-change-display-icon-zoom-over'
                        />
                    );
                })}
            </React.Fragment>
        );
    }

    return (
        <div>
            <div className="d-flex">
                <SideBar
                    active1={false}
                    active2={false}
                    active3={false}
                    active4={false}
                    active5={false}
                    active6={true}
                    active7={false}
                    active8={false}
                    homePage={() => props.history.push('/', { redirect: true })}
                    myPackagePage={() => props.history.push('/myPackages', { redirect: true })}
                    settingPage={() => props.history.push('/settings')}
                    crm_integration={() => props.history.push('/crm_integration')}
                    contactUs={() => props.history.push('/contactUs')}
                    realTimeMonitoring={() => props.history.push('/real_time_monitoring')}
                    graph={() => props.history.push('/charts')}
                    contacts={() => props.history.push('/contacts')}
                    user={user}
                />
                <div className="container-fluid" style={{ height: "100vh", overflow: "auto" }}>
                    <NavBar
                        logo={user ? user.LogoURL : ""}
                        signOut={() => signOut()}
                        mail={localStorage.getItem('email')}
                        title={STRINGS.REAL_TIME_MONITORING}
                        homePage={() => { }} />
                    <div className='d-flex'>
                        <div className='col-3 pl-0'>
                            <label className='rtm-ff-poppins rtm-input-label-header'>{STRINGS.SEARCH_BY_USER}</label>
                            <div className='border-5 form-control'>
                                <input
                                    autoComplete={'chrome-off'}
                                    style={{ outline: 'none', width: '96%' }}
                                    name="rtm-search-input"
                                    id="rtm-search-input"
                                    type='text'
                                    placeholder={`${STRINGS.USER_EMAIL}...`}
                                    className='border-0 h-100 px-0'
                                    onChange={(e) => filterUserByEmail(e)} />
                                <label
                                    htmlFor="rtm-search-input"
                                    className='position-absolute cursorPointer'
                                    onClick={searchIconOnClick}>
                                    <img src='assets/search-outline.svg' alt="" />
                                </label>
                            </div>
                        </div>
                        <div className='col-2'>
                            <label className='rtm-ff-poppins rtm-input-label-header'>{STRINGS.MONITOR_CALLBACK}</label>
                            <div
                                className='border-5 form-control'
                                id='rtm-number-input-div'
                            >
                                <input
                                    id="rtm-number-input"
                                    autoComplete={'chrome-off'}
                                    style={{ outline: 'none' }}
                                    type='text'
                                    className='border-0 h-100 px-0 w-100 rtm-monitor-callback-input'
                                    onChange={(e) => setMonitorCallback(e.target.value)}
                                    value={formatInputValueWithout(monitorCallback)}
                                />
                            </div>
                        </div>
                        {!changeDisplay('real_time_monitoring_register_to_sse') &&
                            <div className='rtm-sse-status-main'>
                                <div
                                    className='rtm-status-circle'
                                    id={'rtm-status-tooltip'}
                                    style={{
                                        backgroundColor: statusColor,
                                    }}
                                />
                                <Tooltip
                                    placement={'left'}
                                    isOpen={tooltipOpen}
                                    target={'rtm-status-tooltip'}
                                    toggle={toggle}
                                >
                                    {sseStatus}
                                </Tooltip>
                            </div>}
                        {errorInSSE &&
                            <div className='rtm-error-in-sse-msg'>
                                <h4 className='rtm-error-in-sse-msg-h4'>
                                    {STRINGS.TRYING_TO_RECONNECT_TO_REAL_TIME_EVENTS}
                                </h4>
                            </div>}
                        {changeDisplay('real_time_monitoring_register_to_sse') &&
                            <Button className="btnColor2 white fs-14 no-border btnColor2"
                                size="lg"
                                style={{ height: '2.375rem', marginTop: 'auto', marginLeft: 'auto' }}
                                onClick={() => getActiveCallsForOrg(user)}
                            >
                                <img
                                    src='assets/get-current-status-icon.svg'
                                    style={{ marginRight: '0.313rem', marginBottom: '0.1rem' }}
                                />
                                {STRINGS.GET_CURRENT_STATUS}
                            </Button>}
                    </div>
                    <div className='rtm-change-display-icons-div'>
                        {multiTooltip()}
                    </div>
                    {isLoader
                        ? <img
                            style={{
                                width: '100px', height: 'auto', right: '50%', zIndex: '100'
                            }}
                            src='assets/spikkoloaderanimation.svg'
                            alt=""
                            className="position-absolute" />
                        : null}
                    {originalRes ?
                        <React.Fragment>
                            {displayMode === displayModeEnum.TABLE &&
                                <div className='rtm-table-main'>
                                    <div className='ttm-table-header'>
                                        <div style={{ width: '9%' }}>{STRINGS.TIME}</div>
                                        <div style={{ width: '17%' }}>{STRINGS.USER}</div>
                                        <div style={{ width: '13%' }}>{STRINGS.SPIKKO_NUMBER}</div>
                                        <div style={{ width: '13%' }}>{STRINGS.SOURCE}</div>
                                        <div style={{ width: '14%' }}>{STRINGS.DESTINATION}</div>
                                        <div style={{ width: '10%' }}>{STRINGS.DIRECTION}</div>
                                        <div style={{ width: '10%' }}>{STRINGS.CALL_DURATION}</div>
                                        <div style={{ width: '8%' }}>{STRINGS.STATUS}</div>
                                        <div style={{ width: '8%' }}>{STRINGS.SILENT_MONITOR}</div>
                                    </div>
                                    {createReport()}
                                </div>}
                            {displayMode === displayModeEnum.SPLIT_TABLE &&
                                <SplitTable
                                    pagination={pagination}
                                    responseToShow={responseToShow}
                                    monitorCallbackOnClick={monitorCallbackOnClick}
                                    drawRow={drawRow}
                                />}

                            {displayMode === displayModeEnum.BOXES &&
                                <DiceDisplay
                                    pagination={pagination}
                                    responseToShow={responseToShow}
                                    monitorCallbackOnClick={monitorCallbackOnClick}
                                    drawRow={drawRow}
                                    newCall={newCall}
                                />}
                        </React.Fragment>
                        : null}
                    <Pagination className="pagination-new"
                        style={{
                            marginLeft: "40%",
                            marginRight: "auto",
                            marginTop: "2vh",
                            maxWidth: "max-content"
                        }}
                        current={pagination.currentPage}
                        selectComponentClass={Select}
                        defaultPageSize={10}
                        pageSize={pagination.pageCount}
                        defaultCurrent={1}
                        onShowSizeChange={pageCount => handelPagination("pageCount", pageCount)}
                        onChange={currentPage => handelPagination("currentPage", currentPage)}
                        locale={localeInfo}
                        total={responseToShow ? responseToShow.length : 0}
                    />
                    {displayMode === displayModeEnum.TABLE &&
                        <Col className="col-3">
                            <FormGroup>
                                <Label htmlFor="pagination" className="gray"></Label>
                                <UncontrolledDropdown id="pagination" className="poppins-regular">
                                    <span className='rtm-ff-poppins'>
                                        {STRINGS.NUMBER_OF_ENTRIES}&nbsp;&nbsp;
                                    </span>
                                    <DropdownToggle className="border mr-2" color={'white'} caret>
                                        {pagination.pageCount}&nbsp;&nbsp;&nbsp;&nbsp;
                                    </DropdownToggle>
                                    <DropdownMenu style={{ width: '3.125rem' }}>
                                        {[10, 25, 50, 100].map((number, i) => {
                                            return <DropdownItem
                                                key={i}
                                                onClick={e => handelPagination("pageCount",
                                                    parseInt(e.currentTarget.textContent))}>
                                                {number}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </FormGroup>
                        </Col>}
                </div>
            </div>
        </div>
    )
}

export default RealTimeMonitoring;
