import { Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import Dashbord from './containers/dashboard'
import Login from './containers/login'
import ResetPassword from './containers/login/resetPassword'
import EnterPassword from './containers/login/enterPassword'
import CreatCount from './containers/login/createCcount'
import SelectPackage from './containers/login/selectPackage'
import SelectPayment from './containers/login/selectPayment'
import EnterCode from './containers/login/enterCode'
import TwoFactor from './containers/login/twoFactor'
import Success from './containers/login/success'
import Settings from './containers/settings'
import ContactUs from './containers/contactUs'
import CRMIntegration from './containers/crm_Integration'
import RealTimeMonitoring from './containers/realTimeMonitoring'
import UsersSmsReport from './containers/dashboard/user-sms-report/usersSmsReport'
import Graph from './containers/charts'
import Contacts from './containers/contacts';
import { USER_REPORT_ENUM } from './enum/index';
import { savePermissionData } from './services/userRoles';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null
    };
    this.halfAnHour = 1800000;
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      if (localStorage.getItem('auth-data')) {
        savePermissionData();
      }
    }, this.halfAnHour);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    return (
      <div className="App container-fluid p-0">
        <Switch>
          <Route key='all-users-packages' exact path='/' render={(props) => <Dashbord isMyPackages={false} {...props} />} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/resetPassword' component={ResetPassword} />
          <Route exact path='/enterPassword' component={EnterPassword} />
          <Route exact path='/creatAnAccount' component={CreatCount} />
          <Route exact path='/selectPackage' component={SelectPackage} />
          <Route exact path='/selectPayment' component={SelectPayment} />
          <Route exact path='/enterCode' component={EnterCode} />
          <Route exact path='/two_factor' component={TwoFactor} />
          <Route exact path='/success' component={Success} />
          <Route key='only-user-package' exact path='/myPackages' render={(props) => <Dashbord isMyPackages={true} {...props} />} />
          <Route exact path='/settings' component={Settings} />
          <Route exact path='/contactUs' component={ContactUs} />
          <Route exact path='/crm_integration' component={CRMIntegration} />
          <Route exact path='/real_time_monitoring' component={RealTimeMonitoring} />
          <Route exact path='/charts' component={Graph} />
          <Route exact path='/contacts' component={Contacts} />
          <Route exact path='/users_sms_report' render={(props) => <UsersSmsReport reportType={USER_REPORT_ENUM.SMS_MODAL_TYPE} {...props} />} />
          <Route exact path='/messages_sms_report' render={(props) => <UsersSmsReport reportType={USER_REPORT_ENUM.MESSAGES_MODAL_TYPE} {...props} />} />
        </Switch>
      </div>
    );
  }
}

export default App;
