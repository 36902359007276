import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import './userConversations.css';
import { UserConversationsDetails } from './userConversationsDetails';
import { formatNumbers } from '../../../services/formatPhoneNumber';
import { USER_REPORT_ENUM } from '../../../enum/index';


export const UserConversations = React.forwardRef((props, ref) => {

    const [openConversations, setOpenConversations] = useState(false);

    const testRef = useRef();

    useEffect(() => {
        if (props.entryForFheFirstTime && props.userConversationsIndex === 0) {
            setOpenConversations(true);
        }
    }, [props.entryForFheFirstTime])

    const userConversationsDetailsRefs = [];

    useImperativeHandle(ref, () => ({
        toggle: (bool) => {
            setOpenConversations(bool);
        },
        number: props.userNumber,
        ref: testRef.current,
    }));

    const changeIcon = () => {
        if (!openConversations) {
            return <img className='user-conversations-select-icon' src='assets/open-select-icon.svg' />;
        } else return <img className='user-conversations-select-icon' src='assets/close-select-icon.svg' />;
    }

    const renderUserConversationsDetails = () => {
        let arr = [];
        if (props.allUserNumbers) {
            returnUserConversationsComp(props.allUserNumbers, arr);
        }
        return arr;
    }

    const returnUserConversationsComp = (allUserNumbers, newArr, i = 0) => {
        for (let index = i, x = 0; x < allUserNumbers.length, index < i + allUserNumbers.length; x++, index++) {
            let element = allUserNumbers[x];
            let detailsForMessages = {
                ...props.detailsForMessages,
            }
            if (props.isSMSreport) {
                detailsForMessages.Contact = element.Contact;
                detailsForMessages.ContactName = element.ContactName;
                delete detailsForMessages.Group;
            } else {
                if (element.type === USER_REPORT_ENUM.NUMBER_CONVERSATIONS_TYPE) {
                    detailsForMessages.Contact = element.Contact;
                    detailsForMessages.ContactName = element.ContactName;
                    delete detailsForMessages.Group;
                } else {
                    detailsForMessages.Group = element.Contact;
                    delete detailsForMessages.Contact;
                    // console.log(detailsForMessages);
                }
            }
            // console.log(detailsForMessages);
            newArr.push(
                <UserConversationsDetails
                    ref={obj => userConversationsDetailsRefs[index] = obj}
                    key={`${index}-conversations-details`}
                    id={`${index}-conversations-details`}
                    fromNumber={`${formatNumbers(props.userNumber)}`}
                    toNumber={
                        element.type === USER_REPORT_ENUM.NUMBER_CONVERSATIONS_TYPE ?
                            element.ContactName ? element.ContactName :
                                `${formatNumbers(element.Contact)}`
                            :
                            element.ContactName ? element.ContactName :
                                element.Contact
                    }
                    detailsForMessages={detailsForMessages}
                    renderConversationMessages={props.renderConversationMessages}
                    userConversationsDetailsOnClick={() => props.userConversationsDetailsOnClick(index)}
                    entryForFheFirstTime={props.entryForFheFirstTime}
                    index={index}
                    userConversationsIndex={props.userConversationsIndex}
                    numbersInfo={props.numbersInfo}
                    userNumber={props.userNumber}
                    smsReportUserDetailsIndex={props.smsReportUserDetailsIndex}
                    isSMSreport={props.isSMSreport}
                    isWAreport={props.isWAreport}
                    userNameIcon={
                        element.type === USER_REPORT_ENUM.NUMBER_CONVERSATIONS_TYP
                            ? 'user-name-icon.svg'
                            : 'group-name-icon.svg'
                    }
                    latestTime={element.LatestTime}
                />
            )
        }
    }

    let numberInfo = props.numbersInfo[props.userNumber];
    let lineName = numberInfo && numberInfo.LineName ? `(${numberInfo.LineName})` : numberInfo ? `(${numberInfo.Country})` : '';
    return (
        <div ref={testRef}>
            <div
                className='user-conversations-main'
                onClick={() => { setOpenConversations(!openConversations);/*props.userConversationsOnClick()*/ }}
            >
                <div className='user-conversations-icon'>
                    {changeIcon()}
                </div>
                <div className='user-conversations-number'>
                    {`${formatNumbers(props.userNumber)} ${lineName} (${props.amountOfCorrespondence})`}
                </div>
            </div>
            {openConversations &&
                <div>
                    {renderUserConversationsDetails()}
                </div>}
        </div>
    )
})