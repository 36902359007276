import http from "../axios" ;


const getCallsForUsers = data => {
    return http.post("Account/GetCallsForUsers", data, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
        }
    });
};
const getUserByUsername = email => {
    return http.get(`Account/GetUserByUsername/${email}/false`, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
        }
    });
};

export default {
    getCallsForUsers, getUserByUsername
};