import React, { useRef, useState } from "react";
import axios from 'axios';
import './secureAudioPlayer.css';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import * as STRINGS from '../../strings/english';

function SecureAudioPlayer(props) {
    const [loader, setLoader] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    let audioRef = useRef(null);

    const getMp3URL = () => {
        setLoader(true);
        axios.get(props.url, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'audio/mpeg',
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(res => {
            let mp3 = new Blob([res.data], { type: 'audio/mp3' });
            let url = window.URL.createObjectURL(mp3);
            audioRef.current.src = url;
            setLoader(false);
        }).catch(err => {
            setLoader(false);
            setAnchorEl(false);
            alert(STRINGS.AN_ERROR_OCCURRED_WHILE_LOADING_AUDIO)
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                className="secure-audio-play-img"
            >
               {loader && <div id="loading-bar-spinner" className="spinner">
                    <div className="spinner-icon"></div>
                </div>}
                <img
                    src={`assets/audio-control-btn.svg`}
                    alt="play-button"
                    onClick={getMp3URL}
                />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <audio
                    id="audio"
                    ref={audioRef}
                    controls
                    className="secure-audio-audio"
                >
                    Your browser does not support the <code>audio</code> element.
                </audio>
            </Popover>
        </div>
    );
}


export default SecureAudioPlayer;
