import http from "../axios";


class ProvisioningService {

    headers() {
        return {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data'),

            }

        }
    }

    getIntegrationOptions() {
        return http.get("Provisioning/GetIntegrationOptions", this.headers());
    };

    getActiveIntegrationOptions(data) {
        return http.post("Provisioning/GetActiveIntegrationOptions", data, this.headers());
    };

    setActiveIntegrationOptions(data) {
        return http.post("Provisioning/SetActiveIntegrationOptions", data, this.headers());
    };
}

export default new ProvisioningService();




