

export const data = {
    dashboard_credit_dropdown_di_purchase_credit_for_calls: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_bank_dropdown_di: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_bank_dropdown_di_show_payments: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_bank_dropdown_di_manage_payment_method: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_bank_dropdown_di_add_funds_to_bank: {
        IsHidden: false,
        Type: '0'
    },

    dashboard_btn_users_sms_report: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_btn_users_messages_report: {
        IsHidden: true,
        Type: '0'
    },
    dashboard_btn_users_call_report: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_btn_upload_users_by_csv: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_btn_register_user: {
        IsHidden: false,
        Type: '0'
    },

    dashboard_more_option_edit_user_details: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_more_option_call_report: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_more_option_sms_report: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_more_option_app_access_number: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_more_option_phone_book_country: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_more_option_revive_or_delete_user: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_more_option_show_payments: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_more_option_open_user_page: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_more_option_edit_caller_ids: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_more_option_edit_login_groups: {
        IsHidden: true,
        Type: '0'
    },
    dashboard_edit_user_email: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_edit_user_full_name: {
        IsHidden: false,
        Type: '0'
    },
    dashboard_edit_user_password: {
        IsHidden: false,
        Type: '0'
    },

    dashboard_btn_view_details: {
        IsHidden: false,
        Type: '0'
    },

    dashboard_btn_add_package: {
        IsHidden: false,
        Type: '0'
    },

    package_btn_sms_recieving: {
        IsHidden: false,
        Type: '0'
    },
    package_btn_call_recording: {
        IsHidden: false,
        Type: '0'
    },
    package_final_expiration_date: {
        IsHidden: false,
        Type: '0'
    },
    package_btn_edit_forward_to: {
        IsHidden: false,
        Type: '0'
    },
    package_btn_add_or_edit_note: {
        IsHidden: false,
        Type: '0'
    },
    package_btn_add_line_name: {
        IsHidden: false,
        Type: '0'
    },
    package_btn_renew_now: {
        IsHidden: false,
        Type: '0'
    },
    package_btn_save: {
        IsHidden: false,
        Type: '0'
    },

    advanced_features_dropdown_di_voice_mail_options: {
        IsHidden: false,
        Type: '0'
    },
    advanced_features_dropdown_di_recording_options: {
        IsHidden: false,
        Type: '0'
    },
    advanced_features_dropdown_di_time_forward_options: {
        IsHidden: false,
        Type: '0'
    },
    advanced_features_dropdown_di_auto_attendant: {
        IsHidden: false,
        Type: '0'
    },
    advanced_features_dropdown_di_group_ringing: {
        IsHidden: false,
        Type: '0'
    },
    advanced_features_dropdown_di_sequential_ringing: {
        IsHidden: false,
        Type: '0'
    },
    real_time_monitoring_register_to_sse: {
        IsHidden: true,
        Type: '0'
    },

    side_bar_dashboard: {
        IsHidden: false,
        Type: '0'
    },
    side_bar_my_packages: {
        IsHidden: false,
        Type: '0'
    },
    side_bar_account_settings: {
        IsHidden: false,
        Type: '0'
    },
    side_bar_crm_integration: {
        IsHidden: false,
        Type: '0'
    },
    side_bar_graph: {
        IsHidden: true,
        Type: '0'
    },
    side_bar_contacts: {
        IsHidden: false,
        Type: '0'
    },
    call_summary: {
        IsHidden: true,
        Type: '0'
    },
}