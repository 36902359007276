import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import ForbiddenModal from './components/forbiddenModal';

const spikkoApi = process.env.REACT_APP_API_URL;

const instance = axios.create({
    baseURL: spikkoApi
});

instance.interceptors.response.use(
    undefined, function axiosRetryInterceptor(err) {
        if (err && err.response) {
            if (err.response.status === 403) {
                ReactDOM.render(
                    <ForbiddenModal />,
                    document.body.appendChild(document.createElement('div')),
                )
            }
            if (err.response.status === 401 ||
                err.response.status === 406) {
                localStorage.clear();
                window.location.replace('/login');
            }
        }
        return Promise.reject(err);
    }
)

export default instance;


