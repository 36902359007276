import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Label,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledDropdown,
    ModalFooter
} from 'reactstrap';
import './advance.css';
import '../index.css';
import FeatureService from "../../../services/FeatureService";
import * as STRINGS from '../../../strings/english';
import {
    formatNumbers,
} from '../../../services/formatPhoneNumber';
import CountrySelect from '../../../services/format-number-input/countrySelect';

function GroupRinging(props) {

    const [isEnabled, setIsEnabled] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [edit, setEdit] = useState({ mode: false, place: null });
    const [divert, setDivert] = useState('');
    const [oldDivert, setOldDivert] = useState('');
    const [extensions, setExtensions] = useState([]);
    const [addDivert, setAddDivert] = useState(false);

    useEffect(() => {
        getDiverts();
    }, [])


    function getDiverts() {
        setIsLoader(true);
        let body = {
            "packageregistrationid": props.PackageRegistrationID
        }
        FeatureService.groupRingingGetAllDiverts(body).then(res => {
            if (res.status === 200) {
                setIsEnabled(res.data.IsEnabled);
                setExtensions(res.data.Diverts);
                setIsLoader(false);
            }
        })
            .catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    }

    const saveChanges = () => {
        setIsLoader(true);
        FeatureService.groupRingingEnableGroupCalling(isEnabled, props.PackageRegistrationID)
            .then((res) => {
                if (res) {
                    setIsLoader(false);
                    closeAddDivert(false);
                    props.toggel();
                }
            })
            .catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        save(false);
    }

    const save = (showAlert) => {
        if (divert) {
            setIsLoader(true);
            let body = {
                packageregistrationid: props.PackageRegistrationID
            }
            if (edit.mode) {
                body['currentdivert'] = oldDivert;
                body['newdivert'] = divert;
            }
            else if (!edit.mode) {
                body['divert'] = divert;
            }
            FeatureService[edit.mode ? 'groupRingingUpdateDivert' : 'groupRingingInsertDivert'](body)
                .then((res) => {
                    if (res) {
                        setIsLoader(false);
                        closeAddDivert(false);
                        getDiverts();
                    }
                })
                .catch(error => {
                    setIsLoader(false);
                    alert(error.response
                        ? error.response.data.Description || error.response.data.Message : error)
                });
        }
        else if (showAlert) alert(STRINGS.INPUT_MUST_BE_FILLED_OUT);
    };


    const deleteDivert = (index) => {
        setIsLoader(true);
        let currenDivert = extensions[index];
        let body = {
            packageregistrationid: props.PackageRegistrationID,
            divert: currenDivert
        }
        FeatureService.groupRingingDeleteDivert(body)
            .then((res) => {
                if (res) {
                    setIsLoader(false);
                    closeAddDivert(false);
                    getDiverts();
                }
            })
            .catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    };


    function closeAddDivert(divertMode) {
        setAddDivert(divertMode)
        setDivert('');
        setOldDivert('');
        setEdit({ mode: false, place: null });
    }

    function editDivert(index) {
        setAddDivert(true);
        setEdit({ mode: true, place: index });
        let currenDivert = extensions[index];
        setDivert(currenDivert);
        setOldDivert(currenDivert);
    }

    const renderTableBody = () => {
        let options = [];
        if (extensions && extensions.length !== 0) {
            extensions.forEach((extension, i) => {
                options.push(<tr
                    key={`listRow${i}`}
                    className={`tr ${extension.Active === 0 ? 'tr-disabled' : 'tr-enabled'}`}
                >
                    <td className='th-3-points'>{formatNumbers(extension)}</td>
                    <td>
                        <UncontrolledDropdown direction="down" style={{ lineHeight: '2rem' }}>
                            <DropdownToggle color={'white'} style={{ marginLeft: '8rem' }}>{''}
                                <img src='assets/combined-shape-copy-3.svg' alt="" className=" m-2" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    className=""
                                    onClick={() => editDivert(i)}>
                                    <img src='assets/edit-2-outline-copy.svg' alt="" className="" />
                                    {STRINGS.EDIT}
                                </DropdownItem>
                                <DropdownItem
                                    className=""
                                    onClick={() => deleteDivert(i)}>
                                    <img src='assets/icon-trash-copy-9.svg' alt="" className="" />
                                    {STRINGS.DELETE}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td>
                </tr>
                )
            })
        }
        return options;
    };

    function divertsTable() {
        return (
            <div>
                <table className="table">
                    <thead className="fs-12 gray thead">
                        <tr className="trh" >
                            <th>{STRINGS.DIVERTED_TO}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: "168px", minHeight: '108px' }} className="tbody aaBody">
                        {renderTableBody()}
                    </tbody>
                </table>
            </div>
        );
    }

    const callBack = (number) => {
        setDivert(number)
    }

    return <span>
        <ModalHeader
            style={{
                display: "flow-root",
                height: "90px"
            }}
        >
            <div className="d-flex justify-content-between fs-22" style={{ padding: "1.5rem  1rem" }}>
                <div>{STRINGS.GROUP_RINGING_OPTIONS}</div>
                <div className='custom-control custom-switch isolate-the-design'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={`customSwitches2-`}
                        checked={isEnabled}
                        onChange={(e) => { setIsEnabled(e.target.checked) }}
                    />
                    <label className='custom-control-label' htmlFor={`customSwitches2-`} />
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
            <Form style={{ padding: "1.5rem  1rem" }}>

                {addDivert && <FormGroup className="background-lightGrey-2">

                    <Label for="DivertedTo" className="fs-12">{STRINGS.DIVERTED_TO}</Label>
                    {/* <Input
                        id="DivertedTo"
                        type="text"
                        onChange={(e) => setDivert(e.target.value)}
                        value={formatInputValue(divert)}>
                    </Input> */}
                    <CountrySelect
                        numberToUpdate={edit.mode ? divert : null}
                        getNumberToUpdate={callBack}
                    />

                    <Row>
                        <Col className="d-flex justify-content-end fs-14 mt-3">
                            <div className="mr-4 pointer" onClick={() => closeAddDivert(false)}>{STRINGS.CANCEL}</div>
                            <div className="mr-0 light-blue pointer"
                                onClick={() => save(true)}> {edit.mode ? STRINGS.UPDATE : STRINGS.SAVE}</div>
                        </Col>
                    </Row>
                </FormGroup>}



                <FormGroup>
                    <div
                        className="d-flex justify-content-start"
                        style={{ width: "fit-content", cursor: "pointer" }}
                        onClick={() => closeAddDivert(true)}>
                        <img className="ml-0" src="assets/plus-circle-btn.svg" alt="" />
                        <div className="ml-2 light-blue fs-14">{STRINGS.ADD_DIVERT}</div>
                    </div>
                </FormGroup>
                {isLoader ?
                    <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }} src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                    : null}

                <FormGroup style={{ height: "20%" }}>
                    {extensions && extensions.length > 0 ? divertsTable() : null}
                </FormGroup>

            </Form>
        </ModalBody>
        <ModalFooter style={{ display: "block" }}>
            <FormGroup row className="justify-content-end">
                <Col md={5}><Button className="btnColor5 fontColor fs-14 no-border" style={{ width: "100%" }}
                    size="lg"
                    onClick={() => props.toggel()}>{STRINGS.CANCEL}</Button></Col>
                <Col md={5}><Button className="btnColor2 white fs-14 no-border btnColor2" style={{ width: "100%" }}
                    size="lg"
                    onClick={() => {
                        saveChanges()
                    }}>{STRINGS.SAVE_CHANGES}</Button></Col>
            </FormGroup>
        </ModalFooter>
    </span>
}

export default GroupRinging;