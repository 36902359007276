import React, { useState, useEffect } from 'react';
import { Button, Col, Input, FormGroup, Label, Form, Alert  } from 'reactstrap';
import './index.css';
import AccountService from "../../services/AccountService";
import UtilsService from "../../services/UtilsService";
import SideBar from '../../components/sideBar';
import NavBar from '../../components/navBar';
import CustomAlert from './alert'

function ContactUs(props) {
    const [user, setUser] = useState(null)
    const [name, setName] = useState({ value: "", validation: true })
    const [subject, setSubject] = useState({ value: "", validation: true })
    const [email, setEmail] = useState({ value: localStorage.getItem('email'), validation: true })
    const [phone, setPhone] = useState("")
    const [sendTo, setSendTo] = useState({ value: "Please choose", validation: true })
    const [message, setMessage] = useState({ value: "", validation: true })
    const [isLoader, setIsLoader] = useState(false)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        if (!user) {
            AccountService.getUserByUsername(localStorage.getItem('email'))
                .then(res => {
                    setUser(res.data)
                })
        }
    })
    const saveChanges = () => {
        let validation = true
        if (name.value.length === 0) {
            setValidation(name, setName, false)
            validation = false
        }
        if (subject.value.length === 0) {
            setValidation(subject, setSubject, false)
            validation = false
        }
        if (!emailValidation(email.value)) {
            setValidation(email, setEmail, false)
            validation = false
        }
        if (!sendToValidation(sendTo.value)) {
            setValidation(sendTo, setSendTo, false)
            validation = false
        }

        if (validation) {
            let body = {
                "message": message.value,
                "subject": subject.value,
                "sendto": sendTo.value !== "Please choose" ? sendTo.value : "",
                "phonenumber": phone,
                name: name.value,
                email: email.value
            }
            setIsLoader(true)
            UtilsService.contactUs(body)
                .then(() => {
                    clearFields()
                    setIsLoader(false)
                    setAlert(true)
                    setTimeout(()=> setAlert(false),3000)

                })
                .catch(e => {
                    console.log(e);
                    alert(e)
                    setIsLoader(false)
                })
        }
    };

    function handleValues(set, event) {
        let value = event.target.value
        let validation = true

        if (set === setSendTo) {
            validation = sendToValidation(value)
        }
        else if (set === setEmail) {
            validation = emailValidation(value)
        } else {
            validation = !!value > 0
        }

        set({ value: event.target.value, validation })
    }
    function sendToValidation(e) {
        return e !== "Please choose"
    }

    function emailValidation(e) {
        if (e.length > 0) {
            let val = true
            if (!e.includes('@') || !e.includes('.') || e.length < 5) {
                val = false
            }
            return val
        } else {
            return false
        }
    }

    function setValidation(name, set, status) {
        set({ ...name, validation: status })
    }

    const signOut = () => {
        localStorage.clear();
        props.history.push('/login')
    }

    const clearFields = () => {
        setName({ value:"", validation: true })
        setSubject({ value:"", validation: true })
        setEmail({ value:localStorage.getItem('email'), validation: true })
        setPhone("")
        setSendTo({ value:"Please choose", validation: true })
        setMessage({ value:"", validation: true })
    }

    return (
        <div className="d-flex">
            <SideBar
                active1={false}
                active2={false}
                active3={false}
                active4={false}
                active5={true}
                active6={false}
                active7={false}
                active8={false}
                homePage={() => props.history.push('/', { redirect: true })}
                myPackagePage={() => props.history.push('/myPackages', { redirect: true })}
                settingPage={() => props.history.push('/settings')}
                crm_integration={() => props.history.push('/crm_integration')}
                contactUs={() => props.history.push('/contactUs')}
                realTimeMonitoring={() => props.history.push('/real_time_monitoring')}
                graph={() => props.history.push('/charts')}
                contacts={() => props.history.push('/contacts')}
                user={user}
            />

            <div className="container-fluid p-0" style={{ height: "100vh" }}>
                <div>
                    <NavBar
                        logo={user ? user.LogoURL : ""}
                        signOut={() => signOut()}
                        mail={localStorage.getItem('email')}
                        title="Account settings" />
                </div>
                <div>
                    <FormGroup className="contactUsBody pb-sm-0 ml-0" row>
                        <Col md={6} className="v-center d-flex justify-content-center">
                            <Form className="form ">
                                <FormGroup>
                                    {alert ? <CustomAlert message="Message sent!"/> : null }
                                    <h4>Contact us</h4>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Name" className={!name.validation ? "red" : null}>*Name</Label>
                                    <Input type="text" name="Name" id="Name" onChange={(e) => handleValues(setName, e)} value={name.value}/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="Subject" className={!subject.validation ? "red" : null}>*Subject</Label>
                                    <Input type="text" name="Subject" id="Subject" onChange={(e) => handleValues(setSubject, e)} value={subject.value} />
                                </FormGroup>

                                {isLoader ? <img
                                    style={{ width: '100px', height: 'auto', right: '0', zIndex: '100' }}
                                    src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" /> : null}

                                <FormGroup>
                                    <Label for="Email" className={!email.validation ? "red" : null}>*Email</Label>
                                    <Input type="Email" name="Email" id="Email" onChange={(e) => handleValues(setEmail, e)} value={email.value} />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="Phone">Phone</Label>
                                    <Input type="number" name="Phone" id="Phone" onChange={(e) => setPhone(e.target.value)} value={phone}/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="SendTo" className={!sendTo.validation ? "red" : null}>*Send to</Label>
                                    <Input type="select" name="SendTo" id="SendTo" onChange={(e) => {handleValues(setSendTo, e)}} value={sendTo.value}>
                                        <option>Please choose</option>
                                        <option>Sales</option>
                                        <option>Support</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="Message">Message</Label>
                                    <Input type="textarea" name="Message" id="Message" onChange={(e) => handleValues(setMessage, e)} value={message.value}/>
                                </FormGroup>

                                <Button className="submit" onClick={() => saveChanges()}>Submit</Button>

                            </Form>
                        </Col>

                        <Col md={6} className="v-center">
                            <img src="Assets/contactUs.svg" alt="" />
                        </Col>
                    </FormGroup>
                </div>
            </div>
        </div>)

}

export default ContactUs