export const eventEnum = Object.freeze({
    STARTCALL_IN: 'STARTCALL_IN',
    STARTCALL_OUT: 'STARTCALL_OUT',
    ANSWERCALL_IN: 'ANSWERCALL_IN',
    ANSWERCALL_OUT: 'ANSWERCALL_OUT',
    CALL_IN: 'CALL_IN',
    CALL_OUT: 'CALL_OUT',
    IN: 'IN',
    OUT: 'OUT',
});

export const displayModeEnum = Object.freeze({
    TABLE: 'TABLE',
    SPLIT_TABLE: 'SPLIT_TABLE',
    BOXES: 'BOXES',
    INCOMING: 'Incoming',
});