import React, { useState, useEffect } from 'react';
import './index.css';
import SideBar from '../../components/sideBar';
import NavBar from '../../components/navBar';
import * as permissionFunc from '../../permissionScheme/permissionFunc';
import {
    calcDateFromToDayDateTo7DaysBefore,
    setTimeForDate
} from '../../services/tools';
import {
    Col,
    Row,
    FormGroup,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import AccountService from "../../services/AccountService";
import axios from '../../axios';
import DataCell from './dataCell';
import Chart from './chart';
import { addFuncToData } from './chartsFunctions';
import * as STRINGS from '../../strings/english';
import SelectUsers from '../dashboard/report-modal/selectUsers';

function Charts(props) {

    const initialDataState = {
        startFrom: setTimeForDate(calcDateFromToDayDateTo7DaysBefore(), "start"),
        endTo: setTimeForDate(new Date()),
    }

    const [dates, setDates] = useState(initialDataState);
    const [user, setUser] = useState(null);
    const [boxes, setBoxes] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [narrowCharts, setNarrowCharts] = useState([]);
    const [wideCharts, setWideCharts] = useState([]);
    const [reportType, setReportType] = useState(STRINGS.DAILY);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [reportTypeDropdownOpen, setReportTypeDropdownOpen] = useState(false);
    const [originUsers, setOriginUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersNameArr, setUsersNameArr] = useState([]);
    const [selectAllOrClearAllPressed, setSelectAllOrClearAllPressed] = useState('');
    const [usersNamesStr, setUsersNamesStr] = useState('');
    const [searchValue, setSearchValue] = useState('');
    
    const reportTypeArr = [
        STRINGS.DAILY,
        STRINGS.WEEKLY,
        STRINGS.CUSTOM_RANGE,
    ]

    const handleInputChange = (name, value) => {
        setDates({ ...dates, [name]: value });
    };

    useEffect(() => {
        axios.post('/Provisioning/GetOrganizationUsers', {
            OrganizationEmail: localStorage.getItem('email'),
            MaxResults: "10000",
            StartFromRow: "1",
            QueryType: "0",
            QueryTerm: "%"
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(res => {
            setOriginUsers(res.data.Users);
            setUsers(res.data.Users);
        }).catch(err => {

        });
    }, []);

    useEffect(() => {
        setUsersNamesStr(usersNameArr.join(','))
    }, [usersNameArr]);

    useEffect(() => {
        if (!user) {
            AccountService.getUserByUsername(localStorage.getItem('email'))
                .then(res => {
                    setUser(res.data);
                })
        }
        getData();
    }, []);

    const getData = () => {
        const validStartDate = dates.startFrom instanceof Date && !isNaN(dates.startFrom.valueOf());
        const validEndtDate = dates.endTo instanceof Date && !isNaN(dates.endTo.valueOf());
        if (validStartDate || validEndtDate) {
            setDataReady(false);
            axios.post('/Insights/ReportData', {
                "ReportStartDate": dates.startFrom,
                "ReportEndDate": reportType === STRINGS.CUSTOM_RANGE
                    ? dates.endTo : '',
                "DataType": "react",
                "ReportName": reportType,
                "filters": `username=${usersNamesStr}`,
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(res => {
                if (res && res.data) {
                    setBoxes(res.data.boxes);
                    setWideCharts(addFuncToData(res.data.WideCharts));
                    setNarrowCharts(addFuncToData(res.data.NarrowCharts));
                    setDataReady(true);
                }
            })
        }
    }

    useEffect(() => {
        const users = Object.assign(JSON.parse(JSON.stringify(originUsers)));
        const filterArr = users.filter(u => u.UserName.startsWith(searchValue));
        setUsers(filterArr);
    }, [searchValue])
    

    const signOut = () => {
        localStorage.clear();
        props.history.push('/login')
    }

    const checkBoxOnChange = (e, item) => {
        setUsers(users.map(user => {
            if (user.UserId !== item.UserId) return user;
            return { ...user, smsReportChecked: e.target.checked };
        }))
        addOrRemoveUserId(item.UserName, e.target.checked);
    }

    const addOrRemoveUserId = (username, bool) => {
        if (bool) {
            setUsersNameArr(state => ([...state, username]));
        }
        else if (!bool) {
            setUsersNameArr(usersNameArr.filter(item => item !== username));
        }
    }

    const selectAll = (e) => {
        setSelectAllOrClearAllPressed(e.target.id);
        setUsers(users.map(user => {
            return { ...user, smsReportChecked: true };
        }));
        setUsersNameArr(users.map(item => item.UserName));
    }

    const clearAll = (e) => {
        setSelectAllOrClearAllPressed(e.target.id);
        setUsers(users.map(user => {
            return { ...user, smsReportChecked: false };
        }));
        setUsersNameArr([])
    }

    const toggle = () => setDropdownOpen(!dropdownOpen);
    const reportTypeToggle = () => setReportTypeDropdownOpen(!reportTypeDropdownOpen);

    if (!permissionFunc.changeDisplay('side_bar_graph')) {
        return (
            <div className="d-flex">
                <SideBar
                    active1={false}
                    active2={false}
                    active3={false}
                    active4={false}
                    active5={false}
                    active6={false}
                    active7={true}
                    active8={false}
                    homePage={() => props.history.push('/', { redirect: true })}
                    myPackagePage={() => props.history.push('/myPackages', { redirect: true })}
                    settingPage={() => props.history.push('/settings')}
                    crm_integration={() => props.history.push('/crm_integration')}
                    contactUs={() => props.history.push('/contactUs')}
                    realTimeMonitoring={() => props.history.push('/real_time_monitoring')}
                    graph={() => props.history.push('/charts')}
                    contacts={() => props.history.push('/contacts')}
                    user={user}
                />

                <div className='chart-screen-main'>
                    <div>
                        <NavBar
                            logo={user ? user.LogoURL : ""}
                            signOut={() => signOut()}
                            mail={localStorage.getItem('email')}
                            title={STRINGS.INSIGHTS}
                            homePage={() => { }} />
                    </div>
                    <div className='chart-screen-dashboard-main'>
                        <Row>
                            <Col className="d-flex flex-row chart-screen-date-col" >
                                <FormGroup className='chart-screen-report-type mt-auto'>
                                    <Label for="chart-screen-report-type" className="chart-screen-date-header">
                                        {STRINGS.REPORT_TYPE}
                                    </Label>
                                    <Dropdown
                                        isOpen={reportTypeDropdownOpen}
                                        toggle={reportTypeToggle}
                                        direction={'down'}
                                        id='chart-screen-report-type'
                                    >
                                        <DropdownToggle
                                            caret
                                            style={{
                                                backgroundColor: '#FFFFFF',
                                                borderColor: '#ced4da',
                                                minWidth: '160px',
                                            }}
                                            color={'#495057'}
                                        >
                                            {reportType}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {reportTypeArr.map((e, i) => {
                                                return (
                                                    <DropdownItem
                                                        key={`report-type-dropdown-item-${i}`}
                                                        onClick={e => setReportType(e.target.innerText)}
                                                    >
                                                        {e}
                                                    </DropdownItem>
                                                )
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </FormGroup>
                                <FormGroup
                                    className='mt-auto'
                                >
                                    <Label
                                        htmlFor="chart-screen-dropdown"
                                        className="chart-screen-date-header"
                                    >
                                        {STRINGS.SELECT}
                                    </Label>
                                    <Dropdown
                                        isOpen={dropdownOpen}
                                        toggle={toggle}
                                        direction={'down'}
                                        id='chart-screen-dropdown'
                                    >
                                        <DropdownToggle
                                            style={{
                                                backgroundColor: '#FFFFFF',
                                                borderColor: '#ced4da'
                                            }}
                                            color={'#495057'}
                                        >
                                            {STRINGS.SELECT_USERS}
                                        </DropdownToggle>
                                        <DropdownMenu >
                                            <SelectUsers
                                                users={users}
                                                checkBoxOnChange={checkBoxOnChange}
                                                selectAllOrClearAllPressed={selectAllOrClearAllPressed}
                                                selectAll={selectAll}
                                                clearAll={clearAll}
                                                type='dropdown'
                                                searchValue={searchValue}
                                                setSearchValue={setSearchValue}
                                            />
                                        </DropdownMenu>
                                    </Dropdown>
                                </FormGroup>
                                <FormGroup className='chart-screen-dropdown-formgroup mt-auto'>
                                    <Label htmlFor="from" className="chart-screen-date-header">From</Label>
                                    <div className='border-52 form-control'>
                                        <DatePicker
                                            id="from"
                                            className="no-border2"
                                            selected={dates.startFrom}
                                            onSelect={(date) => {
                                                handleInputChange("startFrom", setTimeForDate(date, "start"))
                                            }}
                                            strictParsing
                                        />
                                        <img src='assets/calendar.svg' alt="" />
                                    </div>
                                </FormGroup>
                                {reportType === STRINGS.CUSTOM_RANGE &&
                                    <React.Fragment>
                                        <img
                                            alt='right-arrow'
                                            src='assets/chart-right-arrow.svg'
                                            className='chart-screen-arrow-right-icon'
                                        />
                                        <FormGroup className='chart-screen-to-date mt-auto'>
                                            <Label htmlFor="to" className="chart-screen-date-header">To</Label>
                                            <div className='border-52 form-control'>
                                                <DatePicker
                                                    id="to"
                                                    className="no-border2"
                                                    selected={dates.endTo}
                                                    onSelect={(date) => {
                                                        handleInputChange("endTo", setTimeForDate(date))
                                                    }}
                                                    minDate={dates.startFrom}
                                                    strictParsing
                                                />
                                                <img src='assets/calendar.svg' alt="" />
                                            </div>
                                        </FormGroup>
                                    </React.Fragment>}
                                <FormGroup
                                    className='chart-screen-submit-btn-form-group'
                                >
                                    <Button
                                        className='btnColor2 chart-screen-submit-btn'
                                        color='white'
                                        onClick={getData}
                                    >
                                        {STRINGS.SUBMIT}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        {dataReady ? <div className='chart-screen-charts-main'>
                            <div className='chart-screen-dashboard-header'>
                                {STRINGS.USERS_CALLS_REPORT}
                            </div>
                            <div className='chart-screen-charts-line-1'>
                                <div className='chart-screen-boxes-main'>
                                    {boxes.map((elm, i) => {
                                        return (
                                            <DataCell
                                                key={i}
                                                index={i}
                                                header={elm.Title}
                                                content={elm.Value}
                                                color={elm.Style}
                                                iconName={elm.Icon}
                                            />
                                        )
                                    })}
                                </div>
                                {narrowCharts.map((elm, i) => {
                                    return (
                                        <Chart
                                            key={`narrow-charts-${i}`}
                                            data={elm}
                                            index={i}
                                            style={{
                                                maxHeight: '318px',
                                                marginRight: '25px',
                                            }}
                                            name='narrow-charts'
                                        />
                                    )
                                })}
                            </div>
                            {wideCharts.map((elm, i) => {
                                return (
                                    <Chart
                                        key={`wide-charts-${i}`}
                                        data={elm}
                                        index={i}
                                        style={{
                                            marginRight: '25px',
                                        }}
                                        name='wide-charts'
                                    />
                                )

                            })}
                        </div> :
                            <img
                                src='assets/spikkoloaderanimation.svg'
                                alt="Loader"
                                className="chart-screen-loader"
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
    else return null;
}

export default Charts;