import React, { useState, useEffect } from 'react';
import './advance.css';
import {
    Button,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    Input,
    Col
} from 'reactstrap';
import * as STRINGS from '../../../strings/english';
import axios from '../../../axios';


export function UserPhonebookCountryModal(props) {

    const [isLoader, setIsLoader] = useState(false);
    const [countriesOpt, setCountriesOpt] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();
    const [countryCode2, setCountryCode2] = useState();

    useEffect(() => {
        axios.get('/Utils/ShowDidCountries', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        }).then(response => {
            if (response.status === 200) {
                setCountriesOptions(response.data);
                setUserCountry(response.data);
            }
        })
    }, [])

    const setCountriesOptions = (data) => {
        let optionsArr = [<option key={0}>{`${STRINGS.SELECT_COUNTRY}...`}</option>];
        data.map((elm, index) => {
            let option =
                <option
                    key={index + 1}
                    value={elm.CountryName}
                    data-value={JSON.stringify(elm)}
                >
                    {elm.CountryName}
                </option>
            optionsArr.push(option);
        })
        setCountriesOpt(optionsArr);
    }

    const setUserCountry = (data) => {
        let currentCountry = data.find(country => country.CountryCode2 === props.userDetails.HomeCountry);
        if (currentCountry) {
            setSelectedCountry(currentCountry.CountryName);
            setCountryCode2(currentCountry.CountryCode2);
        }
    }

    const onSelectChange = (event) => {
        setSelectedCountry(event.target.value)
        let obj = JSON.parse(event.target[event.target.selectedIndex].getAttribute('data-value'));
        if (obj) {
            setCountryCode2(obj.CountryCode2);
        }
    }

    const saveChangeOnClick = () => {
        if (countryCode2) {
            setIsLoader(true);
            let body = {
                "username": props.userDetails.UserName,
                "iso2": countryCode2
            }
            axios.post('/Account/SetUserHomeCountry', body, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                if (response.status === 200) {
                    setIsLoader(false);
                    alert(STRINGS.THE_CHANGES_HAVE_BEEN_SAVED);
                    props.refresUsers();
                    props.cancelOnClick();
                }
            }).catch(e => {
                if (e.response && e.response.data) {
                    alert(STRINGS.SERVER_ERROR + ': ' + e.response.data);
                }
            });
        }
        else alert(`${STRINGS.PLEASE_SELECT_A_COUNTRY}.`)
    }

    return (
        <React.Fragment>
            <ModalHeader>
                <span className='af-modal-header'>{`${STRINGS.USER_PHONEBOOK_COUNTRY}`}</span>
                <span className='af-modal-header-brackets'>{` (${STRINGS.FOR_SPIKKO_APP})`}</span>
            </ModalHeader>
            <ModalBody>
                <FormGroup row>
                    <Col className='mt-2' sm={12}>
                        <Input
                            type="select"
                            className={''}
                            id='country-select-2'
                            onChange={onSelectChange}
                            value={selectedCountry}
                        >
                            {countriesOpt}
                        </Input>
                    </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    className='col-4 fs-14'
                    style={{ backgroundColor: '#ebebeb' }}
                    color={'white'}
                    onClick={props.cancelOnClick}
                >
                    {STRINGS.CANCEL}
                </Button>
                <Button
                    className='col-4 fs-14 btnColor2 btn btn-white'
                    style={{ color: 'white' }}
                    color={'white'}
                    onClick={saveChangeOnClick}
                >
                    {STRINGS.SAVE_CHANGES}
                </Button>
            </ModalFooter>
            {isLoader
                ? <img
                    style={{
                        width: '60px',
                        height: '50px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                    src='assets/spikkoloaderanimation.svg' />
                : null}
        </React.Fragment>
    )
}