
export const checkDropdownItems = (startWithStr) => {
    let data;
    if (!data) {
        data = JSON.parse(localStorage.getItem('permissionData'));
    } 
    let result = true;
    for (let i in data) {
        if (i.startsWith(startWithStr) && data[i].IsHidden === false) {
            result = false;
            break;
        }
    }
    return result;
}

export const changeDisplay = (id) => {
    let data;
    if (!data) {
        data = JSON.parse(localStorage.getItem('permissionData'));
    } 
    if (data && data[id] && data[id].IsHidden) {
        return true;
    }
    else return false;
}
