import React, { useState, useEffect } from 'react';
import './modal.css';
import {
    Button,
    Col,
    Row,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Form,
    CustomInput
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import * as STRINGS from '../../../strings/english';
import { useHistory } from "react-router-dom";
import {
    downloadCsv,
    calcDateFromToDayDateTo7DaysBefore,
    setTimeForDate
} from '../../../services/tools';
import { USER_REPORT_ENUM } from '../../../enum';
import SelectUsers from './selectUsers';


function ReportModal(props) {

    const initialDataState = {
        startFrom: setTimeForDate(calcDateFromToDayDateTo7DaysBefore(), "start"),
        endTo: setTimeForDate(new Date()),
    }

    const [data, setData] = useState(initialDataState);
    const [isLoader, setIsLoader] = useState(false)
    const [users, setUsers] = useState(!props.isSpecificUser ? props.users.map((item, i) => {
        item.smsReportChecked = false;
        return item;
    })
        : null);
    const [originUsers, setOriginUsers] = useState(!props.isSpecificUser ? props.users.map((item, i) => {
        item.smsReportChecked = false;
        return item;
    })
        : null);
    const [selectAllOrClearAllPressed, setSelectAllOrClearAllPressed] = useState('');
    const [usersIdsArr, setUsersIdsArr] = useState(props.isSpecificUser ? [props.currentUser.UserId] : []);
    const [searchValue, setSearchValue] = useState('');

    const boldObj = { fontWeight: 'bold' };
    const allUsersId = !props.isSpecificUser ? props.users.map(item => item.UserId) : [];

    let history = useHistory();

    useEffect(() => {
        if (!props.isSpecificUser) {
            const users = Object.assign(JSON.parse(JSON.stringify(originUsers)));
            const filterArr = users.filter(u => u.UserName.startsWith(searchValue));
            setUsers(filterArr);
        }
    }, [searchValue])

    const handleInputChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const getNumbersForUsers = (usersArr = []) => {
        setIsLoader(true);
        let body = {
            startFrom: data.startFrom,
            endTo: data.endTo,
            includedusers: usersIdsArr.length > 0 ? usersIdsArr : allUsersId
        };
        props.getNumbersData(body)
            .then(response => {
                setIsLoader(false);
                if (Object.keys(response.data.Users).length > 0) {
                    history.push({
                        pathname: `/${props.pathname}`,
                        state: {
                            // userNumbers: response.data.Users,
                            users: usersArr,
                            startFrom: data.startFrom,
                            endTo: data.endTo,
                            usersIdsArr: usersIdsArr.length > 0 ? usersIdsArr : allUsersId,
                            // NumbersInfo: response.data.NumbersInfo,
                            reportType: USER_REPORT_ENUM.MESSAGES_MODAL_TYPE,
                        }
                    })
                }
                else alert(STRINGS.NO_INFORMATION_TO_DISPLAY)
            }).catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    };

    const getSMSMessagesForUsers = () => {
        setIsLoader(true);
        let body = {
            // StartFrom: data.startFrom,
            // EndTo: data.endTo,
            includedusers: usersIdsArr.length > 0 ? usersIdsArr : allUsersId
        };
        if (data.startFrom && data.endTo) {
            body.StartFrom = data.startFrom;
            body.EndTo = data.endTo;
        }
        props.getMessagesForUsers(body).
            then(response => {
                setIsLoader(false);
                if (Object.keys(response.data).length > 0) {
                    downloadCsv(response.data);
                }
                else alert(STRINGS.NO_INFORMATION_TO_DOWNLOAD);
            }).catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });

    };

    const returnUsersList = () => {
        let arr = users.map((item, index) => {
            return (
                <div
                    key={'sms-report-user-list-' + index}
                    className='sms-report-user-list'
                >
                    <CustomInput
                        type="checkbox"
                        id={"sms-report-user-list-checkbox-" + index}
                        label={item.UserName}
                        onChange={(e) => { checkBoxOnChange(e, item) }}
                        checked={item.smsReportChecked}
                    />
                    {index + 1 !== users.length && <div className='sms-report-user-list-bottom-line' />}
                </div>
            )
        });
        return arr;
    }

    const selectAll = (e) => {
        setSelectAllOrClearAllPressed(e.target.id);
        setUsers(users.map(user => {
            return { ...user, smsReportChecked: true };
        }));
        setUsersIdsArr(users.map(item => item.UserId));
    }

    const clearAll = (e) => {
        setSelectAllOrClearAllPressed(e.target.id);
        setUsers(users.map(user => {
            return { ...user, smsReportChecked: false };
        }));
        setUsersIdsArr([])
    }

    const setStyle = (str) => {
        if (selectAllOrClearAllPressed === str) {
            return boldObj;
        }
        else return {};
    }

    const reportAsTableOnClick = () => {
        if (usersIdsArr.length > 0) {
            getNumbersForUsers(lookingForMarkedUsers());
        }
        else {
            getNumbersForUsers(users);
        }
    }

    const lookingForMarkedUsers = () => {
        if (!props.isSpecificUser) {
            let markedUsers = users.filter(item => item.smsReportChecked);
            return markedUsers;
        }
        else return [props.currentUser];
    }

    const downloadAsCsvOnClick = () => {
        getSMSMessagesForUsers();
    }

    const checkBoxOnChange = (e, item) => {
        setUsers(users.map(user => {
            if (user.UserId !== item.UserId) return user;
            return { ...user, smsReportChecked: e.target.checked };
        }))
        addOrRemoveUserId(item.UserId, e.target.checked);
    }

    const addOrRemoveUserId = (id, bool) => {
        if (bool) {
            setUsersIdsArr(state => ([...state, id]));
        }
        else if (!bool) {
            setUsersIdsArr(usersIdsArr.filter(item => item !== id));
        }
    }


    return (
        <div>
            <ModalHeader className="no-border block" >
                <div className="sms-report-modal-header">
                    {props.headerText}
                </div>
            </ModalHeader>
            <hr />
            <ModalBody style={{ padding: '0 1.625rem' }}>
                <FormGroup>
                    <Form>
                        {/* <div>
                            <span className='sms-reprt-date-header-1'>{STRINGS.SELECT_A_DATE_RANGE}</span>
                            <span className='sms-reprt-date-header-2'>{` (${STRINGS.LEAVE_EMPTY_FOR_ALL_SMS})`}</span>
                        </div> */}
                        <div>
                            <Row>
                                <Col className="d-flex flex-row sms-report-date-col" >
                                    <FormGroup>
                                        <Label htmlFor="from" className="sms-report-date-header">{STRINGS.FROM}</Label>
                                        <div className='border-52 form-control'>
                                            <DatePicker
                                                id="from"
                                                className="no-border2"
                                                selected={data.startFrom}
                                                onChange={(date) => {
                                                    handleInputChange("startFrom", setTimeForDate(date, "start"))
                                                }}
                                            />
                                            <img src='assets/calendar.svg' alt="" />
                                        </div>
                                    </FormGroup>
                                    <FormGroup >
                                        <Label htmlFor="to" className="sms-report-date-header">{STRINGS.TO}</Label>
                                        <div className='border-52 form-control'>
                                            <DatePicker
                                                id="to"
                                                className="no-border2"
                                                selected={data.endTo}
                                                onChange={(date) => {
                                                    handleInputChange("endTo", setTimeForDate(date))
                                                }}
                                            />
                                            <img src='assets/calendar.svg' alt="" />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {!props.isSpecificUser ?
                                <SelectUsers
                                    users={users}
                                    checkBoxOnChange={checkBoxOnChange}
                                    selectAllOrClearAllPressed={selectAllOrClearAllPressed}
                                    selectAll={selectAll}
                                    clearAll={clearAll}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                /> :
                                <div className='sms-report-user-list'>
                                    {props.currentUser.UserName}
                                    <div className='sms-report-user-list-bottom-line' />
                                </div>}
                        </div>
                        <div className='sms-report-buttons-footer'>
                            <Button
                                color=""
                                style={{ color: 'white' }}
                                className="btnColor2 fs-14 sms-report-download-as-csv-button"
                                onClick={downloadAsCsvOnClick}
                            >
                                <img src='assets/export.svg' className="sms-report-export-icon" />
                                {STRINGS.DOWNLOAD_AS_CSV}
                            </Button>
                            <Button
                                color=""
                                style={{ color: 'white' }}
                                className="btnColor2 fs-14 sms-report-export-as-table-button"
                                onClick={reportAsTableOnClick}
                            >
                                {STRINGS.REPORT_AS_TABLE}
                            </Button>
                        </div>
                    </Form>
                </FormGroup>
                {isLoader
                    ? <img
                        style={{
                            width: '60px',
                            height: '50px',
                            margin: 'auto'
                        }}
                        src='assets/spikkoloaderanimation.svg'
                        alt=""
                        className="loginImg d-flex" />
                    : null}
            </ModalBody>
        </div>

    );
}


export default ReportModal;