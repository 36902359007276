import React, {Component} from 'react';
import './index.css';
import axios from '../../axios';
import {Redirect} from 'react-router-dom'
import _ from 'lodash';
import {
    Button,
    Form,
    FormGroup,
    Col,
    Progress,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input, ModalBody
} from 'reactstrap';
import {Link} from 'react-router-dom';
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { formatInputValue } from '../../services/formatPhoneNumber';
import CountrySelect from '../../services/format-number-input/countrySelect';

class SelectPackage extends Component {

    state = {
        newCountry: 'Select',
        oldCountry: 'Select',
        newNumber: 'Select',
        location: 'Select state / city',
        items: '',
        items2: '',
        items3: '',
        items4: '',
        items5: '',
        isDropdownNumber: true,
        isDropdownLocation: true,
        isDropdownPlans: true,
        isDropdownContry: true,
        isLoader1: false,
        isLoader2: false,
        isLoader3: false,
        isLoader4: false,
        plans: 'Select',
        prefix: '',
        formatPrefix: '',
        productID: null,
        redirect: this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : false,
        countries: [],
        locations: [],
        userCountry: []
    }

    next() {

        if (this.state.newNumber !== 'Select' && this.state.oldCountry !== 'Select' && this.state.prefix !== '') {
            this.setState({isLoader3: true})
            axios.post('/Provisioning/RegisterToPackage', {
                "Username": localStorage.getItem('email'),
                "ProductID": this.state.productID,
                "DivertNumber": this.state.newNumber,
                "SpikkoCountryCode": _
                    .find(this.state.ShowDidLocationsData, {'FullName': this.state.location})
                    .SpikkoCountryCodeID,
                "ISOCountryCode": _
                    .find(this.state.ShowDidLocationsData, {'FullName': this.state.location})
                    .CountryName
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {

                this.setState({isLoader3: false})
                this
                    .props
                    .history
                    .push('/selectPayment', {
                        packageId: response.data.PacakgeRegistrationId,
                        redirect: true,
                        countId: this.props.location.state.countId
                    });
            }).catch(error => {
                this.setState({isLoader3: false})
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        } else {
            alert('Fields are missing')
        }
    }

    componentDidMount() {
        axios
            .get('/Utils/ShowDidCountries', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            })
            .then(response => {
                const countries = response.data.map((item => item.CountryName));
                this.setState({countries});
                const ShowDidCountriesData = response.data;
                this.setState({ShowDidCountriesData})
                // const items = ShowDidCountriesData.map((value, index) => < DropdownItem key={
                //     index
                // }
                //     name={
                //         'newCountry'
                //     }
                //     onClick={
                //         (e) => this.onDropClick(e)
                //     }> {
                //     value.CountryName
                // } </DropdownItem>)
                // this.setState({items: items})
            })
            .catch(error => {
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        axios
            .get('/Utils/GetSupportedDestinations', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            })
            .then(response => {
                const GetSupportedDestinationsData = response.data;
                this.setState({GetSupportedDestinationsData})
                const userCountry = response.data.map(item => item.CountryName);
                this.setState({ userCountry });
                // const items = GetSupportedDestinationsData.map((value, index) => < DropdownItem key={
                //     index
                // }
                //     name={
                //         'oldCountry'
                //     }
                //     onClick={
                //         (e) => this.onDropClick(e)
                //     }> {
                //     value.CountryName
                // } </DropdownItem>)
                // this.setState({items2: items})
            })
            .catch(error => {
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    }

    onDropClick(e) {
        switch (e.target.name) {
            case 'newCountry':
                this.setState({
                    isDropdownNumber: true,
                    isDropdownContry: true,
                    isDropdownLocation: true,
                    location: 'Select state / city',
                    newNumber: 'Select',
                    isLoader1: true,
                    [e.target.name]: e.target.innerText
                })
                const countryCode = _
                    .find(this.state.ShowDidCountriesData, {'CountryName': e.target.innerText})
                    .CountryCode;
                this.setState({countryCode})
                axios
                    .get(`/Utils/ShowDidLocations/${countryCode}`, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                        }
                    })
                    .then(response => {
                        const ShowDidLocationsData = response.data;
                        this.setState({ShowDidLocationsData})
                        const newArray = ShowDidLocationsData.filter(value => value.CityName !== "default")
                        const locations = newArray.map(item => item.FullName); // the old value is 'CityName'.
                        this.setState({ locations });
                        // const items = newArray.map((value, index) => < DropdownItem key={
                        //     index
                        // }
                        //     name={
                        //         'location'
                        //     }
                        //     onClick={
                        //         (e) => this.onDropClick(e)
                        //     }> {
                        //     value.CityName
                        // } </DropdownItem>)
                        // this.setState({items3: items, isDropdownLocation: false, isLoader1: false})
                        this.setState({isDropdownLocation: false, isLoader1: false})
                    })
                    .catch(error => {
                        this.setState({isLoader1: false})
                        alert(error.response
                            ? error.response.data.Description || error.response.data.Message : error)
                    });
                break
            case 'location':
                this.setState({
                    isLoader2: true,
                    [e.target.name]: e.target.innerText,
                    isDropdownContry: true,
                    isDropdownNumber: true,
                    newNumber: 'Select'
                })
                const countryCodeID = _.find(this.state.ShowDidLocationsData, {'FullName': e.target.innerText}).SpikkoCountryCodeID;
                axios
                    .post('/Provisioning/GetAvailableNumbersInLocation', {"CountryCodeID": countryCodeID}, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                        }
                    })
                    .then(response => {

                        const GetAvailableNumbersInLocation = response.data.Results;
                        this.setState({GetAvailableNumbersInLocation})
                        if (GetAvailableNumbersInLocation.length > 0) {
                            const items = GetAvailableNumbersInLocation.map((value, index) => < DropdownItem key={
                                index
                            }
                                 name={
                                     'newNumber'
                                 }
                                 onClick={
                                     (e) => this.onDropClick(e)
                                 }> {
                                value.FormattedInternational
                            } </DropdownItem>)
                            this.setState({items4: items, isDropdownNumber: false, isLoader2: false})
                        } else this.setState({isLoader2: false})
                    })
                    .catch(error => {
                        this.setState({isLoader2: false})
                        alert(error.response
                            ? error.response.data.Description || error.response.data.Message : error)
                    });
                break;
            case 'oldCountry':
                this.setState({isLoader4: true, [e.target.name]: e.target.innerText})
                const countryPlan = _.find(this.state.GetSupportedDestinationsData, {'CountryName': e.target.innerText}).IntlPrefix;
                this.setState({countryPlan, prefix: countryPlan})
                axios
                    .post('/Provisioning/GetAvailablePlans ',
                        //  {
                        {
                            "OrganizationEmail": "",
                            "SourceCountry": this.state.countryCode,
                            "DestinationPrefix": countryPlan,
                            "CountryCodeID": _
                                .find(this.state.ShowDidLocationsData, {'FullName': this.state.location})
                                .SpikkoCountryCodeID,
                            "Username": localStorage.getItem('email')
                        },
                        {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                            }
                        }).then(response => {

                    const GetAvailablePlans = response.data;
                    this.setState({GetAvailablePlans})
                    const items = GetAvailablePlans.map((value, index) => < DropdownItem key={
                        index
                    }
                         name={
                             value.ProductID
                         }
                         onClick={
                             (e) => this.onDropClick(e)
                         }> {
                        `${value.Name} (${value.Price}USD / month)`
                    } </DropdownItem>)
                    this.setState({items5: items, isDropdownPlans: false, isLoader4: false})
                })
                    .catch(error => {
                        this.setState({isLoader4: false})
                        alert(error.response
                            ? error.response.data.Description || error.response.data.Message : error)
                    });
                break;
            case 'newNumber':
                this.setState({[e.target.name]: e.target.innerText, isDropdownContry: false})
                break;

            default:
                this.setState({productID: e.target.name, plans: e.target.innerText})

        }
    }

    onOptionClick(e, name, newValue) {
        if (newValue) {
            switch (name) {
                case 'newCountry':
                    this.setState({
                        isDropdownNumber: true,
                        isDropdownContry: true,
                        isDropdownLocation: true,
                        location: 'Select state / city',
                        newNumber: 'Select',
                        isLoader1: true,
                        [name]: newValue
                    })
                    const countryCode = _
                        .find(this.state.ShowDidCountriesData, {'CountryName': newValue})
                        .CountryCode;
                    this.setState({countryCode})
                    axios
                        .get(`/Utils/ShowDidLocations/${countryCode}`, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                            }
                        })
                        .then(response => {
                            const ShowDidLocationsData = response.data;
                            this.setState({ShowDidLocationsData})
                            const newArray = ShowDidLocationsData.filter(value => value.CityName !== "default")
                            const locations = newArray.map(item => item.FullName); // the old value is 'CityName'.
                            this.setState({ locations });
                            // const items = newArray.map((value, index) => < DropdownItem key={
                            //     index
                            // }
                            //     name={
                            //         'location'
                            //     }
                            //     onClick={
                            //         (e) => this.onDropClick(e)
                            //     }> {
                            //     value.CityName
                            // } </DropdownItem>)
                            // this.setState({items3: items, isDropdownLocation: false, isLoader1: false})
                            this.setState({isDropdownLocation: false, isLoader1: false})
                        })
                        .catch(error => {
                            this.setState({isLoader1: false})
                            alert(error.response
                                ? error.response.data.Description || error.response.data.Message : error)
                        });
                    break
                case 'location':
                    this.setState({
                        isLoader2: true,
                        [name]: newValue,
                        isDropdownContry: true,
                        isDropdownNumber: true,
                        newNumber: 'Select'
                    })
                    const countryCodeID = _.find(this.state.ShowDidLocationsData, {'FullName': newValue}).SpikkoCountryCodeID;
                    axios
                        .post('/Provisioning/GetAvailableNumbersInLocation', {"CountryCodeID": countryCodeID}, {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                            }
                        })
                        .then(response => {

                            const GetAvailableNumbersInLocation = response.data.Results;
                            this.setState({GetAvailableNumbersInLocation})
                            if (GetAvailableNumbersInLocation.length > 0) {
                                const items = GetAvailableNumbersInLocation.map((value, index) => < DropdownItem key={
                                    index
                                }
                                     name={
                                         'newNumber'
                                     }
                                     onClick={
                                         (e) => this.onDropClick(e)
                                     }> {
                                    value.FormattedInternational
                                } </DropdownItem>)
                                this.setState({items4: items, isDropdownNumber: false, isLoader2: false})
                            } else this.setState({isLoader2: false})
                        })
                        .catch(error => {
                            this.setState({isLoader2: false})
                            alert(error.response
                                ? error.response.data.Description || error.response.data.Message : error)
                        });
                    break;
                case 'oldCountry':
                    this.setState({isLoader4: true, [name]: newValue})
                    const countryPlan = _.find(this.state.GetSupportedDestinationsData, {'CountryName': newValue}).IntlPrefix;
                    this.setState({countryPlan, prefix: countryPlan, formatPrefix: countryPlan})
                    axios
                        .post('/Provisioning/GetAvailablePlans ',
                            //  {
                            {
                                "OrganizationEmail": "",
                                "SourceCountry": this.state.countryCode,
                                "DestinationPrefix": countryPlan,
                                "CountryCodeID": _
                                    .find(this.state.ShowDidLocationsData, {'FullName': this.state.location})
                                    .SpikkoCountryCodeID,
                                "Username": localStorage.getItem('email')
                            },
                            {
                                headers: {
                                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                                }
                            }).then(response => {

                        const GetAvailablePlans = response.data;
                        this.setState({GetAvailablePlans})
                        const items = GetAvailablePlans.map((value, index) => < DropdownItem key={
                            index
                        }
                             name={
                                 value.ProductID
                             }
                             onClick={
                                 (e) => this.onDropClick(e)
                             }> {
                            `${value.Name} (${value.Price}USD / month)`
                        } </DropdownItem>)
                        this.setState({items5: items, isDropdownPlans: false, isLoader4: false})
                    })
                        .catch(error => {
                            this.setState({isLoader4: false})
                            alert(error.response
                                ? error.response.data.Description || error.response.data.Message : error)
                        });
                    break;
                case 'newNumber':
                    this.setState({[e.target.name]: e.target.innerText, isDropdownContry: false})
                    break;

                default:
                    this.setState({productID: e.target.name, plans: e.target.innerText})

            }

        }
    }


    skip() {
        this.props.history.push('/selectPayment', {redirect: true, countId: this.props.location.state.countId})
    }

    callBack = (number) => {
        this.setState({ prefix: number })
    }

    render() {
        if (this.state.redirect) {
            return (
                <div className=" wrapper row m-0 ">
                    <div className="divCountImg">
                        <img src='assets/Capture3.PNG' alt="" className="countImg"/></div>
                    <Form className="loginForm">
                        {this.state.isLoader4
                            ? <img
                                style={{
                                    width: '60px',
                                    height: '50px',
                                    marginTop: 'auto'
                                }}
                                src='assets/spikkoloaderanimation.svg'
                                alt=""
                                className="loginImg"/>
                            : null}
                        <div className="d-flex">
                            <h4 className="my-3">Select package</h4>
                            <Button
                                onClick={() => this.skip()}
                                className="btn m-2 ml-auto"
                                color={null}
                                style={{
                                    background: 'rgba(255, 255, 255, 0.07)',
                                    color: 'white'
                                }}>Skip for now</Button>
                        </div>
                        <div className="mt-3">Step 3</div>
                        <Progress animated className="mb-3" value="75"/>
                        <Row>
                            <Col>
                                <Label className="font-weight-bold mt-2">1. Pick your number</Label>
                                {/*<FormGroup className="mt-2">*/}
                                {/*    <Label>Select a country for your new number</Label>*/}
                                {/*    <UncontrolledDropdown>*/}
                                {/*        <DropdownToggle className="btn-block btn-light" caret>*/}
                                {/*            {this.state.newCountry}&nbsp;&nbsp;&nbsp;&nbsp;*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu>*/}
                                {/*            {this.state.items}*/}
                                {/*        </DropdownMenu>*/}
                                {/*    </UncontrolledDropdown>*/}
                                {/*</FormGroup>*/}
                                <FormGroup className="mt-2">
                                    <InputLabel style={{color: 'white'}}>
                                        Select a country for your new number
                                    </InputLabel>
                                    <Autocomplete
                                        id="field4"
                                        className="btn-block btn-light"
                                        style={{borderRadius: '5px', color: 'rgb(46, 194, 254);'}}
                                        options={this.state.countries}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, newValue) =>
                                            this.onOptionClick(event, 'newCountry', newValue)}
                                        size="small"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                placeholder="Select a country…"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                }}
                                            />}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <InputLabel style={{color: 'white'}}>
                                        Location
                                    </InputLabel>
                                    <Autocomplete
                                        className="btn-block btn-light"
                                        style={{borderRadius: '5px'}}
                                        id="field5"
                                        disabled={this.state.isDropdownLocation}
                                        options={this.state.locations}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, newValue) =>
                                            this.onOptionClick(event, 'location', newValue)}
                                        size="small"
                                        renderInput={(params) =>
                                            <TextField {...params} 
                                                placeholder="Select state / city" 
                                                variant="outlined" 
                                                inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password", // disable autocomplete and autofill
                                            }}/>}
                                    />
                                    {this.state.isLoader1
                                        ? <img
                                            style={{
                                                marginLeft: '45%',
                                                width: '60px',
                                                height: '50px',
                                                marginTop: 'auto'
                                            }}
                                            src='assets/spikkoloaderanimation.svg'
                                            alt=""
                                            className="loginImg" />
                                        : null}
                                </FormGroup>
                                {/*<FormGroup className="mt-5">*/}
                                {/*    <Label>Location</Label>*/}
                                {/*    <UncontrolledDropdown>*/}
                                {/*        <DropdownToggle*/}
                                {/*            disabled={this.state.isDropdownLocation}*/}
                                {/*            className="btn-block btn-light"*/}
                                {/*            caret>*/}
                                {/*            {this.state.location}&nbsp;&nbsp;&nbsp;&nbsp;*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu>*/}
                                {/*            {this.state.items3}*/}
                                {/*        </DropdownMenu>*/}
                                {/*    </UncontrolledDropdown>*/}
                                    {this.state.isLoader1
                                        ? <img
                                            style={{
                                                width: '60px',
                                                height: '50px',
                                                marginTop: 'auto'
                                            }}
                                            src='assets/spikkoloaderanimation.svg'
                                            alt=""
                                            className="loginImg"/>
                                        : null}
                                {/*</FormGroup>*/}
                                <FormGroup>
                                    <Label>Number</Label>
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            disabled={this.state.isDropdownNumber}
                                            className="btn-block btn-light"
                                            caret>
                                            {this.state.newNumber}&nbsp;&nbsp;&nbsp;&nbsp;
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.items4}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {this.state.isLoader2
                                        ? <img
                                            style={{
                                                width: '60px',
                                                height: '50px',
                                                marginTop: 'auto'
                                            }}
                                            src='assets/spikkoloaderanimation.svg'
                                            alt=""
                                            className="loginImg"/>
                                        : null}
                                </FormGroup>
                            </Col>
                            <Col className="border-left">
                                <Label className="font-weight-bold mt-2">2. Receive calls in</Label>
                                {/*<FormGroup className="mt-2">*/}
                                {/*    <Label>Country</Label>*/}
                                {/*    <UncontrolledDropdown>*/}
                                {/*        <DropdownToggle disabled={this.state.isDropdownContry}*/}
                                {/*                        className="btn-block btn-light" caret>*/}
                                {/*            {this.state.oldCountry}&nbsp;&nbsp;&nbsp;&nbsp;*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu>*/}
                                {/*            {this.state.items2}*/}
                                {/*        </DropdownMenu>*/}
                                {/*    </UncontrolledDropdown>*/}
                                {/*</FormGroup>*/}
                                <FormGroup className="border-top pt-4">
                                    <InputLabel>Country</InputLabel>
                                    <Autocomplete
                                        id="signup-select-user-country"
                                        disabled={this.state.isDropdownContry}
                                        className="btn-block btn-light"
                                        style={{borderRadius: '5px'}}
                                        options={this.state.userCountry}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, newValue) =>
                                            this.onOptionClick(event, 'oldCountry', newValue)}
                                        size="small"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                placeholder="Select a country…"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "disabled", // disable autocomplete and autofill
                                                }}
                                            />}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Number</Label>
                                    {/* <Input
                                        onChange={(e) => this.setState({ prefix: e.target.value })}
                                        value={formatInputValue(this.state.prefix)}
                                        type="text"
                                        name="oldNumber"
                                        id="oldNumber"
                                        innerRef={(oldNumber) => {
                                            this.oldNumber = oldNumber
                                        }}/> */}
                                    <CountrySelect
                                        numberToUpdate={this.state.prefix}
                                        getNumberToUpdate={() => this.callBack()}
                                        style={{ backgroundColor: 'white', borderRadius: '5px' }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Select from available plans</Label>
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            disabled={this.state.isDropdownPlans}
                                            className="btn-block btn-light"
                                            caret>
                                            {this.state.plans}&nbsp;
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.items5}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className="d-flex mt-5">
                            <Link
                                to={'/creatAnAccount'}
                                style={{
                                    color: 'white'
                                }}>Back</Link>
                            {this.state.isLoader3
                                ? <img
                                    style={{
                                        width: '100px',
                                        height: 'auto',
                                        right: '45%',
                                        zIndex: '100'
                                    }}
                                    src='assets/spikkoloaderanimation.svg'
                                    alt=""
                                    className="position-absolute"/>
                                : null}
                            <Button
                                style={{
                                    backgroundColor: ' #2EC2FE'
                                }}
                                className="col-3 ml-auto"
                                onClick={() => this.next()}
                                block>Next
                                <img src='assets/arrow.svg' alt="" className="ml-1"/>
                            </Button>
                        </FormGroup>
                    </Form>
                </div>
            );
        } else {
            return (<Redirect to='/login'/>)
        }
    }
}

export default SelectPackage;