export const USER_REPORT_ENUM = Object.freeze({
    MESSAGES_MODAL_TYPE: 'MessagesReport',
    SMS_MODAL_TYPE: 'SMSreport',
    GET_SMS_MESSAGES_FOR_CONVERSATION: 'GetSMSMessagesForConversation',
    GET_WA_MESSAGES_FOR_CONVERSATION: 'GetWAMessagesForConversation',
    GET_SMS_MESSAGES_FOR_USERS: 'GetSMSMessagesForUsers',
    GET_WA_MESSAGES_FOR_USERS: 'GetWAMessagesForUsers',
    GET_SMS_NUMBERS_FOR_USERS: 'GetSMSNumbersForUsers',
    GET_WA_NUMBERS_FOR_USERS: 'GetWANumbersForUsers',
    GROUP_CONVERSATIONS_TYPE : 'Groups',
    NUMBER_CONVERSATIONS_TYPE: 'Numbers',
});

