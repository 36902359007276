import React, { useState, useEffect } from 'react';
import '../../containers/dashboard/index.css';
import FileDrop from 'react-file-drop';
import { FormGroup, Col, Label, Input, Row } from 'reactstrap';
import * as STRINGS from '../../strings/english';


function UploadFile(props) {
    const fileNameWithStyle = <div className='fs-12'>
        <span style={{ textDecoration: 'underline' }}>{STRINGS.CHOOSE_A_FILE}</span>{' '}
        {STRINGS.OR_DRAG_IT_HERE}
    </div>
    const initializeFile = {
        selectedFile: null,
        fileName: fileNameWithStyle,
        loaded: 0,
        dataFile: null
    }

    const [file, setFile] = useState(initializeFile);
    useEffect(() => {
        props.file(file)
    }, [file])


    const handleDuration = (file) => {
        let duration = 0
        try {
            let reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = () => {
                let media = new Audio(reader.result);
                media.onloadedmetadata = () => {
                    duration = media.duration
                    props.duration(duration)
                    return duration
                }
            };
        } catch (e) {
            console.log(e);
        }

    }

    const handleInput = async (files) => {
        if (files.target && files.target.files) {
            let file = files.target.files[0]
            let duration = await handleDuration(file)
            setFile({
                selectedFile: file,
                loaded: 0,
                fileName: file.name,
                dataFile: file,
                duration: duration
            })
        }
    }

    const handleDrop = (files) => {
        if (files) {
            let file = files[0]
            let duration = handleDuration(file)
            setFile({
                selectedFile: file,
                loaded: 0,
                fileName: file.name,
                dataFile: file,
                duration: duration
            })
        }
    };


    return <Row>
        <Label className='fs-12'>{STRINGS.UPLOAD_AUTO_ATTENDANT_MENU_RECORDING}</Label>
        <Col
            md='12'
            className="Rectangle2"
            style={{ width: '100%' }}
        >
            <FileDrop onDrop={(files, event) => handleDrop(files, event)}>
                <img src='assets/group-16.png' alt="" className="mt-4" style={{
                    height: '2.8rem',
                    width: '2.5rem'
                }} />
                <FormGroup
                    row
                    className='fs-12'
                    style={{ marginLeft: '12rem', marginTop: '0.75rem' }}
                >
                    <Label
                        className=""
                        style={{
                            position: 'relative',
                            textDecoration: 'underline'
                        }}>
                        <Input
                            accept=".mp3, .wav, .m4a"
                            style={{
                                opacity: 0,
                                position: "absolute"
                            }}
                            onChange={(files) => handleInput(files)}
                            type="file"
                            id="uploadFile"
                        />
                        <div
                            className='small'
                            style={{ color: '#3c3f4a' }}>
                        </div>
                        {STRINGS.CHOOSE_A_FILE} </Label>
                    <div style={{ marginLeft: '0.2rem' }}>{STRINGS.OR_DRAG_IT_HERE}</div>
                </FormGroup>
            </FileDrop>
        </Col>

    </Row>
}

export default UploadFile