import React, { useState, useEffect } from 'react';
import './userTermsModal.css';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import * as STRINGS from '../../../strings/english';

export function UserTermsModal(props) {

    const [modal, setModal] = useState(props.userTermsModalData.toggle);

    const toggle = () => {
        if (!props.userTermsModalData.toggle) {
            setModal(false);
        }
        else setModal(true);
    };

    useEffect(() => {
        toggle();
    }, [props.userTermsModalData.toggle])

    let termsURL = props.item.TermsURL;
    let termsRAW = props.item.TermsRAW;
    let termsType = props.item.TermsType;
    let termsString ='';

    if (termsType == 2) {
        termsString = STRINGS.TERMS_AND_CONDITIONS;
    } else {
        termsString = STRINGS.PRIVACY_POLICY;
    }


    return (
        <div>
            <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={'static'}
                size={'md'}
            >
                <ModalHeader className='block'>
                    <div className='user-terms-modal-header'>
                        {STRINGS.PLEASE_CONFIRM_THE_TERMS}
                    </div>
                </ModalHeader>
                <ModalBody>
                    {termsURL ?
                        <a
                            className='user-terms-modal-body-link'
                            href={termsURL} 
                            target='_blank'
                        >
                            {termsString}                            
                        </a>
                        : termsRAW}
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => props.approveTerms(props.userTermsModalIndex)}
                        color=""
                        style={{ color: 'white' }}
                        className="btnColor2"
                    >
                        {STRINGS.OK}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default UserTermsModal;