import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledDropdown,
    ModalFooter
} from 'reactstrap';
import './advancedFeatures/advance.css';
import './index.css';
import FeatureService from "../../services/FeatureService";
import * as STRINGS from '../../strings/english';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import axios from '../../axios';
import { formatNumbers } from '../../services/formatPhoneNumber';

function LoginGroups(props) {

    const [isLoader, setIsLoader] = useState(false);
    const [loginGroups, setLoginGroups] = useState({});

    useEffect(() => {
        getLoginGroups();
    }, [])    

    function getLoginGroups() {
        setIsLoader(true);
        FeatureService.getManagedLoginGroups().then(res => {
            if (res.status === 200) {
                setIsLoader(false);
                setLoginGroups(res.data.GroupsMembers);
            }
        }).catch(error => {
            setIsLoader(false);
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    }

    const renderTableBody = () => {
        let options = [];
        let i = 0;
        if (loginGroups && Object.keys(loginGroups).length !== 0) {
            Object.keys(loginGroups).forEach((groupKey) => {
                Object.keys(loginGroups[groupKey]).forEach((userKey) => {
                options.push(<tr key={`loginGroup-listRow${i}`} className='tr'>
                    <td>{loginGroups[groupKey][userKey].GroupId}</td>
                    <td>{loginGroups[groupKey][userKey].GroupName}</td>
                    <td>{loginGroups[groupKey][userKey].Username}</td>
                    <td>{loginGroups[groupKey][userKey].UserDivert}</td>
                    <td>{loginGroups[groupKey][userKey].LoggedInStatus == 0 ? 'Logged Out' : 'Logged In'}</td>
                </tr>
                )
                i++;
                })
                options.push(<tr className='tr'><td>&nbsp;</td></tr>)
            })
        }
        return options;
    };

    function divertsTable() {
        return (
            <div>
                <table className="table">
                    <thead className="fs-12 gray thead">
                        <tr className="trh" >
                            <th>Group Id</th>
                            <th>Group Name</th>
                            <th>Username</th>
                            <th>Destination</th>
                            <th>Logged in status</th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: "168px", minHeight: '108px' }} className="tbody aaBody">
                        {renderTableBody()}
                    </tbody>
                </table>
            </div>
        );
    }


    return <span>
        <ModalHeader
            style={{
                display: "flow-root",
            }}
        >
            <div className="fs-22">
                <div>Account Managed Login Groups</div>
            </div>
        </ModalHeader>
        <ModalBody>
            <Form style={{ padding: "1.5rem  1rem" }}>

                {isLoader ?
                    <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }}
                        src='assets/spikkoloaderanimation.svg'
                        alt=""
                        className="position-absolute" />
                    : null}

                <FormGroup style={{ height: "20%" }}>
                    {loginGroups && Object.keys(loginGroups).length > 0 ? divertsTable() : null}
                </FormGroup>

            </Form>
        </ModalBody>
        <ModalFooter style={{ display: "block" }}>
            <FormGroup row className="justify-content-end">
                <Col md={5}>
                    <Button 
                        className="btnColor2 fontColor fs-14 no-border" 
                        style={{ width: "100%", color: 'white' }}
                        size="lg"
                        onClick={() => props.cancelOnClick()}>{STRINGS.OK}</Button></Col>
            </FormGroup>
        </ModalFooter>
    </span>
}

export default LoginGroups;