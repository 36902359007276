import FileSaver from 'file-saver';
const { Parser } = require('json2csv');


export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}

export const formatMessagesDate = (date) => {
    let showLocalTime = localStorage.getItem('showLocalTime') === 'true';
    let newDate;
    if (showLocalTime) {
        newDate = new Date(date + 'Z').toLocaleString();
    } else newDate = date;
    let d = new Date(newDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}

export const formatTime = (date, withMilliseconds = false) => {
    let d = new Date(date);
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let milliseconds = d.getMilliseconds()
    let fullTime =
        `${addZeroToNumber(hours)}:${addZeroToNumber(minutes)}${withMilliseconds ? `:${addZeroToNumber(milliseconds)}` : ''}`;
    return fullTime;
}

export const formatMessagesTime = (date, withMilliseconds = false) => {
    let showLocalTime = localStorage.getItem('showLocalTime') === 'true';
    let newDate;
    if (showLocalTime) {
        newDate = new Date(date + 'Z').toLocaleString();
    } else newDate = date;
    let d = new Date(newDate);
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let milliseconds = d.getMilliseconds()
    let fullTime =
        `${addZeroToNumber(hours)}:${addZeroToNumber(minutes)}${withMilliseconds ? `:${addZeroToNumber(milliseconds)}` : ''}`;
    return fullTime;
}

const addZeroToNumber = (num) => {
    if (num < 10) {
        return '0' + num;
    }
    else return num;
}

export const downloadCsv = async (data) => {
    const parser = new Parser();
    const csv = parser.parse(data);
    const csvData = new Blob(["\ufeff" + csv], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, `user-conversation-${new Date().toISOString()}.csv`);
};

export const downloadDocx = async (docxData, filename) => {
    FileSaver.saveAs(docxData, filename);
};

export const calcDateFromToDayDateTo7DaysBefore = () => {
    let days = 7; // Days you want to subtract
    let date = new Date();
    let last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    return last;
}

export const setTimeForDate = (date, status) => {
    if (date) {
        if (status === "start") {
            date.setUTCHours(0)
            date.setUTCMinutes(0)
            date.setUTCSeconds(0)
            date.setUTCMilliseconds(0)
        } else {
            date.setUTCHours(23)
            date.setUTCMinutes(59)
            date.setUTCSeconds(59)
            date.setUTCMilliseconds(0)
        }
        return date
    }
}

export const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
}

export const arrayContains = (needle, arrhaystack) => {
    return (arrhaystack.indexOf(needle) > -1);
}

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const urlify = (text) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, '<a class="message-link" target="_blank" href="$1">$1</a>')
}

export const findDuplicatesId = (arr, idKey) => {
    const uniqueIds = [];
    const unique = arr.filter(element => {
        const isDuplicate = uniqueIds.includes(element[idKey]);
        if (!isDuplicate) {
            uniqueIds.push(element[idKey]);
            return true;
        }
        return false;
    });
    return unique;
}
