import React from 'react';
import './personalUserSettingsModal.css';
import { CustomInput } from 'reactstrap';

export function PersonDetails(props) {

    const headerOfCheckbox = 'Active';

    const dynamicColumns = () => {
        let arr = [];
        let keysArr = [];
        for (const key in props.integrationParameters) {
            keysArr.push(key);
            if (key !== headerOfCheckbox) {
                arr.push(
                    <input
                        key={`dynamic-${key}-input`}
                        name={key}
                        id={key + props.index}
                        autoComplete={'chrome-off'}
                        type='text'
                        className='personal-user-settings-modal-details-input'
                        onChange={(e) => props.personDetailsOnChange(e, props.item)}
                        value={props.inputsData[props.item.UserName] && props.inputsData[props.item.UserName][key]
                            ? props.inputsData[props.item.UserName][key] : ''}
                    />)
            }
            else {
                arr.push(
                    <CustomInput
                        key={`dynamic-${key}-input`}
                        id={key + props.index}
                        name={key}
                        className={`personal-user-settings-modal-details-checkbox 
                            ${key === headerOfCheckbox ? `personal-user-settings-modal-details-active-checkbox` : ``}`}
                        onChange={(e) => props.personDetailsOnChange(e, props.item)}
                        type="checkbox"
                    />
                )
            }

        }
        let indexOfActive = keysArr.indexOf(headerOfCheckbox);
        let activeInput = arr.splice(indexOfActive, 1);
        arr[arr.length] = activeInput;
        return arr;
    }

    return (
        <div className='personal-user-settings-modal-details'>
            <div className='personal-user-settings-modal-details-email'>
                {props.email}
            </div>
            <div className='personal-user-settings-modal-details-name'>
                {props.fullName}
            </div>
            <div className='personal-user-settings-modal-details-inputs'>
                {dynamicColumns()}
            </div>
        </div>
    )
}