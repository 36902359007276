import React, { Component } from 'react';
import './index.css';
import axios from '../../axios';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import PasswordExpiredModal from '../dashboard/passwordExpiredModal';
import { data as permissionData } from '../../permissionScheme/permissionData';
import { savePermissionData } from '../../services/userRoles';

class Login extends Component {
    state = {
        mail: '',
        isRemember: false,
        isLoader: false,
        on: true
        // passwordExpired: false
    }

    login = () => {
        if (this.exmail.value && this.expass.value) {
            this.setState({ isLoader: true })
            let data;
            axios
                .post('/Provisioning/Login', {
                    "username": this.exmail.value,
                    "password": this.expass.value
                })
                .then(response => {
                    this.setState({ isLoader: false });
                    data = response.data;
                    if (data.VerificationType === 'MFA') {
                        this.goToTwoFactor();
                    } else {
                        localStorage.setItem('auth-data', data.HashedPassword);
                        this.state.isRemember ? localStorage.setItem('remember', 'yes') : localStorage.setItem('remember', 'no');
                        localStorage.setItem('email', this.exmail.value);
                        localStorage.setItem('showLocalTime', 'true');
                        this.setState({ mail: this.exmail.value });
                        savePermissionData(this.goToDashboard);
                    }
                    // if (!data.PasswordExpired) {
                    //     localStorage.setItem('auth-data', data.HashedPassword)
                    //     this
                    //         .props
                    //         .history
                    //         .push('/', {mail: this.state.mail,redirect: true})
                    // }
                    // else if (data.PasswordExpired) {
                    //     this.setState({ passwordExpired: true });
                    // }
                })
                .catch(error => {
                    this.setState({ isLoader: false })
                    alert(error.response
                        ? error.response.data.Description || error.response.data.Message : error)
                });
        }
        else {
            alert('Missing details')
        }
    }

    loginForManager = (token, username) => {
        this.setState({ on: false });
        axios.get(`/Account/GetUserByUsername/${username}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(response => {
            if (response && response.data) {
                localStorage.setItem('email', username);
                localStorage.setItem('auth-data', token);
                this.setState({ mail: username }, () => {
                    savePermissionData(this.goToDashboard);
                });
            }
        }).catch(error => {
            this.setState({ isDashboardLoader: false })
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            }
        });
    }

    saveRefId = (refId) => {
        localStorage.setItem('RefId', refId);
    }

    goToDashboard = () => {
        this.props.history.push('/', { mail: this.state.mail, redirect: true });
    }

    goToTwoFactor = () => {
        const data = {
            redirect: true,
            email: this.exmail.value,
        }
        this.props.history.push('/two_factor', data);
    }

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        
        let newParams = new URLSearchParams();
        for (const [name, value] of params) {
            newParams.append(name.toLowerCase(), value);
        }

        let token = newParams.get('token');
        let username = newParams.get('username');
        let partner = newParams.get('partner');
        if (token && username) {
            this.loginForManager(token, username);
        }
        else if (partner) {
            this.saveRefId(partner);
        }
        else if (localStorage.getItem('remember', 'yes')) {
            this
                .props
                .history
                .push('/', { mail: this.state.mail, redirect: true })
        }
    }

    // modalCancel = () => {
    //     this.setState({ passwordExpired: !this.state.passwordExpired });
    // }

    render() {
        return (
            this.state.on && <div className=" wrapper row justify-content-center m-0 ">
                <img src='assets/mobile2crm-logo.png' alt="" className="loginImg" />
                <Form className="loginForm col-3">
                    <h4>Login by mail</h4>
                    <FormGroup>
                        <Label for="loginEmail">Company / Organization name</Label>
                        <Input
                            type="email"
                            name="email"
                            id="loginEmail"
                            innerRef={(exmail) => {
                                this.exmail = exmail
                            }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="loginPassword">Password</Label>
                        <Input
                            innerRef={(expass) => {
                                this.expass = expass
                            }}
                            type="password"
                            name="password"
                            id="loginPassword" />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                onChange={() => this.setState({
                                    isRemember: !this.state.isRemember
                                })}
                                type="checkbox" />
                            Remember me
                        </Label>
                        <Link className="float-right" to="/resetPassword">Forgot password?</Link>
                    </FormGroup>
                    <br />
                    <Button
                        style={{
                            backgroundColor: ' #2EC2FE'
                        }}
                        onClick={() => this.login()}
                        size="lg"
                        block>Sign in
                    </Button>
                    <br />
                    <div >
                        No account?
                        <Link to="/creatAnAccount">
                            Sign up</Link>
                    </div>
                </Form>
                {/* {<PasswordExpiredModal
                    match={this.props.match}
                    passwordExpired={this.state.passwordExpired}
                    history={this.props.history}
                    location={this.props.location}
                    modalCancel={this.modalCancel}
                />} */}
                {this.state.isLoader ?
                    <img style={{ width: '100px', height: 'auto' }} src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute loginImg" />
                    : null}
            </div>
        );
    }
}

export default Login;
