import React, { useState, useEffect } from 'react';
import { Button, Input, FormGroup, Label, Form, ModalHeader } from 'reactstrap';
import './index.css'
import ProvisioningService from '../../services/ProvisioningService'

function CRMFormModal(props) {

    const [parameters, setParameters] = useState(null)
    const [isLoader, setIsLoader] = useState(false)

    useEffect(() => {
        if (!parameters) {
            let active = props.details.Active === "true"
            let params = {};
            for (const key in props.details) {
                key === "Active"
                    ? params[key] = active
                    : params[key] = { value: props.active ? props.details[key] : "", validation: true }
            }
            setParameters(params)
        }
    })

    function handleValues(key, event) {
        let value = event.target.value
        let validation = !parameters[key].length > 0
        setParameters({ ...parameters, [key]: { value: value, validation } })
    }

    function setValidation(key, status) {
        let params = { ...parameters, [key]: { value: parameters[key].value, validation: status } };
        setParameters(params)
    }

    const saveChanges = () => {
        let validation = true
        for (const key in props.details) {
            if (key !== "Active") {
                if (parameters[key].value.length === 0) {
                    setValidation(key, false)
                    validation = false
                }
            }
        }
    //**for validation chenge the true with validation */
        if (true) {
            let body = {
                isactive: parameters.Active,
                integrationname: props.mainDetails.IntegrationName,
            }
            Object.keys(parameters).map((key, index) => {
                if (key !== "Active") {
                    body[key] = parameters[key].value
                }
                return null
            })
            setIsLoader(true)
            ProvisioningService.setActiveIntegrationOptions(body)
                .then(() => {
                    setIsLoader(false)
                    props.refreshData()
                    props.toggel(false)
                })
                .catch(e => {
                    alert(e)
                    setIsLoader(false)
                })
        }
    };

    function renderInputFields() {
        if (parameters !== null) {
            return Object.keys(parameters).map((item, index) => {
                if (item !== "Active") {
                    return (
                        <FormGroup key={index}>
                             {/**for validation change the "" to "red" for red color border*/}
                            <Label for={item} className={!parameters[item].validation ? "" : null}>{item}</Label>
                            <Input type="text" name={item} id={item}
                                value={props.active ? parameters[item].value : parameters[item].value}
                                placeholder={!props.active ? props.details[item] : ""}
                                onChange={(e) => handleValues(item, e)} />
                        </FormGroup>
                    )
                }
                return null
            })
        }
    }

    return (
        <span>
            {parameters ?
                <ModalHeader
                    style={{ display: "flow-root" }}>
                    <div className="d-flex" style={{ padding: "1.5rem 1rem 0 1rem" }}>
                        <FormGroup style={{ width: "-webkit-fill-available" }}>
                            <div className="img"><img src={props.mainDetails.LogoURL} alt="" /></div>
                            <div className="fs-22 mt-3 mb-3" style={{ textAlign: "center" }}>{`Log in with ${props.mainDetails.IntegrationName}`}</div>
                        </FormGroup>
                        <div className='custom-control custom-switch d-flex justify-content-end isolate-the-design'>
                            <input
                                type='checkbox'
                                className='custom-control-input'
                                id={`customSwitches2-`}
                                checked={parameters.Active}
                                onChange={() => { setParameters({ ...parameters, "Active": !parameters.Active }) }}
                            />
                            <label className='custom-control-label' htmlFor={`customSwitches2-`} />
                        </div>
                    </div>
                </ModalHeader>
                : null}

            <Form className="crm-modal">
                {isLoader ? <img
                    style={{ width: '100px', height: 'auto', right: '38%', zIndex: '100' }}
                    src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                    : null}

                {renderInputFields()}

                <Button className="crm-save btnColor2" onClick={() => saveChanges()}>Save</Button>
            </Form>
        </span>
    )
}
export default CRMFormModal