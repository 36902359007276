import React, { useState } from 'react';
import ReportModal from './modal';
import { USER_REPORT_ENUM } from '../../../enum/index';
import axios from '../../../axios';

export const ModelManagement = (props) => {

    const getWANumbersForUsers = (body) => {
        return axios.post('/SMS/GetWANumbersForUsers', body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        })
    };

    const getWAMessagesForUsers = (body) => {
        return axios.post('/SMS/GetWAMessagesForUsers', body, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            }
        })
    }

    const messagesModal = () => {
        return (
            <ReportModal
                getNumbersData={getWANumbersForUsers}
                getMessagesForUsers={getWAMessagesForUsers}
                isSpecificUser={props.isSpecificUser}
                currentUser={props.currentUser}
                users={props.users}
                headerText={props.headerText}
                pathname={'messages_sms_report'}
            />
        )
    }

    const returnModals = (type) => {
        switch (type) {
            case USER_REPORT_ENUM.MESSAGES_MODAL_TYPE:
                return messagesModal();

            default:
                return <React.Fragment />
        }
    }

    return returnModals(props.modalType);

}