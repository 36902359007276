import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from 'react-router-dom';
import './twoFactor.css';
import ReactCodeInput from 'react-code-input';
import * as STRINGS from '../../strings/english';
import * as mfaConf from '../../services/mfaConf';
import { Button, Spinner } from 'reactstrap';
import axios from '../../axios';
import { savePermissionData } from '../../services/userRoles';


function TwoFactor(props) {

    const [isVerifyLoader, setIsVerifyLoader] = useState(false);
    const [pinCode, setPinCode] = useState('');
    const redirect = props.location.state && props.location.state.redirect ? true : false;

    const handleUserKeyPress = useCallback(event => {
        const { keyCode } = event;
        const elmDataId = event.target.getAttribute('data-id');
        if (keyCode === 13 && elmDataId === '5') {
            verifyOnClick();
        }
    }, [pinCode]);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress])

    const verifyOnClick = () => {
        if (pinCode.length === mfaConf.fieldsLength) {
            setIsVerifyLoader(true);
            axios.post(
                '/Provisioning/MFALogin',
                {
                    Username: props.location.state.email,
                    MFACode: pinCode,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                    }
                }
            ).then(res => {
                localStorage.setItem('auth-data', res.data.HashedPassword);
                localStorage.setItem('email', props.location.state.email);
                localStorage.setItem('showLocalTime', 'true');
                savePermissionData(goToDashboard);
                setIsVerifyLoader(false);

            }).catch(error => {
                setIsVerifyLoader(false);
                alert(error.response && error.response.data &&
                    error.response.data.ErrorCode === 33
                    ? STRINGS.WRONG_AUTHENTICATION_CODE : error)
            });
        } else {
            alert(`${STRINGS.PLEASE_MATCH_THE_REQUESTED_FORMAT}`);
        }
    }

    const goToDashboard = () => {
        props.history.push('/', { mail: props.location.state.email, redirect: true });
    }

    if (redirect) {
        return (
            <div className="two-factor-main">
                <div className="two-factor-content-div">
                    <h3 className='two-factor-code-input-header'>
                        {STRINGS.AUTHENTICATION_CODE}
                    </h3>
                    <ReactCodeInput
                        type='number'
                        fields={mfaConf.fieldsLength}
                        {...mfaConf.reactCodeInputProps}
                        onChange={(value) => setPinCode(value)}
                        name='two-factor-code-input'
                    />
                    <Button
                        className='two-factor-verify-btn btnColor2'
                        onClick={verifyOnClick}
                        color=""
                    >
                        {isVerifyLoader ?
                            <Spinner size='md' /> :
                            STRINGS.VERIFY}
                    </Button>
                    <p className='two-factor-explanation-input-code'>
                        {STRINGS.EXPLANATION_INPUT_CODE}
                    </p>
                </div>
            </div>
        );
    } else {
        return (<Redirect to='/login' />);
    }
}

export default TwoFactor;