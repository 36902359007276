import React from 'react';
import './userMessageCube.css';
import * as STRINGS from '../../../strings/english';
import { FileMessage } from './fileMessage';
import { urlify } from '../../../services/tools';
import DOMPurify from 'dompurify';


export const UserMessageCube = (props) => {

    const message = props.message;
    const messageStatusFailed = 3;
    let dirty = urlify(message.Message);
    const clean = message.MessageType !== 7 ?
        DOMPurify.sanitize(dirty) : '';

    return (
        <div className='user-message-cube-main'>
            <div className='user-message-cube-number-and-time'>
                <div className='user-message-cube-number'>
                    {props.userNumber}

                </div>
                <div className='user-message-cube-time'>
                    {props.sendingTime}
                </div>
            </div>
            <div className='user-message-cube-content'>
                <div
                    className='user-message-cube-content-text'
                    dangerouslySetInnerHTML={{ __html: clean }}
                />
                {(message.HasMedia ||
                    message.HasAttachment ||
                    message.MessageType === 7) &&
                    <FileMessage
                        mediaUrl={props.mediaUrl}
                        mediaType={props.mediaType}
                        fileName={props.fileName}
                        message={message}
                    />}
                {props.isSMSreport && message.Status === messageStatusFailed &&
                    <div className='user-message-cube-not-delivered'>
                        <img src='assets/error.svg' className='user-message-cube-not-delivered-icon' />
                        {STRINGS.NOT_DELIVERED}
                    </div>}
            </div>
        </div>
    )
}