import React, {Component} from 'react';
import './index.css';
import axios from '../../axios';
import sourceVariable from '../../globalStartSession';
import { Redirect } from 'react-router-dom'
import {
    Button,
    Form,
    FormGroup,
    Col,
    Progress,
    Row,
    Modal,
    Label
} from 'reactstrap';
import {Link} from 'react-router-dom';

class SelectPayment extends Component {

    state = {
        isLoader3:false,
        payMent: '',
        iframeModal:false,
        iframeURL:'',
        redirect:this.props.location.state&&this.props.location.state.redirect?this.props.location.state.redirect:false,
    }
    activButtonClick(){  
        this.setState({isLoader3:true})
        axios
                .post('/Provisioning/StartSession', {"source": sourceVariable})
                .then(response => {
                    const data = response.data;
                    axios.post('/Payment/ChangePaymentMethod', {
                        "accountid":this.props.location.state.countId ,
                        "paymentmethod": this.state.payMent
                    }, {
                        headers: {
                            Authorization:'Bearer ' + data.SessionKey
                        }
                    }).then(response => {
                        if(this.state.payMent==='PayPal'){

                            window.open(response.data.RedirectURL, "_blank");
                            this.setState({isLoader3:false})
                        }
                      else{  this.setState({iframeURL:response.data.RedirectURL, iframeModal:true,isLoader3:false})}
                    }).catch(error => {
                        this.setState({isLoader3:false})
                        alert(error.response
                            ? error.response.data.Description||error.response.data.Message                            : error)
                    });
                }).catch(error => {
                    this.setState({isLoader3:false})
                    alert(error.response
                        ? error.response.data.Description||error.response.data.Message                        : error)
                });
    }
    next() {
        let packageregistrationid = this.props.location.state.packageId
        if (packageregistrationid) {
            this.setState({isLoader3: true})
            axios.post('/Payment/PayForExistingPackage', {
                "packageregistrationid": packageregistrationid,
                "username": localStorage.getItem('email')
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data')
                }
            }).then(response => {
                this.setState({isLoader3: false})
                this
                    .props
                    .history
                    .push('/', {redirect: true})
            }).catch(error => {
                this.setState({isLoader3: false})
                this
                    .props
                    .history
                    .push('/', {redirect: true})
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message
                    : error)
            });
        } else {
            this
                .props
                .history
                .push('/', {redirect: true})
        }
    }
    modalToggle(value) {
        this.setState({
            [value]: !this.state[value]
        })
    }
   
    render() {
        if(this.state.redirect){
        return (
            <div className=" wrapper row m-0 ">
                <div className="divCountImg">
                    <img src='assets/Capture3.PNG' alt="" className="countImg"/></div>
                <Form className="loginForm">
                {this.state.isLoader3
                        ? <img
                        style={{
                        width: '100px',
                        height: 'auto',
                        right: '45%',
                        zIndex: '100'
                    }}
                        src='assets/spikkoloaderanimation.svg'
                        alt=""
                        className="position-absolute"/>
                : null}
                    <div className="d-flex">
                        <h4 className="my-3">Select payment method</h4>
                        <Button
                            onClick={() =>  this
                                .props
                                .history
                                .push('/',{redirect: true})}
                            className="btn m-2 ml-auto"
                            color={null}
                            style={{
                            background: 'rgba(255, 255, 255, 0.07)',
                            color: 'white'
                        }}>Skip for now</Button>
                    </div>
                    <div className="mt-3">Step 4</div>
                    <Progress animated className="mb-3" value="100"/>
                    <Row className="my-3">
                        <Col >
                            <Label style={{fontSize:'14px'}} className="font-weight-bold">How do you want to pay?</Label>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col className="d-flex" md={12}>
                            <span style={{fontSize:'14px'}} className="font-weight-bold"><span className="border mr-2 p-2" style={{borderRadius:'50%'}}>&#10004;</span>Credit card</span>
                            <Button
                                onClick={() => {this.setState({payMent: 'CreditGuard'});this.activButtonClick('CreditGuard')}}
                                className="btnColor2 ml-auto px-5">Activate</Button>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col className="d-flex" md={12}>
                            <span className="font-weight-bold" style={{fontSize:'14px'}}><img className="mr-2" src='assets/group-17.png' alt=""/>Paypal</span>
                            <Button
                                onClick={() => {this.setState({payMent: 'PayPal'});this.activButtonClick('CreditGuard')}}
                                className="btnColor2 ml-auto px-5">Activate</Button>
                        </Col>
                    </Row>
                    <FormGroup className="d-flex mt-5">
                        <Link
                            to={'/creatAnAccount'}
                            style={{
                            color: 'white'
                        }}>Back</Link>
                        <Button
                            style={{
                            backgroundColor: '#44d551'
                        }}
                            className="col-3 col-sm-5 ml-auto"
                            onClick={() => this.next()}
                            block>
                            <img src='assets/checkmark.png' alt="" className="mr-1"/>Next
                        </Button>
                    </FormGroup>
                </Form>
                <Modal className="iframeModal" isOpen={this.state.iframeModal} toggle={() => this.modalToggle('iframeModal')}><iframe src={this.state.iframeURL} title="aaa" width='1000px' height='720px' name="theFrame"></iframe>
                <Button color={''} style={{color:'white'}} className="btnColor2 col-1 ml-auto mr-2 my-2" onClick={()=>this.setState({iframeModal:false})}>OK</Button>
                </Modal>
            </div>
        );
    }else{
        return(<Redirect to='/login' />)
    }
    }
}

export default SelectPayment;