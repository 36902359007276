import React, { useEffect, useState } from 'react';
import { eventEnum } from './enum';

const Timer = (props) => {

    const [second, setSeconds] = useState(0);

    const formatSeconds = (secs) => {
        const pad = (n) => n < 10 ? `0${n}` : n;

        const h = Math.floor(secs / 3600);
        const m = Math.floor(secs / 60) - (h * 60);
        const s = Math.floor(secs - h * 3600 - m * 60);
        return `${pad(h)}:${pad(m)}:${pad(s)}`;
    }

    useEffect(() => {
        let interval;
        if (props.status === eventEnum.ANSWERCALL_IN ||
            props.status === eventEnum.ANSWERCALL_OUT) {
            interval = setInterval(() => {
                setSeconds(prevSecond => prevSecond + 1);
            }, 1000);
        }
        return () => {
            clearInterval(interval)
        };
    }, [props.status])

    return (
        <div>
            {formatSeconds(second)}
        </div>
    );
}

export default Timer;