import React, { useState, useEffect, useRef } from 'react';
import AccountService from "../../services/AccountService";
import './index.css';
import SideBar from '../../components/sideBar';
import NavBar from '../../components/navBar';
import * as STRINGS from '../../strings/english';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import Select from 'rc-select';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Col,
    FormGroup,
    Label,
    Tooltip,
    Modal,
} from 'reactstrap';
import {
    formatNumbers,
    formatInputValueWithout,
    convertFormatNumToRegularNum
} from '../../services/formatPhoneNumber';
import axios from '../../axios';
import NewContactModal from './newContactModal';



function Contacts(props) {

    const [user, setUser] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [modal, setModal] = useState(false);
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [contactId, setContactId] = useState('');
    const [isNewContact, setIsNewContact] = useState(true);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const initialPaginationState = {
        pageCount: 10,
        currentPage: 1
    }
    const [pagination, setPagination] = useState(initialPaginationState);
    const [isLoader, setIsLoader] = useState(false);

    const authorization = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
        }
    }

    useEffect(() => {
        const res = AccountService.getUserByUsername(localStorage.getItem('email'));
        setUser(res.data);
        getUserContacts();
    }, [])

    const getUserContacts = () => {
        setIsLoader(true);
        axios.post(`/Account/GetUserContacts`, {}, authorization).then(res => {
            setIsLoader(false);
            let arrObj = [];
            Object.keys(res.data).forEach(e => arrObj.push(res.data[e]));
            setContacts(arrObj)
        }).catch(err => {
            console.log(err);
            setIsLoader(false);
        });
    }

    const signOut = () => {
        localStorage.clear();
        props.history.push('/login')
    }

    const toggle = () => setModal(!modal);

    const addContactOnClick = () => {
        if (name && number) {
            const data = {
                "ContactName": name,
                "ContactNumber": number,
            }
            const path = isNewContact ? 'InsertUserContact' :
                'UpdateUserContact';
            if (!isNewContact) {
                data.ContactId = contactId;
            }
            axios.post(`/Account/${path}`, data, authorization)
                .then(res => {
                    getUserContacts();
                    setModal(false);
                }).catch(err => {
                    console.log(err);
                })
        } else alert(STRINGS.MISSING_DETAILS)
    }

    const editContactOnClick = (contact) => {
        setIsNewContact(false);
        setName(contact.ContactName);
        setNumber(contact.ContactNumber);
        setContactId(contact.Id);
        setModal(true);
    }

    const handelPagination = (name, value) => {
        setPagination({ ...pagination, [name]: value });
    }

    const plusIconOnClick = () => {
        setModal(!modal);
        setIsNewContact(true);
        setName('');
        setNumber('');
    }

    const deleteContactOnClick = (contact) => {
        if (window.confirm(STRINGS.ARE_YOU_SURE)) {
            const data = {
                "ContactId": contact.Id,
            }
            axios.post('/Account/DeleteUserContact', data, authorization)
                .then(res => {
                    console.log(res);
                    getUserContacts();
                }).catch(err => {
                    console.log(err);
                })
        }
    }

    const contactsListRows = () => {
        let x = pagination.currentPage;
        let y = pagination.pageCount;
        let max = contacts.length > (x * y) ? (x * y) : contacts.length;
        const currentArray = contacts.slice((x - 1) * y, max);
        let arr = []
        currentArray.map((elm, i) => {
            arr.push(
                <div
                    className='contact-list'
                    key={`conttact-#${i}`}
                >
                    <div
                        className='contact-list-row-name'
                    >
                        {elm.ContactName}
                    </div>
                    <div
                        className='contact-list-row-number'
                    >
                        {formatNumbers(elm.ContactNumber)}
                    </div>
                    <span
                        className='contact-list-row-edit-icon-span'
                    >
                        <img
                            src='assets/pencil.svg'
                            alt='contact-edit-icon'
                            className='contact-list-row-edit-icon'
                            onClick={() => editContactOnClick(elm)}
                        />
                    </span>
                    <img
                        src='assets/trash.svg'
                        alt='contact-delete-icon'
                        className='contact-list-row-delete-icon'
                        onClick={() => deleteContactOnClick(elm)}
                    />
                </div>
            )
        })
        return arr;
    }

    const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <div>
            <div className="d-flex">
                <SideBar
                    active1={false}
                    active2={false}
                    active3={false}
                    active4={false}
                    active5={false}
                    active6={false}
                    active7={false}
                    active8={true}
                    homePage={() => props.history.push('/', { redirect: true })}
                    myPackagePage={() => props.history.push('/myPackages', { redirect: true })}
                    settingPage={() => props.history.push('/settings')}
                    crm_integration={() => props.history.push('/crm_integration')}
                    contactUs={() => props.history.push('/contactUs')}
                    realTimeMonitoring={() => props.history.push('/real_time_monitoring')}
                    graph={() => props.history.push('/charts')}
                    contacts={() => props.history.push('/contacts')}
                    user={user}
                />
                <div className="container-fluid" style={{ height: "100vh", overflow: "auto" }}>
                    <NavBar
                        logo={user ? user.LogoURL : ""}
                        signOut={() => signOut()}
                        mail={localStorage.getItem('email')}
                        title={STRINGS.CONTACTS}
                        homePage={() => { }}
                    />
                    <div>
                        <h4>{`${STRINGS.TOTAL} - ${contacts.length} ${STRINGS.CONTACTS_LOWER_C}`}</h4>
                    </div>
                    {isLoader
                        ? <img
                            style={{
                                width: '100px', height: 'auto', right: '50%', zIndex: '100'
                            }}
                            src='assets/spikkoloaderanimation.svg'
                            alt=""
                            className="position-absolute" />
                        : null}
                    <div>
                        <div className='contact-list-main'>
                            <div className='contact-list-header'>
                                <div className='contact-list-header-name'>
                                    {STRINGS.NAME_UPPERCASE}
                                </div>
                                <div className='contact-list-header-number'>
                                    {STRINGS.PHONE_NUMBER_UPPERCASE}
                                </div>
                            </div>
                            {contactsListRows()}
                        </div>
                    </div>
                    <img
                        src='assets/plus.svg'
                        alt='plus-icon'
                        className='contacts-plus-icon'
                        id='contacts-add-contact-icon'
                        onClick={plusIconOnClick}
                    />
                    <Tooltip
                        isOpen={tooltipOpen}
                        target="contacts-add-contact-icon"
                        toggle={tooltipToggle}
                    >
                        {STRINGS.ADD_A_CONTACT}
                    </Tooltip>
                    <Modal
                        isOpen={modal}
                        toggle={toggle}
                    >
                        <NewContactModal
                            toggle={toggle}
                            name={name}
                            number={number}
                            setName={setName}
                            setNumber={setNumber}
                            addContactOnClick={addContactOnClick}
                            isNewContact={isNewContact}
                            disabledNumber={false}
                        />
                    </Modal>
                    <div className='pagination-div'>
                        <Pagination className="pagination-new"
                            style={{
                                marginLeft: "40%",
                            }}
                            current={pagination.currentPage}
                            selectComponentClass={Select}
                            defaultPageSize={10}
                            pageSize={pagination.pageCount}
                            defaultCurrent={1}
                            onShowSizeChange={pageCount => handelPagination("pageCount", pageCount)}
                            onChange={currentPage => handelPagination("currentPage", currentPage)}
                            locale={localeInfo}
                            total={contacts ? contacts.length : 0}
                        />
                        <Col className="col-3 ml-auto">
                            <FormGroup>
                                <Label htmlFor="pagination" className="gray"></Label>
                                <UncontrolledDropdown id="pagination" className="poppins-regular">
                                    <span className='rtm-ff-poppins'>
                                        {STRINGS.NUMBER_OF_ENTRIES}&nbsp;&nbsp;
                                    </span>
                                    <DropdownToggle className="border mr-2" color={'white'} caret>
                                        {pagination.pageCount}&nbsp;&nbsp;&nbsp;&nbsp;
                                    </DropdownToggle>
                                    <DropdownMenu style={{ width: '3.125rem' }}>
                                        {[10, 15, 25, 50, 100].map((number, i) => {
                                            return <DropdownItem
                                                key={i}
                                                onClick={e => handelPagination("pageCount",
                                                    parseInt(e.currentTarget.textContent))}>
                                                {number}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </FormGroup>
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts;
