import React, { useState } from 'react';
import './userConversationsDetails.css';
import {
    formatMessagesDate,
    formatMessagesTime,
} from '../../../services/tools';
import { formatNumbers } from '../../../services/formatPhoneNumber';


export const UserConversationsDetails = React.forwardRef((props, ref) => {

    let highlight = props.entryForFheFirstTime && props.smsReportUserDetailsIndex === 0 && props.userConversationsIndex === 0 && props.index === 0;
    return (
        <div
            ref={ref}
            className='user-conversations-details-main'
            style={highlight ? { backgroundColor: '#ebf9ff' } : {}}
            onClick={() => { props.renderConversationMessages(props.detailsForMessages); props.userConversationsDetailsOnClick() }}
            id={props.id}
        >
            <div className='user-conversations-details-to'>
                <div className='user-conversations-details-to-left-side'>
                    <img
                        style={{ height: '1.188rem' }}
                        src='assets/arrow-right-small.png'
                    />
                    {props.isWAreport && <img
                        src={`assets/${props.userNameIcon}`}
                        className='user-conversations-user-name-icon'

                    />}
                    <div className='user-conversations-details-to-number'>
                        {formatNumbers(props.toNumber)}
                    </div>
                </div>
                <div className='user-conversations-details-to-latest-time'>
                    {`${formatMessagesDate(props.latestTime)} ${formatMessagesTime(props.latestTime)}`}
                </div>
            </div>
        </div>
    )
})