import React, { useState } from 'react';
import {
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Input,
    Button,
    Label,
} from 'reactstrap';
import './resetPasswordModal.css';
import * as STRINGS from '../../strings/english';
import axios from '../../axios';

export default function ResetPasswordModal(props) {

    const [newPass, setNewPass] = useState('');
    const [newConfirmPass, setNewConfirmPass] = useState('');
    const [email, setEmail] = useState('');

    const resetByAdminType = 'resetByAdmin';
    const resetByEmailType = 'resetByEmail';

    const submitOnClick = () => {
        if (props.resetPassModalType === resetByAdminType &&
            newPass && newConfirmPass) {
            if (newPass === newConfirmPass) {
                if (window.confirm(STRINGS.ARE_YOU_SURE)) {
                    resetByAdminRequest();
                }
            } else {
                alert(STRINGS.PASSWORDS_DO_NOT_MATCH)
            }
        } else if (props.resetPassModalType === resetByEmailType) {
            if (window.confirm(STRINGS.ARE_YOU_SURE)) {
                resetByMailRequest();
            }
        } else {
            alert(STRINGS.INPUT_MUST_BE_FILLED_OUT);
        }
    }

    const resetByAdminRequest = () => {
        const data = {
            Email: props.userData.UserName,
            TempPassword: newPass,
        };
        axios.post(
            '/Provisioning/ForcePasswordExpiration',
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data'),
                }
            }
        ).then(res => {
            if (res.data.Status === "success") {
                alert(STRINGS.THE_OPERATION_WAS_COMPLETED_SUCCESSFULLY);
            } else if (res.data.Status === "fail") {
                alert(res.data.Message);
            }
            props.resetByAdminToggle();
        }).catch(error => {
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    }

    const resetByMailRequest = () => {
        const data = {
            Email: email,
            ReturnURL: "",
            AccountID: props.userData.AccountId
        }
        axios.post(
            '/Provisioning/ResetPasswordForUser',
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('auth-data'),
                }
            }
        ).then(res => {
            if (res.status === 200) {
                alert(STRINGS.THE_OPERATION_WAS_COMPLETED_SUCCESSFULLY);
                props.resetByAdminToggle();
            }
        }).catch(error => {
            alert(error.response
                ? error.response.data.Description || error.response.data.Message : error)
        });
    }


    return (
        <div>
            <ModalHeader className="reset-pass-modal-header">
                {props.resetPassModalType === resetByAdminType &&
                    <React.Fragment>
                        {STRINGS.TEMPORARY_PASSWORD_FOR}
                        <div className='reset-pass-modal-header-username'>
                            {props.userData.UserName}
                        </div>
                    </React.Fragment>}
                {props.resetPassModalType === resetByEmailType &&
                    STRINGS.PASSWORD_RESET_EMAIL}
            </ModalHeader>
            <ModalBody>
                <Form>
                    {props.resetPassModalType === resetByEmailType &&
                        <FormGroup>
                            <Label>
                                {STRINGS.EMAIL}
                            </Label>
                            <Input
                                type='email'
                                className=''
                                defaultValue={props.userData.UserName}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>}
                    {props.resetPassModalType === resetByAdminType &&
                        <React.Fragment>
                            <FormGroup>
                                <Label>
                                    {STRINGS.NEW_PASSWORD}
                                </Label>
                                <Input
                                    type="password"
                                    onChange={(e) => setNewPass(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    {STRINGS.CONFIRM_PASSWORD}
                                </Label>
                                <Input
                                    type="password"
                                    onChange={(e) => setNewConfirmPass(e.target.value)}
                                />
                            </FormGroup>
                        </React.Fragment>}
                    <Button
                        className='reset-pass-submit-btn btnColor2'
                        type='button'
                        onClick={submitOnClick}
                    >
                        {STRINGS.SUBMIT}
                    </Button>
                </Form>
            </ModalBody>
        </div>
    )
}
