import React, { useState } from 'react';
import { Button, Modal } from 'reactstrap';
import './index.css';
import * as STRINGS from '../../strings/english';
import PersonalUserSettingsModal from './personalUserSettingsModal';

function Cell(props) {

    const [personalUserSettingsMode, setPersonalUserSettingsMode] = useState(false)

    const clicked = (integrationName) => {
        if (integrationName === "SalesForce") {
            const rediUrl = window.location.href;
            window.location.replace(`${process.env.REACT_APP_SF_URL}authorize?response_type=code
                        &client_id=${process.env.REACT_APP_CLIENT_ID_SF}&redirect_uri=${rediUrl}`)
        } else {
            props.formCrmModal(true)
        }
    }

    const personalUserSettingsToggle = () => {
        setPersonalUserSettingsMode(!personalUserSettingsMode);
    }

    return (
        <div className="d-flex flex-row cell-content v-center ">
            <div className="p-2 "><img src={props.logoURL} alt="" width="64" /></div>
            <div className="p-2 i-name">{props.integrationName}</div>
            <div className="ml-auto">
                {props.active ? <Button
                    className='crm-integration-cell-personal-user-settings-btn'
                    style={{ backgroundColor: '#ebebeb' }}
                    onClick={personalUserSettingsToggle}
                    color='#3c3f4a'
                >
                    {STRINGS.PERSONAL_USER_SETTINGS}
                </Button> : null}
            </div>
            <div className="crm-integration-cell-login-or-update-btn">
                <Button
                    onClick={() => clicked(props.integrationName)}
                    color={''}
                    style={{ color: 'white' }}
                    className={`${props.active ? 'btnColor3' : 'btnColor2'} mr-5 ml-auto i-button fs-14`}>
                    {!props.active ? `Log in with ${props.integrationName}` : "Update"}
                </Button>
            </div>
            <Modal
                isOpen={personalUserSettingsMode}
                style={{
                    maxHeight: "28.063rem",
                    minWidth: "44.75rem",
                    maxWidth: 'max-content'
                }}
                toggle={personalUserSettingsToggle}
            >
                <PersonalUserSettingsModal
                    cellData={props}
                    personalUserSettingsToggle={personalUserSettingsToggle}
                />
            </Modal>
        </div>
    )
}
export default Cell