import { createStore} from "redux"


let state = {}
// //
const reducer = (state, action) => {
// //     //
// //     //  switch (action.type) {
// //     //      case value:
// //     //          ''
// //     //          return newState;
// //     //
// //     //      default:
// //     //          break;
// //     //  }
    return state
}
// //
//
//
const store = createStore(reducer, state);

export default store;