import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledDropdown,
    ModalFooter
} from 'reactstrap';
import './advance.css';
import '../index.css';
import FeatureService from "../../../services/FeatureService";
import * as STRINGS from '../../../strings/english';
import AudioPlayer from "../../../services/audioPlayer/audioPlayer";
import UploadFile from '../../../services/uploadFiles/AutoAttendantUploadFiles';
import {
    formatNumbers
} from '../../../services/formatPhoneNumber';
import CountrySelect from '../../../services/format-number-input/countrySelect';

function AutoAttendant(props) {

    const [isEnabled, setIsEnabled] = useState(0)
    const [description, setDescription] = useState("");
    const [isLoader, setIsLoader] = useState(false)
    const [edit, setEdit] = useState({ mode: false, place: null })
    const [seconds, setSeconds] = useState(0);
    const [inputTimes, setInputTimes] = useState(0);
    const [divert, setDivert] = useState('')
    const [extNumber, setExtNumber] = useState('');
    const [directtovm, setDirecttovm] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [file, setFile] = useState({});
    const [currentFile, setCurrentFile] = useState();
    const [duration, setDuration] = useState();
    const [uploadingMode, setUploadingMode] = useState(0);
    const [diverts, setDiverts] = useState([]);
    const [didPoolId, setDidPoolId] = useState('');
    const [addDivert, setAddDivert] = useState(false);

    useEffect(() => {
        getFeature();
        getExtensions();
    }, [])

    function getFeature() {
        setIsLoader(true)
        FeatureService.autoAttendantGetFeature(props.PackageRegistrationID)
            .then(res => {
                if (res && res.data) {
                    initialDurationFunc(res.data.AutoAttendantMenuPath);
                    if (res.data.AutoAttendantMenuPath) {
                        setUploadingMode(2);
                    }
                    setIsEnabled(res.data.IsEnabled);
                    setSeconds(res.data.TimeToReplay);
                    setInputTimes(res.data.TimesToPlayAAM);
                    setIsLoader(false);
                }
                initialCurrentFileFunc(res.data.AutoAttendantMenuPath);
            })
            .catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    }

    const initialCurrentFileFunc = (initialCurrentFile) => {
        if (initialCurrentFile) {
            let name = initialCurrentFile.split("/")
            setCurrentFile({
                fileName: name[name.length - 1],
                url: initialCurrentFile
            })
        } else return null
    };

    const initialDurationFunc = (initialDuration) => {
        if (initialDuration) {
            let audio = new Audio(initialDuration)
            audio.onloadedmetadata = () => {
                setDuration(time_convert(audio.duration));
            }
        } else return null
    };

    function getExtensions() {
        let body = {
            "packageregistrationid": props.PackageRegistrationID
        }
        FeatureService.autoAttendantGetExtensions(body).then(res => {
            if (res.status === 200) {
                setDiverts(res.data);
                setIsLoader(false);
            }
        })
    }

    const saveChanges = () => {
        setIsLoader(true);
        let body = {
            "IsEnabled": isEnabled,
            "TimeToReplay": seconds,
            "TimesToPlayAAM": inputTimes
        }
        FeatureService.autoAttendantSetExtensions(body, props.PackageRegistrationID)
            .then((res) => {
                if (res) {
                    setIsLoader(false);
                    getFeature();
                    closeAddDivert(false);
                    props.toggel();
                }
            })
            .catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
        save(false);
    }

    const save = (showAlert) => {
        if (extNumber && divert && description) {
            setIsLoader(true);
            let body = {
                packageregistrationid: props.PackageRegistrationID,
                extnumber: extNumber,
                divert: divert,
                desc: description,
                directtovm: directtovm,
                active: edit.mode ? diverts[edit.place].Active : 1
            }
            if (edit.mode) {
                body['didPoolId'] = didPoolId;
                body['currentextnumber'] = extNumber;
            }
            FeatureService[edit.mode ? 'autoAttendantUpdateExtension' : 'autoAttendantInsertExtension'](body)
                .then((res) => {
                    if (res) {
                        setIsLoader(false);
                        closeAddDivert(false);
                        getExtensions();
                    }
                })
                .catch(error => {
                    setIsLoader(false);
                    alert(error.response
                        ? error.response.data.Description || error.response.data.Message : error)
                });
        }
        else if (showAlert) alert(STRINGS.INPUT_MUST_BE_FILLED_OUT);
    };


    const disabledOrEnabledExtension = (index) => {
        let currentExtension = diverts[index];
        setIsLoader(true);
        let body = {
            packageregistrationid: props.PackageRegistrationID,
            extnumber: currentExtension.Number,
            divert: currentExtension.Divert,
            desc: currentExtension.Description,
            directtovm: currentExtension.DirectToVM,
            active: diverts[index].Active === 0 ? 1 : 0,
            didPoolId: currentExtension.DidPoolId,
            currentextnumber: currentExtension.Number
        }
        FeatureService.autoAttendantUpdateExtension(body)
            .then(response => {
                if (response) {
                    getExtensions();
                }
            })
            .catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error)
            });
    };


    function closeAddDivert(divertMode) {
        setAddDivert(divertMode);
        setDidPoolId('');
        setDescription('');
        setDivert('');
        setExtNumber('');
        setDirecttovm(0);
        setEdit({ mode: false, place: null });
    }

    function editDivert(index) {
        setAddDivert(true);
        setEdit({ mode: true, place: index });
        let currentExtension = diverts[index];
        setDidPoolId(currentExtension.DidPoolId);
        setDescription(currentExtension.Description);
        setDivert(currentExtension.Divert);
        setExtNumber(currentExtension.Number);
        setDirecttovm(currentExtension.DirectToVM);
    }

    const renderTableBody = () => {
        let options = [];
        if (diverts && diverts.length !== 0) {
            diverts.forEach((divert, i) => {
                options.push(<tr
                    key={`listRow${i}`}
                    className={`tr ${divert.Active === 0 ? 'tr-disabled' : 'tr-enabled'}`}
                    style={edit.mode && edit.place === i ? { border: '2px solid #2EC2FE' } : {}}
                >
                    <td id='aa-td-1' className='h th-3-points'>{divert.Number}</td>
                    <td id='aa-td-2' className='h th-3-points'>{formatNumbers(divert.Divert)}</td>
                    <td id='aa-td-3' className='h th-3-points'>{divert.Description}</td>
                    <td className='disabled-row th-3-points' style={{ width: '26%', paddingLeft: '1rem' }}>
                        {divert.DirectToVM ?
                            <img src='assets/v-icon.png' alt="" className="m-2" /> : <span className='m-2'>{'x'}</span>}
                    </td>
                    <td>
                        <UncontrolledDropdown direction="down" style={{ lineHeight: '2rem' }}>
                            <DropdownToggle color={'white'} >{''}
                                <img src='assets/combined-shape-copy-3.svg' alt="" className=" m-2" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    className=""
                                    onClick={() => editDivert(i)}>
                                    <img src='assets/edit-2-outline-copy.svg' alt="" className="" />
                                    {STRINGS.EDIT}
                                </DropdownItem>
                                <DropdownItem
                                    className=""
                                    onClick={() => disabledOrEnabledExtension(i)}>
                                    <img src='assets/icon-trash-copy-9.svg' alt="" className="" />
                                    {diverts[i].Active === 1 ? STRINGS.DISABLED : STRINGS.ENABLED}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td>
                </tr>
                )
            })
        }
        return options;
    };

    function divertsTable() {
        return (
            <div>
                <table className="table">
                    <thead className="fs-12 gray thead">
                        <tr className="trh" >
                            <th id='aa-th-1'>{STRINGS.EXT_NUMBER}</th>
                            <th id='aa-th-2'>{STRINGS.DIVERTED_TO}</th>
                            <th id='aa-th-3'>{STRINGS.DESCRIPTION}</th>
                            <th style={{ width: '26%' }}>{STRINGS.DIRECT_TO_VOICE_MAIl}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: "300px", minHeight: '108px' }} className="tbody aaBody">
                        {renderTableBody()}
                    </tbody>
                </table>
            </div>
        );
    }

    const handleUploadFile = (file) => {
        if (file.selectedFile) {
            setUploadingMode(1)
            let data = new FormData()
            data.append('vm', file.selectedFile)
            data.append('did', props.did)
            data.append('packageregistrationid', props.PackageRegistrationID)
            let config = {
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setPercentage(percentCompleted)
                }
            };
            FeatureService.autoAttendantUpload(data, config.onUploadProgress)
                .then(res => {
                    if (res) {
                        setUploadingMode(2)
                        let currentFile = {
                            fileName: file.fileName,
                            url: res.data.URL,
                            work: false
                        }
                        setCurrentFile(currentFile);
                        props.refresh();
                    }
                })
                .catch(error => {
                    alert(error.response
                        ? error.response.data.Description || error.response.data.Message : error)
                });
        }
    };

    function uploading() {
        return <Row className="fs-10 border-5" style={{ marginTop: '1rem' }}>
            <Col md={2} className="v-center text-left pl-0"><img src="assets/voicemail-3.svg" alt="" /></Col>
            <Col md={9} className="pl-2 pr-0">
                <Row >
                    <Col md={6} className="pl-0 text-left">
                        {uploadingMode > 0 && typeof file.fileName == 'string' ? file.fileName : null}
                    </Col>
                </Row>
                <Row ><div className="progress"><div style={{ width: `${percentage}%` }} /></div></Row>
                <Row >
                    <Col md={6} className="pl-0 text-left fc-grey">{``}</Col>
                    <Col md={6} className="pr-0 text-right fc-grey">{`${percentage > 0 ? percentage + " %" : " "}`}</Col>
                </Row>
            </Col>
        </Row>
    }

    function player() {
        return <Row style={{ marginTop: '0.625rem' }}>
            {currentFile ?
                <React.Fragment>
                    <div style={{ width: '100%' }}>
                        <div className='fs-10 fc-grey v-center box-yellow'
                            style={{ backgroundColor: "#fffbea", height: "40px", width: '100%' }}>
                            {STRINGS.REPLACE_THE_AUTO_ATTENDANT_MENU}
                        </div>
                    </div>

                    <FormGroup style={{ width: '100%', marginTop: '0.625rem' }}>
                        <div className='d-flex fs-10 v-center border-grey ' style={{ height: "40px", overflow: "hidden" }}>
                            <div className="pl-2">
                                <AudioPlayer
                                    style={{ height: "24px", width: "24px" }}
                                    id={currentFile.url}
                                    src={currentFile.url}
                                    setPlaying={() => setPlaying(!playing)}
                                    playing={{ id: currentFile.url, work: playing, audio: false, src: currentFile.url }}
                                    duration={(e) => setDuration(e)}
                                />
                            </div>
                            <div style={{ height: "inherit", width: "35px" }}><img src="assets/voicemail-3.svg" alt="" /></div>
                            <div>{currentFile.fileName}</div>
                            <div className="ml-auto p-2 fc-grey">{`${duration ? duration : "--:--"}`}</div>
                        </div>
                    </FormGroup>
                </React.Fragment>
                : null}
        </Row>
    }

    function time_convert(number) {
        if (number) {
            let num = Math.floor(number)
            const hours = Math.floor(num / 60);
            const minutes = num % 60;
            return `${hours < 10 ? 0 : ""}${hours}:${minutes < 10 ? 0 : ""}${minutes}`;
        } else {
            return null
        }
    }

    const callBack = (number) => {
        setDivert(number)
    }

    return <span>
        <ModalHeader
            style={{
                display: "flow-root",
                height: "90px"
            }}
        >
            <div className="d-flex justify-content-between fs-22" style={{ padding: "1.5rem  1rem" }}>
                <div>{STRINGS.AUTO_ATTENDANT_OPTIONS}</div>
                <div className='custom-control custom-switch isolate-the-design'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={`customSwitches2-`}
                        checked={isEnabled}
                        onChange={(e) => { setIsEnabled(e.target.checked) }}
                    />
                    <label className='custom-control-label' htmlFor={`customSwitches2-`} />
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
            <Form style={{ padding: "1.5rem  1rem" }}>

                <FormGroup className="background-lightGrey">
                    <Row>
                        <FormGroup className='d-flex' >
                            <div className='fs-12' style={{ marginRight: '3.938rem', marginTop: '0.5rem', color: '#3c3f4a' }}>
                                {STRINGS.PLAY_THE_AUTO_ATTENDANT_MENU_AFTER}
                            </div>
                            <Input
                                type="number"
                                name="seconds-input"
                                id="seconds-input"
                                style={{ width: '11.188rem' }}
                                value={seconds}
                                onChange={(e) => setSeconds(e.target.value)}
                            />
                            <div className='fs-12' style={{ marginLeft: '0.938rem', marginTop: '0.5rem', color: '#9199ab' }}>
                                {STRINGS.SECOND}
                            </div>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className='d-flex' >
                            <div className='fs-12' style={{ marginRight: '2.9rem', color: '#3c3f4a' }}>
                                {"When caller doesn't choose extension,"} <br /> {"replay the auto attendant menu"}
                            </div>
                            <Input
                                type="number"
                                name="times-input"
                                id="times-input"
                                style={{ width: '11.188rem' }}
                                value={inputTimes}
                                onChange={(e) => setInputTimes(e.target.value)}
                            />
                            <div className='fs-12' style={{ marginLeft: '0.938rem', marginTop: '0.5rem', color: '#9199ab' }}>
                                {STRINGS.TIMES}
                            </div>
                        </FormGroup>
                    </Row>
                    <React.Fragment>
                        <UploadFile
                            file={
                                file => {
                                    handleUploadFile(file)
                                    setFile(file)
                                }}
                            did={props.did}
                            duration={(e) => setDuration(time_convert(e))}
                            packageregistrationid={props.PackageRegistrationID} />
                        <div>{uploadingMode === 1 ? uploading() : player()}</div>
                    </React.Fragment>
                    {addDivert && <React.Fragment>
                        <FormGroup className="background-lightGrey">
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="Ext.Number" className="fs-12">{STRINGS.EXT_NUMBER}</Label>
                                        <Input
                                            disabled={edit.mode ? true : false}
                                            type='number'
                                            id="Ext.Number"
                                            value={extNumber}
                                            onChange={(e) => setExtNumber(e.target.value)}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="Description" className="fs-12">{STRINGS.DESCRIPTION}</Label>
                                        <Input
                                            type='text'
                                            id="Description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="DivertedTo" className="fs-12">{STRINGS.DIVERTED_TO}</Label>
                                        {/* <Input
                                            id="DivertedTo"
                                            type="text"
                                            onChange={(e) => setDivert(e.target.value)}
                                            value={formatInputValue(divert)}>
                                        </Input> */}
                                        <CountrySelect
                                            numberToUpdate={edit.mode ? divert : null}
                                            getNumberToUpdate={callBack}
                                        />
                                    </FormGroup>
                                    <Label check style={{ marginLeft: '1.4rem' }}>
                                        <Input
                                            type='checkbox'
                                            checked={directtovm}
                                            onChange={(e) => setDirecttovm(e.target.checked ? 1 : 0)}
                                        />
                                        {STRINGS.DIRECT_TO_VOICE_MAIl}
                                    </Label>
                                </Col>
                            </Row>

                        </FormGroup>
                        <Row>
                            <Col className="d-flex justify-content-end fs-14">
                                <div className="mr-2 pointer" onClick={() => closeAddDivert(false)}>{STRINGS.CANCEL}</div>
                                <div className="mr-0 light-blue pointer"
                                    onClick={() => save(true)}> {edit.mode ? STRINGS.UPDATE : STRINGS.SAVE}</div>
                            </Col>
                        </Row>
                    </React.Fragment>}
                </FormGroup>



                <FormGroup>
                    <div
                        className="d-flex justify-content-start"
                        style={{ width: "fit-content", cursor: "pointer" }}
                        onClick={() => closeAddDivert(true)}>
                        <img className="ml-0" src="assets/plus-circle-btn.svg" alt="" />
                        <div className="ml-2 light-blue fs-14">{STRINGS.ADD_EXTENSION}</div>
                    </div>
                </FormGroup>
                {isLoader ?
                    <img style={{ width: '100px', height: 'auto', right: '45%', zIndex: '100' }} src='assets/spikkoloaderanimation.svg' alt="" className="position-absolute" />
                    : null}

                <FormGroup style={{ height: "20%" }}>
                    {diverts && diverts.length > 0 ? divertsTable() : null}
                </FormGroup>

            </Form>
        </ModalBody>
        <ModalFooter style={{ display: "block" }}>
            <FormGroup row className="justify-content-end">
                <Col md={4}><Button className="btnColor5 fontColor fs-14 no-border" style={{ width: "100%" }}
                    size="lg"
                    onClick={() => props.toggel()}>{STRINGS.CANCEL}</Button></Col>
                <Col md={4}><Button className="btnColor2 white fs-14 no-border btnColor2" style={{ width: "100%" }}
                    size="lg"
                    onClick={() => {
                        saveChanges()
                    }}>{STRINGS.SAVE_CHANGES}</Button></Col>
            </FormGroup>
        </ModalFooter>
    </span>
}

export default AutoAttendant