import http from "../axios";


class FeatureService {

    headers() {
        return {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('auth-data'),

            }

        }
    }

    upload(file, onUploadProgress) {
        return http.post("Feature/SetPersonalGreeting", file, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            },
            onUploadProgress
        });
    }

    autoAttendantUpload(file, onUploadProgress) {
        return http.post("Feature/SetAutoAttendantMenu", file, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + localStorage.getItem('auth-data')
            },
            onUploadProgress
        });
    }

    autoAttendantGetFeature(id) {
        return http.get(`Feature/GetFeature/${id}/Extensions`, this.headers());
    }

    enableVMG(status, id) {
        return http.get(`Feature/EnableVMG/${status}/${id}`, this.headers());
    };

    setVoiceMailOptions(data) {
        return http.post("Feature/SetVoiceMailOptions", data, this.headers());
    };

    setRecordingFeatureOptions(data, id) {
        return http.post(`Feature/SetFeatureOptions/${id}/Recording`, data, this.headers());
    };

    getSystemTimeZones() {
        return http.get(`Feature/GetSystemTimeZones`, this.headers());
    }

    getFeature(id) {
        return http.get(`Feature/GetFeature/${id}/TimedForwarding`, this.headers());
    }

    setTimedForwardingFeatureOptions(data, id) {
        return http.post(`Feature/SetFeatureOptions/${id}/TimedForwarding`, data, this.headers());
    }

    insertTimedForwardingDivert(data) {
        return http.post(`Feature/InsertTimedForwardingDivert`, data, this.headers());
    }

    updateTimedForwardingDivert(data) {
        return http.post(`Feature/UpdateTimedForwardingDivert`, data, this.headers());
    }

    removeTimedForwardingDivert(data) {
        return http.post(`Feature/RemoveTimedForwardingDivert`, data, this.headers());
    }
    
    autoAttendantInsertExtension(data) {
        return http.post(`Feature/InsertExtension`, data, this.headers());
    }

    autoAttendantUpdateExtension(data) {
        return http.post(`Feature/UpdateExtension`, data, this.headers());
    }

    autoAttendantGetExtensions(data) {
        return http.post(`Feature/GetExtensions`, data, this.headers() )
    }

    autoAttendantSetExtensions(data, id) {                
        return http.post(`Feature/SetFeatureOptions/${id}/Extensions`, data, this.headers());
    }

    groupRingingGetAllDiverts(data) {
        return http.post(`Feature/GetGroupCallingDiverts`, data, this.headers());
    }

    groupRingingInsertDivert(data) {
        return http.post(`Feature/InsertGroupCallingDivert`, data, this.headers());
    }

    groupRingingUpdateDivert(data) {
        return http.post(`Feature/UpdateGroupCallingDivert`, data, this.headers());
    }

    groupRingingDeleteDivert(data) {
        return http.post(`Feature/RemoveGroupCallingDivert`, data, this.headers());
    }

    groupRingingEnableGroupCalling(bool, id) {
        return http.get(`Feature/EnableGroupCalling/${bool}/${id}`, this.headers());
    }

    sequentialRingingGetSequentialRingingDiverts(data) {
        return http.post(`Feature/GetSequentialRingingDiverts`, data, this.headers());
    }

    sequentialRingingInsertSequentialRingingDivert(data) {
        return http.post(`Feature/InsertSequentialRingingDivert`, data, this.headers());
    }

    sequentialRingingUpdateSequentialRinging(id, data) {
        return http.post(`Feature/UpdateSequentialRinging/${id}`, data, this.headers());
    }

    sequentialRingingEnableSequentialRinging(bool, id) {
        return http.get(`Feature/EnableSequentialRinging/${bool}/${id}`, this.headers());
    }

    callerIdsGetAllowedCLIs(id) {
        return http.get(`Account/GetAllowedCLIs/${id}`, this.headers());
    }

    callerIdsInsertAllowedCLI(data) {
        return http.post(`Account/InsertAllowedCLI`, data, this.headers());
    }
    
    callerIdsRemoveAllowedCLI(data) {
        return http.post(`Account/RemoveAllowedCLI`, data, this.headers());
    }

    getManagedLoginGroups() {
        return http.get(`Feature/GetManagedLoginGroups`, this.headers());
    }
}

export default new FeatureService();




